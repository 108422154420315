import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import PageLoader from "../../common/loader";
import { API, graphqlOperation } from "aws-amplify";
import {
  createCFRRole,
  updateCFRRole,
  createCFRRoleEntity,
  deleteCFRRoleEntity,
} from "../../graphql/mutations";
import {
  listCFRRoles,
  listCFREntities,
  listCFREntityOperations,
  listCFREmailTemplates,
  sendEmail,
} from "../../graphql/queries";
import MaterialTable from "material-table";
import { getLoggedInUser } from "../../Utils/common";
import { AppConstants } from "../../common/constants";

export default function RoleAddEdit(props) {
  const [rolePrivilegeData, setrolePrivilegeData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsToBeSelected, setrowsToBeSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState("Create");
  const alert = useAlert();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  let id;
  let isAddMode = true;
  let privilegeData = [];
  if (props.location.state != null) {
    id = props.location.state.id;
    isAddMode = !id;
  }

  /// this function for Create and update cfr record
  async function onSubmit(data) {
    // console.log(data);
    // console.log(selectedRows);
    const errorLocation =
      "Path Name: components/role/roleAddEdit.js\n Function Name: onSubmit";
    try {
      setIsLoading(true);
      if (isAddMode) {
        console.log("add");

        let searchParameter = { name: { eq: data.name.trim() } };
        const resp = await API.graphql(
          graphqlOperation(listCFRRoles, { filter: searchParameter })
        );
        if (
          resp != null &&
          resp.data.listCFRRoles != null &&
          resp.data.listCFRRoles.items.length > 0
        ) {
          alert.show("The role already present.");
          setIsLoading(false);
          return;
        }

        const newData = {
          ...data,
          name: data.name.trim(),
        };

        const result = await API.graphql(
          graphqlOperation(createCFRRole, { input: newData })
        );
        setIsLoading(false);
        if (result.data) {
          selectedRows.forEach(async (row) => {
            if (row.type == "Operation") {
              const roleEntityData = {
                cFRRoleCfrRoleEntitiesId: result.data.createCFRRole.id,
                cFRRoleEntityEntityId: row.entityId,
                cFRRoleEntityEntityOperationId: row.id,
              };
              const roleEntityResult = await API.graphql(
                graphqlOperation(createCFRRoleEntity, {
                  input: roleEntityData,
                })
              );
            }
          });

          const successMessage = "Role created successfully";
          alert.show(successMessage, {
            type: "success",
            onClose: () => {
              props.history.push("/roles");
            }, // callback that
          });
        } else if (result.errors) {
          if (result.errors.length > 0) {
            alert.show(result.errors[0].message);
          }
        }
      } else {
        console.log("update");
        const result = await API.graphql(
          graphqlOperation(updateCFRRole, { input: data })
        );
        setIsLoading(false);
        if (result.data) {
          result.data.updateCFRRole.cfrRoleEntities.items.forEach(
            async (oldprivilege) => {
              const roleEntityData = {
                id: oldprivilege.id,
              };
              const roleEntityResult = await API.graphql(
                graphqlOperation(deleteCFRRoleEntity, {
                  input: roleEntityData,
                })
              );
            }
          );
          selectedRows.forEach(async (row) => {
            if (row.type == "Operation") {
              const roleEntityData = {
                cFRRoleCfrRoleEntitiesId: result.data.updateCFRRole.id,
                cFRRoleEntityEntityId: row.entityId,
                cFRRoleEntityEntityOperationId: row.id,
              };
              const roleEntityResult = await API.graphql(
                graphqlOperation(createCFRRoleEntity, {
                  input: roleEntityData,
                })
              );
            }
            // else if (row.type == "Entity") {
            //   roleEntityData = {
            //     cFRRoleCfrRoleEntitiesId: result.data.createCFRRole.id,
            //     cFRRoleEntityEntityId: row.id,
            //     cFRRoleEntityEntityOperationId: row.id,
            //   };
            // }
          });

          const successMessage = "Role updated successfully";
          alert.show(successMessage, {
            type: "success",
            onClose: () => {
              props.history.push("/roles");
            }, // callback that
          });
        } else if (result.errors) {
          if (result.errors.length > 0) {
            alert.show(result.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  }

  // this function for get entity data
  const getEntityList = async function () {
    const errorLocation =
      "Path Name: components/role/roleAddEdit.js\n Function Name: getEntityList";
    try {
      setIsLoading(true);
      const response = await API.graphql(graphqlOperation(listCFREntities));
      if (response) {
        const entityData = response.data.listCFREntities.items;
        entityData.forEach((entity) => {
          entity.entityId = null;
          entity.type = "Entity";
          privilegeData.push(entity);
        });
        setIsLoading(false);
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  };

  // this function for get operations data
  const getOperationsList = async function () {
    const errorLocation =
      "Path Name: components/role/roleAddEdit.js\n Function Name: getOperationsList";
    try {
      setIsLoading(true);
      const response = await API.graphql(
        graphqlOperation(listCFREntityOperations)
      );
      if (response) {
        const entityOperations = response.data.listCFREntityOperations.items;
        entityOperations.forEach((operation) => {
          operation.entityId = operation.entity.id;
          operation.type = "Operation";
          privilegeData.push(operation);
        });
        setIsLoading(false);
        setrolePrivilegeData(privilegeData);
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  };

  useEffect(async () => {
    setrolePrivilegeData([]);
    await getEntityList();
    await getOperationsList();
    if (props.location.state != null) {
      if (!isAddMode) {
        setPageTitle("Update");
        const cfrobj = props.location.state;
        const fields = ["id", "name", "details"];
        fields.forEach((field) => {
          setValue(field, cfrobj[field]);
        });
        let existingPrivileges = [];
        if (cfrobj.cfrRoleEntities) {
          if (cfrobj.cfrRoleEntities.items.length > 0) {
            cfrobj.cfrRoleEntities.items.forEach((privilege) => {
              if (privilege.entityOperation) {
                let temp = {};
                temp.id = privilege.entityOperation.id;
                temp.operationName = privilege.entityOperation.name;
                temp.entityName = privilege.entity.name;
                existingPrivileges.push(temp);
              }
            });
          }
        }
        setrowsToBeSelected(existingPrivileges);
      }
    }
  }, []);

  function handleSelectChange(rows) {
    setrowsToBeSelected([]);
    let existingPrivileges = [];
    rows.forEach((privilege) => {
      if (privilege.type == "Operation") {
        let temp = {};
        temp.id = privilege.id;
        // temp.operationName = privilege.entityOperation.name;
        // temp.entityName = privilege.entity.name;
        existingPrivileges.push(temp);
      }
    });
    setrowsToBeSelected(existingPrivileges);
    setSelectedRows(rows);
  }

  return (
    <div className="page-container">
      {isLoading ? <PageLoader></PageLoader> : ""}
      <div className="page-header">
        <h4> {pageTitle} Role </h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-4  mb-3">
                <label className="form-label">
                  Name <span> * </span>
                </label>
                <input
                  type="text"
                  id="txtName"
                  name="name"
                  readOnly={id}
                  className="form-control"
                  {...register("name", {
                    required: true,
                    minLength: 1,
                    maxLength: 100,
                  })}
                />
                {errors.name && errors.name.type === "required" && (
                  <div className="error-msg">Name is required.</div>
                )}
                {errors.name && errors.name.type === "minLength" && (
                  <div className="error-msg">
                    Name should contain atleast 1 characters.
                  </div>
                )}
                {errors.name && errors.name.type === "maxLength" && (
                  <div className="error-msg">
                    Name should not be greter than 100 characters.
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4  mb-3">
                <label className="form-label">Description</label>
                <textarea
                  id="txtDetails"
                  name="details"
                  className="form-control"
                  {...register("details")}
                  rows="3"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="d-none d-sm-block">
          <MaterialTable
            //data={rolePrivilegeData}
            data={rolePrivilegeData.map((row) =>
              rowsToBeSelected.find((selected) => selected.id === row.id)
                ? { ...row, tableData: { checked: true } }
                : row
            )}
            columns={[{ title: "Name", field: "name" }]}
            parentChildData={(row, rows) =>
              rows.find((a) => a.id === row.entityId)
            }
            options={{
              toolbar: false,
              selection: true,
              showTitle: false,
              showTextRowsSelected: false,
              showSelectAllCheckbox: true,
              search: false,
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: "#1468b2",
                color: "#FFF",
                fontWeight: "600",
              },
              selectionProps: (rowData) => ({
                // rowData.tableData.checked = rowData.name === "View",
                //checked: rowData.name === "View",
                color: "primary",
              }),
              // rowStyle: (rowData) => ({
              //   backgroundColor:
              //     selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
              // }),
            }}
            // onRowClick={(evt, selectedRow) =>
            //   setSelectedRows(selectedRow.tableData.id)
            // }
            onSelectionChange={(rows) => handleSelectChange(rows)}
            // onSelectionChange={(rows, selectedRow, evt) => {
            //   debugger;
            //   //selectedRow.tableData.checked = false;
            //   // selectedRow.tableData.checked =
            //   //   !selectedRow.tableData.checked;
            //   setSelectedRows(rows);
            // }}
          />
        </div>
        <div className="form-footer">
          <div className="row">
            <div className="col-sm-12">
              <button type="submit" className="btn btn-sm btn-primary">
                Save
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary ml-10"
                onClick={() => props.history.goBack()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
