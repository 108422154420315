import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import PageLoader from "../../common/loader";
import RoleList from "./roleList";
import MobileRoleList from "./mroleList";
import { API, graphqlOperation } from "aws-amplify";
import { listCFRRoles , listCFRUsers } from "../../graphql/customQueries";
import { deleteCFRRole } from "../../graphql/mutations";
import { isMobile } from "../../Utils/common";
import { getLoggedInUser } from "../../Utils/common";
import { Entity, EntityOperation, AppConstants } from "../../common/constants";
import { listCFREmailTemplates, sendEmail } from "../../graphql/queries";

function Role(props) {
  const alert = useAlert();
  const [deptData, setDeptData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState({});
  const { register, handleSubmit, setValue } = useForm();
  const [loggedinUser, setloggedinUser] = useState(null);
  const [isCreatePermission, setIsCreatePermission] = useState(false);
  const [isUpdatePermission, setIsUpdatePermission] = useState(false);
  const [isDeletePermission, setIsDeletePermission] = useState(false);

  // this function for get customer data
  const getRoleList = useCallback(async () => {
    //let searchParameter = getSearchParameter();
    const errorLocation =
      "Path Name: components/role/role.js\n Function Name: getRoleList";
    try {
      setIsLoading(true);
      const deptData = await API.graphql(graphqlOperation(listCFRRoles));
      if (deptData) {
        const deptList = deptData.data.listCFRRoles.items;
        console.log(deptList);
        setDeptData([]);
        setIsLoading(false);
        //totalItems = parseInt(response.headers.recordcount);
        //setTotalPageCount(totalItems);
        setDeptData(deptList);
      } else {
        if (deptData.errors) {
          if (deptData.errors.length > 0) {
            alert.show(deptData.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  });
  let ShowFilterBox = false;
  // this function for open close filter panel
  const showFilter = () => {
    setFilter(true);
  };
  const hideFilter = () => {
    setFilter(false);
  };

  //this function for filterd the record
  const FilterRecords = (filterData) => {
    setFilterData(filterData);
    setFilter(false);
  };

  // function to reset grid.
  const resetFilters = () => {
    // $scope.pagination.pageNumber = ConstantService.PageNumber;
    const fields = ["Name"];
    setFilterData({});
    setValue("Name", "");
    //getDepartmentList();
    setFilter(false);
  };

  const HandleAddClick = () => {
    props.history.push("/role/add");
  };
  //this function for redirect in view page
  const viewDept = (deptObj, id) => {
    props.history.push({
      pathname: "/role/view/" + id,
      state: deptObj,
    });
  };
  // call user list on page load
  useEffect(() => {
    getRoleList();
    const loggedinUser = getLoggedInUser();
    setloggedinUser(loggedinUser);
    if (loggedinUser) {
      if (loggedinUser.role.cfrRoleEntities) {
        if (loggedinUser.role.cfrRoleEntities.items.length > 0) {
          let configCreateArr = loggedinUser.role.cfrRoleEntities.items.filter(
            (privilege) =>
              privilege.entity.name == Entity.Role &&
              privilege.entityOperation.name == EntityOperation.Create
          );
          if (configCreateArr.length > 0) {
            setIsCreatePermission(true);
          } else {
            setIsCreatePermission(false);
          }
          let configUpdateArr = loggedinUser.role.cfrRoleEntities.items.filter(
            (privilege) =>
              privilege.entity.name == Entity.Role &&
              privilege.entityOperation.name == EntityOperation.Update
          );
          if (configUpdateArr.length > 0) {
            setIsUpdatePermission(true);
          } else {
            setIsUpdatePermission(false);
          }
          let configDeleteArr = loggedinUser.role.cfrRoleEntities.items.filter(
            (privilege) =>
              privilege.entity.name == Entity.Role &&
              privilege.entityOperation.name == EntityOperation.Delete
          );
          if (configDeleteArr.length > 0) {
            setIsDeletePermission(true);
          } else {
            setIsDeletePermission(false);
          }
        }
      }
    }
  }, [filterData]);

  // this function is responsible for open confirmation message box
  const deleteDept = (id) => {
    alert.show("Are you sure, you want to delete a role ?", {
      title: "Confirmation",
      closeCopy: "Cancel",
      actions: [
        {
          copy: "Yes",
          onClick: () => deleteDeptRecord(id),
        },
      ],
    });
  };
  // delete  user recored once click on confirm
  const deleteDeptRecord = async (id) => {
    const errorLocation =
      "Path Name: components/role/role.js\n Function Name: deleteDeptRecord";
    try {
      let searchParameter = {
        isDeleted: { eq: false },
        cFRUserRoleId: { eq: id },
      };
      const res = await API.graphql(
        graphqlOperation(listCFRUsers, { filter: searchParameter })
      );
      if(res.data && res.data?.listCFRUsers?.items.length > 0){
        alert.show("This role has been already assigned to some users. Please make necessary changes before deleting the role.");
      }
      else{
        const deleteObj = {
          id: id,
        };
        setIsLoading(true);
        const deletedDept = await API.graphql(
          graphqlOperation(deleteCFRRole, { input: deleteObj })
        );
        setIsLoading(false);
        if (deletedDept.data) {
          alert.show("Role record is deleted.");
          getRoleList();
        } else {
          if (deletedDept.errors) {
            if (deletedDept.errors.length > 0) {
              alert.show(deletedDept.errors[0].message);
            }
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  };

  // click on update button and redirect to update page
  const editDept = (deptObj, id) => {
    props.history.push({
      pathname: "/role/update/" + id,
      state: deptObj,
    });
  };
  return (
    <div className="page-container">
      {isLoading ? <PageLoader></PageLoader> : ""}
      <div className="page-header">
        <div className="row">
          <div className="col-sm-8 col-8">
            <h4>
              Role List
              {/* <i className="fa fa-filter btn-filter" onClick={showFilter}></i> */}
            </h4>
          </div>
          <div className="col-sm-4 col-4">
            {isCreatePermission == true && (
              <button
                type="button"
                className="btn-grid fa fa-plus"
                title="Add Role"
                onClick={HandleAddClick}
              ></button>
            )}
          </div>
        </div>
      </div>
      <div className="card d-none d-sm-block">
        <div className="card-body">
          <div className="row form-group">
            <div className="col-sm-12">
              <RoleList
                deptData={deptData}
                actionView={viewDept}
                actiondelete={deleteDept}
                actionUpdate={editDept}
                isCreatePermission={isCreatePermission}
                isUpdatePermission={isUpdatePermission}
                isDeletePermission={isDeletePermission}
              ></RoleList>
            </div>
          </div>
        </div>
      </div>

      <MobileRoleList
        deptData={deptData}
        actionView={viewDept}
        actiondelete={deleteDept}
        actionUpdate={editDept}
        isCreatePermission={isCreatePermission}
        isUpdatePermission={isUpdatePermission}
        isDeletePermission={isDeletePermission}
      />
      <form>
        <div
          className={
            isFilter ? "filter-container show-filter" : "filter-container"
          }
        >
          <div className="filter-head">
            <label className="label-heading"> Apply Filter </label>
          </div>
          <div className="filter-body">
            <div className="row mb-3">
              <div className="col-sm-12">
                <label className="form-label"> Name </label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="Name"
                    {...register("Name")}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-action">
            <div
              className="filter-option br-btn"
              onClick={handleSubmit(FilterRecords)}
            >
              <span className="icon-tick"> </span>
            </div>
            <div className="filter-option br-btn" onClick={resetFilters}>
              <span className="icon-refresh"> </span>
            </div>
            <div className="filter-option br-btn" onClick={hideFilter}>
              <span className="icon-cross"> </span>
            </div>
          </div>
        </div>
        <div
          className={isFilter ? "filteroverlay show" : "filteroverlay"}
        ></div>
      </form>
    </div>
  );
}
export default Role;
