import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import PageLoader from "../../common/loader";
import PendingUserList from "./pendingUserList";
import { API, graphqlOperation } from "aws-amplify";
import { isMobile, getLoggedInUser } from "../../Utils/common";
import { Entity, EntityOperation, AppConstants } from "../../common/constants";
import { deleteCFRPendingUser } from "../../graphql/mutations";
import {
  listCFRPendingUsers,
  listCFREmailTemplates,
  sendEmail,
} from "../../graphql/queries";
function PendingUser(props) {
  const alert = useAlert();
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, setValue } = useForm();
  const [loggedinUser, setloggedinUser] = useState(null);
  const [isCreatePermission, setIsCreatePermission] = useState(false);
  const [isUpdatePermission, setIsUpdatePermission] = useState(false);
  const [isDeletePermission, setIsDeletePermission] = useState(false);

  // call user list on page load
  useEffect(() => {
    getUserList();
    const loggedinUser = getLoggedInUser();
    setloggedinUser(loggedinUser);
    if (loggedinUser) {
      if (loggedinUser.role.cfrRoleEntities) {
        if (loggedinUser.role.cfrRoleEntities.items.length > 0) {
          let configCreateArr = loggedinUser.role.cfrRoleEntities.items.filter(
            (privilege) =>
              privilege.entity.name == Entity.User &&
              privilege.entityOperation.name == EntityOperation.Create
          );
          if (configCreateArr.length > 0) {
            setIsCreatePermission(true);
          } else {
            setIsCreatePermission(false);
          }
          let configUpdateArr = loggedinUser.role.cfrRoleEntities.items.filter(
            (privilege) =>
              privilege.entity.name == Entity.User &&
              privilege.entityOperation.name == EntityOperation.Update
          );
          if (configUpdateArr.length > 0) {
            setIsUpdatePermission(true);
          } else {
            setIsUpdatePermission(false);
          }
          let configDeleteArr = loggedinUser.role.cfrRoleEntities.items.filter(
            (privilege) =>
              privilege.entity.name == Entity.User &&
              privilege.entityOperation.name == EntityOperation.Delete
          );
          if (configDeleteArr.length > 0) {
            setIsDeletePermission(true);
          } else {
            setIsDeletePermission(false);
          }
        }
      }
    }
  }, []);

  // this function for get pending user data
  const getUserList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/user/pendingUser.js\n Function Name: getUserList";
    try {
      setIsLoading(true);
      const response = await API.graphql(graphqlOperation(listCFRPendingUsers));
      if (response) {
        const _lstUser = response.data.listCFRPendingUsers.items;
        console.log(_lstUser);
        setIsLoading(false);
        setUserData(_lstUser);
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  });

  //this function for redirect in view page
  const viewUserDetails = (userObj, id) => {
    props.props.history.push({
      pathname: "/user/pendingUserView/" + id,
      state: userObj,
    });
  };

  // this function is responsible for open confirmation message box
  const deleteUserDetails = (id) => {
    alert.show("Are you sure, you want to delete a pending user request?", {
      title: "Confirmation",
      closeCopy: "Cancel",
      actions: [
        {
          copy: "Yes",
          onClick: () => deleteUserRecord(id),
        },
      ],
    });
  };

  // delete  user recored once click on confirm
  const deleteUserRecord = async (id) => {
    const errorLocation =
      "Path Name: components/user/pendingUser.js\n Function Name: deleteUserRecord";
    try {
      const deleteObj = {
        id: id,
      };
      setIsLoading(true);
      const res = await API.graphql(
        graphqlOperation(deleteCFRPendingUser, { input: deleteObj })
      );
      setIsLoading(false);
      if (res.data) {
        alert.show("Pending user request is deleted.");
        getUserList();
      } else {
        if (res.errors) {
          if (res.errors.length > 0) {
            alert.show(res.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.props.history.push("/");
          },
        });
      }
    }
  };

  // click on update button and redirect to update page
  const approveUser = (userObj) => {
    const newUserObj = {
      ...userObj,
      id: null,
    };
    props.props.history.push({
      pathname: "/user/update/",
      state: newUserObj,
    });
  };

  return (
    <div>
      {isLoading ? <PageLoader></PageLoader> : ""}
      <div className="page-header">
        <div className="row">
          <div className="col-sm-8 col-8">
            <h4>
              Pending Request List
              {/* <i className="fa fa-filter btn-filter" onClick={showFilter}></i> */}
            </h4>
          </div>
        </div>
      </div>
      <div className="card d-none d-sm-block">
        <div className="card-body">
          <div className="row form-group">
            <div className="col-sm-12">
              <PendingUserList
                userData={userData}
                actionView={viewUserDetails}
                actiondelete={deleteUserDetails}
                actionUpdate={approveUser}
                isCreatePermission={isCreatePermission}
                isUpdatePermission={isUpdatePermission}
                isDeletePermission={isDeletePermission}
              ></PendingUserList>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PendingUser;
