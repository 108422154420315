import { useForm } from "react-hook-form";
import { useState, useEffect, useCallback } from "react";
import { useAlert } from "react-alert";
import Toggle from "react-toggle";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import "../../assets/stylesheet/react-toggle.css";
import PageLoader from "../../common/loader";
import { API, graphqlOperation } from "aws-amplify";
import { getLoggedInUser } from "../../Utils/common";
import awsconfig from "../../awsconfig.json";
import { listCFRRoles } from "../../graphql/customQueries";
import {
  listCFRDepartments,
  listCFREmailTemplates,
  listCFRConfigs,
  listCFRPendingUsers,
  listAzureUsers,
  listCFRUserNotifications,
  sendEmail,
} from "../../graphql/queries";
import { listCFRUsers } from "../../graphql/customQueries";
import {
  createCFRUser,
  updateCFRUser,
  deleteCFRPendingUser,
  updateCFRDepartment,
  createCFRUserDepartmentMapping,
  deleteCFRUserDepartmentMapping,
  createCFRUserNotifications,
  updateCFRUserNotifications
} from "../../graphql/mutations";
import {
  Roles,
  Entity,
  EntityOperation,
  AppConstants,
} from "../../common/constants";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
export default function UserAddEdit(props) {
  const [roles, setRoles] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [lstAzureUsers, setLstAzureUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [showApprovalLimit, setShowApprovalLimit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState("Create");
  const [env, setEnv] = useState("");
  const alert = useAlert();
  const [selectedRole, setSelectedRole] = useState({});
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [showGlobalUserSwitch, setShowGlobalUserSwitch] = useState(false);
  const [isGlobalUser, setIsGlobalUser] = useState(false);
  const [showBudgetedSwitch, setShowBudgetedSwitch] = useState(false);
  const [showUnBudgetedSwitch, setShowUnBudgetedSwitch] = useState(false);
  const [canApproveBudgetedCFR, setCanApproveBudgetedCFR] = useState(false);
  const [canApproveUnBudgetedCFR, setCanApproveUnBudgetedCFR] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  let id;
  let isAddMode = true;
  let isPendingUser = false;
  //find if add / update user
  if (props.location.state != null) {
    if (props.location.state.id !== null) {
      id = props.location.state.id;
      isAddMode = false;
    } else {
      isPendingUser = true;
      isAddMode = false;
    }
  }

  // This function is called on load
  useEffect(async () => {
    setEnvironment();
    await getDepartmentList();
    getConfigList();

    if (props.location.state != null) {
      if (!isAddMode) {
        setPageTitle("Update");
        if (!isPendingUser) {
          const userobj = props.location.state;
          const fields = [
            "id",
            "email",
            "name",
            "cFRUserRoleId",
            "headoffunction",
            "departmentadmin",
            "limit",
            "toLimit",
            "currencySymbol",
          ];
          fields.forEach((field) => {
            if (field == "cFRUserRoleId") {
              setValue("cFRUserRoleId", userobj.role?.id);
              setSelectedRoleId(userobj.role?.id);
            } else if (field == "headoffunction") {
              setValue(
                "headoffunction",
                userobj.headoffunction == true ? 1 : 0
              );
            } else if (field == "departmentadmin") {
              setValue(
                "departmentadmin",
                userobj.departmentadmin == true ? 1 : 0
              );
            } else {
              setValue(field, userobj[field]);
            }
          });
          if (
            userobj.role?.name === Roles.SUPER_ADMIN ||
            userobj.role?.name === Roles.CFR_APPROVER
          ) {
            if (userobj.isGlobalUser) {
              setShowApprovalLimit(false);
            } else {
              setShowApprovalLimit(true);
            }
          } else {
            setShowApprovalLimit(false);
          }
        } else {
          const userobj = props.location.state;
          console.log(userobj.email);
          setValue("name", userobj.name);
          setValue("email", userobj.email);
        }
      }
    }
  }, []);

  useEffect(async () => {
    await getRoleList();
  }, [departments]);

  // This function is called on change in departmentOptions
  useEffect(async () => {
    if (departmentOptions.length > 0) {
      if (props.location.state != null) {
        if (!isAddMode) {
          const userobj = props.location.state;
          let apiDepts = [];
          if (userobj.department) {
            if (userobj.department.items.length > 0) {
              userobj.department.items.forEach((selectedDept) => {
                departmentOptions.forEach((dropdownDept) => {
                  if (selectedDept.cFRDepartmentID == dropdownDept.value) {
                    apiDepts.push(dropdownDept);
                  }
                });
              });
              setSelectedDepartments(apiDepts);
            }
          }
        }
      }
    }
  }, [departmentOptions]);

  // this function for get role data
  const getRoleList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/user/userAddEdit.js\n Function Name: getRoleList";
    try {
      setIsLoading(true);
      const response = await API.graphql(graphqlOperation(listCFRRoles));
      if (response) {
        const _lstRole = response.data.listCFRRoles.items;
        console.log(_lstRole);
        setIsLoading(false);
        setRoles(_lstRole);

        if (!isAddMode && !isPendingUser) {
          const userobj = props.location.state;
          setShowGlobalUserSwitch(false);
          setShowBudgetedSwitch(false);
          setShowUnBudgetedSwitch(false);
          let role = _lstRole.filter((x) => x.id == userobj.role?.id)?.[0];
          setSelectedRole(role);
          if (role != null) {
            if (role?.cfrRoleEntities?.items?.length > 0) {
              let cfrRoleEntitylst = role.cfrRoleEntities.items.filter(
                (x) => x.entity?.name == Entity.CFR
              );
              if (cfrRoleEntitylst.length > 0) {
                let approvelst = cfrRoleEntitylst.filter(
                  (x) => x.entityOperation?.name == EntityOperation.Approve
                );
                if (approvelst.length > 0) {
                  setShowBudgetedSwitch(true);
                  setShowUnBudgetedSwitch(true);
                  setShowGlobalUserSwitch(true);
                  setCanApproveBudgetedCFR(userobj.canApproveBudgetedCFR);
                  setCanApproveUnBudgetedCFR(userobj.canApproveUnBudgetedCFR);
                  let userDepartments = [];
                  if (departments.length > 0) {
                    departments.forEach((dept) => {
                      userobj?.department?.items.forEach((userdept) => {
                        if (userdept.cFRDepartmentID == dept.id) {
                          userDepartments.push(dept);
                        }
                      });
                    });
                  }
                  if (userDepartments.length > 0) {
                    const isForGlobalUser = userDepartments.filter((dept) => {
                      return dept.isForGlobalUser === true;
                    });
                    if (isForGlobalUser.length > 0) {
                      setIsGlobalUser(userobj.isGlobalUser);
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
            const loggedInUser = getLoggedInUser();
            let filter = { action: { eq: "ERROR_MESSAGE" } };
            const emailTemplates = await API.graphql(
              graphqlOperation(listCFREmailTemplates, { filter: filter })
            );
            const template = emailTemplates.data.listCFREmailTemplates.items[0];
            let body = template.emailcontent;
            let replaceName = body.replace(
              "{Display Name}",
              AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
            );
            let replaceUser = replaceName.replace(
              "{Logged User}",
              loggedInUser.name
            );

            let replaceError = replaceUser.replace(
              "{Error}",
              `Error: ${response.errors[0].message} at ${errorLocation}`
            );
            const emailTemplate = {
              To: AppConstants.ERROR_MAIL_RECEIVER,
              Subject: template.emailsubject,
              MailBody: replaceError,
            };

            const res = await API.graphql({
              query: sendEmail,
              variables: {
                EmailTemplate: JSON.stringify(emailTemplate),
              },
            });

            console.log(res);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  });

  // this function for get department data
  const getDepartmentList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/user/userAddEdit.js\n Function Name: getDepartmentList";
    try {
      setIsLoading(true);
      let searchParameter = { isdeleted: { eq: false } };
      const response = await API.graphql(
        graphqlOperation(listCFRDepartments, { filter: searchParameter })
      );
      setIsLoading(false);
      if (response) {
        const _lstDepartment = response.data.listCFRDepartments.items;
        console.log(_lstDepartment);
        var dropdownData = [];
        _lstDepartment.forEach((dept) => {
          let data = {};
          data.label = dept.name;
          data.value = dept.id;
          dropdownData.push(data);
        });
        setDepartmentOptions(dropdownData);
        const loggedInUser = getLoggedInUser();
        if (loggedInUser) {
          if (loggedInUser.role) {
            if (loggedInUser.role.name == Roles.DEPT_ADMIN) {
              let deptLst = [];
              _lstDepartment.forEach((allDept) => {
                loggedInUser.department?.items?.forEach((dept) => {
                  if (allDept.id == dept.cFRDepartmentID) {
                    deptLst.push(allDept);
                  }
                });
              });
              setDepartments(deptLst);
            } else {
              setDepartments(_lstDepartment);
            }
          }
        }
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  });

  // this function for get config data
  const getConfigList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/user/userAddEdit.js\n Function Name: getConfigList";
    try {
      setIsLoading(true);
      const response = await API.graphql(graphqlOperation(listCFRConfigs));
      if (response) {
        const _lstConfig = response.data.listCFRConfigs.items;
        //console.log(_lstConfig);
        setSelectedCurrency("");
        setIsLoading(false);
        setCurrencies(_lstConfig);
        if (props.location.state) {
          const configObj = props.location.state.currencySymbol;
          setSelectedCurrency(configObj);
          //console.log(props);
        }
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  });

  // this function for get azure AD users
  const getAzureUsers = async (searchtxt) => {
    try {
      const res = await API.graphql({
        query: listAzureUsers,
        variables: { EmailSearchKey: searchtxt },
      });
      return res;
    } catch (error) { }
  };

  // this function is triggered when user types in email input box
  const handleOnSearch = async (searchtxt, results) => {
    console.log(searchtxt, results);
    if (searchtxt.length >= 3) {
      try {
        setIsLoading(true);
        let res = await getAzureUsers(searchtxt);
        setIsLoading(false);
        if (res && res.data && res.data.listAzureUsers) {
          setLstAzureUsers(JSON.parse(res.data.listAzureUsers));
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  // function to handle role change event
  const handleUserRoleChange = (e) => {
    if (e.target.value != "--- select role ---") {
      if (e.target.value != selectedRoleId) {
        setIsGlobalUser(false);
        const selectedRole = e.target.value;
        setSelectedRoleId(e.target.value);
        roles.map((role) => {
          if (role.id === selectedRole) {
            if(canApproveBudgetedCFR == true || canApproveUnBudgetedCFR == true){
              setCanApproveBudgetedCFR(false);
              setCanApproveUnBudgetedCFR(false);
            }
            setSelectedRole(role);
            setShowApprovalLimit(false);
            setShowGlobalUserSwitch(false);
            setShowBudgetedSwitch(false);
            setShowUnBudgetedSwitch(false);
            if (role.cfrRoleEntities) {
              if (role.cfrRoleEntities?.items?.length > 0) {
                let cfrRoleEntitylst = role.cfrRoleEntities.items.filter(
                  (x) => x.entity?.name == Entity.CFR
                );
                if (cfrRoleEntitylst.length > 0) {
                  let approvelst = cfrRoleEntitylst.filter(
                    (x) => x.entityOperation?.name == EntityOperation.Approve
                  );
                  if (approvelst.length > 0) {
                    setShowApprovalLimit(true);
                    setShowBudgetedSwitch(true);
                    setShowUnBudgetedSwitch(true);
                    let tempdept = [];
                    selectedDepartments.map((sDept) => {
                      departments.map((dept) => {
                        if (dept.id == sDept.value) {
                          tempdept.push(dept);
                        }
                      });
                    });
                    const isForGlobalUser = tempdept.filter((dept) => {
                      return dept.isForGlobalUser === true;
                    });
                    if (isForGlobalUser.length > 0) {
                      setShowGlobalUserSwitch(true);
                    }
                  }
                }
              }
            }
            if (role.name === Roles.CFR_APPROVER) {
              const isProcurementOrFinanceDept = selectedDepartments.filter(
                (dept) =>
                  dept.label === AppConstants.PROCUREMENT_DEPT ||
                  dept.label === AppConstants.FINANCE_DEPT
              );
              if (isProcurementOrFinanceDept.length > 0) {
                if (isGlobalUser) {
                  setShowApprovalLimit(false);
                }
              }
            } else if (
              role.name == Roles.CFR_CREATOR ||
              role.name == Roles.DEPT_ADMIN
            ) {
              setShowApprovalLimit(false);
            }
          }
        });
      }
    }
  };

  // function to handle department change event
  const handleDepartmentChange = (selected) => {
    setSelectedDepartments(selected);
    if (selected.length > 0) {
      let isAtleastOneForGlobalUser = false;
      setIsGlobalUser(false);
      setShowGlobalUserSwitch(false);
      setShowBudgetedSwitch(false);
      setShowUnBudgetedSwitch(false);
      selected.map((sDept) => {
        departments.map((dept) => {
          if (dept.id == sDept.value) {
            if (selectedRole?.cfrRoleEntities?.items?.length > 0) {
              let cfrRoleEntitylst = selectedRole.cfrRoleEntities.items.filter(
                (x) => x.entity?.name == Entity.CFR
              );
              if (cfrRoleEntitylst.length > 0) {
                let approvelst = cfrRoleEntitylst.filter(
                  (x) => x.entityOperation?.name == EntityOperation.Approve
                );
                if (approvelst.length > 0) {
                  if (dept.isForGlobalUser) {
                    isAtleastOneForGlobalUser = true;
                  }
                  setShowBudgetedSwitch(true);
                  setShowUnBudgetedSwitch(true);
                }
              }
            }
          }
        });
      });
      if (isAtleastOneForGlobalUser) {
        setShowGlobalUserSwitch(true);
        setIsGlobalUser(isGlobalUser);
      } else {
        setShowApprovalLimit(false);
        //check role and then show approval limit
        if (selectedRole?.cfrRoleEntities?.items?.length > 0) {
          let cfrRoleEntitylst = selectedRole.cfrRoleEntities.items.filter(
            (x) => x.entity?.name == Entity.CFR
          );
          if (cfrRoleEntitylst.length > 0) {
            let approvelst = cfrRoleEntitylst.filter(
              (x) => x.entityOperation?.name == EntityOperation.Approve
            );
            if (approvelst.length > 0) {
              setShowApprovalLimit(true);
            }
          }
        }
      }
      if (selectedRole.name === Roles.CFR_APPROVER) {
        const isProcurementOrFinanceDept = selected.filter(
          (dept) =>
            dept.label === AppConstants.PROCUREMENT_DEPT ||
            dept.label === AppConstants.FINANCE_DEPT
        );
        if (isProcurementOrFinanceDept.length > 0) {
          if (isGlobalUser) {
            setShowApprovalLimit(false);
          }
        }
      } else if (
        selectedRole.name == Roles.CFR_CREATOR ||
        selectedRole.name == Roles.DEPT_ADMIN
      ) {
        setShowApprovalLimit(false);
      }
    }
  };

  // function to handle email select event
  const handleOnSelect = (item) => {
    console.log(item);
    setValue("name", item.displayName);
    setValue("email", item.mail);
  };

  // function to handle email focus event
  const handleOnFocus = () => {
    //console.log("Focused");
  };

  // function to handle email hover event
  const handleOnHover = (result) => {
    //console.log(result);
  };

  // function to handle email clear event
  const handleOnClear = () => {
    setValue("name", "");
    setValue("email", "");
    setLstAzureUsers([]);
  };

  const setEnvironment = () => {
    const enviornment = awsconfig.Auth.redirectSignOut;
    setEnv(enviornment);
  };

  // function to handle is global user toggle
  const actionToggle = (e) => {
    console.log("toggle btn clicked: " + e.target.value);
    setIsGlobalUser(!isGlobalUser);
    if (e.currentTarget.checked) {
      setShowApprovalLimit(false);
    } else {
      setShowApprovalLimit(true);
    }
  };

  // function to handle can approve budgeted CFR toggle
  const actionToggleBudgtedCFR = (e) => {
    console.log("toggle btn clicked: " + e.target.value);
    setCanApproveBudgetedCFR(!canApproveBudgetedCFR);
  };

  // function to handle can approve unbudgeted CFR toggle
  const actionToggleUnBudgtedCFR = (e) => {
    console.log("toggle btn clicked: " + e.target.value);
    setCanApproveUnBudgetedCFR(!canApproveUnBudgetedCFR);
  };

  // this function for create and update user
  async function onSubmit(data) {
    const errorLocation =
      "Path Name: components/user/userAddEdit.js\n Function Name: onSubmit";
    try {
      console.log(data);
      data.headoffunction = data.headoffunction == "1" ? true : false;
      data.departmentadmin = data.departmentadmin == "1" ? true : false;
      data.isGlobalUser = isGlobalUser;
      if (showApprovalLimit) {
        if (data.currencySymbol == "--- select currency ---") {
          alert.show("Please select the currency.");
          return;
        }
      } else {
        data.limit = 0;
        data.toLimit = 0;
        data.currencySymbol = "";
      }
      if (
        canApproveBudgetedCFR === false &&
        canApproveUnBudgetedCFR === false
      ) {
        alert.show(
          "At least one of Budgted/Unbudgted approval must be enabled"
        );
        return;
      } else {
        data.canApproveUnBudgetedCFR = canApproveUnBudgetedCFR;
        data.canApproveBudgetedCFR = canApproveUnBudgetedCFR;
      }
      // const selectedRole = roles.filter(
      //   (role) => role.id === data.cFRUserRoleId
      // );
      // if (selectedRole.length > 0) {
      //   if (selectedRole[0]) {
      //     if (
      //       selectedRole[0].name === Roles.CFR_APPROVER ||
      //       selectedRole[0].name === Roles.SUPER_ADMIN
      //     ) {
      //       const isProcurementOrFinanceDept = selectedDepartments.filter(
      //         (dept) =>
      //           dept.label === AppConstants.PROCUREMENT_DEPT ||
      //           dept.label === AppConstants.FINANCE_DEPT
      //       );
      //       if (isProcurementOrFinanceDept.length > 0) {
      //         if (isGlobalUser) {
      //           data.limit = 0;
      //           data.toLimit = 0;
      //           data.currencySymbol = "";
      //         }
      //       }
      //     } else if (
      //       selectedRole[0].name == Roles.CFR_CREATOR ||
      //       selectedRole[0].name == Roles.DEPT_ADMIN
      //     ) {
      //       data.limit = 0;
      //       data.toLimit = 0;
      //       data.currencySymbol = "";
      //     }
      //   }
      // }

      setIsLoading(true);
      if (isAddMode) {
        let searchParameterSoft = {
          isDeleted: { eq: true },
          email: { eq: data.email },
        };
        const res = await API.graphql(
          graphqlOperation(listCFRUsers, { filter: searchParameterSoft })
        );
        if (res.data && res.data?.listCFRUsers?.items.length > 0) {
          let existingUser = res.data?.listCFRUsers?.items[0];
          let newData = {
            ...existingUser,
          };
          newData.isDeleted = false;
          delete newData.role;
          delete newData.department;
          delete newData.createdAt;
          delete newData.updatedAt;

          const response = await API.graphql(
            graphqlOperation(updateCFRUser, { input: newData })
          );
          if (response.data) {
            if (existingUser.department) {
              if (existingUser.department.items.length > 0) {
                const deletePromises = existingUser.department.items.map(
                  async (oldUserDept) => {
                    let deleteObj = {};
                    deleteObj.id = oldUserDept.id;
                    const userDepartmentMappingDeleteResult = await API.graphql(
                      graphqlOperation(deleteCFRUserDepartmentMapping, {
                        input: deleteObj,
                      })
                    );
                    if (userDepartmentMappingDeleteResult.data) {
                      return userDepartmentMappingDeleteResult.data
                        .deleteCFRUserDepartmentMapping;
                    }
                  }
                );
                //wait for all old user department mapping to be deleted
                const allDeleteResult = await Promise.all(deletePromises);
              }
            }

            console.log(selectedDepartments);
            let userDepartmentMappingArray = [];
            if (selectedDepartments.length > 0) {
              selectedDepartments.map((selectedoutlet) => {
                let mappingObj = {};
                mappingObj.cFRDepartmentID = selectedoutlet.value;
                mappingObj.cFRUserID = response.data.updateCFRUser.id;
                userDepartmentMappingArray.push(mappingObj);
              });
            }
            //create an entry for each user department mapping
            const promises = userDepartmentMappingArray.map(async (level) => {
              const userDepartmentMappingResult = await API.graphql(
                graphqlOperation(createCFRUserDepartmentMapping, {
                  input: level,
                })
              );
              if (userDepartmentMappingResult.data) {
                return userDepartmentMappingResult.data
                  .createCFRUserDepartmentMapping;
              }
            });
            //wait for all user department mapping to be created
            const allresult = await Promise.all(promises);
            const successMessage = "User's access enabled successfully.";
            alert.show(successMessage, {
              type: "success",
              onClose: () => {
                props.history.push("/users");
              }, // callback that
            });
            //send email to created user.
            let filter = { action: { eq: "CREATE_USER" } };
            const emailTemplates = await API.graphql(
              graphqlOperation(listCFREmailTemplates, { filter: filter })
            );
            const template = emailTemplates.data.listCFREmailTemplates.items[0];
            let body = template.emailcontent;
            let replaceName = body.replace(
              "{Display Name}",
              response.data.updateCFRUser.name
            );
            let replaceRole = replaceName.replace(
              "{User Role}",
              response.data.updateCFRUser.role.name
            );
            let finalBody = replaceRole.replace("{Link}", env);
            const emailTemplate = {
              To: response.data.updateCFRUser.email,
              Subject: template.emailsubject,
              MailBody: finalBody,
            };

            const res = await API.graphql({
              query: sendEmail,
              variables: {
                EmailTemplate: JSON.stringify(emailTemplate),
              },
            });
            setIsLoading(false);
          } else if (response.errors) {
            if (response.errors.length > 0) {
              alert.show(response.errors[0].message);
            }
          }
        } //console.log("add: email address: " + data.email);
        else {
          let searchParameter = { email: { eq: data.email } };
          const resp = await API.graphql(
            graphqlOperation(listCFRUsers, { filter: searchParameter })
          );
          if (
            resp != null &&
            resp.data.listCFRUsers != null &&
            resp.data.listCFRUsers.items.length > 0
          ) {
            alert.show("The user already present.");
            setIsLoading(false);
            return;
          }

          const newData = {
            ...data,
            isActive: true,
            isDeleted: false,
          };
          const result = await API.graphql(
            graphqlOperation(createCFRUser, { input: newData })
          );
          if (result.data?.createCFRUser) {
            console.log(selectedDepartments);
            let userDepartmentMappingArray = [];
            if (selectedDepartments.length > 0) {
              selectedDepartments.map((selectedoutlet) => {
                let mappingObj = {};
                mappingObj.cFRDepartmentID = selectedoutlet.value;
                mappingObj.cFRUserID = result.data.createCFRUser.id;
                userDepartmentMappingArray.push(mappingObj);
              });
            }
            //create an entry for each user department mapping
            const promises = userDepartmentMappingArray.map(async (level) => {
              const userDepartmentMappingResult = await API.graphql(
                graphqlOperation(createCFRUserDepartmentMapping, {
                  input: level,
                })
              );
              if (userDepartmentMappingResult.data) {
                return userDepartmentMappingResult.data
                  .createCFRUserDepartmentMapping;
              }
            });
            //wait for all user department mapping to be created
            const allresult = await Promise.all(promises);
            // check if there is entry in pending users table for same email
            const response = await API.graphql(
              graphqlOperation(listCFRPendingUsers)
            );
            if (response.data) {
              const _lstPendingUsers = response.data.listCFRPendingUsers.items;
              _lstPendingUsers.map(async (user) => {
                if (user.email === newData.email) {
                  //if yes, then delete this entry from pending user request access table
                  deletePendingUserRecord(user.id);
                }
              });

              let userNotificationData = {
                userActivation: true,
                userEnabled: true,
                userDeactivation: true,
                cFRCreation: true,
                projectSponsorApproval: true,
                procurementHeadApproval: true,
                functionalHeadApproval: true,
                financeHeadApproval: true,
                cFRUserNotificationsUserId: result.data?.createCFRUser?.id
              }

              const resp = await API.graphql(
                graphqlOperation(createCFRUserNotifications, { input: userNotificationData })
              );

            }

            const successMessage = "User's access enabled successfully.";
            alert.show(successMessage, {
              type: "success",
              onClose: () => {
                props.history.push("/users");
              }, // callback that
            });
            //send email to created user.
            let filter = { action: { eq: "CREATE_USER" } };
            const emailTemplates = await API.graphql(
              graphqlOperation(listCFREmailTemplates, { filter: filter })
            );
            const template = emailTemplates.data.listCFREmailTemplates.items[0];
            let body = template.emailcontent;
            let replaceName = body.replace(
              "{Display Name}",
              result.data.createCFRUser.name
            );
            let replaceRole = replaceName.replace(
              "{User Role}",
              result.data.createCFRUser.role.name
            );
            let finalBody = replaceRole.replace("{Link}", env);
            const emailTemplate = {
              To: result.data.createCFRUser.email,
              Subject: template.emailsubject,
              MailBody: finalBody,
            };

            const res = await API.graphql({
              query: sendEmail,
              variables: {
                EmailTemplate: JSON.stringify(emailTemplate),
              },
            });
            setIsLoading(false);
          } else if (result.errors) {
            if (result.errors.length > 0) {
              alert.show(result.errors[0].message);
            }
          }
        }
      } else if (!isAddMode) {
        if (isPendingUser) {
          console.log("add");
          const newData = {
            ...data,
            isActive: true,
          };
          const result = await API.graphql(
            graphqlOperation(createCFRUser, { input: newData })
          );
          setIsLoading(false);
          if (result.data) {
            console.log(selectedDepartments);

            let userNotificationData = {
              userActivation: true,
              userEnabled: true,
              userDeactivation: true,
              cFRCreation: true,
              projectSponsorApproval: true,
              procurementHeadApproval: true,
              functionalHeadApproval: true,
              financeHeadApproval: true,
              cFRUserNotificationsUserId: result.data?.createCFRUser?.id
            }

            const resp = await API.graphql(
              graphqlOperation(createCFRUserNotifications, { input: userNotificationData })
            );

            let userDepartmentMappingArray = [];
            if (selectedDepartments.length > 0) {
              selectedDepartments.map((selectedoutlet) => {
                let mappingObj = {};
                mappingObj.cFRDepartmentID = selectedoutlet.value;
                mappingObj.cFRUserID = result.data.createCFRUser.id;
                userDepartmentMappingArray.push(mappingObj);
              });
            }
            //create an entry for each user department mapping
            const promises = userDepartmentMappingArray.map(async (level) => {
              const userDepartmentMappingResult = await API.graphql(
                graphqlOperation(createCFRUserDepartmentMapping, {
                  input: level,
                })
              );
              if (userDepartmentMappingResult.data) {
                return userDepartmentMappingResult.data
                  .createCFRUserDepartmentMapping;
              }
            });
            //wait for all user department mapping to be created
            const allresult = await Promise.all(promises);
            // check if there is entry in pending users table for same email
            const response = await API.graphql(
              graphqlOperation(listCFRPendingUsers)
            );
            const _lstPendingUsers = response.data.listCFRPendingUsers.items;
            _lstPendingUsers.map(async (user) => {
              if (user.email === newData.email) {
                deletePendingUserRecord(user.id);
              }
            });
            const successMessage = "User's access enabled successfully.";
            alert.show(successMessage, {
              type: "success",
              onClose: () => {
                props.history.push("/users");
              }, // callback that
            });
          } else if (result.errors) {
            if (result.errors.length > 0) {
              alert.show(result.errors[0].message);
            }
          }
        } else {
          console.log("update");
          console.log("edit data", data);
          let searchParam = {id : { eq : data.id }};
          let searchParam2 = {cFRUserNotificationsUserId : { eq : data.id }};
          const resp = await API.graphql(
            graphqlOperation(listCFRUsers, { filter : searchParam })
          );
          const resp2 = await API.graphql(
            graphqlOperation(listCFRUserNotifications, { filter : searchParam2 })
          );
          let userNotif; 
          if(resp2.data){
            if(resp2.data?.listCFRUserNotifications?.items.length > 0){
              userNotif = resp2.data?.listCFRUserNotifications?.items[0];
            }
          }
          console.log("User Prev record", resp?.data?.listCFRUsers?.items)
          if(resp?.data){
            if(resp?.data?.listCFRUsers?.items.length > 0){
              if(resp?.data?.listCFRUsers?.items[0].cFRUserRoleId != data.cFRUserRoleId ){
                let userNotificationData = {
                  userActivation: true,
                  userEnabled: true,
                  userDeactivation: true,
                  cFRCreation: true,
                  projectSponsorApproval: true,
                  procurementHeadApproval: true,
                  functionalHeadApproval: true,
                  financeHeadApproval: true,
                  cFRUserNotificationsUserId: data.id,
                  id: userNotif.id
                }
                console.log("Inside User Notif");
                const response = await API.graphql(
                  graphqlOperation(updateCFRUserNotifications, { input: userNotificationData })
                );
              }
            }
          }
          const result = await API.graphql(
            graphqlOperation(updateCFRUser, { input: data })
          );
          setIsLoading(false);
          if (result.data) {
            //delete old mapping table entries
            const userobj = props.location.state;
            await removeDepartmentDependency(userobj);
            if (userobj.department) {
              if (userobj.department.items.length > 0) {
                const deletePromises = userobj.department.items.map(
                  async (oldUserDept) => {
                    let deleteObj = {};
                    deleteObj.id = oldUserDept.id;
                    const userDepartmentMappingDeleteResult = await API.graphql(
                      graphqlOperation(deleteCFRUserDepartmentMapping, {
                        input: deleteObj,
                      })
                    );
                    if (userDepartmentMappingDeleteResult.data) {
                      return userDepartmentMappingDeleteResult.data
                        .deleteCFRUserDepartmentMapping;
                    }
                  }
                );
                //wait for all old user department mapping to be deleted
                const allDeleteResult = await Promise.all(deletePromises);
              }
            }

            console.log(selectedDepartments);
            let userDepartmentMappingArray = [];
            if (selectedDepartments.length > 0) {
              selectedDepartments.map((selectedoutlet) => {
                let mappingObj = {};
                mappingObj.cFRDepartmentID = selectedoutlet.value;
                mappingObj.cFRUserID = result.data.updateCFRUser.id;
                userDepartmentMappingArray.push(mappingObj);
              });
            }
            //create an entry for each user department mapping
            const promises = userDepartmentMappingArray.map(async (level) => {
              const userDepartmentMappingResult = await API.graphql(
                graphqlOperation(createCFRUserDepartmentMapping, {
                  input: level,
                })
              );
              if (userDepartmentMappingResult.data) {
                return userDepartmentMappingResult.data
                  .createCFRUserDepartmentMapping;
              }
            });
            //wait for all user department mapping to be created
            const allresult = await Promise.all(promises);
            const successMessage = "User updated successfully.";
            alert.show(successMessage, {
              type: "success",
              onClose: () => {
                props.history.push("/users");
              }, // callback that
            });
          } else if (result.errors) {
            if (result.errors.length > 0) {
              alert.show(result.errors[0].message);
            }
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  }

  const removeDepartmentDependency = async (userObj) => {
    let userFinanceObj = userObj.department.items.filter(
      (x) => x.name == "Finance"
    );
    let userProcurementObj = userObj.department.items.filter(
      (x) => x.name == "Procurement"
    );

    console.log(
      "userFinanceObj+++++",
      userFinanceObj,
    );
    console.log(
      "userProcurementObj-----",
      userProcurementObj
    );

    let searchParameter = { isdeleted: { eq: false } };
    const _deptData = await API.graphql(
      graphqlOperation(listCFRDepartments, { filter: searchParameter })
    );
    const deptData = _deptData?.data?.listCFRDepartments?.items;
    if (userFinanceObj.length > 0) {
      let filteredList = [];
      deptData.forEach((deptObj) => {
        if (deptObj.financeBudgtedUsers || deptObj.financeUnBudgtedUsers) {
          if (deptObj.financeBudgtedUsers.length > 0 || deptObj.financeUnBudgtedUsers.length > 0) {
            if (deptObj.financeBudgtedUsers.includes(userFinanceObj[0].cFRUserID) ||
              deptObj.financeUnBudgtedUsers.includes(userFinanceObj[0].cFRUserID)) {
              filteredList.push(deptObj);
            }
          }
        }
      });
      let listToBeUpdated = [];
      if (filteredList != []) {

        filteredList.forEach((obj) => {
          if (obj.financeBudgtedUsers.includes(userFinanceObj[0].cFRUserID)) {
            let idx = obj.financeBudgtedUsers.indexOf(
              userFinanceObj[0].cFRUserID
            );
            obj.financeBudgtedUsers.splice(idx, 1);
          }
          if (obj.financeUnBudgtedUsers.includes(userFinanceObj[0].cFRUserID)) {
            let idx = obj.financeUnBudgtedUsers.indexOf(
              userFinanceObj[0].cFRUserID
            );
            obj.financeUnBudgtedUsers.splice(idx, 1);
          }
          delete obj.createdAt;
          delete obj.updatedAt;
          delete obj.users;
          listToBeUpdated.push(obj);
        });

        console.log("listtobeupdated", listToBeUpdated);
        const financeUpdatePromises = listToBeUpdated.map(async (data) => {
          let updateData = await API.graphql(
            graphqlOperation(updateCFRDepartment, { input: data })
          );
          if (updateData.data) {
            return updateData.data.updateCFRDepartment;
          }
        });
        const finResult = await Promise.all(financeUpdatePromises);
      }
    }

    if (userProcurementObj.length > 0) {
      let filteredList = [];
      deptData.forEach(
        (deptObj) =>{
          if (deptObj.procurementBudgtedUsers || deptObj.procurementUnBudgtedUsers) {
            if (deptObj.procurementBudgtedUsers.length > 0 || deptObj.procurementUnBudgtedUsers.length > 0 ) {
              if (deptObj.procurementBudgtedUsers.includes(userFinanceObj[0].cFRUserID) ||
                deptObj.procurementUnBudgtedUsers.includes(userFinanceObj[0].cFRUserID)) {
                filteredList.push(deptObj);
              }
            }
          }
        });

      let listToBeUpdated = [];
      if(filteredList != []){
        filteredList.forEach((obj) => {
          if (
            obj.procurementBudgtedUsers.includes(userProcurementObj[0].cFRUserID)
          ) {
            let idx = obj.financeBudgtedUsers.indexOf(
              userProcurementObj[0].cFRUserID
            );
            obj.procurementBudgtedUsers.splice(idx, 1);
          }
          if (
            obj.procurementUnBudgtedUsers.includes(
              userProcurementObj[0].cFRUserID
            )
          ) {
            let idx = obj.procurementUnBudgtedUsers.indexOf(
              userProcurementObj[0].cFRUserID
            );
            obj.procurementUnBudgtedUsers.splice(idx, 1);
          }
          delete obj.createdAt;
          delete obj.updatedAt;
          delete obj.users;
          listToBeUpdated.push(obj);
        });

        const procurementUpdatePromises = listToBeUpdated.map(async (data) => {
          let updateData = await API.graphql(
            graphqlOperation(updateCFRDepartment, { input: data })
          );
          if (updateData.data) {
            return updateData.data.updateCFRDepartment;
          }
        });
        const procResult = await Promise.all(procurementUpdatePromises);
      }
    }
  };

  // this function for deleting pending user if its added
  const deletePendingUserRecord = async (id) => {
    const errorLocation =
      "Path Name: components/user/userAddEdit.js\n Function Name: deletePendingUserRecord";
    try {
      const deleteObj = {
        id: id,
      };
      setIsLoading(true);
      const res = await API.graphql(
        graphqlOperation(deleteCFRPendingUser, { input: deleteObj })
      );
      setIsLoading(false);
      if (res.data) {
        alert.show("Pending User record is deleted.");
      } else {
        if (res.errors) {
          if (res.errors.length > 0) {
            alert.show(res.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  };

  return (
    <div className="page-container">
      {isLoading ? <PageLoader></PageLoader> : ""}
      <div className="page-header">
        <h4> {pageTitle} User </h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-4 mb-3" hidden={pageTitle != "Create"}>
                <label className="form-label">
                  Email <span> * </span>
                </label>
                <ReactSearchAutocomplete
                  items={lstAzureUsers}
                  fuseOptions={{ keys: ["mail"] }}
                  resultStringKeyName="mail"
                  onSearch={handleOnSearch}
                  onHover={handleOnHover}
                  onSelect={handleOnSelect}
                  onFocus={handleOnFocus}
                  onClear={handleOnClear}
                  showIcon={false}
                  className="form-control"
                  styling={{
                    height: "34px",
                    fontSize: "14px",
                    fontFamily: "Segoe UI",
                    borderRadius: "4px",
                    backgroundColor: "white",
                    boxShadow: "none",
                    clearIconMargin: "3px 8px 0 0",
                  }}
                />
              </div>
              <div className="col-sm-4 mb-3" hidden={pageTitle != "Update"}>
                <label className="form-label">
                  Email <span> * </span>
                </label>
                <input
                  type="text"
                  id="txtEmail"
                  name="email"
                  readOnly={id}
                  className="form-control"
                  {...register("email", {
                    required: true,
                    minLength: 1,
                    maxLength: 100,
                  })}
                />
              </div>
              <div className="col-sm-4 mb-3">
                <label className="form-label">
                  Name <span> * </span>
                </label>
                <input
                  type="text"
                  id="txtName"
                  name="name"
                  readOnly
                  className="form-control"
                  {...register("name", {
                    required: true,
                  })}
                />
                {errors.name && errors.name.type === "required" && (
                  <div className="error-msg">Name is required.</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4  mb-3">
                <label className="form-label">
                  Role <span> * </span>{" "}
                </label>
                <select
                  id="roleSelect"
                  name="cFRUserRoleId"
                  className="form-control form-select"
                  onClick={handleUserRoleChange}
                  {...register("cFRUserRoleId", { required: true })}
                >
                  <option defaultValue>--- select role ---</option>
                  {roles.map((item) => (
                    <option
                      selected={selectedRoleId == item.id}
                      key={item.id}
                      value={item.id}
                    >
                      {item.name}
                    </option>
                  ))}
                </select>
                {errors.role && errors.role.type === "required" && (
                  <div className="error-msg">Role is required.</div>
                )}
              </div>
              <div className="col-sm-4 mb-3">
                <label className="form-label">Department</label>
                <ReactSelect
                  options={departmentOptions}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option,
                  }}
                  onChange={handleDepartmentChange}
                  allowSelectAll={true}
                  value={selectedDepartments}
                  name="Outlets"
                />
              </div>

              <div className="col-sm-4 mb-3">
                <label className="form-label"></label>
                <div className="row">
                  <div className="col-md-1">
                    {showGlobalUserSwitch && (
                      <div title="Global User">
                        <Toggle
                          checked={isGlobalUser}
                          icons={false}
                          onChange={actionToggle}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-1">
                    {showBudgetedSwitch && (
                      <div title="Can Approve Budgeted CFR">
                        <Toggle
                          checked={canApproveBudgetedCFR}
                          icons={false}
                          onChange={actionToggleBudgtedCFR}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-1">
                    {showUnBudgetedSwitch && (
                      <div title="Can Approve UnBudgeted CFR">
                        <Toggle
                          checked={canApproveUnBudgetedCFR}
                          icons={false}
                          onChange={actionToggleUnBudgtedCFR}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-4  mb-3">
                <label className="form-label">Head of Department </label>
                <select
                  id="hodSelect"
                  name="headoffunction"
                  className="form-control form-select"
                  {...register("headoffunction")}
                >
                  <option value="0" defaultValue>
                    No
                  </option>
                  <option value="1">Yes</option>
                </select>
              </div>
              <div className="col-sm-4 mb-3">
                <label className="form-label">Department Admin</label>
                <select
                  id="deptAdminSelect"
                  name="departmentadmin"
                  className="form-control form-select"
                  {...register("departmentadmin")}
                >
                  <option value="0" defaultValue>
                    No
                  </option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            {showApprovalLimit === true && (
              <div>
                <div className="row">
                  <div className="col-sm-4 mb-3">
                    <label className="form-label">
                      Approval Limit <span> * </span>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4  mb-3">
                    <label className="form-label">
                      Currency <span> * </span>{" "}
                    </label>
                    <select
                      id="currencySelect"
                      name="currencySymbol"
                      className="ddl-control"
                      // value={selectedCurrency}
                      {...register("currencySymbol")}
                    >
                      <option defaultValue>--- select currency ---</option>
                      {currencies.map((item) => (
                        <option
                          selected={selectedCurrency == item.currencySymbol}
                          key={item.id}
                          value={item.currencySymbol}
                        >
                          {item.currencySymbol}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-4 mb-3">
                    <label className="form-label">
                      <strong>{">="}</strong>
                    </label>
                    <input
                      type="number"
                      id="txtApprovalLimit"
                      name="limit"
                      className="form-control"
                      {...register("limit", {
                        min: 1,
                      })}
                    />
                    {errors.limit && errors.limit.type === "required" && (
                      <div className="error-msg">
                        Approval limit is required.
                      </div>
                    )}
                    {errors.limit && errors.limit.type === "min" && (
                      <div className="error-msg">
                        Apprval limit should be greater than 0.
                      </div>
                    )}
                  </div>
                  <div className="col-sm-4 mb-3">
                    <label className="form-label">
                      <strong>{"<="}</strong>
                    </label>
                    <input
                      type="number"
                      id="txtApprovalLimit"
                      name="toLimit"
                      className="form-control"
                      {...register("toLimit", {
                        min: 1,
                      })}
                    />
                    {errors.limit && errors.limit.type === "required" && (
                      <div className="error-msg">
                        Approval limit is required.
                      </div>
                    )}
                    {errors.limit && errors.limit.type === "min" && (
                      <div className="error-msg">
                        Apprval limit should be greater than 0.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="form-footer">
          <div className="row">
            <div className="col-sm-12">
              <button type="submit" className="btn btn-sm btn-primary">
                Save
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary ml-10"
                onClick={() => props.history.goBack()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
