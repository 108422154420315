function PendingUserView(props) {
  let userObj;
  if (props.location.state) {
    userObj = props.location.state;
  }
  return (
    <div className="page-container">
      <div className="page-header">
        <h4> Pending User Details </h4>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Name </label>
              <div> {userObj.name} </div>
            </div>
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Email </label>
              <div> {userObj.email}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-footer">
        <div className="row">
          <div className="col-sm-12">
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary"
              onClick={() => props.history.goBack()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PendingUserView;
