import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { AppConstants } from "../../common/constants";
import { listCFRUsers } from "../../graphql/customQueries";
import { listCFRDepartments } from "../../graphql/queries";

function DepartmentView(props) {
  let [dept, setdept] = useState({});
  const [financeBudgtedUsers, setFinanceBudgtedUsers] = useState([]);
  const [financeUnBudgtedUsers, setFinanceUnBudgtedUsers] = useState([]);
  const [procurementBudgtedUsers, setProcurementBudgtedUsers] = useState([]);
  const [procurementUnBudgtedUsers, setProcurementUnBudgtedUsers] = useState(
    []
  );
  const [selFinanceBudgtedUsers, setSelFinanceBudgtedUsers] = useState([]);
  const [selFinanceUnBudgtedUsers, setSelFinanceUnBudgtedUsers] = useState([]);
  const [selProcurementBudgtedUsers, setSelProcurementBudgtedUsers] = useState(
    []
  );
  const [selProcurementUnBudgtedUsers, setSelProcurementUnBudgtedUsers] =
    useState([]);

  // called on page load
  useEffect(async () => {
    await sortBudgetedAndUnBudgetedUsers();
    if (props.location.state) {
      let deptObj = props.location.state;
      setdept(props.location.state);
      setSelFinanceBudgtedUsers(deptObj.financeBudgtedUsers);
      setSelFinanceUnBudgtedUsers(deptObj.financeUnBudgtedUsers);
      setSelProcurementBudgtedUsers(deptObj.procurementBudgtedUsers);
      setSelProcurementUnBudgtedUsers(deptObj.procurementUnBudgtedUsers);
    }
  }, []);

  // sort budgeted and unbudgeted users
  const sortBudgetedAndUnBudgetedUsers = async () => {
    const _userData = await API.graphql(graphqlOperation(listCFRUsers));
    const _lstUser = _userData.data.listCFRUsers.items;
    let searchParameter = { isdeleted: { eq: false } };
    const _deptData = await API.graphql(
      graphqlOperation(listCFRDepartments, { filter: searchParameter })
    );
    const _deptList = _deptData.data.listCFRDepartments.items;
    let globalUsers = [];
    let financeId;
    let procurementId;
    let _budgtedUsers = [];
    let _unBudgtedUsers = [];
    let _financeBudgtedUsers = [];
    let _financeUnBudgtedUsers = [];
    let _procurementBudgtedUsers = [];
    let _procurementUnBudgtedUsers = [];
    _lstUser.map((user) => {
      if (user.isGlobalUser === true) {
        globalUsers.push(user);
      }
    });
    globalUsers.map((user) => {
      if (user.canApproveBudgetedCFR === true) {
        _budgtedUsers.push(user);
      }
      if (user.canApproveUnBudgetedCFR === true) {
        _unBudgtedUsers.push(user);
      }
    });

    _deptList.map((dept) => {
      if (dept.name === AppConstants.FINANCE_DEPT) {
        financeId = dept.id;
      } else if (dept.name === AppConstants.PROCUREMENT_DEPT) {
        procurementId = dept.id;
      }
    });

    _budgtedUsers.map((user) => {
      if (user.department) {
        if (user.department.items.length > 0) {
          const isFinanceDept = user.department.items.filter(
            (userdept) => userdept.cFRDepartmentID === financeId
          );
          if (isFinanceDept.length > 0) {
            _financeBudgtedUsers.push(user);
          }
          const isProcurementDept = user.department.items.filter(
            (userdept) => userdept.cFRDepartmentID === procurementId
          );
          if (isProcurementDept.length > 0) {
            _procurementBudgtedUsers.push(user);
          }
        }
      }
    });

    _unBudgtedUsers.map((user) => {
      if (user.department) {
        if (user.department.items.length > 0) {
          const isFinanceDept = user.department.items.filter(
            (userdept) => userdept.cFRDepartmentID === financeId
          );
          if (isFinanceDept.length > 0) {
            _financeUnBudgtedUsers.push(user);
          }
          const isProcurementDept = user.department.items.filter(
            (userdept) => userdept.cFRDepartmentID === procurementId
          );
          if (isProcurementDept.length > 0) {
            _procurementUnBudgtedUsers.push(user);
          }
        }
      }
    });

    setFinanceBudgtedUsers(_financeBudgtedUsers);
    setFinanceUnBudgtedUsers(_financeUnBudgtedUsers);
    setProcurementBudgtedUsers(_procurementBudgtedUsers);
    setProcurementUnBudgtedUsers(_procurementUnBudgtedUsers);
  };

  return (
    <div className="page-container">
      <div className="page-header">
        <h4> Department Details </h4>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Name </label>
              <div> {dept.name} </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Details </label>
              <div> {dept.details}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-6">
              <div className="card">
                <div className="card-header">
                  <label className="form-label">Procurement</label>
                </div>
                <div className="card-body">
                  <label className="form-label">Budgeted Users</label>
                  {Array.from(procurementBudgtedUsers, (e, i) => {
                    return (
                      <table key={i}>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                value={e}
                                readOnly
                                checked={
                                  selProcurementBudgtedUsers &&
                                  selProcurementBudgtedUsers.includes(e.id)
                                    ? true
                                    : false
                                }
                              />
                              {e.name}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    );
                  })}
                  <hr></hr>
                  <label className="form-label">Unbudgeted Users</label>
                  {Array.from(procurementUnBudgtedUsers, (e, i) => {
                    return (
                      <table key={i}>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                value={e}
                                readOnly
                                checked={
                                  selProcurementUnBudgtedUsers &&
                                  selProcurementUnBudgtedUsers.includes(e.id)
                                    ? true
                                    : false
                                }
                              />
                              {e.name}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card">
                <div className="card-header">
                  <label className="form-label">Finance</label>
                </div>
                <div className="card-body">
                  <label className="form-label">Budgeted Users</label>
                  {Array.from(financeBudgtedUsers, (e, i) => {
                    return (
                      <table key={i}>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                value={e}
                                readOnly
                                checked={
                                  selFinanceBudgtedUsers &&
                                  selFinanceBudgtedUsers.includes(e.id)
                                    ? true
                                    : false
                                }
                              />
                              {e.name}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    );
                  })}
                  <hr></hr>
                  <label className="form-label">Unbudgeted Users</label>
                  {Array.from(financeUnBudgtedUsers, (e, i) => {
                    return (
                      <table key={i}>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                value={e}
                                readOnly
                                checked={
                                  selFinanceUnBudgtedUsers &&
                                  selFinanceUnBudgtedUsers.includes(e.id)
                                    ? true
                                    : false
                                }
                              />
                              {e.name}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-footer">
        <div className="row">
          <div className="col-sm-12">
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary ml-10"
              onClick={() => props.history.goBack()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DepartmentView;
