import React from "react";
function PendingUserList(props) {
  console.log(props.userData);
  return (
    <table className="table table-cust ">
      <thead>
        <tr>
          <th className="width15"> Name </th>
          <th className="width20"> Email </th>
          <th className="width15"> Actions </th>
        </tr>
      </thead>
      <tbody>
        {props.userData.length > 0 &&
          props.userData.map((user) => (
            <tr key={user.id}>
              <td className="width15" title={user.name}>
                {user.name}
              </td>
              <td className="width20" title={user.email}>
                {user.email}
              </td>
              <td className="width15">
                <div className="button-group">
                  {props.isDeletePermission && (
                    <button
                      type="button"
                      className="btn-grid fa fa-trash"
                      onClick={() => {
                        props.actiondelete(user.id);
                      }}
                    ></button>
                  )}
                  {props.isUpdatePermission && (
                    <button
                      type="button"
                      className="btn-grid fa fa-pencil"
                      onClick={() => {
                        props.actionUpdate(user, user.id);
                      }}
                    ></button>
                  )}
                  <button
                    type="button"
                    className="btn-grid fa fa-file-text-o"
                    onClick={() => {
                      props.actionView(user, user.id);
                    }}
                  ></button>
                </div>
              </td>
            </tr>
          ))}
        {props.userData.length == 0 && (
          <tr>
            <td colSpan="7" align="center">
              No record Found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
export default PendingUserList;
