import React from "react";
import { useState, useCallback, useEffect } from "react";
import Amplify, { Auth, Hub } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import awsExports from "../../aws-exports";
import awsauth from "../../awsauth";
import { API, graphqlOperation } from "aws-amplify";
import { UserContext } from "../../context/UserContext";
import EventEmitter from "../../Utils/eventEmitter";
import { AppConstants, Entity, EntityOperation } from "../../common/constants";
import { listAzureUsers, listCFRUsers } from "../../graphql/customQueries";
import { createCFRPendingUser } from "../../graphql/mutations";
import {
  listCFREmailTemplates,
  sendEmail,
  listCFRDepartments,
} from "../../graphql/queries";
import {
  setCognitoUser,
  setLoggedInUser,
  getLoggedInUser,
  removeUserSession,
} from "../../Utils/common";
const Home = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();
  const [departments, setDepartments] = useState([]);
  const [loggedinUserEmail, setloggedinUserEmail] = useState(null);
  const { isLoggedin, user, updateLogin, updateCFRUser } =
    React.useContext(UserContext);

  let history = useHistory();
  Amplify.configure(awsExports);
  Auth.configure({ oauth: awsauth });
  // Amplify hub for listening auth events
  Hub.listen("auth", ({ payload: { event, data } }) => {
    switch (event) {
      case "signIn":
        console.log("sign in", event, data);
        updateLogin(true);
        updateCFRUser(data);
        setCognitoUser(data);
        if (data.signInUserSession) {
          if (data.signInUserSession.idToken) {
            if (data.signInUserSession.idToken.payload) {
              console.log(data);
              if (data.signInUserSession.idToken.payload.email) {
                setloggedinUserEmail(
                  data.signInUserSession.idToken.payload.email
                );
                getDepartmentList();
              }
            }
          }
        }
        break;
      case "signOut":
        console.log("sign out");
        sessionStorage.setItem("isLogout", true);
        console.log(localStorage);
        updateLogin(false);
        updateCFRUser(null);
        break;
      default:
        console.log("Hub Message: ", event);
    }
  });

  //This function is called after user requests for access and adds entry in pending user table
  const addPendingUser = async (loggedinUser) => {
    console.log("In addPendingUser");
    try {
      let filter = { email: { eq: loggedinUser[0].mail } };
      const response = await API.graphql(
        graphqlOperation(listCFRUsers, { filter: filter })
      );
      if (
        response !== null &&
        response.data != null &&
        response.data.listCFRUsers.items.length > 0
      ) {
        alert.show("You already have access to the application", {
          onClose: () => {
            handleLogout();
          },
        });
      } else {
        const pendingUserObj = {
          email: loggedinUser[0].mail,
          name: loggedinUser[0].displayName,
          isApproved: false,
        };
        const result = await API.graphql(
          graphqlOperation(createCFRPendingUser, { input: pendingUserObj })
        );
        if (result) {
          alert.show("Access Requested", {
            onClose: () => {
              handleLogout();
            },
          });
        }
      }
    } catch (error) {
      console.log(error);
      alert.show(error.message, {
        onClose: () => {
          //handleLogout();
        },
      });
    }
  };

  // this function for get department data
  const getDepartmentList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/user/user.js\n Function Name: getDepartmentList";
    try {
      setIsLoading(true);
      let searchParameter = { isdeleted: { eq: false } };
      const response = await API.graphql(
        graphqlOperation(listCFRDepartments, { filter: searchParameter })
      );
      setIsLoading(false);
      if (response) {
        const _lstDepartment = response.data.listCFRDepartments.items;
        setDepartments(_lstDepartment);
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  });

  // this function for get logged in user data
  const getUsers = useCallback(async () => {
    try {
      if (departments.length > 0) {
        setIsLoading(true);
        const response = await API.graphql(
          graphqlOperation(listCFRUsers, {
            filter: { email: { eq: loggedinUserEmail } },
          })
        );
        if (response) {
          const userList = response.data.listCFRUsers.items;
          console.log(userList);
          userList.forEach((user) => {
            user.department?.items?.forEach((userdept) => {
              let dept = departments.filter(
                (dept) => dept.id === userdept.cFRDepartmentID
              );
              if (dept.length > 0) {
                userdept.name = dept[0].name;
              }
            });
          });
          userList.forEach((user) => {
            if (user.email.toLowerCase() == loggedinUserEmail.toLowerCase()) {
              setLoggedInUser(user);
            }
          });
          EventEmitter.emit("UserGet", {});
          const loggedinUser = getLoggedInUser();
          console.log(loggedinUser);
          if (loggedinUser) {
            if (loggedinUser.isActive) {
              if (loggedinUser.role) {
                if (loggedinUser.role.cfrRoleEntities) {
                  if (loggedinUser.role.cfrRoleEntities.items.length > 0) {
                    let configArr =
                      loggedinUser.role.cfrRoleEntities.items.filter(
                        (privilege) =>
                          privilege.entity.name == Entity.Config &&
                          privilege.entityOperation.name == EntityOperation.View
                      );
                    if (configArr.length > 0) {
                      history.push("/config");
                    } else {
                      let userArr =
                        loggedinUser.role.cfrRoleEntities.items.filter(
                          (privilege) =>
                            privilege.entity.name == Entity.User &&
                            privilege.entityOperation.name ==
                              EntityOperation.View
                        );
                      if (userArr.length > 0) {
                        history.push("/users");
                      } else {
                        let userArr =
                          loggedinUser.role.cfrRoleEntities.items.filter(
                            (privilege) =>
                              privilege.entity.name == Entity.Role &&
                              privilege.entityOperation.name ==
                                EntityOperation.View
                          );
                        if (userArr.length > 0) {
                          history.push("/roles");
                        } else {
                          let userArr =
                            loggedinUser.role.cfrRoleEntities.items.filter(
                              (privilege) =>
                                privilege.entity.name == Entity.Department &&
                                privilege.entityOperation.name ==
                                  EntityOperation.View
                            );
                          if (userArr.length > 0) {
                            history.push("/departments");
                          } else {
                            let userArr =
                              loggedinUser.role.cfrRoleEntities.items.filter(
                                (privilege) =>
                                  privilege.entity.name == Entity.CFR &&
                                  (privilege.entityOperation.name ==
                                    EntityOperation.View ||
                                    privilege.entityOperation.name ==
                                      EntityOperation.Approve)
                              );
                            if (userArr.length > 0) {
                              history.push("/cfrlist");
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            } else {
              let msg =
                "You dont have access to this application. Please request access to your department admin.";
              alert.show(msg, {
                onClose: () => {
                  handleLogout();
                },
              });
            }
          } else {
            let userDetails;
            const res = await API.graphql({
              query: listAzureUsers,
              variables: { EmailSearchKey: loggedinUserEmail },
            });
            if (res && res.data && res.data.listAzureUsers) {
              const result = JSON.parse(res.data.listAzureUsers);
              userDetails = result;
              console.log(userDetails);
            }

            let msg =
              "You dont have access to this application. Please request access to you department admin.";

            alert.show(msg, {
              actions: [
                {
                  copy: "Request Access",
                  onClick: () => addPendingUser(userDetails),
                },
              ],
              onClose: () => {
                //handleLogout();
              },
            });
          }
          setIsLoading(false);
        } else {
          if (response.errors) {
            if (response.errors.length > 0) {
              alert.show(response.errors[0].message);
            }
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            history.push("/");
          },
        });
      }
    }
  });

  // handle click event of logout button
  const handleLogout = async () => {
    try {
      setIsLoading(true);
      removeUserSession();
      const result = await Auth.signOut();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error signing out: ", error);
      alert.show(error.message);
    }
  };

  // call user list on departments list is changed
  useEffect(async () => {
    getUsers();
  }, [departments]);

  return <div></div>;
};

export default Home;
