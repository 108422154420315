import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useAlert } from "react-alert";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { API, graphqlOperation } from "aws-amplify";
import PageLoader from "../../common/loader";
import { isMobile, getLoggedInUser } from "../../Utils/common";
import UserList from "./userList";
import MobileUserList from "./muserList";
import PendingUser from "./pendingUser";
import awsconfig from "../../awsconfig.json";
import {
  listCFRUsers,
  listCFRCapitalFundRequests,
} from "../../graphql/customQueries";
import {
  listCFREmailTemplates,
  sendEmail,
  listCFRDepartments,
} from "../../graphql/queries";
import {
  updateCFRUser,
  deleteCFRUser,
  deleteCFRUserDepartmentMapping,
} from "../../graphql/mutations";
import {
  Roles,
  Entity,
  EntityOperation,
  AppConstants,
} from "../../common/constants";

function User(props) {
  const alert = useAlert();
  const [departments, setDepartments] = useState([]);
  const [userData, setUserData] = useState([]);
  const [env, setEnv] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loggedinUser, setloggedinUser] = useState(null);
  const [isCreatePermission, setIsCreatePermission] = useState(false);
  const [isUpdatePermission, setIsUpdatePermission] = useState(false);
  const [isDeletePermission, setIsDeletePermission] = useState(false);

  // call user list on departments list is changed
  useEffect(async () => {
    getUserList();
  }, [departments]);

  // call department list on page load
  useEffect(async () => {
    setEnvironment();
    await getDepartmentList();
    const loggedinUser = getLoggedInUser();
    setloggedinUser(loggedinUser);
    if (loggedinUser) {
      if (loggedinUser.role.cfrRoleEntities) {
        if (loggedinUser.role.cfrRoleEntities.items.length > 0) {
          let configCreateArr = loggedinUser.role.cfrRoleEntities.items.filter(
            (privilege) =>
              privilege.entity.name == Entity.User &&
              privilege.entityOperation.name == EntityOperation.Create
          );
          if (configCreateArr.length > 0) {
            setIsCreatePermission(true);
          } else {
            setIsCreatePermission(false);
          }
          let configUpdateArr = loggedinUser.role.cfrRoleEntities.items.filter(
            (privilege) =>
              privilege.entity.name == Entity.User &&
              privilege.entityOperation.name == EntityOperation.Update
          );
          if (configUpdateArr.length > 0) {
            setIsUpdatePermission(true);
          } else {
            setIsUpdatePermission(false);
          }
          let configDeleteArr = loggedinUser.role.cfrRoleEntities.items.filter(
            (privilege) =>
              privilege.entity.name == Entity.User &&
              privilege.entityOperation.name == EntityOperation.Delete
          );
          if (configDeleteArr.length > 0) {
            setIsDeletePermission(true);
          } else {
            setIsDeletePermission(false);
          }
        }
      }
    }
  }, []);

  // this function for get department data
  const getDepartmentList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/user/user.js\n Function Name: getDepartmentList";
    try {
      setIsLoading(true);
      let searchParameter = { isdeleted: { eq: false } };
      const response = await API.graphql(
        graphqlOperation(listCFRDepartments, { filter: searchParameter })
      );
      setIsLoading(false);
      if (response) {
        const _lstDepartment = response.data.listCFRDepartments.items;
        setDepartments(_lstDepartment);
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  });

  // this function for get user data
  const getUserList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/user/user.js\n Function Name: getUserList";
    let searchParameter = {
      isDeleted: { eq: false },
    };
    try {
      setIsLoading(true);
      const response = await API.graphql(
        graphqlOperation(listCFRUsers, { filter: searchParameter })
      );
      console.log(response);
      if (response) {
        let _lstUser = response.data.listCFRUsers.items;
        //order list by name
        _lstUser.sort((a, b) => a.name.localeCompare(b.name));
        _lstUser.forEach((user) => {
          user.department?.items?.forEach((userdept) => {
            let dept = departments.filter(
              (dept) => dept.id === userdept.cFRDepartmentID
            );
            if (dept.length > 0) {
              userdept.name = dept[0].name;
            }
          });
        });
        console.log(_lstUser);
        setUserData([]);

        //to remove logged in user from the list
        const loggedInUser = getLoggedInUser();
        if (loggedInUser != null) {
          var users = _lstUser.filter((it) => it.email == loggedInUser.email);
          if (users.length > 0) {
            var index = _lstUser.indexOf(users[0]);
            _lstUser.splice(index, 1);
          }
          if (loggedInUser.role.name == Roles.DEPT_ADMIN) {
            let deptusers = [];
            _lstUser.forEach((user) => {
              user?.department?.items?.forEach((userdept1) => {
                loggedInUser?.department?.items?.forEach((userdept2) => {
                  if (userdept1.cFRDepartmentID == userdept2.cFRDepartmentID) {
                    deptusers.push(user);
                  }
                });
              });
            });

            if (deptusers.length > 0) {
              setUserData(deptusers);
            }
          } else {
            setUserData(_lstUser);
          }
        }

        setIsLoading(false);
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  });

  //this function for redirect in add page
  const HandleAddClick = () => {
    props.history.push("/user/add");
  };

  //this function for redirect in view page
  const viewUserDetails = (userObj, id) => {
    props.history.push({
      pathname: "/user/view/" + id,
      state: {
        user: userObj,
      },
    });
  };

  const setEnvironment = () => {
    //console.log(awsconfig.Auth.redirectSignOut);
    const enviornment = awsconfig.Auth.redirectSignOut;
    //console.log(enviornment);
    setEnv(enviornment);
  };

  // this function is responsible for open confirmation message box
  const deleteUserDetails = (user, id) => {
    alert.show("Are you sure, you want to delete a user ?", {
      title: "Confirmation",
      closeCopy: "Cancel",
      actions: [
        {
          copy: "Yes",
          onClick: () => deleteUserRecord(user, id),
        },
      ],
    });
  };
  // delete  user recored once click on confirm
  const deleteUserRecord = async (user, id) => {
    const errorLocation =
      "Path Name: components/user/user.js\n Function Name: deleteUserRecord";
    try {
      let searchParam = { cFRCapitalFundRequestCfrCreatorId: { eq: id } };
      const resp = await API.graphql(
        graphqlOperation(listCFRCapitalFundRequests, { filter: searchParam })
      );
      if (
        resp.data &&
        resp.data?.listCFRCapitalFundRequests?.items.length > 0
      ) {
        alert.show(
          "This user is currently assigned as a CFR Creator in the application. Please make necessary changes before deleteing this user."
        );
      } else {
        let newData = {
          ...user,
        };
        newData.isDeleted = true;
        delete newData.role;
        delete newData.department;
        delete newData.createdAt;
        delete newData.updatedAt;
        console.log(newData);
        setIsLoading(true);
        const res = await API.graphql(
          graphqlOperation(updateCFRUser, { input: newData })
        );
        console.log("updateCFRUser", res);
        if (res.data) {
          //delete old mapping table entries
          if (user.department) {
            if (user.department.items.length > 0) {
              const deletePromises = user.department.items.map(
                async (oldUserDept) => {
                  let deleteObj = {};
                  deleteObj.id = oldUserDept.id;
                  const userDepartmentMappingDeleteResult = await API.graphql(
                    graphqlOperation(deleteCFRUserDepartmentMapping, {
                      input: deleteObj,
                    })
                  );
                  if (userDepartmentMappingDeleteResult.data) {
                    return userDepartmentMappingDeleteResult.data
                      .deleteCFRUserDepartmentMapping;
                  }
                }
              );
              //wait for all old user department mapping to be deleted
              const allDeleteResult = await Promise.all(deletePromises);
            }
          }
          setIsLoading(false);
          alert.show("User record is deleted.");
          getUserList();
        } else {
          if (res.errors) {
            if (res.errors.length > 0) {
              alert.show(res.errors[0].message);
            }
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  };

  // click on update button and redirect to update page
  const editUserDetails = (userObj, id) => {
    props.history.push({
      pathname: "/user/update/" + id,
      state: userObj,
    });
  };

  // this function is responsible for active toggle button
  const handleToggleChange = (user) => {
    onToggleChange(user);
  };

  //this function is responsible for activation/de activation of user
  async function onToggleChange(data) {
    const errorLocation =
      "Path Name: components/user/user.js\n Function Name: onToggleChange";
    try {
      const id = data.id;
      const email = data.email;
      const name = data.name;
      const limit = data.limit;
      const toLimit = data.toLimit;
      const currencySymbol = data.currencySymbol;
      const departmentadmin = data.departmentadmin;
      const headoffunction = data.headoffunction;
      const isActive = !data.isActive;
      const roleId = data.role.id;

      const newObject = {
        id: id,
        email: email,
        name: name,
        limit: limit,
        toLimit: toLimit,
        currencySymbol: currencySymbol,
        departmentadmin: departmentadmin,
        headoffunction: headoffunction,
        isActive: isActive,
        cFRUserRoleId: roleId,
      };
      // console.log("update");
      // console.log(newObject);
      const result = await API.graphql(
        graphqlOperation(updateCFRUser, { input: newObject })
      );
      if (result.data) {
        const successMessage = "User updated successfully";
        console.log(successMessage);
        getUserList();
        if (result.data.updateCFRUser.isActive === true) {
          let filter = { action: { eq: "REACTIVATE_USER" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            result.data.updateCFRUser.name
          );
          let replaceRole = replaceName.replace(
            "{User Role}",
            result.data.updateCFRUser.role.name
          );
          let finalBody = replaceRole.replace("{Link}", env);

          const emailTemplate = {
            To: result.data.updateCFRUser.email,
            Subject: template.emailsubject,
            MailBody: finalBody,
          };

          console.log(emailTemplate);

          const res = await API.graphql({
            query: sendEmail,
            variables: { EmailTemplate: JSON.stringify(emailTemplate) },
          });
          console.log(res);
        } else {
          let filter = { action: { eq: "DEACTIVATE_USER" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            result.data.updateCFRUser.name
          );
          const emailTemplate = {
            To: result.data.updateCFRUser.email,
            Subject: template.emailsubject,
            MailBody: replaceName,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: { EmailTemplate: JSON.stringify(emailTemplate) },
          });
          console.log(res);
        }
        // alert.show(successMessage, {
        //   type: "success",
        // });
      } else if (result.errors) {
        // if (result.errors.length > 0) {
        //   alert.show(result.errors[0].message);
        // }
        console.log(result.errors);
      }
    } catch (error) {
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
      console.log(error);
    }
  }

  return (
    <div className="page-container">
      <Tabs>
        <TabList>
          <Tab>Users</Tab>
          <Tab>Pending Requests</Tab>
        </TabList>
        <TabPanel>
          <div>
            {isLoading ? <PageLoader></PageLoader> : ""}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-8 col-8">
                  <h4>
                    User List
                    {/* <i className="fa fa-filter btn-filter" onClick={showFilter}></i> */}
                  </h4>
                </div>
                <div className="col-sm-4 col-4">
                  {isCreatePermission == true && (
                    <button
                      type="button"
                      className="btn-grid fa fa-plus"
                      title="Add User"
                      onClick={HandleAddClick}
                    ></button>
                  )}
                </div>
              </div>
            </div>
            <div className="card d-none d-sm-block">
              <div className="card-body">
                <div className="row form-group">
                  <div className="col-sm-12">
                    <UserList
                      userData={userData}
                      actionView={viewUserDetails}
                      actiondelete={deleteUserDetails}
                      actionUpdate={editUserDetails}
                      actionToggle={handleToggleChange}
                      isCreatePermission={isCreatePermission}
                      isUpdatePermission={isUpdatePermission}
                      isDeletePermission={isDeletePermission}
                    ></UserList>
                  </div>
                </div>
              </div>
            </div>

            <MobileUserList
              userData={userData}
              actionView={viewUserDetails}
              actiondelete={deleteUserDetails}
              actionUpdate={editUserDetails}
              isCreatePermission={isCreatePermission}
              isUpdatePermission={isUpdatePermission}
              isDeletePermission={isDeletePermission}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <PendingUser props={props} />
        </TabPanel>
      </Tabs>
    </div>
  );
}
export default User;
