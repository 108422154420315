const Countries = Object.freeze({
  India: "INDIA",
  USA: "USA",
  UK: "UK",
  France: "FRANCE",
  Germany: "GERMANY",
  Spain: "SPAIN",
  Canada: "CANADA",
});

const Currencies = Object.freeze({
  India: "INR",
  USA: "USD",
  UK: "GBP",
  France: "EUR",
  Germany: "EUR",
  Spain: "EUR",
  Canada: "CAD",
});

const Entity = Object.freeze({
  Config: "Config",
  User: "User",
  Role: "Role",
  Department: "Department",
  CFR: "CFR",
});
const EntityOperation = Object.freeze({
  Create: "Create",
  Update: "Update",
  Delete: "Delete",
  View: "View",
  Approve: "Approve",
});

const Roles = Object.freeze({
  SUPER_ADMIN: "Super Admin",
  DEPT_ADMIN: "Department Admin",
  CFR_APPROVER: "CFR Approver",
  CFR_CREATOR: "CFR Creator",
});

const BudgetMultiple = Object.freeze({
  TOTAL_AMT_MULTIPLE: 100000,
});

const AppConstants = Object.freeze({
  PROCUREMENT_DEPT: "Procurement",
  FINANCE_DEPT: "Finance",
  PROCUREMENT_LVL: "Procurement",
  PROVIDER: "Dev-AzureCognitoIdentityProvider",
  IO_MAIL_RECEIVER: ["Pronobesh.Mukherjee@tataconsumer.com"],
  ERROR_MAIL_RECEIVER: "Pronobesh.Mukherjee@tataconsumer.com",
  ERROR_MAIL_RECEIVER_DISPLAY_NAME: "Pronobesh",
  MANUAL_DOWNLOAD_KEY : "CFR_User_Manual-v.1.pdf"
});

const TemplateDownloadKeys = Object.freeze({
  CCPCTemplateKey: "Department-PC-CC-Template.xlsx",
  PlantTemplateKey: "Plant_List_Template.xlsx",
  AssetTemplateKey: "Asset_Data_Template.xlsx",
  BudgetTemplateKey: "Budget_Data_Template.xlsx",
});

export {
  Countries,
  Currencies,
  Entity,
  EntityOperation,
  Roles,
  BudgetMultiple,
  AppConstants,
  TemplateDownloadKeys,
};
