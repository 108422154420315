import { useForm } from "react-hook-form";
import { useState, useEffect, useCallback, useRef } from "react";
import { useAlert } from "react-alert";
import { API, graphqlOperation } from "aws-amplify";
import PageLoader from "../../common/loader";
import { getLoggedInUser } from "../../Utils/common";
import { AppConstants } from "../../common/constants";
import { listCFRUsers } from "../../graphql/customQueries";
import {
  listCFRDepartments,
  listCFREmailTemplates,
  sendEmail,
} from "../../graphql/queries";
import {
  createCFRDepartment,
  updateCFRDepartment,
} from "../../graphql/mutations";

export default function DepartmentAddEdit(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState("Create");
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [userData, setUserData] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [financeBudgtedUsers, setFinanceBudgtedUsers] = useState([]);
  const [financeUnBudgtedUsers, setFinanceUnBudgtedUsers] = useState([]);
  const [procurementBudgtedUsers, setProcurementBudgtedUsers] = useState([]);
  const [procurementUnBudgtedUsers, setProcurementUnBudgtedUsers] = useState(
    []
  );
  const [selFinanceBudgtedUsers, setSelFinanceBudgtedUsers] = useState([]);
  const [selFinanceUnBudgtedUsers, setSelFinanceUnBudgtedUsers] = useState([]);
  const [selProcurementBudgtedUsers, setSelProcurementBudgtedUsers] = useState(
    []
  );
  const [selProcurementUnBudgtedUsers, setSelProcurementUnBudgtedUsers] =
    useState([]);
  const [deptData, setDeptData] = useState([]);
  const alert = useAlert();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  let id;
  let isAddMode = true;
  if (props.location.state != null) {
    id = props.location.state.id;
    isAddMode = !id;
  }

  // called on page load
  useEffect(async () => {
    await getUserList();
    await getDepartmentList();
    await sortBudgetedAndUnBudgetedUsers();
    if (props.location.state != null) {
      if (!isAddMode) {
        setPageTitle("Update");
        const deptobj = props.location.state;
        setSelFinanceBudgtedUsers(deptobj.financeBudgtedUsers);
        setSelFinanceUnBudgtedUsers(deptobj.financeUnBudgtedUsers);
        setSelProcurementBudgtedUsers(deptobj.procurementBudgtedUsers);
        setSelProcurementUnBudgtedUsers(deptobj.procurementUnBudgtedUsers);
        var fields = ["id", "name", "details"];

        fields.forEach((field) => {
          setValue(field, deptobj[field]);
        });
      }
    }
  }, []);

  // this function for get user data
  const getUserList = async function () {
    const errorLocation =
      "Path Name: components/department/departmentAddEdit.js\n Function Name: getUserList";
    try {
      setIsLoading(true);
      const response = await API.graphql(graphqlOperation(listCFRUsers));
      if (response) {
        const _lstUser = response.data.listCFRUsers.items;
        setUserData([]);
        var dropdownData = [];
        _lstUser.forEach((user) => {
          let data = {};
          data.label = user.name;
          data.value = user.id;
          dropdownData.push(data);
        });
        setUserOptions(dropdownData);
        setIsLoading(false);
        setUserData(_lstUser);
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  };

  // this function for get department data
  const getDepartmentList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/department/departmentAddEdit.js\n Function Name: getDepartmentList";
    try {
      setIsLoading(true);
      let searchParameter = { isdeleted: { eq: false } };
      const _deptData = await API.graphql(
        graphqlOperation(listCFRDepartments, { filter: searchParameter })
      );

      if (_deptData) {
        const deptList = _deptData.data.listCFRDepartments.items;
        console.log(deptList);
        setDeptData([]);
        setIsLoading(false);
        setDeptData(deptList);
      } else {
        if (_deptData.errors) {
          if (_deptData.errors.length > 0) {
            alert.show(_deptData.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  });

  // sort budgeted and unbudgeted users
  const sortBudgetedAndUnBudgetedUsers = async () => {
    const _userData = await API.graphql(graphqlOperation(listCFRUsers));
    const _lstUser = _userData.data.listCFRUsers.items;
    let searchParameter = { isdeleted: { eq: false } };
    const _deptData = await API.graphql(
      graphqlOperation(listCFRDepartments, { filter: searchParameter })
    );
    const _deptList = _deptData.data.listCFRDepartments.items;
    let globalUsers = [];
    let financeId;
    let procurementId;
    let _budgtedUsers = [];
    let _unBudgtedUsers = [];
    let _financeBudgtedUsers = [];
    let _financeUnBudgtedUsers = [];
    let _procurementBudgtedUsers = [];
    let _procurementUnBudgtedUsers = [];
    _lstUser.map((user) => {
      if (user.isGlobalUser === true) {
        globalUsers.push(user);
      }
    });
    globalUsers.map((user) => {
      if (user.canApproveBudgetedCFR === true) {
        _budgtedUsers.push(user);
      }
      if (user.canApproveUnBudgetedCFR === true) {
        _unBudgtedUsers.push(user);
      }
    });

    _deptList.map((dept) => {
      if (dept.name === AppConstants.FINANCE_DEPT) {
        financeId = dept.id;
      } else if (dept.name === AppConstants.PROCUREMENT_DEPT) {
        procurementId = dept.id;
      }
    });

    _budgtedUsers.map((user) => {
      if (user.department) {
        if (user.department.items.length > 0) {
          const isFinanceDept = user.department.items.filter(
            (userdept) => userdept.cFRDepartmentID === financeId
          );
          if (isFinanceDept.length > 0) {
            _financeBudgtedUsers.push(user);
          }
          const isProcurementDept = user.department.items.filter(
            (userdept) => userdept.cFRDepartmentID === procurementId
          );
          if (isProcurementDept.length > 0) {
            _procurementBudgtedUsers.push(user);
          }
        }
      }
    });

    _unBudgtedUsers.map((user) => {
      if (user.department) {
        if (user.department.items.length > 0) {
          const isFinanceDept = user.department.items.filter(
            (userdept) => userdept.cFRDepartmentID === financeId
          );
          if (isFinanceDept.length > 0) {
            _financeUnBudgtedUsers.push(user);
          }
          const isProcurementDept = user.department.items.filter(
            (userdept) => userdept.cFRDepartmentID === procurementId
          );
          if (isProcurementDept.length > 0) {
            _procurementUnBudgtedUsers.push(user);
          }
        }
      }
    });

    setFinanceBudgtedUsers(_financeBudgtedUsers);
    setFinanceUnBudgtedUsers(_financeUnBudgtedUsers);
    setProcurementBudgtedUsers(_procurementBudgtedUsers);
    setProcurementUnBudgtedUsers(_procurementUnBudgtedUsers);
  };

  //handle procurement budgeted users check event
  const handleProcurementBudgtedUsers = (e, i) => {
    // console.log(e);
    // console.log(i);
    if (
      selProcurementBudgtedUsers &&
      selProcurementBudgtedUsers.includes(e.id)
    ) {
      let newArray = selProcurementBudgtedUsers.filter((user) => user !== e.id);
      setSelProcurementBudgtedUsers(newArray);
      forceUpdate();
    } else if (selProcurementBudgtedUsers) {
      let newArray = selProcurementBudgtedUsers;
      newArray.push(e.id);
      setSelProcurementBudgtedUsers(newArray);
      forceUpdate();
    } else {
      let newArray = [];
      newArray.push(e.id);
      setSelProcurementBudgtedUsers(newArray);
      forceUpdate();
    }
  };

  //handle procurement unbudgeted users check event
  const handleProcurementUnBudgtedUsers = (e, i) => {
    // console.log(e);
    // console.log(i);
    if (
      selProcurementUnBudgtedUsers &&
      selProcurementUnBudgtedUsers.includes(e.id)
    ) {
      let newArray = selProcurementUnBudgtedUsers.filter(
        (user) => user !== e.id
      );
      setSelProcurementUnBudgtedUsers(newArray);
      forceUpdate();
    } else if (selProcurementUnBudgtedUsers) {
      let newArray = selProcurementUnBudgtedUsers;
      newArray.push(e.id);
      setSelProcurementUnBudgtedUsers(newArray);
      forceUpdate();
    } else {
      let newArray = [];
      newArray.push(e.id);
      setSelProcurementUnBudgtedUsers(newArray);
    }
  };

  //handle finance budgeted users check event
  const handleFinanceBudgtedUsers = (e, i) => {
    // console.log(e);
    // console.log(i);
    if (selFinanceBudgtedUsers && selFinanceBudgtedUsers.includes(e.id)) {
      let newArray = selFinanceBudgtedUsers.filter((user) => user !== e.id);
      setSelFinanceBudgtedUsers(newArray);
      forceUpdate();
    } else if (selFinanceBudgtedUsers) {
      let newArray = selFinanceBudgtedUsers;
      newArray.push(e.id);
      setSelFinanceBudgtedUsers(newArray);
      forceUpdate();
    } else {
      let newArray = [];
      newArray.push(e.id);
      setSelFinanceBudgtedUsers(newArray);
      forceUpdate();
    }
  };

  //handle finance unbudgeted users check event
  const handleFinanceUnBudgtedUsers = (e, i) => {
    // console.log(e);
    // console.log(i);
    if (selFinanceUnBudgtedUsers && selFinanceUnBudgtedUsers.includes(e.id)) {
      let newArray = selFinanceUnBudgtedUsers.filter((user) => user !== e.id);
      setSelFinanceUnBudgtedUsers(newArray);
      forceUpdate();
    } else if (selFinanceUnBudgtedUsers) {
      let newArray = selFinanceUnBudgtedUsers;
      newArray.push(e.id);
      setSelFinanceUnBudgtedUsers(newArray);
      forceUpdate();
    } else {
      let newArray = [];
      newArray.push(e.id);
      setSelFinanceUnBudgtedUsers(newArray);
      forceUpdate();
    }
  };

  /// this function for create and update department record
  async function submitForm(data) {
    const errorLocation =
      "Path Name: components/department/departmentAddEdit.js\n Function Name: submitForm";
    try {
      setIsLoading(true);
      if (isAddMode) {
        console.log("add");

        let searchParameter = {
          and: { name: { eq: data.name.trim() }, isdeleted: { eq: false } },
        };
        const resp = await API.graphql(
          graphqlOperation(listCFRDepartments, { filter: searchParameter })
        );
        if (
          resp != null &&
          resp.data.listCFRDepartments != null &&
          resp.data.listCFRDepartments.items.length > 0
        ) {
          alert.show("The department already present.");
          setIsLoading(false);
          return;
        }
        const newData = {
          ...data,
          name: data.name.trim(),
          isdeleted: false,
          financeBudgtedUsers: selFinanceBudgtedUsers,
          financeUnBudgtedUsers: selFinanceUnBudgtedUsers,
          procurementBudgtedUsers: selProcurementBudgtedUsers,
          procurementUnBudgtedUsers: selProcurementUnBudgtedUsers,
        };
        console.log(newData);

        const result = await API.graphql(
          graphqlOperation(createCFRDepartment, { input: newData })
        );

        if (result.data) {
          const successMessage = "Department created successfully.";
          alert.show(successMessage, {
            type: "success",
            onClose: () => {
              props.history.push("/departments");
            }, // callback that
          });
        } else if (result.errors) {
          if (result.errors.length > 0) {
            alert.show(result.errors[0].message);
          }
        }
        setIsLoading(false);
      } else {
        console.log("update");
        const newData = {
          ...data,
          isdeleted: false,
          financeBudgtedUsers: selFinanceBudgtedUsers,
          financeUnBudgtedUsers: selFinanceUnBudgtedUsers,
          procurementBudgtedUsers: selProcurementBudgtedUsers,
          procurementUnBudgtedUsers: selProcurementUnBudgtedUsers,
        };
        console.log(newData);

        const result = await API.graphql(
          graphqlOperation(updateCFRDepartment, { input: newData })
        );

        if (result.data) {
          const successMessage = "Department updated successfully.";
          alert.show(successMessage, {
            type: "success",
            onClose: () => {
              props.history.push("/departments");
            }, // callback that
          });
        } else if (result.errors) {
          if (result.errors.length > 0) {
            alert.show(result.errors[0].message);
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  }

  return (
    <div className="page-container">
      {isLoading ? <PageLoader></PageLoader> : ""}
      <div className="page-header">
        <h4> {pageTitle} Department </h4>
      </div>
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-4  mb-3">
                <label className="form-label">
                  Name <span> * </span>
                </label>
                <input
                  type="text"
                  id="txtName"
                  name="name"
                  readOnly={id}
                  className="form-control"
                  {...register("name", {
                    required: true,
                    minLength: 1,
                    maxLength: 100,
                  })}
                />
                {errors.name && errors.name.type === "required" && (
                  <div className="error-msg">Name is required.</div>
                )}
                {errors.name && errors.name.type === "minLength" && (
                  <div className="error-msg">
                    Name should contain atleast 1 characters.
                  </div>
                )}
                {errors.name && errors.name.type === "maxLength" && (
                  <div className="error-msg">
                    Name should not be greter than 100 characters.
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4  mb-3">
                <label className="form-label">Details</label>
                <textarea
                  id="txtDetails"
                  name="details"
                  className="form-control"
                  {...register("details")}
                  rows="3"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-header">
                    <label className="form-label">Procurement</label>
                  </div>
                  <div className="card-body">
                    <label className="form-label">Budgeted Users</label>
                    {Array.from(procurementBudgtedUsers, (e, i) => {
                      return (
                        <table key={i}>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  value={e}
                                  checked={
                                    selProcurementBudgtedUsers &&
                                    selProcurementBudgtedUsers.includes(e.id)
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    handleProcurementBudgtedUsers(e, i)
                                  }
                                />
                                {e.name}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      );
                    })}
                    <hr></hr>
                    <label className="form-label">Unbudgeted Users</label>
                    {Array.from(procurementUnBudgtedUsers, (e, i) => {
                      return (
                        <table key={i}>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  value={e}
                                  checked={
                                    selProcurementUnBudgtedUsers &&
                                    selProcurementUnBudgtedUsers.includes(e.id)
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    handleProcurementUnBudgtedUsers(e, i)
                                  }
                                />
                                {e.name}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-header">
                    <label className="form-label">Finance</label>
                  </div>
                  <div className="card-body">
                    <label className="form-label">Budgeted Users</label>
                    {Array.from(financeBudgtedUsers, (e, i) => {
                      return (
                        <table key={i}>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  value={e}
                                  checked={
                                    selFinanceBudgtedUsers &&
                                    selFinanceBudgtedUsers.includes(e.id)
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    handleFinanceBudgtedUsers(e, i)
                                  }
                                />
                                {e.name}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      );
                    })}
                    <hr></hr>
                    <label className="form-label">Unbudgeted Users</label>
                    {Array.from(financeUnBudgtedUsers, (e, i) => {
                      return (
                        <table key={i}>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  value={e}
                                  checked={
                                    selFinanceUnBudgtedUsers &&
                                    selFinanceUnBudgtedUsers.includes(e.id)
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    handleFinanceUnBudgtedUsers(e, i)
                                  }
                                />
                                {e.name}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-footer">
          <div className="row">
            <div className="col-sm-12">
              <button type="submit" className="btn btn-sm btn-primary">
                Save
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary ml-10"
                onClick={() => props.history.goBack()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
