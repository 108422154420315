import { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import PageLoader from "../../common/loader";
import { API, graphqlOperation } from "aws-amplify";
import {
  listCFREntities,
  listCFREntityOperations,
} from "../../graphql/queries";
import MaterialTable from "material-table";

function RoleView(props) {
  const [rolePrivilegeData, setrolePrivilegeData] = useState([]);
  const [rowsToBeSelected, setrowsToBeSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const alert = useAlert();
  let privilegeData = [];
  let roleObj;
  if (props.location.state) {
    roleObj = props.location.state;
    console.log(roleObj);
  }
  // this function for get entity data
  const getEntityList = async function () {
    try {
      setIsLoading(true);
      const response = await API.graphql(graphqlOperation(listCFREntities));
      if (response) {
        const entityData = response.data.listCFREntities.items;
        entityData.forEach((entity) => {
          entity.entityId = null;
          entity.type = "Entity";
          privilegeData.push(entity);
        });
        setIsLoading(false);
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  };

  // this function for get operations data
  const getOperationsList = async function () {
    try {
      setIsLoading(true);
      const response = await API.graphql(
        graphqlOperation(listCFREntityOperations)
      );
      if (response) {
        const entityOperations = response.data.listCFREntityOperations.items;
        entityOperations.forEach((operation) => {
          operation.entityId = operation.entity.id;
          operation.type = "Operation";
          privilegeData.push(operation);
        });
        setIsLoading(false);
        setrolePrivilegeData(privilegeData);
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  };

  useEffect(async () => {
    setrolePrivilegeData([]);
    await getEntityList();
    await getOperationsList();
    if (props.location.state != null) {
      roleObj = props.location.state;
      let existingPrivileges = [];
      if (roleObj.cfrRoleEntities) {
        if (roleObj.cfrRoleEntities.items.length > 0) {
          roleObj.cfrRoleEntities.items.forEach((privilege) => {
            if (privilege.entityOperation) {
              let temp = {};
              temp.id = privilege.entityOperation.id;
              temp.operationName = privilege.entityOperation.name;
              temp.entityName = privilege.entity.name;
              existingPrivileges.push(temp);
            }
          });
        }
        setrowsToBeSelected(existingPrivileges);
      }
    }
  }, []);

  return (
    <div className="page-container">
      {isLoading ? <PageLoader></PageLoader> : ""}
      <div className="page-header">
        <h4> Role Details </h4>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Name </label>
              <div> {roleObj.name} </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Details </label>
              <div> {roleObj.details}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-none d-sm-block">
        <MaterialTable
          data={rolePrivilegeData.map((row) =>
            rowsToBeSelected.find((selected) => selected.id === row.id)
              ? { ...row, tableData: { checked: true } }
              : row
          )}
          columns={[{ title: "Name", field: "name" }]}
          parentChildData={(row, rows) =>
            rows.find((a) => a.id === row.entityId)
          }
          options={{
            toolbar: false,
            selection: true,
            showTitle: false,
            showTextRowsSelected: false,
            showSelectAllCheckbox: true,
            search: false,
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#1468b2",
              color: "#FFF",
              fontWeight: "600",
            },
            selectionProps: (rowData) => ({
              color: "primary",
              disabled: true,
            }),
          }}
        />
      </div>
      <div className="form-footer">
        <div className="row">
          <div className="col-sm-12">
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary ml-10"
              onClick={() => props.history.goBack()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default RoleView;
