import { useForm } from "react-hook-form";
import { useState, useEffect, useCallback } from "react";
import { useAlert } from "react-alert";
import {
  Roles,
  Entity,
  EntityOperation,
  AppConstants,
} from "../../common/constants";
import PageLoader from "../../common/loader";
import awsExports from "../../aws-exports";
import { API, graphqlOperation, Storage } from "aws-amplify";
import {
  sendEmail,
  listCFRUsers,
  listAzureUsers,
  listCFRRoles,
  listCFRCapitalFundRequests,
} from "../../graphql/customQueries";
import {
  listCFRConfigs,
  listCFREmailTemplates,
  getCFRCapitalFundRequest,
  listCFRPlants,
  listCFRAssetClasses,
  listCFRBudgetReferences,
  listCFRApprovalLevels,
  listCFRDepartments,
  listCFRUserNotifications,
  listCFRMultipleBudgetReferences,
  listCFRRiskAndMitigations,
  listCFRTimelineActivities
} from "../../graphql/queries";
import {
  createCFRUser,
  createCFRCapitalFundRequest,
  updateCFRCapitalFundRequest,
  createCFRApprover,
  updateCFRApprover,
  createCFRResource,
  updateCFRResource,
  createCFRS3Object,
  deleteCFRS3Object,
  updateCFRBudgetReference,
  createCFRUserDepartmentMapping,
  createCFRMultipleBudgetReference,
  deleteCFRMultipleBudgetReference,
  createCFRRiskAndMitigation,
  createCFRTimelineActivity,
  deleteCFRRiskAndMitigation,
  deleteCFRTimelineActivity
} from "../../graphql/mutations";
import { getLoggedInUser } from "../../Utils/common";
import Modal from "react-modal";
import _ from "lodash";
import "../../assets/stylesheet/collapsable-panel.css";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { event } from "jquery";
import awsconfig from "../../awsconfig.json";

export default function CFRChangeRequest(props) {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [cfrVersion, setcfrVersion] = useState("");
  const [cfrForUpdate, setcfrForUpdate] = useState(1);
  const [isCapitalBudget, setIsCapitalBudget] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState("Create");
  const [allUsers, setAllUsers] = useState([]);
  const [userData, setUserData] = useState([]);
  const [deptData, setDeptData] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [userNotificationsForEmail, setUserNotificationsForEmail] = useState(
    []
  );
  const [userOptions, setUserOptions] = useState([]);
  const [approvallevels, setapprovallevels] = useState([]);
  const [assetLife, setAssetLife] = useState(0);
  const [selectedBudgetRefs, setSelectedBudgetRefs] = useState([]);
  const [selectedBudgetRefIdList, setSelectedBudgetRefIdList] = useState([]);
  const [openBudgetRefs, setOpenBudgetRefs] = useState([]);
  const [env, setEnv] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const [capExRows, setCapExRows] = useState([
    {
      capex_resource: "",
      capex_category: "",
      capex_quantity: "",
      capex_unitcost: "",
      capex_totalunitcost: "",
      // capex_life: 0,
      // capex_assetName: "Select",
      // capex_evaluationCategory: "Select",
      // capex_description: "",
      //capex_assetDict: [],
    },
  ]);
  const [revExRows, setRevExRows] = useState([
    {
      revex_resource: "",
      // revex_start_year: "",
      // revex_end_year: "",
      revex_cost: "",
    },
  ]);

  const [modalCancelVisible, setModalCancelVisible] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [colWidth, setColWidth] = useState(0);

  const [currency, setCurrency] = useState("");
  const [currencylogo, setCurrencylogo] = useState("");
  const [capex_total, setCapex_total] = useState(0);
  const [capex_contingency, setCapex_contingency] = useState(0);
  const [capExContingencyChecked, setCapExContingencyChecked] = useState(false);
  const [capExContingencyType, setCapExContingencyType] =
    useState("Percentage");
  const [contingencyPercentage, setContingencyPercentage] = useState(0);
  const [revex_total, setRevex_total] = useState(0);
  const [capexValueChanged, setCapexValueChanged] = useState(false);
  const [revexValueChanged, setRevexValueChanged] = useState(false);
  const [yearlyCapexValues, setYearlyCapexValues] = useState([]);
  const [yearlyRevexValues, setYearlyRevexValues] = useState([]);
  const [costValues, setCostValues] = useState([]);
  const [totalSavings, setTotalSavings] = useState([{ Saving: "0" }]);
  const [cashFlowValues, setCashFlowValues] = useState([]);
  const [cumulativeCFValues, setCumulativeCFValues] = useState([]);
  const [positiveCashFlowValues, setPositiveCashFlowValues] = useState([]);
  const [pvValues, setPvValues] = useState([]);
  const [pvOfCashflowValues, setPvOfCashflowValues] = useState([]);
  const [cumulativePVValues, setCumulativePVValues] = useState([]);
  const [interestRate, setInterestRate] = useState(9);
  const [undiscountedPaybackPeriod, setUndiscountedPaybackPeriod] = useState(0);
  const [partialYearPaybackPeriod, setPartialYearPaybackPeriod] = useState(0);
  const [irrValue, setIRRValue] = useState(0);
  const [npvValue, setNPVValue] = useState(0);
  const [geography, setGeography] = useState("");
  const [cfrUploadedFiles, setCfrUploadedFiles] = useState([]);
  const [cfrUploadedExistingFiles, setCfrUploadedExistingFiles] = useState([]);
  const [isNpvCalBtnDisabled, setIsNpvCalBtnDisabled] = useState(true);
  const [plants, setPlants] = useState([]);
  const [masterCfrId, setMasterCfrId] = useState("");
  const [lstAzureUsers, setLstAzureUsers] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [budgetReferenceNumber, setBudgetReferenceNumber] = useState([]);
  const [selectedIsBudgetedOption, setSelectedIsBudgetedOption] = useState("");
  const [userDataForEmail, setUserDataForEmail] = useState([]);
  const [selectedBudgetReferenceOption, setSelectedBudgetReferenceOption] =
    useState(null);
  const [configData, setConfigData] = useState([]);
  const [budgetBenefit, setBudgetBenefit] = useState("");
  const [capexAnalysisPeriod, setCapexAnalysisPeriod] = useState(1);
  const [capexAnalysisPeriodArray, setcapexAnalysisPeriodArray] = useState([]);

  const [risks, setRisks] = useState([
    {
      risk: "",
      mitigation: "",
      category: "",
      occurrence: "",
      consequence: "",
      owner: "",
    },
  ]);
  const [timelineActivities, setTimelineActivities] = useState([
    {
      activityName: "",
      activityDate: "",
    },
  ]);

  const verticalAlign = {
    verticalAlign: "middle"
  }

  const customStyles = {
    content: {
      top: "15%",
      left: "55%",
      right: "auto",
      bottom: "auto",
      width: "80%",
      height: "80%",
      transform: "translate(-50%, 0%)",
    },
    overlay: {
      backgroundColor: "#e9eaec",
    },
  };

  const alert = useAlert();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  let id;
  let isAddMode = true;

  let cfrObj;
  if (props.location.state) {
    cfrObj = props.location.state;

    if (props.location.pathname.indexOf("update") > -1) {
      isAddMode = false;
    }
  }

  function validateCFR(cfrdata) {
    // if (!cfrdata.projecttitle) {
    //   alert.show("Please enter project title.");
    //   return false;
    // }
    // if (!cfrdata.territory) {
    //   alert.show("Please enter geography.");
    //   return false;
    // }
    // if (cfrdata.iscapitalbudget == "Yes") {
    //   if (!cfrdata.budgetReferenceNo) {
    //     alert.show("Please enter budget reference number.");
    //     return false;
    //   }
    // }
    if (!cfrdata.proposalvalue) {
      alert.show("Please enter proposed value.");
      return false;
    }
    // if (cfrdata.budgetReferenceNo) {
    //   const selectedBudgetReference = budgetReferenceNumber.filter(
    //     (budget) => budget.budgetReferenceNo == cfrdata.budgetReferenceNo
    //   );
    //   if (selectedBudgetReference) {
    //     if (selectedBudgetReference.length > 0) {
    //       const budgetData = selectedBudgetReference[0];
    //       if (
    //         parseFloat(cfrdata.proposalvalue) >
    //         parseFloat(budgetData.remainingAmount) * 1
    //       ) {
    //         alert.show(
    //           "Please enter proposal value less than remaining amount in budget. Remaining amount is " +
    //             parseFloat(budgetData.remainingAmount) * 1
    //         );
    //         return false;
    //       }
    //     }
    //   }
    // }

    // if (!cfrdata.assetlife) {
    //   alert.show("Please enter asset life in years.");
    //   return false;
    // }
    if (!cfrdata.cfrbenefits) {
      alert.show("Please enter cfr benefits.");
      return false;
    }
    if (cfrdata.proposalvalue) {
      if (capex_total) {
        if (parseFloat(capex_total) > parseFloat(cfrdata.proposalvalue)) {
          alert.show(
            "Total CapEx value should be less than or equal to proposed value."
          );
          return false;
        }
      }
    }
    if (!cfrdata.expectedCompletionDate) {
      alert.show("Please select expected completion date.");
      return false;
    }
    if (cfrdata.expectedCompletionDate) {
      if (
        new Date(cfrdata.expectedCompletionDate).getTime() <
        new Date().getTime()
      ) {
        alert.show("Expected completion date can not be in the past.");
        return false;
      }
    }
    if (capexValueChanged == true || revexValueChanged == true) {
      alert.show("Please re calculate NPV.");
      return false;
    }
    return true;
  }

  function hasDuplicates(array) {
    return new Set(array).size !== array.length;
  }

  /// this function for Change CFR request record
  async function onCFRSubmit(data, event) {
    if (event.nativeEvent.submitter.id == "submitCFR") {
      let isValid = validateCFR(data);
      if (!isValid) {
        return;
      }
    }

    if (data.projectsponsoremail) {
      var selectedProjectSponsor = null;
      var isUserPresentInSystem = false;
      let searchParameter = {};
      searchParameter.email = { contains: data.projectsponsoremail };
      setIsLoading(true);
      const userResponse = await API.graphql(
        graphqlOperation(listCFRUsers, { filter: searchParameter })
      );
      if (userResponse.data.listCFRUsers) {
        if (userResponse.data.listCFRUsers.items.length == 0) {
          isUserPresentInSystem = false;
        } else {
          isUserPresentInSystem = true;
          selectedProjectSponsor = userResponse.data.listCFRUsers.items[0];
        }
      }
      if (isUserPresentInSystem) {
        data["Project Sponsor"] = selectedProjectSponsor.id;
        const loggedInUser = getLoggedInUser();
        if (loggedInUser) {
          if (loggedInUser.id == selectedProjectSponsor.id) {
            alert.show("CFR creator cannot be project sponsor.");
            setIsLoading(false);
            return;
          }
        }
        postCfrChangeRequest(data, event);
      } else {
        let user = {};
        user.email = data.projectsponsoremail;
        user.name = data.projectsponsorname;
        const roleResponse = await API.graphql(graphqlOperation(listCFRRoles));
        if (roleResponse.data.listCFRRoles) {
          if (roleResponse.data.listCFRRoles.items.length > 0) {
            let roleData = roleResponse.data.listCFRRoles.items;
            roleData
              .filter(
                (role) =>
                  role.name !== Roles.SUPER_ADMIN &&
                  role.name !== Roles.DEPT_ADMIN
              )
              .forEach((role) => {
                if (role.cfrRoleEntities) {
                  if (role.cfrRoleEntities.items.length > 0) {
                    role.cfrRoleEntities.items.forEach((permission) => {
                      if (
                        permission.entity.name == Entity.CFR &&
                        permission.entityOperation.name ==
                        EntityOperation.Approve
                      ) {
                        user.cFRUserRoleId = role.id;
                      }
                    });
                  }
                }
              });
          }
        }
        user.departmentadmin = false;
        user.headoffunction = false;
        user.isActive = true;
        user.limit = 0;
        user.toLimit = 1;
        user.currencySymbol = "₹";
        const userResponse = await API.graphql(
          graphqlOperation(createCFRUser, { input: user })
        );
        if (userResponse.data) {
          if (userResponse.data.createCFRUser) {
            const loggedInUser = getLoggedInUser();
            if (loggedInUser) {
              if (loggedInUser.department) {
                let userDepartmentMappingArray = [];
                loggedInUser.department.items.map((userdept) => {
                  let mappingObj = {};
                  mappingObj.cFRDepartmentID = userdept.cFRDepartmentID;
                  mappingObj.cFRUserID = userResponse.data.createCFRUser.id;
                  userDepartmentMappingArray.push(mappingObj);
                });
                //create an entry for each user department mapping
                const promises = userDepartmentMappingArray.map(
                  async (level) => {
                    const userDepartmentMappingResult = await API.graphql(
                      graphqlOperation(createCFRUserDepartmentMapping, {
                        input: level,
                      })
                    );
                    if (userDepartmentMappingResult.data) {
                      return userDepartmentMappingResult.data
                        .createCFRUserDepartmentMapping;
                    }
                  }
                );
                //wait for all user department mapping to be created
                const allresult = await Promise.all(promises);
              }
            }
            data["Project Sponsor"] = userResponse.data.createCFRUser.id;
            postCfrChangeRequest(data, event);
          }
        }
      }
    } else {
      data["Project Sponsor"] = "";
      postCfrChangeRequest(data, event);
    }
  }

  async function postCfrChangeRequest(data, event) {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: postCfrChangeRequest";
    data.iscapitalbudget = data.iscapitalbudget == "Yes" ? true : false;
    data.budgetvalue = data.budgetvalue ? parseFloat(data.budgetvalue) : 0;
    data.proposalvalue = data.proposalvalue
      ? parseFloat(data.proposalvalue)
      : 0;
    data.irr = data.irr;
    data.npv = data.npv ? parseFloat(data.npv) : 0;
    data.payback = data.payback;
    data.assetlife = data.assetlife ? parseFloat(data.assetlife) : 0;
    data.cfrsubmissiondate = new Date().toISOString();
    data.version = "CR " + cfrVersion;
    data.projecttitle = cfrObj.projecttitle;
    data.territory = cfrObj.territory;
    data.location = cfrObj.location;
    data.budgetReferenceNo = cfrObj.budgetReferenceNo;
    data.masterCFRID = masterCfrId;
    data.budgetbenefits = budgetBenefit;
    if (data.expectedCompletionDate) {
      data.expectedCompletionDate = new Date(
        data.expectedCompletionDate
      ).toISOString();
    } else {
      data.expectedCompletionDate = null;
    }

    if (event.nativeEvent.submitter.id == "submitCFR") {
      data.cfrstatus = "submitted";
    } else if (event.nativeEvent.submitter.id == "draftCFR") {
      data.cfrstatus = "draft";
    }

    let npvdata = {
      assetLife: assetLife,
      capExRows: capExRows,
      revExRows: revExRows,
      capExContingencyChecked: capExContingencyChecked,
      capExContingencyType: capExContingencyType,
      contingencyPercentage: contingencyPercentage,
      capex_contingency: capex_contingency,
      capex_total: capex_total,
      currency: currency,
      currencySymbol: currencylogo,
      interestRate: interestRate,
      npvValue: npvValue,
      irrValue: irrValue,
      revex_total: revex_total,
      geography: geography,
      paybackYear: data.payback,
      totalSavings: totalSavings,
      yearlyCapexValues: yearlyCapexValues,
      yearlyRevexValues: yearlyRevexValues,
      costValues: costValues,
      capexAnalysisPeriod: capexAnalysisPeriod,
    };

    data.npvjson = JSON.stringify(npvdata);
    console.log(data);

    if (cfrForUpdate.cfrstatus == "needammendment") {
      data.masterCFRID = data.id;
      data.version = data.version + ".1";
      setcfrVersion(data.version);
      forceUpdate();
      delete data.id;
      delete data.createdAt;
      delete data.updatedAt;
      isAddMode = true;
      if (event.nativeEvent.submitter.id == "submitCFR") {
        updateMasterCFR("ammended");
      } else if (event.nativeEvent.submitter.id == "draftCFR") {
        updateMasterCFR("ammendmentinprocess");
      }
    }
    console.log(data);

    try {
      setIsLoading(true);
      const loggedInUser = getLoggedInUser();
      if (isAddMode) {
        console.log("add");
        let cfrApprovals = [];
        approvallevels.forEach((approvalLevel, index) => {
          if (approvalLevel.sequencenumber == 1) {
            let tempdata = {};
            tempdata.cfrVersion = cfrVersion;
            tempdata.approvalstatus = "pending";
            tempdata.approverjustification = null;
            tempdata.approvalDateTime = null;
            tempdata.expectedCfrApprovers = [data["Project Sponsor"]];
            tempdata.cFRApproverCfrId = null;
            tempdata.cFRApproverApprovalLevelId = approvalLevel.id;
            tempdata.cFRApproverActualCfrApproverId = null;
            cfrApprovals.push(tempdata);
          } else if (approvalLevel.sequencenumber == 2) {
            let selectedUsers = [];
            deptData.map((dept) => {
              if (loggedInUser.department) {
                if (loggedInUser.department.items.length > 0) {
                  if (
                    dept.id === loggedInUser.department.items[0].cFRDepartmentID
                  ) {
                    const budgeted = getValues("iscapitalbudget");
                    if (budgeted === "Yes") {
                      selectedUsers = dept.procurementBudgtedUsers;
                    } else {
                      selectedUsers = dept.procurementUnBudgtedUsers;
                    }
                  }
                }
              }
            });
            let tempdata = {};
            tempdata.cfrVersion = cfrVersion;
            tempdata.approvalstatus = "pending";
            tempdata.approverjustification = null;
            tempdata.approvalDateTime = null;
            tempdata.cFRApproverCfrId = null;
            tempdata.cFRApproverApprovalLevelId = approvalLevel.id;
            tempdata.cFRApproverActualCfrApproverId = null;
            tempdata.expectedCfrApprovers = selectedUsers;
            cfrApprovals.push(tempdata);
          } else if (approvalLevel.sequencenumber == 3) {
            let tempdata = {};
            tempdata.cfrVersion = cfrVersion;
            tempdata.approvalstatus = "pending";
            tempdata.approverjustification = null;
            tempdata.approvalDateTime = null;
            tempdata.cFRApproverCfrId = null;
            tempdata.cFRApproverApprovalLevelId = approvalLevel.id;
            tempdata.cFRApproverActualCfrApproverId = null;
            tempdata.expectedCfrApprovers = [data["functionalHead"]];
            cfrApprovals.push(tempdata);
          } else if (approvalLevel.sequencenumber == 4) {
            let selectedUsers = [];
            deptData.map((dept) => {
              if (loggedInUser.department) {
                if (loggedInUser.department.items.length > 0) {
                  if (
                    dept.id === loggedInUser.department.items[0].cFRDepartmentID
                  ) {
                    const budgeted = getValues("iscapitalbudget");
                    if (budgeted === "Yes") {
                      selectedUsers = dept.financeBudgtedUsers;
                    } else {
                      selectedUsers = dept.financeUnBudgtedUsers;
                    }
                  }
                }
              }
            });
            let tempdata = {};
            tempdata.cfrVersion = cfrVersion;
            tempdata.approvalstatus = "pending";
            tempdata.approverjustification = null;
            tempdata.approvalDateTime = null;
            tempdata.cFRApproverCfrId = null;
            tempdata.cFRApproverApprovalLevelId = approvalLevel.id;
            tempdata.cFRApproverActualCfrApproverId = null;
            tempdata.expectedCfrApprovers = selectedUsers;
            cfrApprovals.push(tempdata);
          } else {
            let tempdata = {};
            tempdata.cfrVersion = cfrVersion;
            tempdata.approvalstatus = "pending";
            tempdata.approverjustification = null;
            tempdata.approvalDateTime = null;
            tempdata.cFRApproverCfrId = null;
            tempdata.cFRApproverApprovalLevelId = approvalLevel.id;
            tempdata.cFRApproverActualCfrApproverId = null;
            tempdata.expectedCfrApprovers = [];
            allUsers.forEach((user) => {
              if (user.department) {
                if (user.department.items) {
                  user.department.items.forEach((userdept) => {
                    if (
                      userdept.cFRDepartmentID == approvalLevel.department.id
                    ) {
                      if (user.isGlobalUser) {
                        tempdata.expectedCfrApprovers.push(user.id);
                      }
                    }
                  });
                }
              }
            });

            cfrApprovals.push(tempdata);
          }
        });
        data.isDeleted = false;
        delete data.projectsponsoremail;
        delete data.projectsponsorname;
        delete data.functionalHead;
        delete data["Project Sponsor"];
        delete data.budgetReferenceNo;
        delete data.riskAndMitigation;
        delete data.timelineActivities;

        const newData = {
          ...data,
          cFRCapitalFundRequestCfrCreatorId: loggedInUser.id,
          isIORequestGenerated: false,
          IONumber: "",
        };

        //create CapitalFundRequest
        const result = await API.graphql(
          graphqlOperation(createCFRCapitalFundRequest, { input: newData })
        );
        let createdCFRObject = {};
        if (result.data) {
          //assign newly created CFR id to CFRApprover cfr id property
          cfrApprovals.forEach((lvl) => {
            lvl.cFRApproverCfrId = result.data.createCFRCapitalFundRequest.id;
            lvl.cFRCapitalFundRequestCfrApprovalsId =
              result.data.createCFRCapitalFundRequest.id;
          });
          createdCFRObject = result.data?.createCFRCapitalFundRequest;
          //create an entry for each CFR Approver
          const promises = cfrApprovals.map(async (level) => {
            const cfrApproverResult = await API.graphql(
              graphqlOperation(createCFRApprover, {
                input: level,
              })
            );
            if (cfrApproverResult.data) {
              return cfrApproverResult.data.createCFRApprover;
            }
          });
          //wait for all CFRApprover to be created
          const allresult = await Promise.all(promises);

          //check if this CFR was child of any other cfr, and if yes then update master CFR
          if (data.cfrstatus == "submitted") {
            if (data.masterCFRID) {
              //Question???
              let masterCFR = {
                id: data.masterCFRID,
                cfrstatus: "ammended",
              };
              delete data.cfrCreator;
              console.log("update master cfr");
              const masterCFRresult = await API.graphql(
                graphqlOperation(updateCFRCapitalFundRequest, {
                  input: masterCFR,
                })
              );
              if (masterCFRresult.data) {
                console.log("update master cfr success");
              } else if (masterCFRresult.errors) {
                if (masterCFRresult.errors.length > 0) {
                  alert.show(masterCFRresult.errors[0].message);
                }
              }
            }
          }

          let selectedBudgetReference;
          if(openBudgetRefs && openBudgetRefs.length > 0){
            selectedBudgetReference = openBudgetRefs.filter((budget) =>
            selectedBudgetRefIdList.includes(budget.budgetReferenceNo));
          }
          else if(selectedBudgetRefs && selectedBudgetRefs.length > 0){
            selectedBudgetReference = selectedBudgetRefs;
          }
          
          if (selectedBudgetReference) {
            if (selectedBudgetReference.length > 0) {
              let tempResponse = selectedBudgetReference.map(async (budget) => {
                let newData = {};
                newData.budgetReferenceNo = budget.budgetReferenceNo;
                newData.description = budget.projectDescription ? budget.projectDescription : budget.description;
                newData.remainingAmount = budget.remainingAmount;
                newData.proposedAmount = budget.proposedValTemp ? budget.proposedValTemp : budget.proposedAmount;
                newData.cFRCapitalFundRequestBudgetReferenceNoId =
                  createdCFRObject.id;

                let resData = await API.graphql(
                  graphqlOperation(createCFRMultipleBudgetReference, {
                    input: newData,
                  })
                );
                console.log("MultipleBudget", resData);
              });
            }
          }

          if (risks) {
            if (risks.length > 0) {
              let tempVar = risks.map(async risk => {
                let newData = {
                  ...risk,
                  cFRCapitalFundRequestRiskAndMitigationId : createdCFRObject.id,
                }
                let resData = await API.graphql(
                  graphqlOperation(createCFRRiskAndMitigation, { input: newData, })
                )
              })
            }
          }

          if (timelineActivities) {
            if (timelineActivities.length > 0) {
              let tempVar = timelineActivities.map(async activity => {
                let newData = {
                  ...activity,
                  cFRCapitalFundRequestTimelineActivitiesId : createdCFRObject.id
                }
                let resData = await API.graphql(
                  graphqlOperation(createCFRTimelineActivity, { input: newData, })
                )
              })
            }
          }

          //to upload files in s3 bucket and save files data in to db
          let files = [...cfrUploadedFiles];
          if (files.length > 0) {
            const filepromises = files.map(async (file) => {
              let fileName =
                file.name + "/" + result.data.createCFRCapitalFundRequest.id;
              const res = await Storage.put(fileName, file, {
                contentType: file.type,
              });
              if (res) {
                const fileObj = {
                  bucket: awsExports.aws_user_files_s3_bucket,
                  region: awsExports.aws_user_files_s3_bucket_region,
                  key: res.key,
                  s3ObjectCfrId: result.data.createCFRCapitalFundRequest.id,
                };
                await addFileToDB(fileObj);
                console.log("added completed");
              }
            });
            await Promise.all(filepromises);
          }

          //only if cfr is submitted then send emails
          if (data.cfrstatus == "submitted") {
            let filter = { action: { eq: "CREATE_CFR" } };
            const emailTemplates = await API.graphql(
              graphqlOperation(listCFREmailTemplates, { filter: filter })
            );
            if (emailTemplates.data.listCFREmailTemplates.items.length > 0) {
              const template =
                emailTemplates.data.listCFREmailTemplates.items[0];
              if (cfrApprovals.length > 0) {
                cfrApprovals.map(async (approver) => {
                  if (approver.expectedCfrApprovers) {
                    if (approver.expectedCfrApprovers.length > 0) {
                      approver.expectedCfrApprovers.map(async (approverid) => {
                        let approverId = approverid;
                        let approverNotif = userNotificationsForEmail.filter(
                          (notif) =>
                            notif.cFRUserNotificationsUserId === approverId
                        );
                        if (userDataForEmail.length > 0) {
                          userDataForEmail.map(async (user) => {
                            if (approverId === user.id) {
                              if (
                                approverNotif.length > 0 &&
                                approverNotif[0].cFRCreation
                              ) {
                                let body = template.emailcontent;
                                let replaceName = body.replace(
                                  "{Display Name}",
                                  user.name
                                );
                                let finalBody = replaceName.replace(
                                  "{Link}",
                                  env
                                );

                                const emailTemplate = {
                                  To: user.email,
                                  Subject: template.emailsubject,
                                  MailBody: finalBody,
                                };

                                const res = await API.graphql({
                                  query: sendEmail,
                                  variables: {
                                    EmailTemplate:
                                      JSON.stringify(emailTemplate),
                                  },
                                });
                                console.log(res);
                              }
                            }
                          });
                        }
                      });
                    }
                  }
                });
              }
              if (createdCFRObject != {}) {
                let body = template.emailcontent;
                let replaceName = body.replace("{Display Name}", createdCFRObject.cfrCreator.name);
                let replaceString = replaceName.replace("You have been added as an approver in the CFR.","");
                let finalBody = replaceString.replace("{Link}", env);

                const emailTemplate = {
                  To: createdCFRObject.cfrCreator.email,
                  Subject: template.emailsubject,
                  MailBody: finalBody,
                };

                const res = await API.graphql({
                  query: sendEmail,
                  variables: {
                    EmailTemplate: JSON.stringify(emailTemplate),
                  },
                });
                console.log(res);
              }
            }
          }

          //update budget reference number spend/remaining/locked amount
          if (data.cfrstatus == "submitted") {
            let selectedBudgetReference;
            if(openBudgetRefs && openBudgetRefs.length > 0){
                selectedBudgetReference = openBudgetRefs.filter((budget) =>
                selectedBudgetRefIdList.includes(budget.budgetReferenceNo));
              }
              else if(selectedBudgetRefs && selectedBudgetRefs.length > 0){
                selectedBudgetRefs.forEach((selBudget) =>{
                  budgetReferenceNumber.forEach((budget)=>{
                    if(selBudget.budgetReferenceNo == budget.budgetReferenceNo){
                      let tempObj = {...budget};
                      tempObj.proposedValTemp = selBudget.proposedValTemp ? selBudget.proposedvalTemp : selBudget.proposedAmount;
                      tempObj.remainingAmount = selBudget.remainingAmount;

                      selectedBudgetReference.push(tempObj);
                    }
                  })
              });
              }
            if (selectedBudgetReference) {
              //Task - 4 ==  Iterate selected multi budg ref nos and update locked and remain for each
              if (selectedBudgetReference.length > 0) {
                let tempResponse = selectedBudgetReference.map(
                  async (budget) => {
                    let budgetData = { ...budget };
                    delete budgetData.createdAt;
                    delete budgetData.updatedAt;
                    delete budgetData.isSelected;
                    budgetData.lockedAmount =
                      parseFloat(budgetData.lockedAmount) +
                      parseFloat(budget.proposedValTemp / 1);
                    
                    delete budgetData.proposedValTemp;
                    let budgetReferenceResult = await API.graphql(
                      graphqlOperation(updateCFRBudgetReference, {
                        input: budgetData,
                      })
                    );
                    return budgetReferenceResult;
                  }
                );
              }
            }
          }
          const successMessage = "CFR saved successfully";
          alert.show(successMessage, {
            type: "success",
            onClose: () => {
              props.history.push("/cfrlist");
            }, // callback that
          });

          setIsLoading(false);
        } else if (result.errors) {
          if (result.errors.length > 0) {
            alert.show(result.errors[0].message);
          }
          setIsLoading(false);
        }
      } else {
        if (cfrForUpdate.cfrstatus == "draft") {
          console.log("update");
          data.id = cfrForUpdate.id;
          //data.masterCFRID = cfrForUpdate.masterCFRID;
          let cfrApprovals = [];
          approvers.forEach((approver, index) => {
            if (approver.approvalLevel.sequencenumber == 1) {
              let tempdata = {};
              tempdata.id = approver.id;
              tempdata.cfrVersion = approver.cfrVersion;
              tempdata.approvalstatus = approver.approvalstatus;
              tempdata.approvalDateTime = approver.approvalDateTime;
              tempdata.approverjustification = approver.approverjustification;
              tempdata.cFRApproverCfrId = approver.cFRApproverCfrId;
              tempdata.cFRCapitalFundRequestCfrApprovalsId =
                approver.cFRCapitalFundRequestCfrApprovalsId;
              tempdata.cFRApproverApprovalLevelId =
                approver.cFRApproverApprovalLevelId;
              tempdata.cFRApproverActualCfrApproverId =
                approver.cFRApproverActualCfrApproverId;
              tempdata.expectedCfrApprovers = [data["Project Sponsor"]];
              cfrApprovals.push(tempdata);
            } else if (approver.approvalLevel.sequencenumber == 2) {
              let selectedUsers = [];
              deptData.map((dept) => {
                if (loggedInUser.department) {
                  if (loggedInUser.department.items.length > 0) {
                    if (
                      dept.id ===
                      loggedInUser.department.items[0].cFRDepartmentID
                    ) {
                      const budgeted = getValues("iscapitalbudget");
                      if (budgeted === "Yes") {
                        selectedUsers = dept.procurementBudgtedUsers;
                      } else {
                        selectedUsers = dept.procurementUnBudgtedUsers;
                      }
                    }
                  }
                }
              });
              let tempdata = {};
              tempdata.id = approver.id;
              tempdata.cfrVersion = approver.cfrVersion;
              tempdata.approvalstatus = approver.approvalstatus;
              tempdata.approvalDateTime = approver.approvalDateTime;
              tempdata.approverjustification = approver.approverjustification;
              tempdata.cFRApproverCfrId = approver.cFRApproverCfrId;
              tempdata.cFRCapitalFundRequestCfrApprovalsId =
                approver.cFRCapitalFundRequestCfrApprovalsId;
              tempdata.cFRApproverApprovalLevelId =
                approver.cFRApproverApprovalLevelId;
              tempdata.cFRApproverActualCfrApproverId =
                approver.cFRApproverActualCfrApproverId;
              tempdata.expectedCfrApprovers = selectedUsers;
              cfrApprovals.push(tempdata);
            } else if (approver.approvalLevel.sequencenumber == 3) {
              let tempdata = {};
              tempdata.id = approver.id;
              tempdata.cfrVersion = approver.cfrVersion;
              tempdata.approvalstatus = approver.approvalstatus;
              tempdata.approvalDateTime = approver.approvalDateTime;
              tempdata.approverjustification = approver.approverjustification;
              tempdata.cFRApproverCfrId = approver.cFRApproverCfrId;
              tempdata.cFRCapitalFundRequestCfrApprovalsId =
                approver.cFRCapitalFundRequestCfrApprovalsId;
              tempdata.cFRApproverApprovalLevelId =
                approver.cFRApproverApprovalLevelId;
              tempdata.cFRApproverActualCfrApproverId =
                approver.cFRApproverActualCfrApproverId;
              tempdata.expectedCfrApprovers = [data["functionalHead"]];
              cfrApprovals.push(tempdata);
            } else if (approver.approvalLevel.sequencenumber == 4) {
              let selectedUsers = [];
              deptData.map((dept) => {
                if (loggedInUser.department) {
                  if (loggedInUser.department.items.length > 0) {
                    if (
                      dept.id ===
                      loggedInUser.department.items[0].cFRDepartmentID
                    ) {
                      const budgeted = getValues("iscapitalbudget");
                      if (budgeted === "Yes") {
                        selectedUsers = dept.financeBudgtedUsers;
                      } else {
                        selectedUsers = dept.financeUnBudgtedUsers;
                      }
                    }
                  }
                }
              });
              let tempdata = {};
              tempdata.id = approver.id;
              tempdata.cfrVersion = approver.cfrVersion;
              tempdata.approvalstatus = approver.approvalstatus;
              tempdata.approvalDateTime = approver.approvalDateTime;
              tempdata.approverjustification = approver.approverjustification;
              tempdata.cFRApproverCfrId = approver.cFRApproverCfrId;
              tempdata.cFRCapitalFundRequestCfrApprovalsId =
                approver.cFRCapitalFundRequestCfrApprovalsId;
              tempdata.cFRApproverApprovalLevelId =
                approver.cFRApproverApprovalLevelId;
              tempdata.cFRApproverActualCfrApproverId =
                approver.cFRApproverActualCfrApproverId;
              tempdata.expectedCfrApprovers = selectedUsers;
              cfrApprovals.push(tempdata);
            } else {
              let tempdata = {};
              tempdata.id = approver.id;
              tempdata.cfrVersion = approver.cfrVersion;
              tempdata.approvalstatus = approver.approvalstatus;
              tempdata.approvalDateTime = approver.approvalDateTime;
              tempdata.approverjustification = approver.approverjustification;
              tempdata.cFRApproverCfrId = approver.cFRApproverCfrId;
              tempdata.cFRCapitalFundRequestCfrApprovalsId =
                approver.cFRCapitalFundRequestCfrApprovalsId;
              tempdata.cFRApproverApprovalLevelId =
                approver.cFRApproverApprovalLevelId;
              tempdata.cFRApproverActualCfrApproverId =
                approver.cFRApproverActualCfrApproverId;
              tempdata.expectedCfrApprovers = [];
              approvallevels.forEach((lvl) => {
                if (approver.approvalLevel.id == lvl.id) {
                  allUsers.forEach((user) => {
                    if (user.department) {
                      if (user.department.items) {
                        user.department.items.forEach((userdept) => {
                          if (userdept.cFRDepartmentID == lvl.department.id) {
                            if (user.isGlobalUser) {
                              tempdata.expectedCfrApprovers.push(user.id);
                            }
                          }
                        });
                      }
                    }
                  });
                }
              });
              cfrApprovals.push(tempdata);
            }
          });
          delete data.tableData;
          delete data.projectsponsoremail;
          delete data.projectsponsorname;
          delete data.functionalHead;
          delete data["Project Sponsor"];
          delete data.budgetReferenceNo;
          delete data.riskAndMitigation;
          delete data.timelineActivities;

          //const loggedInUser = getLoggedInUser();
          const newData = {
            ...data,
            cFRCapitalFundRequestCfrCreatorId: loggedInUser.id,
          };

          delete newData.cfrCreator;

          const result = await API.graphql(
            graphqlOperation(updateCFRCapitalFundRequest, { input: newData })
          );
          let updatedCFRObject = {};
          if (result.data) {
            //update each entry of CFR Approver
            const promises = cfrApprovals.map(async (level) => {
              const cfrApproverResult = await API.graphql(
                graphqlOperation(updateCFRApprover, {
                  input: level,
                })
              );
              if (cfrApproverResult.data) {
                return cfrApproverResult.data.createCFRApprover;
              }
            });
            updatedCFRObject = result.data?.updateCFRCapitalFundRequest;
            //wait for all CFRApprover to be updated
            const allresult = await Promise.all(promises);

            //check if this CFR was child of any other cfr, and if yes then update master CFR
            //Question???
            if (data.cfrstatus == "submitted") {
              //sending email
              let filter = { action: { eq: "CREATE_CFR" } };
              const emailTemplates = await API.graphql(
                graphqlOperation(listCFREmailTemplates, { filter: filter })
              );
              if (emailTemplates.data.listCFREmailTemplates.items.length > 0) {
                const template =
                  emailTemplates.data.listCFREmailTemplates.items[0];
                if (cfrApprovals.length > 0) {
                  cfrApprovals.map(async (approver) => {
                    if (approver.expectedCfrApprovers) {
                      if (approver.expectedCfrApprovers.length > 0) {
                        approver.expectedCfrApprovers.map(
                          async (approverid) => {
                            let approverId = approverid;
                            let approverNotif =
                              userNotificationsForEmail.filter(
                                (notif) =>
                                  notif.cFRUserNotificationsUserId ===
                                  approverId
                              );
                            if (userDataForEmail.length > 0) {
                              userDataForEmail.map(async (user) => {
                                if (approverId === user.id) {
                                  if (
                                    approverNotif.length > 0 &&
                                    approverNotif[0].cFRCreation
                                  ) {
                                    let body = template.emailcontent;
                                    let replaceName = body.replace(
                                      "{Display Name}",
                                      user.name
                                    );
                                    let finalBody = replaceName.replace(
                                      "{Link}",
                                      env
                                    );

                                    const emailTemplate = {
                                      To: user.email,
                                      Subject: template.emailsubject,
                                      MailBody: finalBody,
                                    };

                                    const res = await API.graphql({
                                      query: sendEmail,
                                      variables: {
                                        EmailTemplate:
                                          JSON.stringify(emailTemplate),
                                      },
                                    });
                                    console.log(res);
                                  }
                                }
                              });
                            }
                          }
                        );
                      }
                    }
                  });
                }
                if (updatedCFRObject != {}) {
                  let body = template.emailcontent;
                  let replaceName = body.replace("{Display Name}", updatedCFRObject.cfrCreator.name);
                  let replaceString = replaceName.replace("You have been added as an approver in the CFR.","");
                  let finalBody = replaceString.replace("{Link}", env);

                  const emailTemplate = {
                    To: updatedCFRObject.cfrCreator.email,
                    Subject: template.emailsubject,
                    MailBody: finalBody,
                  };

                  const res = await API.graphql({
                    query: sendEmail,
                    variables: {
                      EmailTemplate: JSON.stringify(emailTemplate),
                    },
                  });
                  console.log(res);
                }
              }
              // till here
              if (data.masterCFRID) {
                let masterCFR = {
                  id: data.masterCFRID,
                  cfrstatus: "ammended",
                };
                delete data.cfrCreator;
                console.log("update master cfr");
                const masterCFRresult = await API.graphql(
                  graphqlOperation(updateCFRCapitalFundRequest, {
                    input: masterCFR,
                  })
                );
                if (masterCFRresult.data) {
                  console.log("update master cfr success");
                } else if (masterCFRresult.errors) {
                  if (masterCFRresult.errors.length > 0) {
                    alert.show(masterCFRresult.errors[0].message);
                  }
                }
              }
            }

            //to upload files in s3 bucket and save files data in to db
            let files = [...cfrUploadedFiles];
            if (files.length > 0) {
              const filepromises = files.map(async (file) => {
                let fileName =
                  file.name + "/" + result.data.updateCFRCapitalFundRequest.id;
                const res = await Storage.put(fileName, file, {
                  contentType: file.type,
                });
                if (res) {
                  const fileObj = {
                    bucket: awsExports.aws_user_files_s3_bucket,
                    region: awsExports.aws_user_files_s3_bucket_region,
                    key: res.key,
                    cFRS3ObjectCfrId:
                      result.data.updateCFRCapitalFundRequest.id,
                    cFRCapitalFundRequestCfrFilesId:
                      result.data.updateCFRCapitalFundRequest.id,
                  };
                  await addFileToDB(fileObj);
                  console.log("added completed");
                }
              });
              await Promise.all(filepromises);
            }

            //update budget reference number spend/remaining/locked amount
            if (data.cfrstatus == "draft" || data.cfrstatus == "submitted") {
              // let selectedBudgetReference = openBudgetRefs.filter((budget) =>
              //   selectedBudgetRefIdList.includes(budget.budgetReferenceNo)
              // );
              let selectedBudgetReference;
              if(openBudgetRefs && openBudgetRefs.length > 0){
                selectedBudgetReference = openBudgetRefs.filter((budget) =>
                selectedBudgetRefIdList.includes(budget.budgetReferenceNo));
              }
              else if(selectedBudgetRefs && selectedBudgetRefs.length > 0){
                selectedBudgetReference = selectedBudgetRefs.filter((budget) =>
                selectedBudgetRefIdList.includes(budget.budgetReferenceNo));
              }
              if (selectedBudgetReference) {
                if (selectedBudgetReference.length > 0) {
                  console.log("inside box");
                  let searchParam = {cFRCapitalFundRequestBudgetReferenceNoId : {eq : updatedCFRObject.id}}

                  let respo = await API.graphql(
                    graphqlOperation(listCFRMultipleBudgetReferences, { filter: searchParam })
                  );
                  let budgetRefsToDelete = respo.data?.listCFRMultipleBudgetReferences?.items;
                  if(respo.data){
                    if(respo.data?.listCFRMultipleBudgetReferences?.items.length > 0){
                      try{
                        budgetRefsToDelete.forEach(async (bud) => {
                          let tempRes = await API.graphql(
                            graphqlOperation(deleteCFRMultipleBudgetReference, { input : {id : bud.id} })
                          );
                        })
                      }
                      catch(err){

                      }
                    }
                  }

                  let tempResponse = selectedBudgetReference.map(
                    async (budget) => {
                      let newData = {};
                      newData.budgetReferenceNo = budget.budgetReferenceNo;
                      newData.description = budget.projectDescription ? budget.projectDescription : budget.description;
                      newData.remainingAmount = budget.remainingAmount;
                      newData.proposedAmount = budget.proposedValTemp ? budget.proposedValTemp : budget.proposedAmount;
                      newData.cFRCapitalFundRequestBudgetReferenceNoId =
                        updatedCFRObject.id;

                      let resData = await API.graphql(
                        graphqlOperation(createCFRMultipleBudgetReference, {
                          input: newData,
                        })
                      );
                    }
                  );
                }
              }

              if(risks){
                if(risks.length > 0){
                  let searchParam = {
                    cFRCapitalFundRequestRiskAndMitigationId: {
                      eq: updatedCFRObject.id,
                    },
                  };

                  let respo = await API.graphql(
                    graphqlOperation(listCFRRiskAndMitigations, {
                      filter: searchParam,
                    })
                  );
                  let risksToDelete = respo.data?.listCFRRiskAndMitigations?.items;
                  if (respo.data) {
                    if (
                      respo.data?.listCFRRiskAndMitigations?.items
                        .length > 0
                    ) {
                      try {
                        risksToDelete.forEach(async (risk) => {
                          let tempRes = await API.graphql(
                            graphqlOperation(
                              deleteCFRRiskAndMitigation,
                              { input: { id: risk.id } }
                            )
                          );
                        });
                      } catch (err) { }
                    }
                  }

                  let tempResponse = risks.map(
                    async (risk) => {
                      let newData = {
                        ...risk,
                        cFRCapitalFundRequestRiskAndMitigationId : updatedCFRObject?.id
                      };
                      delete newData.id;
                      delete newData.createdAt;
                      delete newData.updatedAt;
                      let resData = await API.graphql(
                        graphqlOperation(createCFRRiskAndMitigation, {
                          input: newData,
                        })
                      );
                    }
                  );
                }
              }

              if(timelineActivities){
                if(timelineActivities.length > 0){
                  let searchParam = {
                    cFRCapitalFundRequestTimelineActivitiesId: {
                      eq: updatedCFRObject.id,
                    },
                  };

                  let respo = await API.graphql(
                    graphqlOperation(listCFRTimelineActivities, {
                      filter: searchParam,
                    })
                  );
                  let activitiesToDelete = respo.data?.listCFRTimelineActivities?.items;
                  if (respo.data) {
                    if (
                      respo.data?.listCFRTimelineActivities?.items
                        .length > 0
                    ) {
                      try {
                        activitiesToDelete.forEach(async (activity) => {
                          let tempRes = await API.graphql(
                            graphqlOperation(
                              deleteCFRTimelineActivity,
                              { input: { id: activity.id } }
                            )
                          );
                        });
                      } catch (err) { }
                    }
                  }

                  let tempResponse = timelineActivities.map(
                    async (activity) => {
                      let newData = {
                        ...activity,
                        cFRCapitalFundRequestTimelineActivitiesId : updatedCFRObject.id
                      };
                      delete newData.id;
                      delete newData.createdAt;
                      delete newData.updatedAt;
                      let resData = await API.graphql(
                        graphqlOperation(createCFRTimelineActivity, {
                          input: newData,
                        })
                      );
                    }
                  );
                }
              }

            
            }

            if (data.cfrstatus == "submitted") {

              let selectedBudgetReference;
              if(openBudgetRefs && openBudgetRefs.length > 0){
                selectedBudgetReference = openBudgetRefs.filter((budget) =>
                selectedBudgetRefIdList.includes(budget.budgetReferenceNo));
              }
              else if(selectedBudgetRefs && selectedBudgetRefs.length > 0){
                selectedBudgetRefs.forEach((selBudget) =>{
                  budgetReferenceNumber.forEach((budget)=>{
                    if(selBudget.budgetReferenceNo == budget.budgetReferenceNo){
                      let tempObj = {...budget};
                      tempObj.proposedValTemp = selBudget.proposedValTemp ? selBudget.proposedvalTemp : selBudget.proposedAmount;
                      tempObj.remainingAmount = selBudget.remainingAmount;

                      selectedBudgetReference.push(tempObj);
                    }
                  })
              });
              }
              if (selectedBudgetReference) {
                //Task - 4 ==  Iterate selected multi budg ref nos and update locked and remain for each
                if (selectedBudgetReference.length > 0) {
                  let tempResponse = selectedBudgetReference.map(
                    async (budget) => {
                      let budgetData = { ...budget };
                      delete budgetData.createdAt;
                      delete budgetData.updatedAt;
                      delete budgetData.isSelected;
                      budgetData.lockedAmount =
                        parseFloat(budgetData.lockedAmount) +
                        parseFloat(budget.proposedValTemp / 1);
                      
                      delete budgetData.proposedValTemp;

                      let budgetReferenceResult = await API.graphql(
                        graphqlOperation(updateCFRBudgetReference, {
                          input: budgetData,
                        })
                      );
                      return budgetReferenceResult;
                    }
                  );
                }
              }
            }

            setIsLoading(false);
            const successMessage = "CFR updated successfully";
            alert.show(successMessage, {
              type: "success",
              onClose: () => {
                props.history.push("/cfrlist");
              }, // callback that
            });

            setIsLoading(false);
          } else if (result.errors) {
            if (result.errors.length > 0) {
              alert.show(result.errors[0].message);
            }
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  }

  /// this function for Create and update cfr record
  async function updateMasterCFR(arg) {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: updateMasterCFR";
    let cfrdata = _.cloneDeep(cfrForUpdate);
    cfrdata.cfrstatus = arg;
    delete cfrdata.cfrApprovals;
    //delete cfrdata.cfrResources;
    delete cfrdata.cfrFiles;
    delete cfrdata.createdAt;
    delete cfrdata.updatedAt;
    delete cfrdata.tableData;
    delete cfrdata.cfrCreator;
    delete cfrdata.projectsponsoremail;
    delete cfrdata.projectsponsorname;
    delete cfrdata.budgetReferenceNo;
    delete cfrdata.riskAndMitigation;
    delete cfrdata.timelineActivities;
    console.log(cfrdata);
    try {
      setIsLoading(true);
      console.log("update master cfr");
      const loggedInUser = getLoggedInUser();
      const newCfrData = {
        ...cfrdata,
        //cFRCapitalFundRequestCfrCreatorId: loggedInUser.id,
      };
      const result = await API.graphql(
        graphqlOperation(updateCFRCapitalFundRequest, { input: newCfrData })
      );
      setIsLoading(false);
      if (result.data) {
        console.log("update master cfr success");
      } else if (result.errors) {
        if (result.errors.length > 0) {
          alert.show(result.errors[0].message);
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  }

  // this function gets the list of user's notification preferences
  const getUserNotifications = async function () {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: getUserNotifications";
    try {
      setIsLoading(true);
      const response = await API.graphql(
        graphqlOperation(listCFRUserNotifications)
      );
      if (response) {
        const userNotifs = response.data.listCFRUserNotifications.items;
        setUserNotificationsForEmail([]);
        setIsLoading(false);
        setUserNotificationsForEmail(userNotifs);
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  };

  // this function for get user data
  const getUserList = async function () {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: getUserList";
    const loggedInUser = getLoggedInUser();
    console.log(loggedInUser);
    if (loggedInUser) {
      if (loggedInUser.department) {
        try {
          if (!isAddMode) {
            setIsLoading(true);
            const currentState = props.location.state;
            const proposedVal = currentState.proposalvalue;

            const response = await API.graphql(graphqlOperation(listCFRUsers));
            if (response) {
              const allUsers = response.data.listCFRUsers.items;
              setAllUsers(allUsers);
              let noLoggedInUserData = allUsers.filter(
                (u) => u.name !== loggedInUser.name
              );
              let sameDeptUserData = [];
              noLoggedInUserData.forEach((user) => {
                if (user.department) {
                  if (user.department.items) {
                    user.department.items.forEach((userdept1) => {
                      if (loggedInUser.department) {
                        if (loggedInUser.department.items) {
                          loggedInUser.department.items.forEach((userdept2) => {
                            if (
                              userdept1.cFRDepartmentID ==
                              userdept2.cFRDepartmentID
                            ) {
                              sameDeptUserData.push(user);
                            }
                          });
                        }
                      }
                    });
                  }
                }
              });
              const budgted = getValues("iscapitalbudget");
              let sortedUsers = [];
              if (budgted === "Yes") {
                sortedUsers = sameDeptUserData.filter(
                  (u) => u.canApproveBudgetedCFR
                );
              } else {
                sortedUsers = sameDeptUserData.filter(
                  (u) => u.canApproveUnBudgetedCFR
                );
              }
              let filteredUsers = [];
              sortedUsers.map((user) => {
                if (
                  proposedVal >= parseInt(user.limit) &&
                  proposedVal <= parseInt(user.toLimit)
                ) {
                  filteredUsers.push(user);
                }
              });
              let approverHODs = [];
              filteredUsers.forEach((user) => {
                if (user.department) {
                  if (user.department.items) {
                    user.department.items.forEach((userdept1) => {
                      loggedInUser.department.items.forEach((userdept2) => {
                        if (
                          userdept1.cFRDepartmentID == userdept2.cFRDepartmentID
                        ) {
                          approverHODs.push(user);
                        }
                      });
                    });
                  }
                }
              });
              setUserData([]);
              var dropdownData = [];
              approverHODs.forEach((user) => {
                let data = {};
                data.label = user.name;
                data.value = user.id;
                dropdownData.push(data);
              });
              setUserOptions(dropdownData);
              setIsLoading(false);
              setUserData(approverHODs);
            } else {
              if (response.errors) {
                if (response.errors.length > 0) {
                  alert.show(response.errors[0].message);
                }
              }
            }
          }
        } catch (error) {
          setIsLoading(false);
          if (error.errors) {
            if (error.errors.length > 0) {
              alert.show(error.errors[0].message);
              const loggedInUser = getLoggedInUser();
              let filter = { action: { eq: "ERROR_MESSAGE" } };
              const emailTemplates = await API.graphql(
                graphqlOperation(listCFREmailTemplates, { filter: filter })
              );
              const template =
                emailTemplates.data.listCFREmailTemplates.items[0];
              let body = template.emailcontent;
              let replaceName = body.replace(
                "{Display Name}",
                AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
              );
              let replaceUser = replaceName.replace(
                "{Logged User}",
                loggedInUser.name
              );

              let replaceError = replaceUser.replace(
                "{Error}",
                `Error: ${error.errors[0].message} at ${errorLocation}`
              );
              const emailTemplate = {
                To: AppConstants.ERROR_MAIL_RECEIVER,
                Subject: template.emailsubject,
                MailBody: replaceError,
              };

              const res = await API.graphql({
                query: sendEmail,
                variables: {
                  EmailTemplate: JSON.stringify(emailTemplate),
                },
              });

              console.log(res);
            }
          } else if (error.message) {
            alert.show(error.message, {
              onClose: () => {
                props.history.push("/");
              },
            });
          }
        }
      }
    }
  };

  const getUserListForEmail = async function () {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: getUserListForEmail";
    try {
      setIsLoading(true);
      const response = await API.graphql(graphqlOperation(listCFRUsers));
      if (response) {
        const allUsers = response.data.listCFRUsers.items;

        setUserDataForEmail([]);
        setIsLoading(false);
        setUserDataForEmail(allUsers);
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  };

  // this function for get customer data
  const getApprovalLevelList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: getApprovalLevelList";
    try {
      setIsLoading(true);
      const approvalData = await API.graphql(
        graphqlOperation(listCFRApprovalLevels)
      );
      setIsLoading(false);
      if (approvalData) {
        const approvalList = approvalData.data.listCFRApprovalLevels.items;
        setapprovallevels([]);
        console.log(approvalList);
        let latestVersionApprovalList = [];
        //find latest version
        let version = 0;
        approvalList.forEach((lvl) => {
          if (lvl.version > version) {
            version = lvl.version;
          }
        });
        //take only latest version records
        approvalList.forEach((lvl) => {
          if (lvl.version == version) {
            latestVersionApprovalList.push(lvl);
          }
        });

        latestVersionApprovalList.sort((a, b) => {
          return a.sequencenumber - b.sequencenumber;
        });
        setapprovallevels(latestVersionApprovalList);
      } else {
        if (approvalData.errors) {
          if (approvalData.errors.length > 0) {
            alert.show(approvalData.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  });

  const getDepartmentList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: getDepartmentList";
    try {
      setIsLoading(true);
      let searchParameter = { isdeleted: { eq: false } };
      const _deptData = await API.graphql(
        graphqlOperation(listCFRDepartments, { filter: searchParameter })
      );

      if (_deptData) {
        const deptList = _deptData.data.listCFRDepartments.items;
        console.log(deptList);
        setIsLoading(false);
        setDeptData(deptList);
      } else {
        if (_deptData.errors) {
          if (_deptData.errors.length > 0) {
            alert.show(_deptData.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  });

  const getAzureUsers = async (searchtxt) => {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: getAzureUsers";
    try {
      const res = await API.graphql({
        query: listAzureUsers,
        variables: { EmailSearchKey: searchtxt },
      });
      return res;
    } catch (error) {
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      }
    }
  };

  const handleOnSearch = async (searchtxt, results) => {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: handleOnSearch";
    console.log(searchtxt, results);
    if (searchtxt.length >= 3) {
      try {
        setIsLoading(true);
        let res = await getAzureUsers(searchtxt);
        setIsLoading(false);
        if (res && res.data && res.data.listAzureUsers) {
          setLstAzureUsers(JSON.parse(res.data.listAzureUsers));
        }
      } catch (error) {
        setIsLoading(false);
        if (error.errors) {
          if (error.errors.length > 0) {
            alert.show(error.errors[0].message);
            const loggedInUser = getLoggedInUser();
            let filter = { action: { eq: "ERROR_MESSAGE" } };
            const emailTemplates = await API.graphql(
              graphqlOperation(listCFREmailTemplates, { filter: filter })
            );
            const template = emailTemplates.data.listCFREmailTemplates.items[0];
            let body = template.emailcontent;
            let replaceName = body.replace(
              "{Display Name}",
              AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
            );
            let replaceUser = replaceName.replace(
              "{Logged User}",
              loggedInUser.name
            );

            let replaceError = replaceUser.replace(
              "{Error}",
              `Error: ${error.errors[0].message} at ${errorLocation}`
            );
            const emailTemplate = {
              To: AppConstants.ERROR_MAIL_RECEIVER,
              Subject: template.emailsubject,
              MailBody: replaceError,
            };

            const res = await API.graphql({
              query: sendEmail,
              variables: {
                EmailTemplate: JSON.stringify(emailTemplate),
              },
            });

            console.log(res);
          }
        }
      }
    }
  };

  const handleOnSelect = (item) => {
    console.log(item);
    setValue("projectsponsoremail", item.mail);
    setValue("projectsponsorname", item.displayName);
  };

  const handleOnFocus = () => {
    //console.log("Focused");
  };

  const handleOnHover = (result) => {
    //console.log(result);
  };

  const handleOnClear = () => {
    setValue("projectsponsoremail", "");
    setValue("projectsponsorname", "");
    setLstAzureUsers([]);
  };

  const setEnvironment = () => {
    const enviornment = awsconfig.Auth.redirectSignOut;
    setEnv(enviornment);
  };

  useEffect(async () => {
    await setEnvironment();
    await getPlantList();
    await getBudgetReferenceNumbers();
    //await getAssetClass();
    await getConfigList();
    await getApprovalLevelList();
    await getDepartmentList();
    await getUserList();
    await getUserListForEmail();
    await getUserNotifications();
    forceUpdate();

    setIsLoading(true);
    if (props.location.state != null) {
      if (!isAddMode) {
        setPageTitle("Update");
        const cfrobj = props.location.state;
        let cfrdata = _.cloneDeep(cfrobj);
        setcfrForUpdate(cfrdata);

        const fields = [
          "iscapitalbudget",
          "budgetReferenceNo",
          "budgetvaluetype",
          "budgetvalue",
          "proposalvaluetype",
          "proposalvalue",
          "irr",
          "npv",
          "payback",
          "assetlife",
          "budgetbenefits",
          "cfrbenefits",
          "cfrsubmissiondate",
          "spendtype",
          "maincontractors",
          "businesscontext",
          "justification",
          "optionsconsidered",
          "isDeleted",
          "projectsponsoremail",
          "projectsponsorname",
          "expectedCompletionDate",
        ];

        setIRRValue(cfrobj.irr);
        setBudgetBenefit(cfrobj.budgetbenefits);

        if (cfrobj.territory != undefined) {
          setIsNpvCalBtnDisabled(cfrobj.territory.length == 0);
        }

        if (cfrobj.cfrApprovals) {
          if (cfrobj.cfrApprovals.items) {
            if (cfrobj.cfrApprovals.items.length > 0) {
              let cfrApprovers = [];
              cfrobj.cfrApprovals.items.forEach((lvl) => {
                cfrApprovers.push(lvl);
              });
              setApprovers(cfrApprovers);
              cfrApprovers.sort((a, b) => {
                if (a.approvalLevel) {
                  if (b.approvalLevel) {
                    return (
                      a.approvalLevel.sequencenumber -
                      b.approvalLevel.sequencenumber
                    );
                  }
                }
              });
              cfrApprovers.forEach((lvl) => {
                if (lvl.approvalLevel.sequencenumber == 1) {
                  if (lvl.expectedCfrApprovers) {
                    if (lvl.expectedCfrApprovers.length > 0) {
                      if (allUsers.length > 0) {
                        allUsers.forEach((user) => {
                          if (lvl.expectedCfrApprovers[0] == user.id) {
                            cfrobj.projectsponsoremail = user.email;
                            cfrobj.projectsponsorname = user.name;
                            setSearchString(user.name);
                          }
                        });
                      }
                    }
                  }
                } else if (lvl.approvalLevel.sequencenumber == 3) {
                  if (lvl.expectedCfrApprovers) {
                    if (lvl.expectedCfrApprovers.length > 0) {
                      setValue("functionalHead", lvl.expectedCfrApprovers[0]);
                    }
                  }
                }
              });
            }
          }
        }

        if (cfrobj.budgetReferenceNo) {
          if (cfrobj.budgetReferenceNo.items) {
            if (cfrobj.budgetReferenceNo.items.length > 0) {
              let tempSelectedRefId = [];
              setSelectedBudgetRefs(cfrobj.budgetReferenceNo.items);
              cfrobj.budgetReferenceNo.items.forEach((budget) => {
                tempSelectedRefId.push(budget.budgetReferenceNo);
              });
              setSelectedBudgetRefIdList(tempSelectedRefId);
            }
          }
        }

        //bind cfr data
        fields.forEach((field) => {
          if (field == "iscapitalbudget") {
            setValue(field, cfrobj[field] == true ? "Yes" : "No");
            if (cfrobj[field] == true) {
              setIsCapitalBudget(true);
              setSelectedIsBudgetedOption("Yes");
            } else {
              setIsCapitalBudget(false);
              setSelectedIsBudgetedOption("No");
            }
          } else if (field == "expectedCompletionDate") {
            if (cfrobj["expectedCompletionDate"]) {
              setValue(
                "expectedCompletionDate",
                cfrobj["expectedCompletionDate"].split("T")[0]
              );
            }
          } else {
            console.log(field, cfrobj[field]);
            setValue(field, cfrobj[field]);
          }
        });

        if (cfrobj.npvjson != null) {
          let npvdata = JSON.parse(cfrobj.npvjson);
          setCapExRows(npvdata.capExRows);
          setRevExRows(npvdata.revExRows);
          setCapExContingencyChecked(npvdata.capExContingencyChecked);
          setCapExContingencyType(npvdata.capExContingencyType);
          setContingencyPercentage(npvdata.contingencyPercentage);
          setCapex_contingency(npvdata.capex_contingency);
          setCapex_total(npvdata.capex_total);
          setYearlyCapexValues(npvdata.yearlyCapexValues);
          setYearlyRevexValues(npvdata.yearlyRevexValues);
          setCostValues(npvdata.costValues);
          setTotalSavings(npvdata.totalSavings);
          setRevex_total(npvdata.revex_total);
          setAssetLife(npvdata.assetLife);
          setInterestRate(npvdata.interestRate);
          setCurrency(npvdata.currency);
          setCurrencylogo(
            npvdata.currencySymbol != undefined ? npvdata.currencySymbol : ""
          );
          setGeography(npvdata.geography);
          setUndiscountedPaybackPeriod(
            npvdata.paybackYear != null ? npvdata.paybackYear : cfrobj.payback
          );
          setCapexAnalysisPeriod(npvdata.capexAnalysisPeriod);
        }
        if (cfrobj.cfrFiles) {
          if (cfrobj.cfrFiles.items) {
            if (cfrobj.cfrFiles.items.length > 0) {
              cfrobj.cfrFiles.items.forEach((item) => {
                item.name = item.key.split("/")[0];
              });
            }
          }
        }

        setCfrUploadedExistingFiles(cfrobj.cfrFiles.items);
      } else {
        const cfrobj = props.location.state;
        if (cfrobj.npvjson != null) {
          let npvdata = JSON.parse(cfrobj.npvjson);
          setCurrency(npvdata.currency);
          setCurrencylogo(
            npvdata.currencySymbol != undefined ? npvdata.currencySymbol : ""
          );
          setInterestRate(npvdata.interestRate);
        }

        if (cfrobj.territory != undefined) {
          setIsNpvCalBtnDisabled(cfrobj.territory.length == 0);
        }

      }
      //debugger;
      let cfrVersion = "";
      let masterCfrIdentifier;
      let masterCfrVersion = "";
      if (isAddMode) {
        masterCfrVersion = cfrObj.version;
        masterCfrIdentifier = cfrObj.id;
        // if (cfrObj.masterCFRID != null) {
        //   masterCfrVersion = cfrObj.version;
        //   masterCfrIdentifier = cfrObj.id;
        //   const _lstCfr = await API.graphql(
        //     graphqlOperation(getCFRCapitalFundRequest, {
        //       id: cfrObj.masterCFRID,
        //     })
        //   );
        //   if (
        //     _lstCfr != null &&
        //     _lstCfr.data?.getCFRCapitalFundRequest != null
        //   ) {
        //     const masterCfr = _lstCfr.data.getCFRCapitalFundRequest;
        //     masterCfrIdentifier = masterCfr.id;
        //     masterCfrVersion = masterCfr.version;
        //   }
        // }

        let filter = { masterCFRID: { eq: masterCfrIdentifier } };
        const _lstChildCfr = await API.graphql(
          graphqlOperation(listCFRCapitalFundRequests, { filter: filter })
        );
        if (
          _lstChildCfr != null &&
          _lstChildCfr.data?.listCFRCapitalFundRequests != null &&
          _lstChildCfr.data?.listCFRCapitalFundRequests.items.length > 0
        ) {
          var sortedCfrList =
            _lstChildCfr.data?.listCFRCapitalFundRequests.items.sort(function (
              a,
              b
            ) {
              return new Date(b.createdAt) - new Date(a.createdAt);
            });
          const lastChildCfr = sortedCfrList[0];
          let lastChildCfrVersion = lastChildCfr.version.replaceAll("CR ", "");
          cfrVersion =
            lastChildCfrVersion.substring(
              0,
              lastChildCfrVersion.lastIndexOf(".")
            ) +
            "." +
            (Number(
              lastChildCfrVersion.substring(
                lastChildCfrVersion.lastIndexOf(".") + 1,
                lastChildCfrVersion.length
              )
            ) +
              1);
        } else {
          cfrVersion = masterCfrVersion + ".1";
        }
      } else {
        masterCfrIdentifier = cfrObj.masterCFRID;
        cfrVersion = cfrObj.version.replaceAll("CR ", "");
      }

      setMasterCfrId(masterCfrIdentifier);
      console.log(cfrVersion);
      setcfrVersion(cfrVersion);
      //setIRRValue(cfrObj.irr);
    }
    setIsLoading(false);
  }, []);

  useEffect(async () => {
    if (props.location.state != null) {
      if (!isAddMode) {
        setPageTitle("Update");
        const cfrobj = props.location.state;
        if (cfrobj.cfrApprovals) {
          if (cfrobj.cfrApprovals.items) {
            if (cfrobj.cfrApprovals.items.length > 0) {
              let cfrApprovers = [];
              cfrobj.cfrApprovals.items.forEach((lvl) => {
                cfrApprovers.push(lvl);
              });
              cfrApprovers.sort((a, b) => {
                if (a.approvalLevel) {
                  if (b.approvalLevel) {
                    return (
                      a.approvalLevel.sequencenumber -
                      b.approvalLevel.sequencenumber
                    );
                  }
                }
              });
              cfrApprovers.forEach((lvl) => {
                if (lvl.approvalLevel.sequencenumber == 1) {
                  if (lvl.expectedCfrApprovers) {
                    if (lvl.expectedCfrApprovers.length > 0) {
                      if (allUsers.length > 0) {
                        allUsers.forEach((user) => {
                          if (lvl.expectedCfrApprovers[0] == user.id) {
                            cfrobj.projectsponsoremail = user.email;
                            cfrobj.projectsponsorname = user.name;
                            setSearchString(user.name);
                          }
                        });
                      }
                    }
                  }
                } else if (lvl.approvalLevel.sequencenumber == 3) {
                  if (lvl.expectedCfrApprovers) {
                    if (lvl.expectedCfrApprovers.length > 0) {
                      setValue("functionalHead", lvl.expectedCfrApprovers[0]);
                    }
                  }
                }
              });
            }
          }
        }
      }
    }
  }, [allUsers]);

  useEffect(async () => {
    await getBudgetReferenceNumbers();
  }, [deptData]);

  const getPlantList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: getPlantList";
    try {
      setIsLoading(true);
      const plantData = await API.graphql(graphqlOperation(listCFRPlants));
      setIsLoading(false);
      if (plantData) {
        const records = plantData.data.listCFRPlants.items;
        setPlants([]);
        setPlants(records);
      } else {
        if (plantData.errors) {
          if (plantData.errors.length > 0) {
            alert.show(plantData.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  });

  // const getAssetClass = useCallback(async () => {
  //   try {
  //     setIsLoading(true);
  //     const assetClassData = await API.graphql(
  //       graphqlOperation(listCFRAssetClasses)
  //     );
  //     setIsLoading(false);
  //     if (assetClassData) {
  //       const records = assetClassData.data.listCFRAssetClasses.items;
  //       setAssetClass([]);
  //       setAssetClass(records);
  //       createAssetMap(records);
  //       let unique = [];
  //       records.map((item) => {
  //         if (!unique.includes(item.asset)) {
  //           unique.push(item.asset);
  //         }
  //       });
  //       setFilteredAssetCode(unique);
  //     } else {
  //       if (assetClassData.errors) {
  //         if (assetClassData.errors.length > 0) {
  //           alert.show(assetClassData.errors[0].message);
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     if (error.errors) {
  //       if (error.errors.length > 0) {
  //         alert.show(error.errors[0].message);
  //       }
  //     } else if (error.message) {
  //       alert.show(error.message);
  //     }
  //   }
  // });

  const getConfigList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: getConfigList";
    try {
      setIsLoading(true);
      const configData = await API.graphql(graphqlOperation(listCFRConfigs));
      if (configData) {
        const configList = configData.data.listCFRConfigs.items;
        setConfigData([]);
        setIsLoading(false);
        setConfigData(configList);
      } else {
        if (configData.errors) {
          if (configData.errors.length > 0) {
            alert.show(configData.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${configData.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  });

  
  const getBudgetReferenceNumbers = useCallback(async () => {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: getUserListForEmail";
    try {
      setIsLoading(true);
      let searchParameter = {};
      searchParameter.status = { eq: "Open" };
      const budgetData = await API.graphql(
        graphqlOperation(listCFRBudgetReferences, {
          filter: searchParameter,
          limit: 1000,
        })
      );
      setIsLoading(false);
      if (budgetData) {
        const records = budgetData.data.listCFRBudgetReferences.items;
        const budgtedRecords = records.filter(
          (rec) => rec.budgetReferenceNo.trim() !== "Unbudgeted"
        );
        let filteredRec = [];
        //debugger;
        if (deptData.length > 0) {
          const loggedInUser = getLoggedInUser();
          if (loggedInUser.department) {
            if (loggedInUser.department.items) {
              loggedInUser.department.items.forEach((userdept) => {
                const dept = deptData.filter(
                  (dept) => dept.id === userdept.cFRDepartmentID
                );
                if (dept.length > 0) {
                  let currentDept = dept[0].name;
                  budgtedRecords.forEach((rec) => {
                    if (rec.departmentName === currentDept) {
                      filteredRec.push(rec);
                    }
                  });
                }
              });
            }
          }
        }

        let resArr = [];
        filteredRec.filter(function (item) {
          let i = resArr.findIndex(
            (x) => x.budgetReferenceNo === item.budgetReferenceNo
          );
          if (i <= -1) {
            resArr.push(item);
          }
          return null;
        });

        console.log(resArr);

        setBudgetReferenceNumber(resArr);
      } else {
        if (budgetData.errors) {
          if (budgetData.errors.length > 0) {
            alert.show(budgetData.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  });

  const handleIsBudgeted = function (e) {
    // ;
    console.log(e.type);
    console.log(selectedIsBudgetedOption);
    if (selectedIsBudgetedOption != e.target.value) {
      setSelectedIsBudgetedOption(e.target.value);
      if (e.target.value == "Yes") {
        setIsCapitalBudget(true);
        getBudgetReferenceNumbers();
        setValue("budgetReferenceNo", "default");
        setValue("budgetvalue", 0);
      } else if (e.target.value == "No") {
        setIsCapitalBudget(false);
        setValue("budgetReferenceNo", "");
        setValue("budgetvalue", 0);
        if(isAddMode){
          setSelectedBudgetRefs([]);
          setSelectedBudgetRefIdList([]);
        }
        // setBudgetReferenceNumber([]);
        // setValue("budgetReferenceNo", "Unbudgeted");
        // setBudgetReferenceNumber([
        //   {
        //     budgetReferenceNo: "Unbudgeted",
        //   },
        // ]);
        // // const selectedBudgetReference = budgetReferenceNumber.filter(
        // //   (budget) => budget.budgetReferenceNo === 'Unbudgeted '
        // // );
        // const selectedBudgetReference = "Unbudgeted";
        // if (selectedBudgetReference) {
        //   if (selectedBudgetReference.length > 0) {
        //     const budgetData = selectedBudgetReference[0];
        //     setValue("budgetvalue", parseFloat(budgetData.remainingAmount) * 1);
        //   }
        // }
      }
    }
  };

  const handleBudgetReferenceNo = function (e) {
    console.log(selectedBudgetReferenceOption);
    if (e.target.value) {
      if (selectedBudgetReferenceOption != e.target.value) {
        setSelectedBudgetReferenceOption(e.target.value);
        const selectedBudgetReference = budgetReferenceNumber.filter(
          (budget) => budget.budgetReferenceNo == e.target.value
        );
        if (selectedBudgetReference) {
          if (selectedBudgetReference.length > 0) {
            const budgetData = selectedBudgetReference[0];
            setValue("budgetvalue", parseFloat(budgetData.remainingAmount) * 1);
          }
        }
      }
    }
  };

  const handleProposedValue = function (propsedValue) {
    let users = userDataForEmail;
    const loggedInUser = getLoggedInUser();
    let noLoggedInUserData = users.filter((u) => u.name !== loggedInUser.name);
    let sameDeptUserData = [];
    noLoggedInUserData.forEach((user) => {
      if (user.department) {
        if (user.department.items) {
          user.department.items.forEach((userdept1) => {
            if (loggedInUser.department) {
              if (loggedInUser.department.items) {
                loggedInUser.department.items.forEach((userdept2) => {
                  if (userdept1.cFRDepartmentID == userdept2.cFRDepartmentID) {
                    sameDeptUserData.push(user);
                  }
                });
              }
            }
          });
        }
      }
    });
    const budgted = getValues("iscapitalbudget");
    let sortedUsers = [];
    if (budgted === "Yes") {
      sortedUsers = sameDeptUserData.filter((u) => u.canApproveBudgetedCFR);
    } else {
      sortedUsers = sameDeptUserData.filter((u) => u.canApproveUnBudgetedCFR);
    }
    let filteredUsers = [];
    sortedUsers.map((user) => {
      if (
        propsedValue >= parseInt(user.limit) &&
        propsedValue <= parseInt(user.toLimit)
      ) {
        filteredUsers.push(user);
      }
    });

    setUserData(filteredUsers);
    if (filteredUsers.length > 0)
      setValue("functionalHead", filteredUsers[0].id);
    forceUpdate();

    // if (propsedValue < 500000) {
    //   //users below 5 lac approval limit range
    //   const filteredUsers = sortedUsers.filter(
    //     (user) =>
    //       500000 > parseInt(user.limit) && 500000 <= parseInt(user.toLimit)
    //   );
    //   if (filteredUsers.length > 0) {
    //     setUserData(filteredUsers);
    //     setValue("functionalHead", filteredUsers[0].id);
    //     forceUpdate();
    //   }
    //   // filteredUsers.forEach((user) => {
    //   //   setValue("functionalHead", user.id);
    //   // });
    // } else if (propsedValue > 500000 && propsedValue < 2500000) {
    //   //users between 5 lac amd 25 lac approval limit range
    //   const filteredUsers = sortedUsers.filter(
    //     (user) =>
    //       500000 < parseInt(user.limit) && 2500000 >= parseInt(user.toLimit)
    //   );
    //   if (filteredUsers.length > 0) {
    //     setUserData(filteredUsers);
    //     setValue("functionalHead", filteredUsers[0].id);
    //     forceUpdate();
    //   }
    //   // filteredUsers.forEach((user) => {
    //   //   setValue("functionalHead", user.id);
    //   // });
    // } else if (propsedValue > 2500000 && propsedValue < 5000000) {
    //   //users between 25 lac and 50 lac approval limit range
    //   const filteredUsers = sortedUsers.filter(
    //     (user) =>
    //       2500000 < parseInt(user.limit) && 5000000 >= parseInt(user.toLimit)
    //   );
    //   if (filteredUsers.length > 0) {
    //     setUserData(filteredUsers);
    //     setValue("functionalHead", filteredUsers[0].id);
    //     forceUpdate();
    //   }
    //   filteredUsers.forEach((user) => {
    //     setValue("functionalHead", user.id);
    //   });
    // }
  };

  // const createAssetMap = (records) => {
  //   const mapAssets = new Map();
  //   let unique = [];
  //   records.map((item) => {
  //     if (!unique.includes(item.asset)) {
  //       unique.push(item.asset);
  //     }
  //   });
  //   unique.map((assets) => {
  //     mapAssets.set(assets, []);
  //   });

  //   records.map((rec) => {
  //     let mapArr = mapAssets.get(rec.asset);
  //     mapArr.push(rec);
  //     mapAssets.set(rec.asset, mapArr);
  //   });

  //   console.log(mapAssets);
  //   setAssetDict(mapAssets);
  // };

  const handleAssetLifeValueChange = function (assetLifeVal) {
    setAssetLife(Number(assetLifeVal));
  };

  // const handleGeographyValueChange = function (geographyVal) {
  //   setGeography(geographyVal);
  //   let geoVal = geographyVal;
  //   getConfig(geoVal);

  //   if (geographyVal.length > 0) {
  //     setIsNpvCalBtnDisabled(false);
  //   } else {
  //     setIsNpvCalBtnDisabled(true);
  //   }
  // };

  const handleRemoveCapexRow = (idx) => () => {
    setCapexValueChanged(true);
    setYearlyCapexValues([]);
    const rows = [...capExRows];
    rows.splice(idx, 1);
    setCapExRows(rows);

    let total = 0;
    rows.forEach((row) => {
      total = total + Number(row.capex_unitcost);
    });

    if (capExContingencyChecked) {
      let contingencyVal = total / contingencyPercentage;
      setCapex_contingency(contingencyVal);
      setCapex_total(total + contingencyVal);
    } else {
      setCapex_contingency(0);
      setCapex_total(total);
    }
  };

  const handleCapExValueChange = (idx) => (e) => {
    setCapexValueChanged(true);
    setYearlyCapexValues([]);
    const { name, value } = e.target;
    const rows = [...capExRows];

    let _val = value;
    let currentCapexRow = rows[idx];
    if (name == "capex_unitcost") {
      currentCapexRow.capex_totalunitcost =
        currentCapexRow.capex_quantity * _val;
    } else if (name == "capex_quantity") {
      _val = value <= 0 ? 1 : value;
      currentCapexRow.capex_totalunitcost =
        _val * currentCapexRow.capex_unitcost;
    } else if (name == "capex_category") {
      currentCapexRow.capex_category = value;
    }
    // else if (name == "capex_assetName") {
    //   const filteredClass = assetClass.filter(
    //     (aClass) => aClass.asset === _val
    //   );
    //   currentCapexRow.capex_assetDict = filteredClass;
    //   setFilteredAssetClass(filteredClass);
    //   // if(assetCode.length === 0){
    //   //   setAssetCode([_val]);
    //   //   console.log(assetCode);
    //   // }else{
    //   //   setAssetCode([...assetCode, _val]);
    //   //   console.log(assetCode);
    //   // }
    //   currentCapexRow.capex_evaluationCategory = "Select";
    //   currentCapexRow.capex_description = "";
    //   currentCapexRow.capex_life = 0;
    // }
    // else if (name == "capex_evaluationCategory") {
    //   let selectedObj = {};
    //   filteredAssetClass.map((val) => {
    //     if (val.evgr === _val) {
    //       selectedObj = val;
    //     }
    //   });
    //   currentCapexRow.capex_description = selectedObj.description;
    //   currentCapexRow.capex_life = selectedObj.revisedUsefullLife;
    // }

    let row = { ...currentCapexRow, [name]: _val };
    rows[idx] = row;
    setCapExRows(rows);

    if (name == "capex_unitcost" || name == "capex_quantity") {
      calculateContingency(
        capExContingencyChecked,
        capExContingencyType,
        contingencyPercentage
      );
    }
  };

  const handleAddNewCapExRow = () => {
    setCapexValueChanged(true);
    setYearlyCapexValues([]);
    const item = {
      capex_resource: "",
      capex_category: "",
      capex_quantity: "",
      capex_unitcost: "",
      capex_totalunitcost: "",
      // capex_life: 0,
      // capex_assetName: "",
      // capex_evaluationCategory: "",
      // capex_description: "",
      //capex_assetDict: [],
    };
    setCapExRows([...capExRows, item]);
  };

  const handleCapExContingencyChecked = (e) => {
    if (e.target) {
      setCapexValueChanged(true);
      setYearlyCapexValues([]);
      setCapExContingencyChecked(e.target.checked);
      if (!e.target.checked) {
        setCapExContingencyType("Percentage");
        setContingencyPercentage(0);
      }
      calculateContingency(
        e.target.checked,
        capExContingencyType,
        contingencyPercentage
      );
    }
  };

  const handleContingencyType = (e) => {
    if (e.target) {
      setCapexValueChanged(true);
      setYearlyCapexValues([]);
      setCapExContingencyType(e.target.value);
      calculateContingency(
        capExContingencyChecked,
        e.target.value,
        contingencyPercentage
      );
    }
  };

  const handleCapExContingency = (e) => {
    if (e.target) {
      if (e.target.value) {
        setCapexValueChanged(true);
        setYearlyCapexValues([]);
        setContingencyPercentage(parseFloat(e.target.value));
        calculateContingency(
          capExContingencyChecked,
          capExContingencyType,
          parseFloat(e.target.value)
        );
      }
    }
  };

  const calculateContingency = (
    capExContingencyChecked,
    capExContingencyType,
    contingencyPercentage
  ) => {
    let total = 0;
    const rows = [...capExRows];
    if (capExContingencyChecked) {
      rows.forEach((row) => {
        total = total + Number(row.capex_totalunitcost);
      });
      if (contingencyPercentage > 0) {
        let contingencyVal = 0;
        if (capExContingencyType == "Percentage") {
          contingencyVal = Math.ceil((total * contingencyPercentage) / 100);
        } else if (capExContingencyType == "Amount") {
          contingencyVal = contingencyPercentage;
        }
        setCapex_contingency(contingencyVal);
        total = total + contingencyVal;
        setCapex_total(total);
      } else {
        setCapex_contingency(0);
        setCapex_total(total);
      }
    } else {
      rows.forEach((row) => {
        total = total + Number(row.capex_totalunitcost);
      });
      setCapex_contingency(0);
      setCapex_total(total);
    }
  };

  const handleRemoveRevExRow = (idx) => () => {
    setRevexValueChanged(true);
    const rows = [...revExRows];
    rows.splice(idx, 1);
    setRevExRows(rows);

    let total = 0;
    rows.forEach((row) => {
      total = total + Number(row.revex_cost);
    });
    setRevex_total(total);
  };

  const handleRevExValueChange = (idx) => (e) => {
    setRevexValueChanged(true);
    const { name, value } = e.target;
    let rows = [...revExRows];

    let currentRevexRow = rows[idx];
    let _val = value;
    // if (name == "revex_start_year" || name == "revex_end_year") {
    //   _val = value > assetLife ? assetLife : value;
    // }

    let row = {
      ...currentRevexRow,
      [name]: _val,
    };
    rows[idx] = row;
    setRevExRows(rows);

    if (name == "revex_cost") {
      let total = 0;
      rows.forEach((row) => {
        total = total + Number(row.revex_cost);
      });
      setRevex_total(total);
    }
  };

  const handleAddNewRevExRow = () => {
    setRevexValueChanged(true);
    const item = {
      revex_resource: "",
      // revex_start_year: "",
      // revex_end_year: "",
      revex_cost: "",
    };
    setRevExRows([...revExRows, item]);
  };

  const handleRiskChange = (idx) => (e) => {
    const { name, value } = e.target;
    let rows = [...risks];

    let currentRiskRow = rows[idx];
    let _val = value;

    let row = {
      ...currentRiskRow,
      [name]: _val,
    };
    rows[idx] = row;
    setRisks(rows);
    forceUpdate();
    console.log(risks);
  };

  const handleTimelineActivityChange = (idx) => (e) => {
    const { name, value } = e.target;
    let rows = [...timelineActivities];

    let currentActivity = rows[idx];
    let _val = value;

    let row = {
      ...currentActivity,
      [name]: _val,
    };
    rows[idx] = row;
    setTimelineActivities(rows);
    forceUpdate();
    console.log(timelineActivities);
  }

  const handleAddNewRiskRow = () => {
    //setRevexValueChanged(true);
    const item = {
      risk: "",
      mitigation: "",
      category: "",
      occurrence: "",
      consequence: "",
      owner: "",
    };
    setRisks([...risks, item]);
  };


  const handleAddTimelineActivity = () => {
    //setRevexValueChanged(true);
    const item = {
      activityName: "",
      activityDate: "",
    };
    setTimelineActivities([...timelineActivities, item]);
  };

  const handleRiskRemoval = (idx) => {
    const rows = [...risks];
    rows.splice(idx, 1);
    setRisks(rows);
  }

  const handleTimelineActivityRemoval = (idx) => {
    const rows = [...timelineActivities];
    rows.splice(idx, 1);
    setTimelineActivities(rows);
  }

  const HandleCalNpvView = () => {
    setIsOpen(true);
  };

  function afterOpenModal() {
    var data = {
      //AssetLife: Number(assetLife),
      AssetLife: capexAnalysisPeriod,
      RevExRows: [...revExRows],
      CapExTotal: capex_total,
      RevExTotal: revex_total,
    };
    let arr = [];
    for (let i = 0; i < capexAnalysisPeriod; i++) {
      arr.push(i);
    }
    setcapexAnalysisPeriodArray(arr);
    setColWidth(75 / capexAnalysisPeriod);
    forceUpdate();
    let _lstTotalSaving = [...totalSavings];
    handleCapexRevexCostValues(
      data.RevExRows,
      data.RevExTotal,
      data.CapExTotal,
      //data.AssetLife + 1
      capexAnalysisPeriod,
      _lstTotalSaving
    );
  }

  function closeModal() {
    setIsOpen(false);
    setRevexValueChanged(false);
    setCapexValueChanged(false);
  }

  function onOkBtnClicked() {
    let yearlyCapexTotal = yearlyCapexValues.reduce(
      (total, obj) => Number(parseFloat(Number(obj.Cost) + total).toFixed(2)),
      0
    );
    if (yearlyCapexTotal != capex_total) {
      alert.show(
        "Yearwise capex total (" +
        yearlyCapexTotal +
        ") does not match with capex total value (" +
        capex_total +
        ") entered in Capex section."
      );
      setModalCancelVisible(false);
      return;
    } else {
      let yearlyRevexTotal = yearlyRevexValues.reduce(
        (total, obj) => Number(parseFloat(Number(obj.Cost) + total).toFixed(2)),
        0
      );
      if (yearlyRevexTotal != revex_total) {
        alert.show(
          "Yearwise revex total (" +
          yearlyRevexTotal +
          ") does not match with revex total value (" +
          revex_total +
          ") entered in Revex section."
        );
        setModalCancelVisible(false);
        return;
      } else {
        setModalCancelVisible(true);
      }
    }
    setValue("irr", irrValue);
    setValue("npv", npvValue);
    setValue("payback", undiscountedPaybackPeriod);
    setIsOpen(false);
    setRevexValueChanged(false);
    setCapexValueChanged(false);
  }

  const handleCapexAnalysisPeriodValueChange = (e) => {
    if (e.target) {
      if (e.target.value) {
        setCapexAnalysisPeriod(e.target.value);
        setRevexValueChanged(true);
        setCapexValueChanged(true);
        setYearlyCapexValues([]);
        let arr = [];
        for (let i = 0; i < e.target.value; i++) {
          arr.push(i);
        }
        setcapexAnalysisPeriodArray(arr);
        let _lstTotalSaving = [...totalSavings];
        if (parseInt(e.target.value) > parseInt(capexAnalysisPeriod)) {
          _lstTotalSaving.push({ Saving: "0" });
          setTotalSavings(_lstTotalSaving);
        } else if (parseInt(e.target.value) < parseInt(capexAnalysisPeriod)) {
          _lstTotalSaving.splice(_lstTotalSaving.length - 1);
          setTotalSavings(_lstTotalSaving);
        }
        forceUpdate();
        setColWidth(75 / Number(e.target.value));
      }
    }
  };
  useEffect(() => {
    var data = {
      CapexAnalysisPeriod: Number(capexAnalysisPeriod),
      RevExRows: [...revExRows],
      CapExTotal: capex_total,
      RevExTotal: revex_total,
    };
    handleCapexRevexCostValues(
      data.RevExRows,
      data.RevExTotal,
      data.CapExTotal,
      data.CapexAnalysisPeriod,
      totalSavings
    );
  }, [capexAnalysisPeriod]);

  const handleCapexRevexCostValues = (
    revexrows,
    revextotal,
    capextotal,
    capexAnalysisPeriod,
    _lstTotalSaving
  ) => {
    let capexYearlyValues = [...yearlyCapexValues];
    let revexYearlyValues = revexValueChanged ? [] : [...yearlyRevexValues];
    let costValues = [];

    //CapEx
    if (capexYearlyValues.length == 0) {
      //let yearlyCapex = parseFloat(capextotal / capexAnalysisPeriod).toFixed(2);
      for (var i = 0; i < capexAnalysisPeriod; i++) {
        if (i == 0) {
          capexYearlyValues.push({ Year: i, Cost: parseFloat(capextotal) });
        } else {
          capexYearlyValues.push({ Year: i, Cost: 0 });
        }
      }
      setYearlyCapexValues(capexYearlyValues);
    }
    //RevEx

    if (revexYearlyValues.length == 0) {
      let yearlyRevEx = parseFloat(revextotal / capexAnalysisPeriod).toFixed(2);
      for (var i = 0; i < capexAnalysisPeriod; i++) {
        revexYearlyValues.push({ Year: i, Cost: yearlyRevEx });
      }
      setYearlyRevexValues(revexYearlyValues);
    }

    //cost

    for (var i = 0; i < capexYearlyValues.length; i++) {
      costValues.push({ Year: i, Cost: capexYearlyValues[i].Cost });
    }
    for (var i = 0; i < revexYearlyValues.length; i++) {
      for (var j = 0; j < costValues.length; j++) {
        if (revexYearlyValues[i].Year == costValues[j].Year) {
          costValues[j].Cost =
            Number(costValues[j].Cost) + Number(revexYearlyValues[i].Cost);
        }
      }
    }
    setCostValues(costValues);

    // let _lstTotalSaving = [...totalSavings];
    // if (_lstTotalSaving.length > 0) {
    handleCashFlowValues(costValues, _lstTotalSaving);
    //}
  };

  const handleCapexValueChange = (idx) => (e) => {
    let _lstTotalSaving = [...totalSavings];
    let _lstYearlyCapexValues = [...yearlyCapexValues];
    let _lstYearlyRevexValues = [...yearlyRevexValues];

    let currentCapexValue = _lstYearlyCapexValues[idx];
    let capexobj = { ...currentCapexValue, Cost: e.target.value };
    _lstYearlyCapexValues[idx] = capexobj;
    setYearlyCapexValues(_lstYearlyCapexValues);

    let _lstCostValues = [];
    for (var i = 0; i < _lstYearlyCapexValues.length; i++) {
      _lstCostValues.push({ Year: i, Cost: _lstYearlyCapexValues[i].Cost });
    }
    for (var i = 0; i < _lstYearlyRevexValues.length; i++) {
      for (var j = 0; j < _lstCostValues.length; j++) {
        if (_lstYearlyRevexValues[i].Year == _lstCostValues[j].Year) {
          _lstCostValues[j].Cost =
            Number(_lstCostValues[j].Cost) +
            Number(_lstYearlyRevexValues[i].Cost);
        }
      }
    }
    setCostValues(_lstCostValues);

    handleCashFlowValues(_lstCostValues, _lstTotalSaving);
  };

  const handleRevexValueChange = (idx) => (e) => {
    let _lstTotalSaving = [...totalSavings];
    let _lstYearlyCapexValues = [...yearlyCapexValues];
    let _lstYearlyRevexValues = [...yearlyRevexValues];

    let currentRevexValue = _lstYearlyRevexValues[idx];
    let revexobj = { ...currentRevexValue, Cost: e.target.value };
    _lstYearlyRevexValues[idx] = revexobj;
    setYearlyRevexValues(_lstYearlyRevexValues);

    let _lstCostValues = [];
    for (var i = 0; i < _lstYearlyCapexValues.length; i++) {
      _lstCostValues.push({ Year: i, Cost: _lstYearlyCapexValues[i].Cost });
    }
    for (var i = 0; i < _lstYearlyRevexValues.length; i++) {
      for (var j = 0; j < _lstCostValues.length; j++) {
        if (_lstYearlyRevexValues[i].Year == _lstCostValues[j].Year) {
          _lstCostValues[j].Cost =
            Number(_lstCostValues[j].Cost) +
            Number(_lstYearlyRevexValues[i].Cost);
        }
      }
    }
    setCostValues(_lstCostValues);

    handleCashFlowValues(_lstCostValues, _lstTotalSaving);
  };

  const handleSavingValueChange = (idx) => (e) => {
    let _lstTotalSaving = [...totalSavings];
    let currentsaving = _lstTotalSaving[idx];
    let savingobj = { ...currentsaving, Saving: e.target.value };
    _lstTotalSaving[idx] = savingobj;
    setTotalSavings(_lstTotalSaving);

    let _lstCostValues = [...costValues];
    handleCashFlowValues(_lstCostValues, _lstTotalSaving);
  };

  const handleCashFlowValues = (_lstCostValues, _lstTotalSaving) => {
    let _lstCashflow = [];
    _lstTotalSaving.forEach((item, idx) => {
      let costVal = _lstCostValues[idx];
      let calcCost = 0;
      if (Number(item.Saving) - Number(costVal.Cost) < 0) {
        calcCost = Math.floor(Number(item.Saving) - Number(costVal.Cost));
      } else {
        calcCost = Math.ceil(Number(item.Saving) - Number(costVal.Cost));
      }
      _lstCashflow.push({ Year: idx, Cost: calcCost });
    });

    setCashFlowValues(_lstCashflow);
    handleCumulativeCfValues(_lstCashflow);
    handleIrrValueCalculation(_lstCashflow, interestRate);
  };

  const handleModalShow = (id) => {
    if (id === "budgetRef") {
      setModalShow(true);
      let propValAddedList = [];
      budgetReferenceNumber.forEach((obj) => {
        let tempObj = {
          ...obj,
          proposedValTemp: 0,
          isSelected: false
        }
        propValAddedList.push(tempObj);
      })
      setOpenBudgetRefs(propValAddedList);
    }
    if (id === "pencilIcon") {
      if (isAddMode) {
        setModalShow(true);
        let propValAddedList = [];
        //debugger
        budgetReferenceNumber.forEach((obj) => {
          if (selectedBudgetRefIdList.includes(obj.budgetReferenceNo)) {
            selectedBudgetRefs.forEach((element) => {
              if (obj.budgetReferenceNo == element.budgetReferenceNo) {
                let tempObj = {
                  ...obj,
                  proposedValTemp: element.proposedValTemp,
                  remainingAmount: element.remainingAmount,
                  isSelected: true,
                };
                propValAddedList.push(tempObj);
              }
            });
          }
          if (!selectedBudgetRefIdList.includes(obj.budgetReferenceNo)) {
            let tempObj = {
              ...obj,
              proposedValTemp: 0,
              isSelected: false,
            };
            propValAddedList.push(tempObj);
          }
        });
        console.log("propValAddedList", propValAddedList)
        console.log("selected budg refs", selectedBudgetRefs);
        setOpenBudgetRefs(propValAddedList);
      }
      if (!isAddMode) {
        setModalShow(true);
        let propValAddedList = [];
        //debugger
        budgetReferenceNumber.forEach((obj) => {
          if (selectedBudgetRefIdList.includes(obj.budgetReferenceNo)) {
            selectedBudgetRefs.forEach((element) => {
              if (obj.budgetReferenceNo == element.budgetReferenceNo) {
                let tempObj = {
                  ...obj,
                  proposedValTemp: !(element.proposedValTemp) ? element.proposedAmount : element.proposedValTemp,
                  remainingAmount: element.remainingAmount - (element.proposedAmount ? element.proposedAmount : element.proposedValTemp),
                  isSelected: true,
                };
                propValAddedList.push(tempObj);
              }
            });
          }
          if (!selectedBudgetRefIdList.includes(obj.budgetReferenceNo)) {
            let tempObj = {
              ...obj,
              proposedValTemp: 0,
              isSelected: false,
            };
            propValAddedList.push(tempObj);
          }
        });
        console.log("propValAddedList", propValAddedList)
        console.log("selected budg refs", selectedBudgetRefs);
        setOpenBudgetRefs(propValAddedList);
      }

    }
  };

  const handleModalOnClose = (id) => {
    // setSelectedBudgetRefs([]);
    // setSelectedBudgetRefIdList([]);
    console.log("Close id", id);
    if (id == "isAddMode") {
      //setSelectedBudgetRefs([]);
      //setSelectedBudgetRefIdList([]);
      setModalShow(false);
    }
    if (id == "isEditMode") {
      setModalShow(false);
    }
  };

  const handleModalOkClick = (e, idx) => {
    setModalShow(false);
    let propVal = 0;
    console.log(selectedBudgetRefs);
    selectedBudgetRefs.forEach((obj) => {
      console.log("selected budg in ok click", obj);
      if (obj.proposedValTemp) {
        propVal = propVal + parseInt(obj.proposedValTemp)
      }
      else {
        propVal = propVal + parseInt(obj.proposedAmount)
      }
    })
    //setFinalPropVal(propVal);
    setValue("proposalvalue", propVal);
    handleProposedValue(propVal);
    forceUpdate();
    console.log(propVal, "propVal");
  };

  const handleCheckBox = (e, idx) => {
    if (e.target.checked === true) {
      selectedBudgetRefs.push(openBudgetRefs[idx]);
      selectedBudgetRefIdList.push(openBudgetRefs[idx].budgetReferenceNo);
      openBudgetRefs[idx].isSelected = true;
      forceUpdate();
      console.log(selectedBudgetRefs);
    }
    else {
      let filteredList = selectedBudgetRefs.filter(
        (obj) => obj.budgetReferenceNo == openBudgetRefs[idx].budgetReferenceNo
      );
      let filteredIndex = selectedBudgetRefs.indexOf(filteredList[0]);
      selectedBudgetRefs.splice(filteredIndex, 1);
      selectedBudgetRefIdList.splice(filteredIndex, 1);
      openBudgetRefs[idx].isSelected = false;
      openBudgetRefs[idx].remainingAmount = budgetReferenceNumber[idx].remainingAmount;
      forceUpdate();
    }
  };

  const handleProposedValueChange = (e, idx) => {
    console.log(e.target.value);
    // proposedAmt.splice(idx,0,e.target.value);
    if (e.target.value !== "") {
      openBudgetRefs[idx].proposedValTemp = parseInt(e.target.value);
      if (selectedBudgetRefs.length > 0) {
        selectedBudgetRefs.forEach((sel) => {
          if (sel.budgetReferenceNo == openBudgetRefs[idx].budgetReferenceNo) {
            sel.proposedValTemp = parseInt(e.target.value);
          }
        })
      }
      console.log(openBudgetRefs[idx].proposedValTemp);
    }
  };

  const handleModalBlurEvent = (e, idx) => {
    if (parseInt(e.target.value) == openBudgetRefs[idx].proposedValTemp) {
      //openBudgetRefs[idx].remainingAmount = openBudgetRefs[idx].remainingAmount - parseInt(openBudgetRefs[idx].proposedValTemp);
      openBudgetRefs[idx].remainingAmount =
        budgetReferenceNumber[idx].remainingAmount -
        parseInt(openBudgetRefs[idx].proposedValTemp);
      setOpenBudgetRefs(openBudgetRefs);
      forceUpdate();
      // openBudgetRefs[idx].remainingAmount = budgetReferenceNumber[idx].remainingAmount - parseInt(openBudgetRefs[idx].proposedValTemp);
    }
  };

  const handleCumulativeCfValues = (_lstCashflow) => {
    let _lstCumulativeCfValues = [];
    let lastCalculatedCfValue = 0;
    _lstCashflow.forEach((item, idx) => {
      if (idx == 0) {
        lastCalculatedCfValue = Number(_lstCashflow[idx].Cost);
      } else {
        let calcCfVal = 0;
        if (lastCalculatedCfValue + Number(_lstCashflow[idx].Cost) < 0) {
          calcCfVal = Math.floor(
            lastCalculatedCfValue + Number(_lstCashflow[idx].Cost)
          );
        } else {
          calcCfVal = Math.ceil(
            lastCalculatedCfValue + Number(_lstCashflow[idx].Cost)
          );
        }
        lastCalculatedCfValue = calcCfVal;
      }
      _lstCumulativeCfValues.push({ Year: idx, Cost: lastCalculatedCfValue });
    });

    setCumulativeCFValues(_lstCumulativeCfValues);
    handlePositiveCashflowValues(_lstCumulativeCfValues, _lstCashflow);
    //handlePvValues(_lstCashflow);
  };

  const handlePositiveCashflowValues = (
    _lstCumulativeCfValues,
    _lstCashflow
  ) => {
    if (_lstCumulativeCfValues.length > 0) {
      let _lstPositiveCashFlows = [];
      _lstCumulativeCfValues.forEach((item) => {
        if (Number(item.Cost) > 0) {
          _lstPositiveCashFlows.push("TRUE");
        } else {
          _lstPositiveCashFlows.push("FALSE");
        }
      });

      setPositiveCashFlowValues(_lstPositiveCashFlows);
      let period =
        _lstPositiveCashFlows.findIndex((it) => it == "TRUE") >= 0
          ? _lstPositiveCashFlows.findIndex((it) => it == "TRUE")
          : "N/A";
      setUndiscountedPaybackPeriod(period);

      if (period != "N/A") {
        if (period > 0) {
          if (_lstCashflow.length > period) {
            let partialYearPaybackPeriodVal = parseFloat(
              period -
              _lstCumulativeCfValues[period].Cost / _lstCashflow[period].Cost
            ).toFixed(2);
            setPartialYearPaybackPeriod(partialYearPaybackPeriodVal);
          }
        } else {
          setPartialYearPaybackPeriod(0);
        }
      } else {
        setPartialYearPaybackPeriod("N/A");
      }
    }
  };

  useEffect(() => {
    if (capexAnalysisPeriodArray.length > 0) {
      if (cashFlowValues.length > 0) {
        handlePvValues(cashFlowValues);
      }
    }
  }, [capexAnalysisPeriodArray]);

  useEffect(() => {
    if (capexAnalysisPeriodArray.length > 0) {
      if (cashFlowValues.length > 0) {
        handlePvValues(cashFlowValues);
      }
    }
  }, [cashFlowValues]);

  const handlePvValues = (_lstCashflow) => {
    let lstPvValues = [];
    let lstRoundedPvValues = [];
    let lastCalculatedPvValue = 0;
    //Array.from(Array(assetLife + 1), (e, i) => {
    capexAnalysisPeriodArray.map((item, i) => {
      if (i == 0) {
        lastCalculatedPvValue = 100;
      } else {
        lastCalculatedPvValue =
          lastCalculatedPvValue / (1 + interestRate / 100);
        // lastCalculatedPvValue = Math.round(
        //   lastCalculatedPvValue / (1 + interestRate / 100)
        // );
        //Math.ceil(lastCalculatedPvValue / (1 + interestRate / 100));
      }
      lstPvValues.push(lastCalculatedPvValue);
    });
    lstPvValues.map((value) => {
      let roundvalue = Math.round(value);
      lstRoundedPvValues.push(roundvalue);
    });
    setPvValues(lstRoundedPvValues);
    handlePvOfCashflow(_lstCashflow, lstPvValues);
  };

  const handlePvOfCashflow = (lstCashflow, lstPvValues) => {
    let lstPvOfCashflow = [];
    //Array.from(Array(assetLife + 1), (e, i) => {
    capexAnalysisPeriodArray.map((item, i) => {
      lstPvOfCashflow.push(
        lstCashflow[i] != undefined && lstPvValues[i] != undefined
          ? Math.ceil(
            (Number(lstCashflow[i].Cost) * Number(lstPvValues[i])) / 100
          )
          : 0
      );
    });
    setPvOfCashflowValues(lstPvOfCashflow);
    handleCumulativePvValues(lstPvOfCashflow);
  };

  const handleCumulativePvValues = (lstPvOfCashflow) => {
    let lstCumulativePvValues = [];
    let lastCalculatedPvValue = 0;
    lstPvOfCashflow.forEach((item, idx) => {
      if (idx == 0) {
        lastCalculatedPvValue = Number(lstPvOfCashflow[idx]);
      } else {
        lastCalculatedPvValue = Math.ceil(
          parseFloat(lastCalculatedPvValue + Number(lstPvOfCashflow[idx]))
        );
        //Number(parseFloat(lastCalculatedPvValue + Number(lstPvOfCashflow[idx])).toFixed(2));
      }
      lstCumulativePvValues.push(lastCalculatedPvValue);
    });

    setCumulativePVValues(lstCumulativePvValues);
    let npvVal =
      lstCumulativePvValues[parseFloat(capexAnalysisPeriod) - 1] != undefined
        ? lstCumulativePvValues[parseFloat(capexAnalysisPeriod) - 1]
        : 0;
    setNPVValue(npvVal);
  };

  const handleIrrValueCalculation = (lstCashflow, interestRateVal) => {
    let NPV = 0;
    if (lstCashflow.length > 0) {
      let min = 0.0;
      let max = 1.0;
      let guess = 0;
      do {
        guess = (min + max) / 2;
        NPV = 0;
        for (var j = 0; j < lstCashflow.length; j++) {
          NPV += Math.ceil(
            parseFloat(lstCashflow[j].Cost / Math.pow(1 + guess, j))
          );
        }

        if (guess == 0 || guess == 1) {
          break;
        }

        if (NPV > 0) {
          min = guess;
        } else {
          max = guess;
        }
        //console.log("Math.abs(NPV): " + Math.abs(NPV) + " guess: " + guess);
      } while (Math.abs(NPV) > 0.000001);

      if (Math.ceil(guess * 100 > 0)) {
        setIRRValue(Math.ceil(guess * 100));
      } else {
        setIRRValue("N/A");
      }
    }
  };

  const getConfig = useCallback(async (geoVal) => {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: getConfig";
    try {
      //to do -- uncomment below code once the changes related auto-binding of geogarphy done.
      //setIsLoading(true);
      // let filter = {geography: { contains: geoVal }};
      // const configData = await API.graphql(graphqlOperation(listCFRConfigs, { filter: filter } ));
      // if (configData) {
      //   const config = configData.data.listCFRConfigs.items[0];
      //   if(config != undefined) {
      //     setCurrency(selectLogo(config.currency));
      //     setInterestRate(config.interestRate);
      //   }else {
      //     setCurrency('');
      //     setInterestRate(0);
      //   }
      //   //setIsLoading(false);
      // } else {
      //   if (configData.errors) {
      //     if (configData.errors.length > 0) {
      //       alert.show(configData.errors[0].message);
      //     }
      //   }
      // }

      const configData = await API.graphql(graphqlOperation(listCFRConfigs));
      if (configData) {
        const configs = configData.data.listCFRConfigs.items;
        if (configs != undefined) {
          for (var i = 0; i < configs.length; i++) {
            if (
              configs[i].geography.toLocaleLowerCase() ===
              geoVal.toLocaleLowerCase()
            ) {
              setCurrency(configs[i].currency);
              setCurrencylogo(configs[i].currencySymbol);
              setInterestRate(configs[i].interestRate);
              return;
            } else {
              setCurrency("");
              setInterestRate(0);
            }
          }
        } else {
          setCurrency("");
          setInterestRate(0);
        }
        //setIsLoading(false);
      } else {
        if (configData.errors) {
          if (configData.errors.length > 0) {
            alert.show(configData.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  });

  const handleBudgetBenifits = (e) => {
    const selectedBudgetBenefit = e.target.value;
    setBudgetBenefit(selectedBudgetBenefit);
  };

  const addFileToDB = async (fileObj) => {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: getUserListForEmail";
    try {
      await API.graphql(
        graphqlOperation(createCFRS3Object, { input: fileObj })
      );
    } catch (error) {
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      }
    }
  };

  const DeleteFile = (file, id) => {
    alert.show("Are you sure, you want to delete a file ?", {
      title: "Confirmation",
      closeCopy: "Cancel",
      actions: [
        {
          copy: "Yes",
          onClick: () => deleteFileFromS3Bucket(file, id),
        },
      ],
    });
  };

  const deleteFileFromS3Bucket = async (fileObj, id) => {
    const res = await Storage.remove(fileObj.key);
    // const res = await Storage.remove("test.txt");
    if (res) {
      await deleteFileFromDB(fileObj.id);
      console.log("delete completed");
      alert.show("CFR file is deleted.");
    }
  };

  const deleteFileFromDB = async (id) => {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: getUserListForEmail";
    try {
      const deleteObj = {
        id: id,
      };
      const result = await API.graphql(
        graphqlOperation(deleteCFRS3Object, { input: deleteObj })
      );
      if (result.data) {
        let remainingFiles = [];
        cfrUploadedExistingFiles.forEach((item) => {
          if (item.id != id) {
            remainingFiles.push(item);
          }
        });
        setCfrUploadedExistingFiles(remainingFiles);
      }
    } catch (error) {
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      }
    }
  };

  const onUploadFile = async (evt) => {
    let files = [];
    if (evt.target.files) {
      if (evt.target.files.length > 0) {
        Array.from(evt.target.files).forEach((item, idx) => {
          const file = item;
          files.push(file);
        });
      }
    }
    setCfrUploadedFiles(files);
  };

  const onError = (errors, e) => {
    console.log(errors, e);
  };

  const DownloadFile = (item, fileId) => async () => {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: DownloadFile";
    //console.log("downloading" + item.key);
    setIsLoading(true);
    try {
      const result = await Storage.get(item.key, { download: true });
      if (result) {
        await downloadBlob(result.Body, item.key);
      }
      setIsLoading(false);
    } catch (error) {
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      }
      setIsLoading(false);
      alert.show(
        "Something went wrong while downloading, please contact administrator.",
        {
          type: "success",
          onClose: () => {
            //props.history.push("/cfrlist");
          }, // callback that
        }
      );
    }
  };

  async function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    let file = filename.split("/")[0];
    a.download = file || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  // const handleAssetCodeChange = (e) => {
  //   console.log(e.target.value);
  //   const selectedAssetCode = e.target.value;
  //   const filteredClass = assetClass.filter(
  //     (aClass) => aClass.asset === selectedAssetCode
  //   );
  //   setFilteredAssetClass(filteredClass);
  //   setAssetDesc("");
  //   setAssetRevLife(0);
  //   setSelEvalCat("Select");
  // };

  // const handleAssetChange = (idx) => (e) => {
  //   const selectedEvalCat = e.target.value;
  //   setSelEvalCat(selectedEvalCat);
  //   const index = idx;
  //   let selectedObj = {};
  //   filteredAssetClass.map((val) => {
  //     if (val.evgr === selectedEvalCat) {
  //       selectedObj = val;
  //     }
  //   });
  //   setAssetDesc(selectedObj.description);
  //   setAssetRevLife(selectedObj.revisedUsefullLife);

  //   const rows = [...capExRows];
  //   let row = {
  //     ...rows[idx],
  //     capex_assetName: selectedObj.asset,
  //     capex_evaluationCategory: selectedObj.evgr,
  //     capex_description: selectedObj.description,
  //     capex_life: selectedObj.revisedUsefullLife,
  //   };
  //   rows[idx] = row;
  //   setCapExRows(rows);
  // };

  return (
    <div id="cfrFormDiv" className="page-container">
      {isLoading ? <PageLoader></PageLoader> : ""}
      <div className="page-header">
        <h4> CFR Change Request </h4>
      </div>
      <form
        onSubmit={handleSubmit((data, event) => {
          onCFRSubmit(data, event);
        })}
      >
        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <label className="pull-left form-label">Project Information</label>
              <div className="pull-right">
                <i className="fa fa-chevron-down"></i>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-4  mb-3">
                      <label className="form-label"> Project Title </label>
                      <div> {cfrObj.projecttitle} </div>
                    </div>
                    <div className="col-sm-4  mb-3">
                      <label className="form-label"> Version </label>
                      <div>CR {cfrVersion}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4  mb-3">
                      <label className="form-label"> Geography</label>
                      <div> {cfrObj.territory} </div>
                    </div>
                    {/* <div className="col-sm-4  mb-3">
                      <label className="form-label"> Plant Id/Location </label>
                      <div> {cfrObj.location}</div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-sm-4  mb-3">
                      <label className="form-label"> Budgeted </label>
                      {/* <div> {cfrObj.iscapitalbudget == true ? "Yes" : "No"} </div> */}
                      <select
                        className="ddl-control"
                        id="txtIsBudgeted"
                        name="iscapitalbudget"
                        //value={selectedIsBudgetedOption}
                        // onChange={handleIsBudgeted}
                        // onSelect={handleIsBudgeted}
                        onClick={handleIsBudgeted}
                        // onSelect={(e) => {
                        //    ;
                        //   handleIsBudgeted(e);
                        // }}
                        {...register("iscapitalbudget", {
                          required: true,
                        })}
                      >
                        <option value="Yes" defaultValue>
                          Yes
                        </option>
                        <option value="No"> No </option>
                      </select>
                      {errors.iscapitalbudget &&
                        errors.iscapitalbudget.type === "required" && (
                          <div className="error-msg">Budgeted is required.</div>
                        )}
                    </div>
                    {(selectedIsBudgetedOption == "" || selectedIsBudgetedOption == "Yes") &&
                      (selectedBudgetRefs && selectedBudgetRefs.length <= 0) ? (
                      <div className="col-sm-4  mb-3">
                        <label className="form-label">Budget References</label>
                        <br />
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-secondary ml-5"
                          id="budgetRef"
                          onClick={(e) => {
                            handleModalShow(e.target.id);
                          }}
                        >
                          Add Budget References
                        </button>
                      </div>
                    ) : ((selectedIsBudgetedOption == "" || selectedIsBudgetedOption == "Yes") &&
                      <div className="col-sm-4  mb-3">
                        <div>
                          <label className="form-label">Budget References</label>
                          <button
                            type="button"
                            id="pencilIcon"
                            className="btn-grid fa fa-pencil"
                            style={{ marginRight: 0 }}
                            onClick={(e) => {
                              handleModalShow(e.target.id);
                            }}
                          ></button>
                        </div>
                        <div>
                          {selectedBudgetRefs.map((obj) => (
                            <tr className="ddl-control">
                              {<li> {obj.budgetReferenceNo} </li>}
                            </tr>
                          ))}
                        </div>
                      </div>)
                    }
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <label className="pull-left form-label">Budget Information</label>
              <div className="pull-right">
                <i className="fa fa-chevron-down"></i>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-4  mb-3">
                      <label className="form-label">
                        Budget Value Type <span> * </span>
                      </label>
                      <select
                        className="ddl-control"
                        id="txtBudgetValueType"
                        name="budgetvaluetype"
                        {...register("budgetvaluetype", { required: true })}
                      >
                        <option> Capital </option>
                        <option> Non-Capital </option>
                      </select>
                      {errors.budgetvaluetype &&
                        errors.budgetvaluetype.type === "required" && (
                          <div className="error-msg">
                            Budget value type is required.
                          </div>
                        )}
                    </div>
                    {/* <div className="col-sm-4  mb-3">
                      <label className="form-label">
                        Budget Remaining Value
                        {currencylogo != "" ? currencylogo : ""}
                      </label>
                      <input
                        type="number"
                        id="txtBudgetValue"
                        name="budgetvalue"
                        className="form-control"
                        readOnly
                        {...register("budgetvalue", {})}
                      />
                    </div>
                  </div> */}
                    <div className="col-sm-4  mb-3">
                      <label className="form-label">
                        Proposed Value {currencylogo != "" ? currencylogo : ""}
                        <span> * </span>
                      </label>
                      {(selectedIsBudgetedOption == "" || selectedIsBudgetedOption == "Yes") ? <input
                        type="number"
                        id="txtProposedValue"
                        name="proposalvalue"
                        max="5000000"
                        min="0"
                        className="form-control"
                        readOnly
                        //onChange={handleProposedValue}
                        // onKeyUp={(e) => {
                        //   handleProposedValue(e.target.value);
                        // }}
                        {...register("proposalvalue", { max: 5000000 })}
                      /> : 
                      <input
                        type="number"
                        id="txtProposedValue"
                        name="proposalvalue"
                        max="5000000"
                        min="0"
                        className="form-control"
                        defaultValue={0}
                        //onChange={handleProposedValue}
                        // onKeyUp={(e) => {
                        //   handleProposedValue(e.target.value);
                        // }}
                        {...register("proposalvalue", { max: 5000000 })}
                      />}
                      {errors.proposalvalue &&
                        errors.proposalvalue.type === "required" && (
                          <div className="error-msg">
                            Proposed value is required.
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="row">
                  <div className="col-sm-4  mb-3">
                      <label className="form-label">
                        Proposed Value Type <span> * </span>
                      </label>
                      <select
                        className="ddl-control"
                        id="txtProposedValueType"
                        name="proposalvaluetype"
                        {...register("proposalvaluetype", { required: true })}
                      >
                        <option> Capital </option>
                        <option> Non-Capital </option>
                      </select>
                      {errors.proposalvaluetype &&
                        errors.proposalvaluetype.type === "required" && (
                          <div className="error-msg">
                            Proposed value type is required.
                          </div>
                        )}
                    </div>
                    <div className="col-sm-4  mb-3">
                      <label className="form-label">NPV</label>
                      <input
                        type="number"
                        id="txtNPV"
                        name="npv"
                        readOnly
                        className="form-control"
                        {...register("npv", {})}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4  mb-3">
                      <label className="form-label">
                        Asset Life (in years)
                      </label>
                      <input
                        type="number"
                        min="0"
                        id="txtAssetLife"
                        name="assetlife"
                        className="form-control"
                        {...register("assetlife", {})}
                        onChange={(e) =>
                          handleAssetLifeValueChange(e.target.value)
                        }
                      />
                      {errors.assetlife &&
                        errors.assetlife.type === "required" && (
                          <div className="error-msg">
                            Asset life value is required.
                          </div>
                        )}
                    </div>
                    
                    <div className="col-sm-4  mb-3">
                      <label className="form-label">IRR (%)</label>
                      <input
                        type="text"
                        id="txtIRR"
                        name="irr"
                        readOnly
                        className="form-control"
                        {...register("irr", {})}
                      />
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-sm-4  mb-3">
                      <label className="form-label">
                        CFR Benefits <span> * </span>
                      </label>
                      <input
                        type="text"
                        id="txtCFRBenefits"
                        name="cfrbenefits"
                        className="form-control"
                        {...register("cfrbenefits", {})}
                      />
                      {errors.cfrbenefits &&
                        errors.cfrbenefits.type === "required" && (
                          <div className="error-msg">
                            CFR benefits value is required.
                          </div>
                        )}
                    </div>
                    
                    <div className="col-sm-4  mb-3">
                      <label className="form-label">Payback (in years)</label>
                      <input
                        type="text"
                        id="txtPayback"
                        name="payback"
                        readOnly
                        className="form-control"
                        {...register("payback", {})}
                      />
                    </div>                    
                  </div>
                  <div className="row">
                  <div className="col-sm-4  mb-3">
                      <label className="form-label">Budget Benefits</label>
                      {/* <input
                        type="text"
                        id="txtBudgetBenefits"
                        name="budgetbenefits"
                        className="form-control"
                        {...register("budgetbenefits", {})}
                      /> */}
                      <select
                        name="capex_category"
                        className="ddl-control"
                        {...register("budgetbenefits")}
                        onChange={handleBudgetBenifits}
                        value={budgetBenefit}
                      >
                        <option defaultValue>Please Select</option>
                        <option value="Compliance">Compliance</option>
                        <option value="Cost Reduction">Cost Reduction</option>
                        <option value="Health and Safety">
                          Health and Safety
                        </option>
                        <option value="Major Maintanence">
                          Major Maintanence
                        </option>
                        <option value="New Products or Packaging">
                          New Products or Packaging
                        </option>
                        <option value="Non Profit-Others">
                          Non Profit-Others
                        </option>
                        <option value="Obsolescence">Obsolescence</option>
                        <option value="Quality Improvement">
                          Quality Improvement
                        </option>
                        <option value="Volume Increase">Volume Increase</option>
                      </select>
                    </div>
                    <div className="col-sm-4  mb-3">
                      <label className="form-label">
                        Expected Completion Date<span> * </span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="dd-mm-yyyy"
                        name="expectedCompletionDate"
                        {...register("expectedCompletionDate")}
                      ></input>
                      {errors.expectedCompletionDate &&
                        errors.expectedCompletionDate.type === "required" && (
                          <div className="error-msg">
                            Expected completion date is required.
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <label className="pull-left form-label">Other Information</label>
              <div className="pull-right">
                <i className="fa fa-chevron-down"></i>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-4  mb-3">
                      <label className="form-label">
                        Spend Type (Principal driver)
                      </label>
                      <input
                        type="text"
                        id="txtSpendType"
                        name="spendtype"
                        className="form-control"
                        {...register("spendtype", {})}
                      />
                    </div>
                    <div className="col-sm-4  mb-3">
                      <label className="form-label">Main Contractor(s)</label>
                      <input
                        type="text"
                        id="txtMainContractor"
                        name="maincontractors"
                        className="form-control"
                        {...register("maincontractors", {})}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-8  mb-3">
                      <label className="form-label">Business Context</label>
                      <textarea
                        id="txtBusinessContext"
                        name="businesscontext"
                        className="form-control"
                        {...register("businesscontext", {})}
                        rows="3"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-8  mb-3">
                      <label className="form-label">Justification</label>
                      <textarea
                        id="txtJustification"
                        name="justification"
                        className="form-control"
                        {...register("justification", {
                          //required: true
                        })}
                        rows="3"
                      />
                      {/* {errors.justification &&
                        errors.justification.type === "required" && (
                          <div className="error-msg">Justification is required.</div>
                      )} */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-8  mb-3">
                      <label className="form-label">Options Considered</label>
                      <textarea
                        id="txtOptionsConsidered"
                        name="optionsconsidered"
                        className="form-control"
                        {...register("optionsconsidered", {})}
                        rows="3"
                      />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="7">
              <label className="pull-left form-label">Timeline</label>
              <div className="pull-right">
                <i className="fa fa-chevron-down"></i>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="7">
              <Card.Body>
                <div className="container">
                  <div className="card d-sm-block border-0">
                    <div className="card-body">
                      <div className="row form-group">
                        <div className="col-sm-12">
                          <table
                            className="table table-bordered table-sm table-responsive"
                            id="TimelineActivities"
                          >
                            <thead>
                              <tr>
                                <th colSpan="5" className="text-center">
                                  <div className="row">
                                    <div className="col-sm-12 text-center">
                                      <label className="control-label">
                                        Timeline Activities
                                      </label>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          handleAddTimelineActivity();
                                        }}
                                        className="btn-grid fa fa-plus float-right"
                                        title="Add Activity"
                                      ></button>
                                    </div>
                                  </div>
                                </th>
                              </tr>
                              <tr className="bg-primary text-white">
                                <th className="width40"> Activity </th>
                                <th className="width40"> Date </th>
                                <th className="width5"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {timelineActivities.map((item, idx) => (
                                <tr id="timelineAct" key={idx}>
                                  <td className="width40">
                                    <input
                                      type="text"
                                      name="activityName"
                                      value={timelineActivities[idx].activityName}
                                      onChange={handleTimelineActivityChange(idx)}
                                      className="form-control"
                                    />
                                  </td>
                                  <td className="width40">
                                    <input
                                      type="date"
                                      name="activityDate"
                                      value={timelineActivities[idx].activityDate}
                                      onChange={handleTimelineActivityChange(idx)}
                                      className="form-control"
                                    />
                                  </td>
                                  <td className="width5">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        handleTimelineActivityRemoval();
                                      }}
                                      className="btn-grid fa fa-trash float-center"
                                      title="Remove Activity"
                                    ></button>
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td className="width10" />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="container">
                  <Card>
                    <Card.Header>
                      <button
                        type="button"
                        onClick={() => {
                          handleAddNewRiskRow();
                        }}
                        className="btn-grid fa fa-plus float-right"
                        title="Add Risk"
                      ></button>
                    </Card.Header>
                    <Card.Body>
                    {risks &&   risks.map((item,idx) => {
                      <div>
                      <Card>
                        <Card.Header>{item.risk}</Card.Header>
                        <Card.Body>{item.mitigation}</Card.Body>
                      </Card>
                      </div>
                    })}</Card.Body>
                    
                  </Card>
                </div> */}
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              <label className="pull-left form-label">Approvals</label>
              <div className="pull-right">
                <i className="fa fa-chevron-down"></i>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-2  mb-3">
                      <label className="form-label">Project Sponsor</label>
                    </div>
                    <div className="col-sm-4  mb-3">
                      <ReactSearchAutocomplete
                        inputSearchString={searchString}
                        items={lstAzureUsers}
                        fuseOptions={{ keys: ["displayName"] }}
                        resultStringKeyName="displayName"
                        onSearch={handleOnSearch}
                        onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        onFocus={handleOnFocus}
                        onClear={handleOnClear}
                        showIcon={false}
                        className="form-control"
                        styling={{
                          height: "34px",
                          fontSize: "14px",
                          fontFamily: "Segoe UI",
                          borderRadius: "4px",
                          backgroundColor: "white",
                          boxShadow: "none",
                          clearIconMargin: "3px 8px 0 0",
                          zIndex: "999",
                        }}
                        {...register("projectsponsorname", {
                          //required: true,
                        })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2  mb-3">
                      <label className="form-label">Functional Head</label>
                    </div>
                    <div className="col-sm-4  mb-3">
                      <select
                        id="functionalHead"
                        name="functionalHead"
                        className="ddl-control"
                        {...register("functionalHead", {
                          //required: true,
                        })}
                      >
                        <option value="">--- select approver ---</option>
                        {userData.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="4">
              <label className="pull-left form-label">
                Project Resources and Attachments
              </label>
              <div className="pull-right">
                <i className="fa fa-chevron-down"></i>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                <div className="card d-sm-block border-0">
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-12">
                        <table
                          className="table table-bordered table-sm table-responsive"
                          id="CapExTable"
                        >
                          <thead>
                            <tr>
                              <th colSpan="10">
                                <div className="row">
                                  <div className="col-sm-12 text-center">
                                    <label className="control-label">
                                      Capital Expenditure (CapEx)
                                    </label>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        handleAddNewCapExRow();
                                      }}
                                      className="btn-grid fa fa-plus float-right"
                                      title="Add New CapEx"
                                    ></button>
                                  </div>
                                </div>
                              </th>
                            </tr>
                            <tr className="bg-primary text-white">
                              <th className="width26">
                                Resource <span> * </span>
                              </th>
                              {/* <th className="width10"> Asset Code </th>
                              <th className="width10"> Eval Cat </th>
                              <th className="width10"> Desc </th>
                              <th className="width10"> Life </th> */}
                              <th className="width20"> Category </th>
                              <th className="width10"> Quantity </th>
                              <th className="width20">
                                Unit Cost ({currencylogo})
                              </th>
                              <th className="width20">
                                Total Cost ({currencylogo})
                              </th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {capExRows.map((item, idx) => (
                              <tr id="capex" key={idx}>
                                <td className="width26">
                                  <div>
                                    <input
                                      type="text"
                                      name="capex_resource"
                                      value={capExRows[idx].capex_resource}
                                      onChange={handleCapExValueChange(idx)}
                                      className="form-control"
                                    />
                                  </div>
                                </td>
                                {/* <td className="width10">
                                  <select
                                    name="capex_assetName"
                                    value={capExRows[idx].capex_assetName}
                                    className="ddl-control"
                                    onChange={handleCapExValueChange(idx)}
                                  >
                                    <option defaultValue>Select</option>
                                    {filteredAssetCode.map((data) => (
                                      <option value={data}>{data}</option>
                                    ))}
                                  </select>
                                </td>
                                <td className="width10">
                                  <select
                                    name="capex_evaluationCategory"
                                    className="ddl-control"
                                    onChange={handleCapExValueChange(idx)}
                                    value={
                                      capExRows[idx].capex_evaluationCategory
                                    }
                                  >
                                    <option defaultValue>Select</option>
                                    {capExRows[idx].capex_assetDict.map(
                                      (data) => (
                                        <option value={data.evgr}>
                                          {data.evgr}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </td>
                                <td className="width10">
                                  <input
                                    type="text"
                                    name="assetDesc"
                                    value={capExRows[idx].capex_description}
                                    className="form-control"
                                    readOnly={true}
                                  />
                                </td> 
                                <td className="width10">
                                  <input
                                    type="text"
                                    name="assetLife"
                                    value={capExRows[idx].capex_life}
                                    className="form-control"
                                    readOnly={true}
                                  />
                                </td>*/}
                                <td className="width20">
                                  {/* <input
                                    type="text"
                                    name="capex_category"
                                    value={capExRows[idx].capex_category}
                                    onChange={handleCapExValueChange(idx)}
                                    className="form-control"
                                  /> */}
                                  <select
                                    name="capex_category"
                                    className="ddl-control"
                                    onChange={handleCapExValueChange(idx)}
                                    value={capExRows[idx].capex_category}
                                  >
                                    <option defaultValue>Please Select</option>
                                    <option value="Internal">Internal</option>
                                    <option value="External">External</option>
                                  </select>
                                </td>
                                <td className="width10">
                                  <input
                                    type="number"
                                    name="capex_quantity"
                                    min="0"
                                    value={capExRows[idx].capex_quantity}
                                    onChange={handleCapExValueChange(idx)}
                                    className="form-control"
                                  />
                                </td>
                                <td className="width20">
                                  <input
                                    type="number"
                                    name="capex_unitcost"
                                    value={capExRows[idx].capex_unitcost}
                                    min="0"
                                    onChange={handleCapExValueChange(idx)}
                                    className="form-control"
                                  />
                                </td>
                                <td className="width20">
                                  <input
                                    type="number"
                                    name="capex_totalunitcost"
                                    value={capExRows[idx].capex_totalunitcost}
                                    min="0"
                                    readOnly
                                    onChange={handleCapExValueChange(idx)}
                                    className="form-control"
                                  />
                                </td>
                                <td>
                                  <button
                                    className="btn-grid fa fa-trash"
                                    title="Remove CapEx Row"
                                    onClick={handleRemoveCapexRow(idx)}
                                  ></button>
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan="4">
                                <div className="row form-group m-0">
                                  <div className="form-check pt-1 col-sm-2">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      name="capExContingency"
                                      id="capExContingency"
                                      checked={capExContingencyChecked}
                                      onChange={(e) =>
                                        handleCapExContingencyChecked(e)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="defaultCheck1"
                                    >
                                      Contingency
                                    </label>
                                  </div>
                                  {capExContingencyChecked == true && (
                                    <div className="col-sm-3 pt-1">
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        name="contigencyType"
                                        id="percentage"
                                        value="Percentage"
                                        checked={
                                          capExContingencyType == "Percentage"
                                        }
                                        onChange={handleContingencyType}
                                        style={{ "margin-right": "5px" }}
                                      />
                                      <span style={{ "padding-right": "10px" }}>
                                        Percentage
                                      </span>
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        name="contigencyType"
                                        id="amount"
                                        value="Amount"
                                        checked={
                                          capExContingencyType == "Amount"
                                        }
                                        onChange={handleContingencyType}
                                        style={{ "margin-right": "5px" }}
                                      />
                                      Amount
                                    </div>
                                  )}

                                  {capExContingencyChecked == true && (
                                    <div className="col-sm-2">
                                      <input
                                        type="number"
                                        name="contigency"
                                        value={contingencyPercentage}
                                        min="0"
                                        className="form-control"
                                        onChange={handleCapExContingency}
                                      />
                                    </div>
                                  )}
                                  {capExContingencyChecked == true &&
                                    capExContingencyType == "Percentage" && (
                                      <div className="col-sm-1 p-0 pt-1">
                                        <span>%</span>
                                      </div>
                                    )}
                                </div>
                              </td>
                              <td className="width15">
                                <input
                                  type="number"
                                  name="capex_contingency"
                                  value={capex_contingency}
                                  min="0"
                                  readOnly
                                  className="form-control"
                                />
                              </td>
                              <td className="width10" />
                            </tr>
                            <tr>
                              <td colSpan="4" align="right">
                                <div className="pt-1">
                                  <label className="form-label">Total</label>
                                </div>
                              </td>
                              <td className="width15">
                                <input
                                  type="number"
                                  name="capex_total"
                                  value={capex_total}
                                  min="0"
                                  className="form-control"
                                  readOnly
                                />
                              </td>
                              <td className="width10" />
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card d-sm-block border-0">
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-12">
                        <table
                          className="table table-bordered table-sm table-responsive"
                          id="RevExTable"
                        >
                          <thead>
                            <tr>
                              <th colSpan="5" className="text-center">
                                <div className="row">
                                  <div className="col-sm-12 text-center">
                                    <label className="control-label">
                                      Capital Related Revenue (CRR)
                                    </label>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        handleAddNewRevExRow();
                                      }}
                                      className="btn-grid fa fa-plus float-right"
                                      title="Add New CRR"
                                    ></button>
                                  </div>
                                </div>
                              </th>
                            </tr>
                            <tr className="bg-primary text-white">
                              <th className="width60"> Resource </th>
                              {/* <th className="width10"> Start Year </th>
                              <th className="width10"> End Year </th> */}
                              <th className="width35">Cost ({currencylogo})</th>
                              <th className="width5" />
                            </tr>
                          </thead>
                          <tbody>
                            {revExRows.map((item, idx) => (
                              <tr id="revex" key={idx}>
                                <td className="width60">
                                  <input
                                    type="text"
                                    name="revex_resource"
                                    value={revExRows[idx].revex_resource}
                                    onChange={handleRevExValueChange(idx)}
                                    className="form-control"
                                  />
                                </td>
                                {/* <td className="width10">
                                  <input
                                    type="number"
                                    name="revex_start_year"
                                    value={revExRows[idx].revex_start_year}
                                    min="0"
                                    max={assetLife}
                                    onChange={handleRevExValueChange(idx)}
                                    className="form-control"
                                  />
                                </td>
                                <td className="width10">
                                  <input
                                    type="number"
                                    name="revex_end_year"
                                    value={revExRows[idx].revex_end_year}
                                    min="0"
                                    max={assetLife}
                                    onChange={handleRevExValueChange(idx)}
                                    className="form-control"
                                  />
                                </td> */}
                                <td className="width15">
                                  <input
                                    type="number"
                                    name="revex_cost"
                                    value={revExRows[idx].revex_cost}
                                    min="0"
                                    onChange={handleRevExValueChange(idx)}
                                    className="form-control"
                                  />
                                </td>
                                <td className="width5">
                                  <button
                                    className="btn-grid fa fa-trash"
                                    title="Remove CRR Row"
                                    onClick={handleRemoveRevExRow(idx)}
                                  ></button>
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan="1" align="right">
                                <div className="pt-1">
                                  <label className="form-label">Total</label>
                                </div>
                              </td>
                              <td className="width15">
                                <input
                                  type="number"
                                  name="revex_total"
                                  value={revex_total}
                                  className="form-control"
                                  readOnly
                                />
                              </td>
                              <td className="width10" />
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card d-sm-block border-0">
                  <div className="card-header">
                    <div className="bg-primary px-1 pt-1">
                      <label className="form-label text-white">
                        Upload Required Documents
                      </label>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row form-group mb-3">
                      <div className="col-sm-12">
                        <input
                          type="file"
                          multiple="multiple"
                          onChange={(evt) => onUploadFile(evt)}
                        ></input>
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-12">
                        <table className="table" id="fileTable">
                          <tbody>
                            {cfrUploadedExistingFiles.map((item, idx) => (
                              <tr id="cfrfile" key={item.id}>
                                <td>{item.name}</td>
                                <td className="width15">
                                  <button
                                    type="button"
                                    className="btn-grid fa fa-download pull-left"
                                    title="Download file"
                                    onClick={DownloadFile(item, item.id)}
                                  ></button>
                                  <button
                                    type="button"
                                    className="btn-grid fa fa-trash pull-left"
                                    title="Delete file"
                                    onClick={(evt) => {
                                      DeleteFile(item, item.id);
                                    }}
                                  ></button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="5">
              <label className="pull-left form-label">Risks and Mitigation Plan</label>
              <div className="pull-right">
                <i className="fa fa-chevron-down"></i>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="5">
              <Card.Body>
                <div className="container">
                  <div className="card d-sm-block border-0">
                    <div className="card-body">
                      <div className="row form-group">
                        <div className="col-sm-12">
                          <table
                            className="table table-bordered table-sm table-responsive"
                            id="RisksAndMitigation"
                          >
                            <thead>
                              <tr>
                                <th colSpan="15" className="text-center">
                                  <div className="row">
                                    <div className="col-sm-12 text-center">
                                      <label className="control-label">
                                        Risks and Mitigation
                                      </label>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          handleAddNewRiskRow();
                                        }}
                                        className="btn-grid fa fa-plus float-right"
                                        title="Add Risk"
                                      ></button>
                                    </div>
                                  </div>
                                </th>
                              </tr>
                              <tr className="bg-primary text-white">
                                <th className="width20"> Risk </th>
                                <th className="width20"> Mitigation </th>
                                <th className="width20"> Consequence </th>
                                <th className="width20"> Category </th>
                                <th className="width10"> Occurrence </th>
                                <th className="width20"> Owner </th>
                                <th className="width5" />

                              </tr>
                            </thead>
                            <tbody>
                              {risks.map((item, idx) => (
                                <tr id="risks" key={idx}>
                                  <td className="width20">
                                    <textarea
                                      type="text"
                                      name="risk"
                                      value={risks[idx].risk}
                                      onChange={handleRiskChange(idx)}
                                      className="form-control"
                                      rows={3}
                                    />
                                  </td>
                                  <td className="width20">
                                    <textarea
                                      type="text"
                                      name="mitigation"
                                      value={risks[idx].mitigation}
                                      onChange={handleRiskChange(idx)}
                                      className="form-control"
                                      rows={3}
                                    />
                                  </td>
                                  <td className="width20">
                                    <textarea
                                      type="text"
                                      name="consequence"
                                      value={risks[idx].consequence}
                                      onChange={handleRiskChange(idx)}
                                      className="form-control"
                                      rows={3}
                                    />
                                  </td>
                                  <td className="width20"
                                    style={verticalAlign}>
                                    <select
                                      name="category"
                                      className="form-control"
                                      onChange={handleRiskChange(idx)}
                                      value={risks[idx].category}
                                    >
                                      <option defaultValue>----Select----</option>
                                      <option value="Functional">Functional</option>
                                      <option value="Technical">Technical</option>
                                    </select>
                                  </td>
                                  <td className="width10"
                                    style={verticalAlign}>
                                    <select
                                      name="occurrence"
                                      className="form-control"
                                      onChange={handleRiskChange(idx)}
                                      value={risks[idx].occurrence}
                                    >
                                      <option defaultValue>----Select----</option>
                                      <option value="high">High</option>
                                      <option value="medium">Medium</option>
                                      <option value="low">Low</option>
                                    </select>
                                  </td>
                                  <td className="width20"
                                    style={verticalAlign}>
                                    <input
                                      type="text"
                                      name="owner"
                                      value={risks[idx].owner}
                                      onChange={handleRiskChange(idx)}
                                      className="form-control"
                                    />
                                  </td>
                                  <td className="width5"
                                    style={verticalAlign}>
                                    <button
                                      className="btn-grid fa fa-trash"
                                      title="Remove Risk"
                                      onClick={() => handleRiskRemoval(idx)}
                                    ></button>
                                  </td>
                                </tr  >
                              ))}
                              <tr>
                                <td className="width10" />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="container">
                  <Card>
                    <Card.Header>
                      <button
                        type="button"
                        onClick={() => {
                          handleAddNewRiskRow();
                        }}
                        className="btn-grid fa fa-plus float-right"
                        title="Add Risk"
                      ></button>
                    </Card.Header>
                    <Card.Body>
                    {risks &&   risks.map((item,idx) => {
                      <div>
                      <Card>
                        <Card.Header>{item.risk}</Card.Header>
                        <Card.Body>{item.mitigation}</Card.Body>
                      </Card>
                      </div>
                    })}</Card.Body>
                    
                  </Card>
                </div> */}
              </Card.Body>
            </Accordion.Collapse>
          </Card>

        </Accordion>

        <div className="form-footer">
          <div className="row">
            <div className="col-sm-12">
              <button
                type="submit"
                className="btn btn-sm btn-primary"
                id="submitCFR"
                disabled={irrValue == 0}
              >
                Submit
              </button>
              <button
                type="submit"
                className="btn btn-sm btn-outline-secondary ml-10"
                id="draftCFR"
              >
                Save As Draft
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary ml-10"
                onClick={() => props.history.goBack()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary ml-10"
                disabled={isNpvCalBtnDisabled}
                onClick={() => {
                  HandleCalNpvView();
                }}
              >
                Calculate NPV
              </button>
            </div>
          </div>
        </div>
      </form>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        //onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
      >
        <div className="row">
          <div className="col-sm-9">
            <h4 style={{ paddingLeft: "15px" }}>NPV Calculation</h4>
          </div>
          <div className="col-sm-3">
            {modalCancelVisible && (
              <button
                className="btn btn-sm btn-primary"
                style={{ float: "right", width: "80px", marginRight: "15px" }}
                onClick={closeModal}
              >
                CANCEL
              </button>
            )}
            <button
              className="btn btn-sm btn-primary"
              style={{ float: "right", width: "80px", marginRight: "5px" }}
              onClick={onOkBtnClicked}
            >
              OK
            </button>
          </div>
        </div>

        <div className="card d-sm-block border-0 overflow-auto">
          <div className="card-body">
            <div className="row form-group">
              <div className="col-sm-12">
                <table
                  className="table table-bordered table-sm table-responsive"
                  id="CumulativeTable"
                >
                  <tbody>
                    <tr>
                      <td className="width15">
                        <label className="form-label pt-1">Interest Rate</label>
                      </td>
                      <td className="width15" align="right">
                        <label className="form-label pt-1">
                          {interestRate}%
                        </label>
                      </td>
                      <td colSpan={4}></td>
                    </tr>
                    <tr>
                      <td className="width15">
                        <label className="form-label pt-1">
                          Capex Analysis Period
                        </label>
                      </td>
                      <td className="width15" align="right">
                        <input
                          type="number"
                          name={"capexAnalysisPeriod"}
                          onChange={handleCapexAnalysisPeriodValueChange}
                          className="form-control text-right"
                          min="1"
                          value={capexAnalysisPeriod}
                          style={{ "text-align": "right" }}
                        />
                      </td>
                      <td colSpan={4}></td>
                    </tr>
                  </tbody>
                </table>
                <table
                  className="table table-bordered table-sm table-responsive"
                  id="CumulativeTable"
                >
                  <tbody>
                    <tr>
                      <td className="width15" />
                      {capexAnalysisPeriodArray.map((item, idx) => {
                        return (
                          <td
                            className=""
                            align="center"
                            style={{ width: colWidth + "%" }}
                          >
                            <label className="form-label">Y{idx}</label>
                          </td>
                        );
                      })}
                      <td align="center" className="width10 ">
                        <label className="form-label">Total</label>
                      </td>
                    </tr>
                    <tr>
                      <td className="width15 ">
                        <label className="form-label pt-1">CapEx</label>
                      </td>
                      {yearlyCapexValues.map((item, idx) => {
                        return (
                          <td align="center" style={{ width: colWidth + "%" }}>
                            <input
                              type="text"
                              name={"capex" + idx}
                              onChange={handleCapexValueChange(idx)}
                              className="form-control text-center"
                              min="0"
                              value={item != undefined ? item.Cost : 0}
                            />
                          </td>
                        );
                      })}

                      <td align="center" className="width10">
                        <label className="form-label">
                          {yearlyCapexValues.reduce(
                            (total, obj) =>
                              Number(
                                parseFloat(Number(obj.Cost) + total).toFixed(2)
                              ),
                            0
                          )}
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td className="width15 ">
                        <label className="form-label pt-1">CRR</label>
                      </td>
                      {yearlyRevexValues.map((item, idx) => {
                        return (
                          <td align="center" style={{ width: colWidth + "%" }}>
                            <input
                              type="text"
                              name={"capex" + idx}
                              onChange={handleRevexValueChange(idx)}
                              className="form-control text-center"
                              min="0"
                              value={item != undefined ? item.Cost : 0}
                            />
                          </td>
                        );
                      })}

                      <td align="center" className="width10">
                        <label className="form-label">
                          {yearlyRevexValues.reduce(
                            (total, obj) =>
                              Number(
                                parseFloat(Number(obj.Cost) + total).toFixed(2)
                              ),
                            0
                          )}
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td className="width15 ">
                        <label className="form-label pt-1">Cost</label>
                      </td>
                      {costValues == undefined || costValues.length == 0
                        ? Array.from(Array(capexAnalysisPeriod), (e, i) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              0
                            </td>
                          );
                        })
                        : costValues.map((item, idx) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                              key={idx}
                            >
                              <span>{costValues[idx].Cost}</span>
                            </td>
                          );
                        })}

                      <td align="center" className="width10">
                        <label className="form-label">
                          {costValues.reduce(
                            (total, obj) =>
                              Number(
                                parseFloat(Number(obj.Cost) + total).toFixed(2)
                              ),
                            0
                          )}
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td className="width15 ">
                        <label className="form-label pt-1">Savings</label>
                      </td>
                      {capexAnalysisPeriodArray.map((item, i) => {
                        return (
                          <td align="center" style={{ width: colWidth + "%" }}>
                            <input
                              type="text"
                              name={"saving_" + i}
                              onChange={handleSavingValueChange(i)}
                              className="form-control text-center"
                              min="0"
                              value={
                                totalSavings[i] != undefined
                                  ? totalSavings[i].Saving
                                  : 0
                              }
                            />
                          </td>
                        );
                      })}
                      <td align="center">
                        <label className="form-label pt-1">
                          {totalSavings.reduce(
                            (total, obj) => Number(obj.Saving) + total,
                            0
                          ) != 0
                            ? totalSavings.reduce(
                              (total, obj) => Number(obj.Saving) + total,
                              0
                            )
                            : ""}
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td className="width15 ">
                        <label className="form-label pt-1">Cashflow</label>
                      </td>
                      {cashFlowValues == undefined || cashFlowValues.length == 0
                        ? capexAnalysisPeriodArray.map((item, i) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              0
                            </td>
                          );
                        })
                        : cashFlowValues.map((item, idx) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                              key={idx}
                            >
                              <span>{cashFlowValues[idx].Cost}</span>
                            </td>
                          );
                        })}
                      <td />
                    </tr>
                    <tr>
                      <td className="width15 ">
                        <label className="form-label pt-1">Cumulative CF</label>
                      </td>
                      {cumulativeCFValues == undefined ||
                        cumulativeCFValues.length == 0
                        ? capexAnalysisPeriodArray.map((item, i) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              0
                            </td>
                          );
                        })
                        : cumulativeCFValues.map((item, idx) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                              key={idx}
                            >
                              <span>{cumulativeCFValues[idx].Cost}</span>
                            </td>
                          );
                        })}
                      <td />
                    </tr>
                    <tr>
                      <td className="width15 ">
                        <label className="form-label pt-1">+ve Cashflow</label>
                      </td>
                      {positiveCashFlowValues == undefined ||
                        positiveCashFlowValues.length == 0
                        ? capexAnalysisPeriodArray.map((item, i) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              FALSE
                            </td>
                          );
                        })
                        : positiveCashFlowValues.map((item, idx) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                              key={idx}
                            >
                              <span>{positiveCashFlowValues[idx]}</span>
                            </td>
                          );
                        })}
                      <td />
                    </tr>
                    <tr>
                      <td className="width15 ">
                        <label className="form-label pt-1">PV</label>
                      </td>
                      {pvValues == undefined || pvValues.length == 0
                        ? capexAnalysisPeriodArray.map((item, i) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              0
                            </td>
                          );
                        })
                        : pvValues.map((item, idx) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                              key={idx}
                            >
                              <span>{pvValues[idx]}%</span>
                            </td>
                          );
                        })}
                      <td />
                    </tr>
                    <tr>
                      <td className="width15 ">
                        <label className="form-label pt-1">
                          PV of Cashflow
                        </label>
                      </td>
                      {pvOfCashflowValues == undefined ||
                        pvOfCashflowValues.length == 0
                        ? capexAnalysisPeriodArray.map((item, i) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              0
                            </td>
                          );
                        })
                        : pvOfCashflowValues.map((item, idx) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                              key={idx}
                            >
                              <span>{pvOfCashflowValues[idx]}</span>
                            </td>
                          );
                        })}
                      <td />
                    </tr>
                    <tr>
                      <td className="width15 ">
                        <label className="form-label pt-1">Cumulative PV</label>
                      </td>
                      {cumulativePVValues == undefined ||
                        cumulativePVValues.length == 0
                        ? capexAnalysisPeriodArray.map((item, i) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              0
                            </td>
                          );
                        })
                        : cumulativePVValues.map((item, idx) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                              key={idx}
                            >
                              <span>{cumulativePVValues[idx]}</span>
                            </td>
                          );
                        })}
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row form-group pt-3">
              <div className="col-sm-12">
                <table
                  className="table table-bordered table-sm table-responsive width30"
                  id="npvTable"
                >
                  <tbody>
                    <tr>
                      <td className="width40"></td>
                      <td className=" width10" align="center">
                        <label className="form-label">
                          {capexAnalysisPeriod} Years
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td className=" width40">
                        <label className="form-label pt-1">NPV </label>
                      </td>
                      <td className="width10" align="center">
                        {npvValue}
                      </td>
                    </tr>
                    <tr>
                      <td className=" width40">
                        <label className="form-label pt-1">IRR (%) </label>
                      </td>
                      <td className="width10" align="center">
                        {irrValue}
                      </td>
                    </tr>
                    <tr>
                      <td className=" width40">
                        <label className="form-label pt-1">
                          Undiscounted Payback Period
                        </label>
                      </td>
                      <td className="width10" align="center">
                        {undiscountedPaybackPeriod}
                      </td>
                    </tr>
                    <tr>
                      <td className=" width40">
                        <label className="form-label pt-1">
                          Partial Year Payback Period
                        </label>
                      </td>
                      <td className="width10" align="center">
                        {partialYearPaybackPeriod}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalShow}
        onAfterOpen={afterOpenModal}
        //onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
      >
        <div className="row">
          <div className="col-sm-9">
            <h4 style={{ paddingLeft: "15px" }}>Add Budget References</h4>
          </div>
          <div className="col-sm-3">
            {modalCancelVisible && (
              <button
                className="btn btn-sm btn-primary"
                id={isAddMode ? "isAddMode" : "isEditMode"}
                style={{ float: "right", width: "80px", marginRight: "15px" }}
                onClick={(e) => handleModalOnClose(e.target.id)}
              >
                CANCEL
              </button>
            )}
            <button
              className="btn btn-sm btn-primary"
              style={{ float: "right", width: "80px", marginRight: "5px" }}
              onClick={handleModalOkClick}
            >
              OK
            </button>
          </div>
        </div>
        <div className="card d-sm-block border-0 overflow-auto">
          <div className="card-body">
            <div className="row form-group">
              <div className="col-sm-12">
                <table className="table table-cust ">
                  <thead>
                    <tr>
                      <th className="width5"> Select </th>
                      <th className="width15"> Budget ref. no.</th>
                      <th className="width20"> Description </th>
                      <th className="width15"> Remaining Amount </th>
                      <th className="width15"> Proposed Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {openBudgetRefs.length > 0 &&
                      openBudgetRefs.map((budget, idx) => (
                        <tr key={idx}>
                          <td className="width5">
                            <input
                              type="checkbox"
                              name="budgetSelect"
                              className="text-right"
                              onChange={(e) => handleCheckBox(e, idx)}
                              style={{ float: "left" }}
                              defaultChecked={budget.isSelected}
                            />
                          </td>
                          <td
                            className="width15"
                            title={budget.budgetReferenceNo}
                          >
                            {budget.budgetReferenceNo}
                          </td>
                          <td className="width20" title={budget.projectDescription}>
                            {budget.projectDescription}
                          </td>
                          <td
                            className="width15"
                            title={budget.remainingAmount}
                          >
                            {budget.remainingAmount}
                          </td>
                          <td className="width15">
                            {budget.isSelected ? <input
                              type="text"
                              name="proposedValue"
                              className="form-control"
                              defaultValue={openBudgetRefs[idx].proposedValTemp}
                              onChange={(e) =>
                                handleProposedValueChange(e, idx)
                              }
                              onBlur={(e) => handleModalBlurEvent(e, idx)}
                            //readOnly={!budget.isSelected}
                            /> :
                              <input
                                type="text"
                                name="proposedValue"
                                className="form-control"
                                value={0}
                                //defaultValue={openBudgetRefs[idx].proposedValTemp}
                                // onChange={(e) =>
                                //   handleProposedValueChange(e, idx)
                                // }
                                //onBlur={(e) => handleModalBlurEvent(e, idx)}
                                readOnly
                              />
                            }
                            {/* {!selectedBudgetRefIdList.includes(budget.id) && <input
                            type="text"
                            name="proposedValue"
                            className="form-control"
                            defaultValue = {0}
                            readOnly
                            //onChange = {(e) => handleProposedValueChange(e,idx)}
                          />} */}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );

  Modal.setAppElement("#cfrFormDiv");
}
