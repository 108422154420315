import React from "react";
import { useState, useEffect } from "react";
import { getLoggedInUser } from "../Utils/common";
import EventEmitter from "../Utils/eventEmitter";
import { BrowserRouter as Router, Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTag, faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { Entity, EntityOperation } from "../common/constants";

function Navigation(props) {
  const [isUserVisible, setUserVisible] = useState(false);
  const [isRoleVisible, setRoleVisible] = useState(false);
  const [isDeptVisible, setDeptVisible] = useState(false);
  const [isCFRVisible, setCFRVisible] = useState(false);
  const [isConfigVisible, setConfigVisible] = useState(false);

  const [activeNavigationUser, setActiveNavigationUser] = useState(false);
  const [activeNavigationRole, setActiveNavigationRole] = useState(false);
  const [activeNavigationDept, setActiveNavigationDept] = useState(false);
  const [activeNavigationCfr, setActiveNavigationCfr] = useState(false);
  const [activeNavigationConfig, setActiveNavigationConfig] = useState(true);

  const getRoute = () => {
    const location = window.location.pathname;
    console.log(location);
    if (location.includes("/config")) {
      setActiveNavigationUser(false);
      setActiveNavigationRole(false);
      setActiveNavigationDept(false);
      setActiveNavigationCfr(false);
      setActiveNavigationConfig(true);
    } else if (
      location.includes("/departments") ||
      location.includes("/department")
    ) {
      setActiveNavigationUser(false);
      setActiveNavigationRole(false);
      setActiveNavigationDept(true);
      setActiveNavigationCfr(false);
      setActiveNavigationConfig(false);
    } else if (location.includes("/users") || location.includes("/user")) {
      setActiveNavigationUser(true);
      setActiveNavigationRole(false);
      setActiveNavigationDept(false);
      setActiveNavigationCfr(false);
      setActiveNavigationConfig(false);
    } else if (location.includes("/roles") || location.includes("/role")) {
      setActiveNavigationUser(false);
      setActiveNavigationRole(true);
      setActiveNavigationDept(false);
      setActiveNavigationCfr(false);
      setActiveNavigationConfig(false);
    } else if (location.includes("/cfrlist") || location.includes("/cfr")) {
      setActiveNavigationUser(false);
      setActiveNavigationRole(false);
      setActiveNavigationDept(false);
      setActiveNavigationCfr(true);
      setActiveNavigationConfig(false);
    }
  };

  const changeColor = (arg) => {
    // console.log(activeNavigationUser);
    // console.log(activeNavigationRole);
    // console.log(activeNavigationDept);
    // console.log(activeNavigationCfr);
    // console.log(activeNavigationConfig);
    console.log(arg.target.innerText);
    if (arg.target.innerText == "Users") {
      console.log("Users active");
      setActiveNavigationUser(true);
      setActiveNavigationRole(false);
      setActiveNavigationDept(false);
      setActiveNavigationCfr(false);
      setActiveNavigationConfig(false);
    } else if (arg.target.innerText == "Departments") {
      console.log("Departments active");
      setActiveNavigationUser(false);
      setActiveNavigationRole(false);
      setActiveNavigationDept(true);
      setActiveNavigationCfr(false);
      setActiveNavigationConfig(false);
    } else if (arg.target.innerText == "Roles") {
      console.log("Roles active");
      setActiveNavigationUser(false);
      setActiveNavigationRole(true);
      setActiveNavigationDept(false);
      setActiveNavigationCfr(false);
      setActiveNavigationConfig(false);
    } else if (arg.target.innerText == "CFR") {
      console.log("CFR active");
      setActiveNavigationUser(false);
      setActiveNavigationRole(false);
      setActiveNavigationDept(false);
      setActiveNavigationCfr(true);
      setActiveNavigationConfig(false);
    } else if (arg.target.innerText == "Config") {
      console.log("Config active");
      setActiveNavigationUser(false);
      setActiveNavigationRole(false);
      setActiveNavigationDept(false);
      setActiveNavigationCfr(false);
      setActiveNavigationConfig(true);
    }
    props.closeNavigationSidebar();
  };

  const checkRolePermission = () => {
    const loggedinUser = getLoggedInUser();
    console.log(loggedinUser);
    if (loggedinUser) {
      if (loggedinUser.role.cfrRoleEntities) {
        if (loggedinUser.role.cfrRoleEntities.items.length > 0) {
          loggedinUser.role.cfrRoleEntities.items.forEach((privilege) => {
            if (
              privilege.entity.name == Entity.Config &&
              privilege.entityOperation.name == EntityOperation.View
            ) {
              setConfigVisible(true);
            }
            if (
              privilege.entity.name == Entity.User &&
              privilege.entityOperation.name == EntityOperation.View
            ) {
              setUserVisible(true);
            }
            if (
              privilege.entity.name == Entity.Role &&
              privilege.entityOperation.name == EntityOperation.View
            ) {
              setRoleVisible(true);
            }
            if (
              privilege.entity.name == Entity.Department &&
              privilege.entityOperation.name == EntityOperation.View
            ) {
              setDeptVisible(true);
            }
            if (
              privilege.entity.name == Entity.CFR &&
              privilege.entityOperation.name == EntityOperation.View
            ) {
              setCFRVisible(true);
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    checkRolePermission();
    getRoute();

    // const OnMyProfileClicked = (eventData) => {
    //   console.log("Users active");
    //   setActiveNavigationUser(true);
    //   setActiveNavigationRole(false);
    //   setActiveNavigationDept(false);
    //   setActiveNavigationCfr(false);
    //   setActiveNavigationConfig(false);
    // };

    // const myProfileClickedListener = EventEmitter.addListener(
    //   "MyProfileClicked",
    //   OnMyProfileClicked
    // );
    // return () => myProfileClickedListener.remove();
  }, []);

  useEffect(() => {
    const onUserGet = (eventData) => {
      checkRolePermission();
    };

    const listener = EventEmitter.addListener("UserGet", onUserGet);
    return () => listener.remove();
  }, []);

  return (
    <Sidebar
      isUserVisible={isUserVisible}
      isDeptVisible={isDeptVisible}
      isRoleVisible={isRoleVisible}
      isCFRVisible={isCFRVisible}
      isConfigVisible={isConfigVisible}
      activeNavigationUser={activeNavigationUser}
      activeNavigationRole={activeNavigationRole}
      activeNavigationDept={activeNavigationDept}
      activeNavigationCfr={activeNavigationCfr}
      activeNavigationConfig={activeNavigationConfig}
      changeColor={() => changeColor}
    ></Sidebar>
  );
}

const Sidebar = (props) => {
  //console.log(props.changeColor(props.activeNavigationConfig));
  return (
    <div className="navigation-area">
      <ul className="navigation-ul">
        {props.isConfigVisible && (
          <li className="navigation-li">
            <Link
              to="/config"
              className={
                props.activeNavigationConfig
                  ? "navigation-li-a-active"
                  : "navigation-li-a"
              }
              onClick={props.changeColor("config")}
            >
              <i className="fa fa-cog"></i>
              <span className="nav-name"> Config </span>
            </Link>
          </li>
        )}
        {props.isDeptVisible && (
          <li className="navigation-li">
            <Link
              to="/departments"
              className={
                props.activeNavigationDept
                  ? "navigation-li-a-active"
                  : "navigation-li-a"
              }
              onClick={props.changeColor("dept")}
            >
              <i className="fa fa-users"></i>
              <span className="nav-name"> Departments </span>
            </Link>
          </li>
        )}
        {props.isUserVisible && (
          <li className="navigation-li" onClick={props.closeNavigationSidebar}>
            <Link
              to="/users"
              className={
                props.activeNavigationUser
                  ? "navigation-li-a-active"
                  : "navigation-li-a"
              }
              onClick={props.changeColor("user")}
            >
              <i className="fa fa-user"></i>Users
            </Link>
          </li>
        )}
        {props.isRoleVisible && (
          <li className="navigation-li">
            <Link
              to="/roles"
              className={
                props.activeNavigationRole
                  ? "navigation-li-a-active"
                  : "navigation-li-a"
              }
              onClick={props.changeColor("role")}
            >
              <i>
                <FontAwesomeIcon icon={faUserTag} />
              </i>
              <span className="nav-name"> Roles </span>
            </Link>
          </li>
        )}
        {props.isCFRVisible && (
          <li className="navigation-li">
            <Link
              to="/cfrlist"
              className={
                props.activeNavigationCfr
                  ? "navigation-li-a-active"
                  : "navigation-li-a"
              }
              onClick={props.changeColor("cfr")}
            >
              <i>
                <FontAwesomeIcon icon={faFileInvoice} />
              </i>
              <span className="nav-name"> CFR </span>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};
export default Navigation;
