import { getLoggedInUser } from "../../Utils/common";
import { Entity, EntityOperation } from "../../common/constants";
import { useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import PageLoader from "../../common/loader";
import { useEffect } from "react";
import {
  listCFRApprovalLevels,
  listCFRUserNotifications,
} from "../../graphql/queries";
import { updateCFRUserNotifications } from "../../graphql/mutations";
import { useState } from "react";

function UserProfile(props) {
  let history = useHistory();
  const loggedinUser = getLoggedInUser();
  const [isLoading, setIsLoading] = useState(false);
  const [userNotification, setUserNotification] = useState([]);
  const [changedOptionsCounter, setChangedOptionsCounter] = useState(0);
  const [sequencenumber, setSequencenumber] = useState(0);
  const [disabledFlag, setdisabledFlag] = useState(false);
  const [approvalLevels, setApprovalLevels] = useState([]);
  const [projectSponsorApproval, setProjectSponsorApproval] = useState();
  const [procurementHeadApproval, setProcurementHeadApproval] = useState();
  const [functionalHeadApproval, setFunctionalHeadApproval] = useState();
  const [financeHeadApproval, setFinanceHeadApproval] = useState();
  const [cFRCreation, setcFRCreation] = useState();

  let userObj;
  if (props.location.state) {
    if (props.location.state.user) {
      userObj = props.location.state.user;
    }
  }

  useEffect(async () => {
    await getUserNotifications();
    await getApprovalLevels();
    console.log("==============", loggedinUser);
  }, []);

  const getUserNotifications = async () => {
    setIsLoading(true);
    let searchParam = { cFRUserNotificationsUserId: { eq: loggedinUser.id } };
    const resp = await API.graphql(
      graphqlOperation(listCFRUserNotifications, { filter: searchParam })
    );

    if (resp.data && resp.data?.listCFRUserNotifications?.items.length > 0) {
      setUserNotification(resp.data?.listCFRUserNotifications?.items[0]);
    }
    let tempObj = resp.data?.listCFRUserNotifications?.items[0];
    console.log(tempObj);
    populateChecked(tempObj);
    setIsLoading(false);
  };

  const getApprovalLevels = async () => {
    setIsLoading(true);

    const res = await API.graphql(graphqlOperation(listCFRApprovalLevels));
    if (res.data && res.data?.listCFRApprovalLevels?.items.length > 0) {
      setApprovalLevels(res.data?.listCFRApprovalLevels?.items);
    }

    let tempObj = res.data?.listCFRApprovalLevels?.items;
    console.log(tempObj);
    handleDisable(tempObj);
    setIsLoading(false);
  };

  const populateChecked = (obj) => {
    if (obj?.cFRCreation != null) {
      setcFRCreation(obj?.cFRCreation);
    }
    if (obj?.projectSponsorApproval != null) {
      setProjectSponsorApproval(obj?.projectSponsorApproval);
    }
    if (obj?.procurementHeadApproval != null) {
      setProcurementHeadApproval(obj?.procurementHeadApproval);
    }
    if (obj?.functionalHeadApproval != null) {
      setFunctionalHeadApproval(obj?.functionalHeadApproval);
    }
    if (obj?.financeHeadApproval != null) {
      setFinanceHeadApproval(obj?.financeHeadApproval);
    }
  };

  const handleDisable = (approvalLevels) => {
    let userRoleEntities = loggedinUser.role.cfrRoleEntities.items;
    let userRoleEntityName = []; //userRoleEntities.entity.name;
    let userRoleEntityOp = []; //userRoleEntities.entityOperation.name;
    let isCFRCreator = false;
    let isCFRApprover = false;
    let userDepartments = loggedinUser.department.items.map((x) => {
      return x.name;
    });
    debugger;
    userRoleEntities.forEach((element) => {
      // userRoleEntityName.push(element.entity.name);
      // userRoleEntityOp.push({entity: element.entity.name , entityOp: element.entityOperation.name});
      if (
        element.entity.name == "CFR" &&
        element.entityOperation.name == "Create"
      ) {
        isCFRCreator = true;
      } else if (
        element.entity.name == "CFR" &&
        element.entityOperation.name == "Approve"
      ) {
        isCFRApprover = true;
      }
    });

    if (isCFRCreator) {
      setdisabledFlag(true);
    } else if (isCFRApprover) {
      let temp = [];
      approvalLevels.forEach((app) => {
        if (userDepartments.includes(app.department?.name)) {
          temp.push(app.sequencenumber);
        }
      });
      console.log("temp", temp);
      if (temp.length > 0) {
        setSequencenumber(temp[0]);
      } else {
        if (loggedinUser.headoffunction) {
          setSequencenumber(3);
        }
      }
    }
    if (isCFRApprover) {
      setdisabledFlag(true);
    }
  };

  const handleChecked = (e) => {
    //let [name, value] = e.target;
    let name = e.target.name;
    let checked = e.target.checked;

    if (name == "cFRCreation") {
      setcFRCreation(checked);
    }
    if (name == "projectSponsorApproval") {
      setProjectSponsorApproval(checked);
    }
    if (name == "procurementHeadApproval") {
      setProcurementHeadApproval(checked);
    }
    if (name == "functionalHeadApproval") {
      setFunctionalHeadApproval(checked);
    }
    if (name == "financeHeadApproval") {
      setFinanceHeadApproval(checked);
    }
    console.log(name, e.target.checked);
    saveButtonShow(e);
    console.log(sequencenumber);
  };

  const saveButtonShow = (e) => {
    let name = e.target.name;
    let checked = e.target.checked;

    if (userNotification[name] !== checked) {
      setChangedOptionsCounter(changedOptionsCounter + 1);
    } else {
      setChangedOptionsCounter(changedOptionsCounter - 1);
    }
  };

  const handleCancelClick = () => {
    populateChecked(userNotification);
    setChangedOptionsCounter(0);
  };

  const handleSaveClick = async () => {
    setIsLoading(true);
    let newData = {
      ...userNotification,
    };
    newData.cFRCreation = cFRCreation;
    newData.projectSponsorApproval = projectSponsorApproval;
    newData.procurementHeadApproval = procurementHeadApproval;
    newData.functionalHeadApproval = functionalHeadApproval;
    newData.financeHeadApproval = financeHeadApproval;
    delete newData.createdAt;
    delete newData.updatedAt;
    delete newData.user;

    const response = await API.graphql(
      graphqlOperation(updateCFRUserNotifications, { input: newData })
    );

    if (response.data && response.data?.updateCFRUserNotifications) {
      getUserNotifications();
    }
    setIsLoading(false);
    setChangedOptionsCounter(0);
  };

  const BackBtnClicked = () => {
    if (props.location.state) {
      console.log(loggedinUser);
      if (loggedinUser) {
        if (loggedinUser.isActive) {
          if (loggedinUser.role) {
            if (loggedinUser.role.cfrRoleEntities) {
              if (loggedinUser.role.cfrRoleEntities.items.length > 0) {
                let configArr = loggedinUser.role.cfrRoleEntities.items.filter(
                  (privilege) =>
                    privilege.entity.name == Entity.Config &&
                    privilege.entityOperation.name == EntityOperation.View
                );
                if (configArr.length > 0) {
                  history.push("/config");
                } else {
                  let userArr = loggedinUser.role.cfrRoleEntities.items.filter(
                    (privilege) =>
                      privilege.entity.name == Entity.User &&
                      privilege.entityOperation.name == EntityOperation.View
                  );
                  if (userArr.length > 0) {
                    history.push("/users");
                  } else {
                    let userArr =
                      loggedinUser.role.cfrRoleEntities.items.filter(
                        (privilege) =>
                          privilege.entity.name == Entity.Role &&
                          privilege.entityOperation.name == EntityOperation.View
                      );
                    if (userArr.length > 0) {
                      history.push("/roles");
                    } else {
                      let userArr =
                        loggedinUser.role.cfrRoleEntities.items.filter(
                          (privilege) =>
                            privilege.entity.name == Entity.Department &&
                            privilege.entityOperation.name ==
                              EntityOperation.View
                        );
                      if (userArr.length > 0) {
                        history.push("/departments");
                      } else {
                        let userArr =
                          loggedinUser.role.cfrRoleEntities.items.filter(
                            (privilege) =>
                              privilege.entity.name == Entity.CFR &&
                              (privilege.entityOperation.name ==
                                EntityOperation.View ||
                                privilege.entityOperation.name ==
                                  EntityOperation.Approve)
                          );
                        if (userArr.length > 0) {
                          history.push("/cfrlist");
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  return (
    <div className="page-container">
      {isLoading ? <PageLoader></PageLoader> : ""}
      <div className="page-header">
        <h4> User Profile </h4>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Name </label>
              <div> {userObj?.name} </div>
            </div>
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Email </label>
              <div> {userObj?.email}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Department </label>
              <div>
                {userObj?.department &&
                userObj?.department?.items &&
                userObj?.department?.items?.length > 1 ? (
                  <span>Multiple Departments </span>
                ) : userObj?.department?.items?.length == 1 ? (
                  userObj?.department?.items[0]?.name
                ) : (
                  <span>No Department</span>
                )}
              </div>
            </div>
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Role </label>
              <div>
                {userObj?.role ? (
                  <span>{userObj?.role?.name}</span>
                ) : (
                  <span>No Role</span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Head of Department </label>
              <div>
                {userObj?.headoffunction ? <span>Yes</span> : <span>No</span>}
              </div>
            </div>
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Department Admin </label>
              <div>
                {userObj?.departmentadmin ? <span>Yes</span> : <span>No</span>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Approval Limit Range </label>
              <div>
                {`${userObj.currencySymbol}${userObj.limit} - ${userObj.currencySymbol}${userObj.toLimit}`}
              </div>
            </div>
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Is Global User </label>
              <div>
                {userObj?.isGlobalUser ? <span>Yes</span> : <span>No</span>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Can Approve Budgeted CFR </label>
              <div>
                {userObj?.canApproveBudgetedCFR ? (
                  <span>Yes</span>
                ) : (
                  <span>No</span>
                )}
              </div>
            </div>
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Can Approve UnBudgeted CFR </label>
              <div>
                {userObj?.canApproveUnBudgetedCFR ? (
                  <span>Yes</span>
                ) : (
                  <span>No</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <label className="form-label">CFR Lifecycle</label>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <label className="form-label">CFR Creation</label>
                  <input
                    type="checkbox"
                    name="cFRCreation"
                    className="text-right"
                    checked={cFRCreation}
                    disabled={disabledFlag || sequencenumber == 3}
                    onChange={handleChecked}
                    style={{ float: "right" }}
                  />
                  <hr></hr>
                  <label className="form-label">Project Sponsor Approval</label>
                  <input
                    type="checkbox"
                    name="projectSponsorApproval"
                    className="text-center"
                    checked={projectSponsorApproval}
                    disabled={sequencenumber == 2}
                    onChange={handleChecked}
                    style={{ float: "right" }}
                  />
                  <hr></hr>
                  <label className="form-label">
                    Procurement Head Approval
                  </label>
                  <input
                    type="checkbox"
                    name="procurementHeadApproval"
                    className="text-center"
                    checked={procurementHeadApproval}
                    disabled={sequencenumber == 3}
                    onChange={handleChecked}
                    style={{ float: "right" }}
                  />
                  <hr></hr>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <label className="form-label">Functional Head Approval</label>
                  <input
                    type="checkbox"
                    name="functionalHeadApproval"
                    className="text-center"
                    checked={functionalHeadApproval}
                    disabled={sequencenumber == 4}
                    onChange={handleChecked}
                    style={{ float: "right" }}
                  />
                  <hr></hr>
                  <label className="form-label">Finance Head Approval</label>
                  <input
                    type="checkbox"
                    name="financeHeadApproval"
                    className="text-center"
                    checked={financeHeadApproval}
                    disabled={disabledFlag || sequencenumber == 5}
                    onChange={handleChecked}
                    style={{ float: "right" }}
                  />
                  <hr></hr>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-footer">
        <div className="row">
          <div className="col-sm-12">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              hidden={!(changedOptionsCounter > 0)}
              //onClick={() => history.goBack()}
              onClick={handleSaveClick}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary ml-10"
              hidden={!(changedOptionsCounter > 0)}
              //onClick={() => history.goBack()}
              onClick={handleCancelClick}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary ml-10"
              onClick={() => {
                props.history.goBack();
                props.history.goBack();
              }}
              //onClick={() => BackBtnClicked()}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UserProfile;
