function MobileUserList(props) {
  return (
    <div>
      {props.userData.length > 0 &&
        props.userData.map((user) => (
          <div className="card d-block d-sm-none mb-3" key={user.id}>
            <div className="card-body">
              <table className="table table-cust ">
                <tbody>
                  <tr>
                    <td className="width30">
                      <label className="label-heading"> Name</label>
                    </td>
                    <td className="width70">{user.name}</td>
                  </tr>
                  <tr>
                    <td className="width30">
                      <label className="label-heading"> Email</label>
                    </td>
                    <td className="width70"> {user.email} </td>
                  </tr>
                  <tr>
                    <td className="width30">
                      <label className="label-heading"> Role</label>
                    </td>
                    <td className="width70">
                      {user.role && user.role.name ? (
                        user.role.name
                      ) : (
                        <span>No Role</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="width30">
                      <label className="label-heading"> Department</label>
                    </td>
                    <td className="width70">
                      {user.department && user.department.name ? (
                        user.department.name
                      ) : (
                        <span>No Department</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="width30">
                      <label className="label-heading">
                        Approval Limit Range
                      </label>
                    </td>
                    <td className="width70">
                      {`${user.currencySymbol}${user.limit} - ${user.currencySymbol}${user.toLimit}`}
                    </td>
                  </tr>
                  <tr>
                    <td className="width100 text-center" colSpan="2">
                      <div className="button-group">
                        {props.isDeletePermission && (
                          <button
                            type="button"
                            className="btn-grid fa fa-trash"
                            onClick={() => {
                              props.actiondelete(user.id);
                            }}
                          ></button>
                        )}
                        {props.isUpdatePermission && (
                          <button
                            type="button"
                            className="btn-grid fa fa-pencil"
                            onClick={() => {
                              props.actionUpdate(user, user.id);
                            }}
                          ></button>
                        )}
                        <button
                          type="button"
                          className="btn-grid fa fa-file-text-o"
                          onClick={() => {
                            props.actionView(user, user.id);
                          }}
                        ></button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ))}
      {props.userData.length == 0 && (
        <div className="mobile-msg d-block d-sm-none">No record found</div>
      )}
    </div>
  );
}
export default MobileUserList;
