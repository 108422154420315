export const sendEmail = /* GraphQL */ `
  query SendEmail($EmailTemplate: String) {
    sendEmail(EmailTemplate: $EmailTemplate)
  }
`;

export const listAzureUsers = /* GraphQL */ `
  query ListAzureUsers($EmailSearchKey: String) {
    listAzureUsers(EmailSearchKey: $EmailSearchKey)
  }
`;

export const getDepartment = /* GraphQL */ `
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      name
      details
      isdeleted
      isForGlobalUser
      financeBudgtedUsers
      financeUnBudgtedUsers
      procurementBudgtedUsers
      procurementUnBudgtedUsers
      createdAt
      updatedAt
      cFRUserDepartmentId
    }
  }
`;

export const listCFRDepartments = /* GraphQL */ `
  query ListDepartments(
    $filter: ModelCFRDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        details
        isdeleted
        isForGlobalUser
        financeBudgtedUsers
        financeUnBudgtedUsers
        procurementBudgtedUsers
        procurementUnBudgtedUsers
        cFRUserDepartmentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCFRCapitalFundRequests = /* GraphQL */ `
  query ListCapitalFundRequests(
    $filter: ModelCFRCapitalFundRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRCapitalFundRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        masterCFRID
        projecttitle
        version
        territory
        location
        iscapitalbudget
        budgetReferenceNo {
          items {
            id
            budgetReferenceNo
            description
            remainingAmount
            proposedAmount
            createdAt
            updatedAt
            cFRCapitalFundRequestBudgetReferenceNoId
          }
          nextToken
        }
        budgetvaluetype
        budgetvalue
        proposalvaluetype
        proposalvalue
        irr
        npv
        payback
        assetlife
        budgetbenefits
        cfrbenefits
        cfrsubmissiondate
        spendtype
        maincontractors
        cfrFiles {
          items {
            id
            bucket
            region
            key
            createdAt
            updatedAt
          }
          nextToken
        }
        cfrstatus
        businesscontext
        justification
        optionsconsidered
        npvjson
        cfrApprovals {
          items {
            id
            cfrVersion
            approvalstatus
            approverjustification
            approvalDateTime
            approvalLevel {
              id
              sequencenumber
              levelName
              ismandatory
              version
              createdAt
              updatedAt
            }
            expectedCfrApprovers
            actualCfrApprover {
              id
              email
              name
              limit
              departmentadmin
              headoffunction
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cfrCreator {
          id
          email
          name
          limit
          toLimit
          currencySymbol
          departmentadmin
          headoffunction
          isActive
          createdAt
          updatedAt
        }
        isDeleted
        costcenter
        profitcenter
        isIORequestGenerated
        IONumber
        expectedCompletionDate
        createdAt
        updatedAt
        riskAndMitigation {
          items {
          id
          risk
          mitigation
          category
          occurrence
          consequence
          owner
          isDeleted
          cFRCapitalFundRequestRiskAndMitigationId
          }
          nextToken

        }
        timelineActivities {
          items {
          id
          activityName
          activityDate
          cFRCapitalFundRequestTimelineActivitiesId
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listCFRRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelCFRRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        details
        cfrRoleEntities {
          items {
            id
            entity {
              id
              name
              createdAt
              updatedAt
            }
            entityOperation {
              id
              name
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCFRUsers = /* GraphQL */ `
  query ListCFRUsers(
    $filter: ModelCFRUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          items {
            id
            cFRUserID
            cFRDepartmentID
            createdAt
            updatedAt
          }
          nextToken
        }
        role {
          id
          name
          details
          cfrRoleEntities {
            items {
              id
              entity {
                id
                name
                createdAt
                updatedAt
              }
              entityOperation {
                id
                name
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        isDeleted
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        createdAt
        updatedAt
        cFRUserRoleId
      }
      nextToken
    }
  }
`;
