import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { API, graphqlOperation } from "aws-amplify";
import PageLoader from "../../common/loader";
import DepartmentList from "./departmentList";
import MobileDepartmentList from "./mdepartmentList";
import { getLoggedInUser } from "../../Utils/common";
import { Entity, EntityOperation, AppConstants } from "../../common/constants";
import { updateCFRDepartment, deleteDepartment } from "../../graphql/mutations";
import {
  listCFRDepartments,
  listCFREmailTemplates,
  sendEmail,
} from "../../graphql/queries";

function Department(props) {
  const alert = useAlert();
  const [deptData, setDeptData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState({});
  const { register, handleSubmit, setValue } = useForm();
  const [isCreatePermission, setIsCreatePermission] = useState(false);
  const [isUpdatePermission, setIsUpdatePermission] = useState(false);
  const [isDeletePermission, setIsDeletePermission] = useState(false);
  let ShowFilterBox = false;

  //This function retrieves department data
  const getDepartmentList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/department/department.js\n Function Name: getUserListForEmail";
    try {
      setIsLoading(true);
      let searchParameter = { isdeleted: { eq: false } };
      if (filterData.name) {
        searchParameter.name = { contains: filterData.name };
      }
      const _deptData = await API.graphql(
        graphqlOperation(listCFRDepartments, { filter: searchParameter })
      );

      if (_deptData) {
        const deptList = _deptData.data.listCFRDepartments.items;
        deptList.sort((a, b) => a.name.localeCompare(b.name));
        console.log(deptList);
        setIsLoading(false);
        setDeptData(deptList);
      } else {
        if (_deptData.errors) {
          if (_deptData.errors.length > 0) {
            alert.show(_deptData.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  });

  // this function for open filter panel
  const showFilter = () => {
    setFilter(true);
  };
  // this function for close filter panel
  const hideFilter = () => {
    setFilter(false);
  };

  //this function for filterd the record
  const FilterRecords = (filterData) => {
    setFilterData(filterData);
    setFilter(false);
  };

  // function to reset grid.
  const resetFilters = () => {
    const fields = ["name"];
    setFilterData({});
    setValue("name", "");
    setFilter(false);
  };

  // click on add button and redirect to add page
  const HandleAddClick = () => {
    props.history.push("/department/add");
  };

  //this function for redirect in view page
  const viewDept = (deptObj, id) => {
    props.history.push({
      pathname: "/department/view/" + id,
      state: deptObj,
    });
  };

  // this function is responsible for open confirmation message box
  const deleteDept = (deptObj, id) => {
    alert.show("Are you sure, you want to delete a department ?", {
      title: "Confirmation",
      closeCopy: "Cancel",
      actions: [
        {
          copy: "Yes",
          onClick: () => deleteDeptRecord(deptObj, id),
        },
      ],
    });
  };

  // delete department record once click on confirm
  const deleteDeptRecord = async (deptObj, id) => {
    const errorLocation =
      "Path Name: components/department/department.js\n Function Name: deleteDeptRecord";
    try {
      delete deptObj.users;
      delete deptObj.createdAt;
      delete deptObj.updatedAt;
      deptObj.isdeleted = true;
      setIsLoading(true);
      const deletedDept = await API.graphql(
        graphqlOperation(updateCFRDepartment, { input: deptObj })
      );
      setIsLoading(false);
      if (deletedDept.data) {
        alert.show("Department record is deleted.");
        getDepartmentList();
      } else {
        if (deletedDept.errors) {
          if (deletedDept.errors.length > 0) {
            alert.show(deletedDept.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  };

  // click on update button and redirect to update page
  const editDept = (deptObj, id) => {
    props.history.push({
      pathname: "/department/update/" + id,
      state: deptObj,
    });
  };

  // function to handle global user toggle change
  const handleToggleChange = (dept) => {
    onDeptUpdate(dept);
  };

  // function to update global user toggle change
  async function onDeptUpdate(dept) {
    try {
      delete dept["users"];
      delete dept["createdAt"];
      delete dept["updatedAt"];

      const toUpdateDept = {
        ...dept,
        isForGlobalUser: !dept.isForGlobalUser,
      };

      console.log(toUpdateDept);
      const result = await API.graphql(
        graphqlOperation(updateCFRDepartment, { input: toUpdateDept })
      );
      if (result.data) {
        getDepartmentList();
      }
    } catch (error) {
      console.log(error);
    }
  }

  // call dept list on page load
  useEffect(() => {
    getDepartmentList();
    const loggedinUser = getLoggedInUser();
    if (loggedinUser) {
      if (loggedinUser.role.cfrRoleEntities) {
        if (loggedinUser.role.cfrRoleEntities.items.length > 0) {
          let configCreateArr = loggedinUser.role.cfrRoleEntities.items.filter(
            (privilege) =>
              privilege.entity.name == Entity.Department &&
              privilege.entityOperation.name == EntityOperation.Create
          );
          if (configCreateArr.length > 0) {
            setIsCreatePermission(true);
          } else {
            setIsCreatePermission(false);
          }
          let configUpdateArr = loggedinUser.role.cfrRoleEntities.items.filter(
            (privilege) =>
              privilege.entity.name == Entity.Department &&
              privilege.entityOperation.name == EntityOperation.Update
          );
          if (configUpdateArr.length > 0) {
            setIsUpdatePermission(true);
          } else {
            setIsUpdatePermission(false);
          }
          let configDeleteArr = loggedinUser.role.cfrRoleEntities.items.filter(
            (privilege) =>
              privilege.entity.name == Entity.Department &&
              privilege.entityOperation.name == EntityOperation.Delete
          );
          if (configDeleteArr.length > 0) {
            setIsDeletePermission(true);
          } else {
            setIsDeletePermission(false);
          }
        }
      }
    }
  }, [filterData]);

  return (
    <div className="page-container">
      {isLoading ? <PageLoader></PageLoader> : ""}
      <div className="page-header">
        <div className="row">
          <div className="col-sm-8 col-8">
            <h4>
              Department List
              <i className="fa fa-filter btn-filter" onClick={showFilter}></i>
            </h4>
          </div>
          <div className="col-sm-4 col-4">
            {isCreatePermission == true && (
              <button
                type="button"
                className="btn-grid fa fa-plus"
                title="Add Department"
                onClick={HandleAddClick}
              ></button>
            )}
          </div>
        </div>
      </div>
      <div className="card d-none d-sm-block">
        <div className="card-body">
          <div className="row form-group">
            <div className="col-sm-12">
              <DepartmentList
                deptData={deptData}
                actionView={viewDept}
                actiondelete={deleteDept}
                actionUpdate={editDept}
                actionToggle={handleToggleChange}
                isCreatePermission={isCreatePermission}
                isUpdatePermission={isUpdatePermission}
                isDeletePermission={isDeletePermission}
              ></DepartmentList>
            </div>
          </div>
        </div>
      </div>

      <MobileDepartmentList
        deptData={deptData}
        actionView={viewDept}
        actiondelete={deleteDept}
        actionUpdate={editDept}
        isCreatePermission={isCreatePermission}
        isUpdatePermission={isUpdatePermission}
        isDeletePermission={isDeletePermission}
      />
      <form>
        <div
          className={
            isFilter ? "filter-container show-filter" : "filter-container"
          }
        >
          <div className="filter-head">
            <label className="label-heading"> Apply Filter </label>
          </div>
          <div className="filter-body">
            <div className="row mb-3">
              <div className="col-sm-12">
                <label className="form-label"> Name </label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="Name"
                    {...register("name")}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-action">
            <div
              className="filter-option br-btn"
              onClick={handleSubmit(FilterRecords)}
            >
              <span className="icon-tick"> </span>
            </div>
            <div className="filter-option br-btn" onClick={resetFilters}>
              <span className="icon-refresh"> </span>
            </div>
            <div className="filter-option br-btn" onClick={hideFilter}>
              <span className="icon-cross"> </span>
            </div>
          </div>
        </div>
        <div
          className={isFilter ? "filteroverlay show" : "filteroverlay"}
        ></div>
      </form>
    </div>
  );
}
export default Department;
