/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listAzureUsers = /* GraphQL */ `
  query ListAzureUsers($EmailSearchKey: String) {
    listAzureUsers(EmailSearchKey: $EmailSearchKey)
  }
`;
export const sendEmail = /* GraphQL */ `
  query SendEmail($EmailTemplate: String) {
    sendEmail(EmailTemplate: $EmailTemplate)
  }
`;
export const getCFRRole = /* GraphQL */ `
  query GetCFRRole($id: ID!) {
    getCFRRole(id: $id) {
      id
      name
      details
      cfrRoleEntities {
        items {
          id
          createdAt
          updatedAt
          cFRRoleCfrRoleEntitiesId
          cFRRoleEntityEntityId
          cFRRoleEntityEntityOperationId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCFRRoles = /* GraphQL */ `
  query ListCFRRoles(
    $filter: ModelCFRRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        details
        cfrRoleEntities {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCFRUser = /* GraphQL */ `
  query GetCFRUser($id: ID!) {
    getCFRUser(id: $id) {
      id
      email
      name
      limit
      toLimit
      currencySymbol
      departmentadmin
      headoffunction
      department {
        items {
          id
          cFRUserID
          cFRDepartmentID
          createdAt
          updatedAt
        }
        nextToken
      }
      role {
        id
        name
        details
        cfrRoleEntities {
          nextToken
        }
        createdAt
        updatedAt
      }
      isActive
      isGlobalUser
      canApproveBudgetedCFR
      canApproveUnBudgetedCFR
      isDeleted
      createdAt
      updatedAt
      cFRUserRoleId
    }
  }
`;
export const listCFRUsers = /* GraphQL */ `
  query ListCFRUsers(
    $filter: ModelCFRUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          nextToken
        }
        role {
          id
          name
          details
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        isDeleted
        createdAt
        updatedAt
        cFRUserRoleId
      }
      nextToken
    }
  }
`;
export const getCFRDepartment = /* GraphQL */ `
  query GetCFRDepartment($id: ID!) {
    getCFRDepartment(id: $id) {
      id
      name
      details
      isdeleted
      isForGlobalUser
      financeBudgtedUsers
      financeUnBudgtedUsers
      procurementBudgtedUsers
      procurementUnBudgtedUsers
      users {
        items {
          id
          cFRUserID
          cFRDepartmentID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCFRDepartments = /* GraphQL */ `
  query ListCFRDepartments(
    $filter: ModelCFRDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        details
        isdeleted
        isForGlobalUser
        financeBudgtedUsers
        financeUnBudgtedUsers
        procurementBudgtedUsers
        procurementUnBudgtedUsers
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCFRApprovalLevel = /* GraphQL */ `
  query GetCFRApprovalLevel($id: ID!) {
    getCFRApprovalLevel(id: $id) {
      id
      sequencenumber
      levelName
      ismandatory
      version
      department {
        id
        name
        details
        isdeleted
        isForGlobalUser
        financeBudgtedUsers
        financeUnBudgtedUsers
        procurementBudgtedUsers
        procurementUnBudgtedUsers
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      cFRApprovalLevelDepartmentId
    }
  }
`;
export const listCFRApprovalLevels = /* GraphQL */ `
  query ListCFRApprovalLevels(
    $filter: ModelCFRApprovalLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRApprovalLevels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sequencenumber
        levelName
        ismandatory
        version
        department {
          id
          name
          details
          isdeleted
          isForGlobalUser
          financeBudgtedUsers
          financeUnBudgtedUsers
          procurementBudgtedUsers
          procurementUnBudgtedUsers
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        cFRApprovalLevelDepartmentId
      }
      nextToken
    }
  }
`;
export const getCFRCapitalFundRequest = /* GraphQL */ `
  query GetCFRCapitalFundRequest($id: ID!) {
    getCFRCapitalFundRequest(id: $id) {
      id
      masterCFRID
      projecttitle
      version
      territory
      location
      iscapitalbudget
      budgetReferenceNo {
        items {
          id
          budgetReferenceNo
          description
          remainingAmount
          proposedAmount
          createdAt
          updatedAt
          cFRCapitalFundRequestBudgetReferenceNoId
        }
        nextToken
      }
      budgetvaluetype
      budgetvalue
      proposalvaluetype
      proposalvalue
      irr
      npv
      payback
      assetlife
      budgetbenefits
      cfrbenefits
      cfrsubmissiondate
      spendtype
      maincontractors
      cfrFiles {
        items {
          bucket
          region
          key
          id
          createdAt
          updatedAt
          cFRCapitalFundRequestCfrFilesId
          cFRS3ObjectCfrId
        }
        nextToken
      }
      cfrstatus
      businesscontext
      justification
      optionsconsidered
      npvjson
      cfrApprovals {
        items {
          id
          expectedCfrApprovers
          cfrVersion
          approvalstatus
          approverjustification
          approvalDateTime
          createdAt
          updatedAt
          cFRCapitalFundRequestCfrApprovalsId
          cFRApproverCfrId
          cFRApproverApprovalLevelId
          cFRApproverActualCfrApproverId
        }
        nextToken
      }
      cfrCreator {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          nextToken
        }
        role {
          id
          name
          details
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        isDeleted
        createdAt
        updatedAt
        cFRUserRoleId
      }
      isDeleted
      costcenter
      profitcenter
      isIORequestGenerated
      IONumber
      expectedCompletionDate
      riskAndMitigation {
        items {
          id
          risk
          mitigation
          category
          occurrence
          consequence
          owner
          isDeleted
          createdAt
          updatedAt
          cFRCapitalFundRequestRiskAndMitigationId
        }
        nextToken
      }
      timelineActivities {
        items {
          id
          activityName
          activityDate
          createdAt
          updatedAt
          cFRCapitalFundRequestTimelineActivitiesId
        }
        nextToken
      }
      createdAt
      updatedAt
      cFRCapitalFundRequestCfrCreatorId
    }
  }
`;
export const listCFRCapitalFundRequests = /* GraphQL */ `
  query ListCFRCapitalFundRequests(
    $filter: ModelCFRCapitalFundRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRCapitalFundRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        masterCFRID
        projecttitle
        version
        territory
        location
        iscapitalbudget
        budgetReferenceNo {
          nextToken
        }
        budgetvaluetype
        budgetvalue
        proposalvaluetype
        proposalvalue
        irr
        npv
        payback
        assetlife
        budgetbenefits
        cfrbenefits
        cfrsubmissiondate
        spendtype
        maincontractors
        cfrFiles {
          nextToken
        }
        cfrstatus
        businesscontext
        justification
        optionsconsidered
        npvjson
        cfrApprovals {
          nextToken
        }
        cfrCreator {
          id
          email
          name
          limit
          toLimit
          currencySymbol
          departmentadmin
          headoffunction
          isActive
          isGlobalUser
          canApproveBudgetedCFR
          canApproveUnBudgetedCFR
          isDeleted
          createdAt
          updatedAt
          cFRUserRoleId
        }
        isDeleted
        costcenter
        profitcenter
        isIORequestGenerated
        IONumber
        expectedCompletionDate
        riskAndMitigation {
          nextToken
        }
        timelineActivities {
          nextToken
        }
        createdAt
        updatedAt
        cFRCapitalFundRequestCfrCreatorId
      }
      nextToken
    }
  }
`;
export const getCFRS3Object = /* GraphQL */ `
  query GetCFRS3Object($id: ID!) {
    getCFRS3Object(id: $id) {
      bucket
      region
      key
      cfr {
        id
        masterCFRID
        projecttitle
        version
        territory
        location
        iscapitalbudget
        budgetReferenceNo {
          nextToken
        }
        budgetvaluetype
        budgetvalue
        proposalvaluetype
        proposalvalue
        irr
        npv
        payback
        assetlife
        budgetbenefits
        cfrbenefits
        cfrsubmissiondate
        spendtype
        maincontractors
        cfrFiles {
          nextToken
        }
        cfrstatus
        businesscontext
        justification
        optionsconsidered
        npvjson
        cfrApprovals {
          nextToken
        }
        cfrCreator {
          id
          email
          name
          limit
          toLimit
          currencySymbol
          departmentadmin
          headoffunction
          isActive
          isGlobalUser
          canApproveBudgetedCFR
          canApproveUnBudgetedCFR
          isDeleted
          createdAt
          updatedAt
          cFRUserRoleId
        }
        isDeleted
        costcenter
        profitcenter
        isIORequestGenerated
        IONumber
        expectedCompletionDate
        riskAndMitigation {
          nextToken
        }
        timelineActivities {
          nextToken
        }
        createdAt
        updatedAt
        cFRCapitalFundRequestCfrCreatorId
      }
      id
      createdAt
      updatedAt
      cFRCapitalFundRequestCfrFilesId
      cFRS3ObjectCfrId
    }
  }
`;
export const listCFRS3Objects = /* GraphQL */ `
  query ListCFRS3Objects(
    $filter: ModelCFRS3ObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRS3Objects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        bucket
        region
        key
        cfr {
          id
          masterCFRID
          projecttitle
          version
          territory
          location
          iscapitalbudget
          budgetvaluetype
          budgetvalue
          proposalvaluetype
          proposalvalue
          irr
          npv
          payback
          assetlife
          budgetbenefits
          cfrbenefits
          cfrsubmissiondate
          spendtype
          maincontractors
          cfrstatus
          businesscontext
          justification
          optionsconsidered
          npvjson
          isDeleted
          costcenter
          profitcenter
          isIORequestGenerated
          IONumber
          expectedCompletionDate
          createdAt
          updatedAt
          cFRCapitalFundRequestCfrCreatorId
        }
        id
        createdAt
        updatedAt
        cFRCapitalFundRequestCfrFilesId
        cFRS3ObjectCfrId
      }
      nextToken
    }
  }
`;
export const getCFRApprover = /* GraphQL */ `
  query GetCFRApprover($id: ID!) {
    getCFRApprover(id: $id) {
      id
      cfr {
        id
        masterCFRID
        projecttitle
        version
        territory
        location
        iscapitalbudget
        budgetReferenceNo {
          nextToken
        }
        budgetvaluetype
        budgetvalue
        proposalvaluetype
        proposalvalue
        irr
        npv
        payback
        assetlife
        budgetbenefits
        cfrbenefits
        cfrsubmissiondate
        spendtype
        maincontractors
        cfrFiles {
          nextToken
        }
        cfrstatus
        businesscontext
        justification
        optionsconsidered
        npvjson
        cfrApprovals {
          nextToken
        }
        cfrCreator {
          id
          email
          name
          limit
          toLimit
          currencySymbol
          departmentadmin
          headoffunction
          isActive
          isGlobalUser
          canApproveBudgetedCFR
          canApproveUnBudgetedCFR
          isDeleted
          createdAt
          updatedAt
          cFRUserRoleId
        }
        isDeleted
        costcenter
        profitcenter
        isIORequestGenerated
        IONumber
        expectedCompletionDate
        riskAndMitigation {
          nextToken
        }
        timelineActivities {
          nextToken
        }
        createdAt
        updatedAt
        cFRCapitalFundRequestCfrCreatorId
      }
      approvalLevel {
        id
        sequencenumber
        levelName
        ismandatory
        version
        department {
          id
          name
          details
          isdeleted
          isForGlobalUser
          financeBudgtedUsers
          financeUnBudgtedUsers
          procurementBudgtedUsers
          procurementUnBudgtedUsers
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        cFRApprovalLevelDepartmentId
      }
      expectedCfrApprovers
      actualCfrApprover {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          nextToken
        }
        role {
          id
          name
          details
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        isDeleted
        createdAt
        updatedAt
        cFRUserRoleId
      }
      cfrVersion
      approvalstatus
      approverjustification
      approvalDateTime
      createdAt
      updatedAt
      cFRCapitalFundRequestCfrApprovalsId
      cFRApproverCfrId
      cFRApproverApprovalLevelId
      cFRApproverActualCfrApproverId
    }
  }
`;
export const listCFRApprovers = /* GraphQL */ `
  query ListCFRApprovers(
    $filter: ModelCFRApproverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRApprovers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cfr {
          id
          masterCFRID
          projecttitle
          version
          territory
          location
          iscapitalbudget
          budgetvaluetype
          budgetvalue
          proposalvaluetype
          proposalvalue
          irr
          npv
          payback
          assetlife
          budgetbenefits
          cfrbenefits
          cfrsubmissiondate
          spendtype
          maincontractors
          cfrstatus
          businesscontext
          justification
          optionsconsidered
          npvjson
          isDeleted
          costcenter
          profitcenter
          isIORequestGenerated
          IONumber
          expectedCompletionDate
          createdAt
          updatedAt
          cFRCapitalFundRequestCfrCreatorId
        }
        approvalLevel {
          id
          sequencenumber
          levelName
          ismandatory
          version
          createdAt
          updatedAt
          cFRApprovalLevelDepartmentId
        }
        expectedCfrApprovers
        actualCfrApprover {
          id
          email
          name
          limit
          toLimit
          currencySymbol
          departmentadmin
          headoffunction
          isActive
          isGlobalUser
          canApproveBudgetedCFR
          canApproveUnBudgetedCFR
          isDeleted
          createdAt
          updatedAt
          cFRUserRoleId
        }
        cfrVersion
        approvalstatus
        approverjustification
        approvalDateTime
        createdAt
        updatedAt
        cFRCapitalFundRequestCfrApprovalsId
        cFRApproverCfrId
        cFRApproverApprovalLevelId
        cFRApproverActualCfrApproverId
      }
      nextToken
    }
  }
`;
export const getCFRConfig = /* GraphQL */ `
  query GetCFRConfig($id: ID!) {
    getCFRConfig(id: $id) {
      id
      geography
      currency
      currencySymbol
      interestRate
      createdAt
      updatedAt
    }
  }
`;
export const listCFRConfigs = /* GraphQL */ `
  query ListCFRConfigs(
    $filter: ModelCFRConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        geography
        currency
        currencySymbol
        interestRate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCFRPendingUser = /* GraphQL */ `
  query GetCFRPendingUser($id: ID!) {
    getCFRPendingUser(id: $id) {
      id
      email
      name
      isApproved
      createdAt
      updatedAt
    }
  }
`;
export const listCFRPendingUsers = /* GraphQL */ `
  query ListCFRPendingUsers(
    $filter: ModelCFRPendingUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRPendingUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        name
        isApproved
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCFREmailTemplate = /* GraphQL */ `
  query GetCFREmailTemplate($id: ID!) {
    getCFREmailTemplate(id: $id) {
      id
      action
      emailsubject
      emailcontent
      isActive
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const listCFREmailTemplates = /* GraphQL */ `
  query ListCFREmailTemplates(
    $filter: ModelCFREmailTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFREmailTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        action
        emailsubject
        emailcontent
        isActive
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCFRCurrencyRepo = /* GraphQL */ `
  query GetCFRCurrencyRepo($id: ID!) {
    getCFRCurrencyRepo(id: $id) {
      id
      configOne {
        id
        geography
        currency
        currencySymbol
        interestRate
        createdAt
        updatedAt
      }
      configTwo {
        id
        geography
        currency
        currencySymbol
        interestRate
        createdAt
        updatedAt
      }
      repoRate
      createdAt
      updatedAt
      cFRCurrencyRepoConfigOneId
      cFRCurrencyRepoConfigTwoId
    }
  }
`;
export const listCFRCurrencyRepos = /* GraphQL */ `
  query ListCFRCurrencyRepos(
    $filter: ModelCFRCurrencyRepoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRCurrencyRepos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        configOne {
          id
          geography
          currency
          currencySymbol
          interestRate
          createdAt
          updatedAt
        }
        configTwo {
          id
          geography
          currency
          currencySymbol
          interestRate
          createdAt
          updatedAt
        }
        repoRate
        createdAt
        updatedAt
        cFRCurrencyRepoConfigOneId
        cFRCurrencyRepoConfigTwoId
      }
      nextToken
    }
  }
`;
export const getCFREntity = /* GraphQL */ `
  query GetCFREntity($id: ID!) {
    getCFREntity(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listCFREntities = /* GraphQL */ `
  query ListCFREntities(
    $filter: ModelCFREntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFREntities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCFREntityOperation = /* GraphQL */ `
  query GetCFREntityOperation($id: ID!) {
    getCFREntityOperation(id: $id) {
      id
      name
      entity {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      cFREntityOperationEntityId
    }
  }
`;
export const listCFREntityOperations = /* GraphQL */ `
  query ListCFREntityOperations(
    $filter: ModelCFREntityOperationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFREntityOperations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        entity {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        cFREntityOperationEntityId
      }
      nextToken
    }
  }
`;
export const getCFRRoleEntity = /* GraphQL */ `
  query GetCFRRoleEntity($id: ID!) {
    getCFRRoleEntity(id: $id) {
      id
      entity {
        id
        name
        createdAt
        updatedAt
      }
      entityOperation {
        id
        name
        entity {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        cFREntityOperationEntityId
      }
      createdAt
      updatedAt
      cFRRoleCfrRoleEntitiesId
      cFRRoleEntityEntityId
      cFRRoleEntityEntityOperationId
    }
  }
`;
export const listCFRRoleEntities = /* GraphQL */ `
  query ListCFRRoleEntities(
    $filter: ModelCFRRoleEntityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRRoleEntities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        entity {
          id
          name
          createdAt
          updatedAt
        }
        entityOperation {
          id
          name
          createdAt
          updatedAt
          cFREntityOperationEntityId
        }
        createdAt
        updatedAt
        cFRRoleCfrRoleEntitiesId
        cFRRoleEntityEntityId
        cFRRoleEntityEntityOperationId
      }
      nextToken
    }
  }
`;
export const getCFRPlant = /* GraphQL */ `
  query GetCFRPlant($id: ID!) {
    getCFRPlant(id: $id) {
      id
      plantCode
      name
      createdAt
      updatedAt
    }
  }
`;
export const listCFRPlants = /* GraphQL */ `
  query ListCFRPlants(
    $filter: ModelCFRPlantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRPlants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        plantCode
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCFRAssetClass = /* GraphQL */ `
  query GetCFRAssetClass($id: ID!) {
    getCFRAssetClass(id: $id) {
      id
      cwip
      asset
      evgr
      description
      depKey
      revisedUsefullLife
      depRate
      createdAt
      updatedAt
    }
  }
`;
export const listCFRAssetClasses = /* GraphQL */ `
  query ListCFRAssetClasses(
    $filter: ModelCFRAssetClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRAssetClasses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cwip
        asset
        evgr
        description
        depKey
        revisedUsefullLife
        depRate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCFRCostAndProfitCentre = /* GraphQL */ `
  query GetCFRCostAndProfitCentre($id: ID!) {
    getCFRCostAndProfitCentre(id: $id) {
      id
      costcenter
      profitcenter
      profitcenterdesc
      departmentId
      createdAt
      updatedAt
    }
  }
`;
export const listCFRCostAndProfitCentres = /* GraphQL */ `
  query ListCFRCostAndProfitCentres(
    $filter: ModelCFRCostAndProfitCentreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRCostAndProfitCentres(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        costcenter
        profitcenter
        profitcenterdesc
        departmentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCFRBudgetReference = /* GraphQL */ `
  query GetCFRBudgetReference($id: ID!) {
    getCFRBudgetReference(id: $id) {
      id
      departmentId
      departmentName
      budgetReferenceNo
      projectDescription
      totalAmount
      spendAmount
      remainingAmount
      lockedAmount
      year
      status
      createdAt
      updatedAt
    }
  }
`;
export const listCFRBudgetReferences = /* GraphQL */ `
  query ListCFRBudgetReferences(
    $filter: ModelCFRBudgetReferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRBudgetReferences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentId
        departmentName
        budgetReferenceNo
        projectDescription
        totalAmount
        spendAmount
        remainingAmount
        lockedAmount
        year
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCFRUserNotifications = /* GraphQL */ `
  query GetCFRUserNotifications($id: ID!) {
    getCFRUserNotifications(id: $id) {
      id
      user {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          nextToken
        }
        role {
          id
          name
          details
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        isDeleted
        createdAt
        updatedAt
        cFRUserRoleId
      }
      userActivation
      userEnabled
      userDeactivation
      cFRCreation
      ioRequest
      ioGenerated
      cFRCreatorChanged
      projectSponsorApproval
      procurementHeadApproval
      functionalHeadApproval
      financeHeadApproval
      createdAt
      updatedAt
      cFRUserNotificationsUserId
    }
  }
`;
export const listCFRUserNotifications = /* GraphQL */ `
  query ListCFRUserNotifications(
    $filter: ModelCFRUserNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRUserNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user {
          id
          email
          name
          limit
          toLimit
          currencySymbol
          departmentadmin
          headoffunction
          isActive
          isGlobalUser
          canApproveBudgetedCFR
          canApproveUnBudgetedCFR
          isDeleted
          createdAt
          updatedAt
          cFRUserRoleId
        }
        userActivation
        userEnabled
        userDeactivation
        cFRCreation
        ioRequest
        ioGenerated
        cFRCreatorChanged
        projectSponsorApproval
        procurementHeadApproval
        functionalHeadApproval
        financeHeadApproval
        createdAt
        updatedAt
        cFRUserNotificationsUserId
      }
      nextToken
    }
  }
`;
export const getCFRMultipleBudgetReference = /* GraphQL */ `
  query GetCFRMultipleBudgetReference($id: ID!) {
    getCFRMultipleBudgetReference(id: $id) {
      id
      budgetReferenceNo
      description
      remainingAmount
      proposedAmount
      createdAt
      updatedAt
      cFRCapitalFundRequestBudgetReferenceNoId
    }
  }
`;
export const listCFRMultipleBudgetReferences = /* GraphQL */ `
  query ListCFRMultipleBudgetReferences(
    $filter: ModelCFRMultipleBudgetReferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRMultipleBudgetReferences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        budgetReferenceNo
        description
        remainingAmount
        proposedAmount
        createdAt
        updatedAt
        cFRCapitalFundRequestBudgetReferenceNoId
      }
      nextToken
    }
  }
`;
export const getCFRRiskAndMitigation = /* GraphQL */ `
  query GetCFRRiskAndMitigation($id: ID!) {
    getCFRRiskAndMitigation(id: $id) {
      id
      risk
      mitigation
      category
      occurrence
      consequence
      owner
      isDeleted
      createdAt
      updatedAt
      cFRCapitalFundRequestRiskAndMitigationId
    }
  }
`;
export const listCFRRiskAndMitigations = /* GraphQL */ `
  query ListCFRRiskAndMitigations(
    $filter: ModelCFRRiskAndMitigationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRRiskAndMitigations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        risk
        mitigation
        category
        occurrence
        consequence
        owner
        isDeleted
        createdAt
        updatedAt
        cFRCapitalFundRequestRiskAndMitigationId
      }
      nextToken
    }
  }
`;
export const getCFRTimelineActivity = /* GraphQL */ `
  query GetCFRTimelineActivity($id: ID!) {
    getCFRTimelineActivity(id: $id) {
      id
      activityName
      activityDate
      createdAt
      updatedAt
      cFRCapitalFundRequestTimelineActivitiesId
    }
  }
`;
export const listCFRTimelineActivities = /* GraphQL */ `
  query ListCFRTimelineActivities(
    $filter: ModelCFRTimelineActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRTimelineActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activityName
        activityDate
        createdAt
        updatedAt
        cFRCapitalFundRequestTimelineActivitiesId
      }
      nextToken
    }
  }
`;
export const getCFRUserDepartmentMapping = /* GraphQL */ `
  query GetCFRUserDepartmentMapping($id: ID!) {
    getCFRUserDepartmentMapping(id: $id) {
      id
      cFRUserID
      cFRDepartmentID
      cFRUser {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          nextToken
        }
        role {
          id
          name
          details
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        isDeleted
        createdAt
        updatedAt
        cFRUserRoleId
      }
      cFRDepartment {
        id
        name
        details
        isdeleted
        isForGlobalUser
        financeBudgtedUsers
        financeUnBudgtedUsers
        procurementBudgtedUsers
        procurementUnBudgtedUsers
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCFRUserDepartmentMappings = /* GraphQL */ `
  query ListCFRUserDepartmentMappings(
    $filter: ModelCFRUserDepartmentMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCFRUserDepartmentMappings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cFRUserID
        cFRDepartmentID
        cFRUser {
          id
          email
          name
          limit
          toLimit
          currencySymbol
          departmentadmin
          headoffunction
          isActive
          isGlobalUser
          canApproveBudgetedCFR
          canApproveUnBudgetedCFR
          isDeleted
          createdAt
          updatedAt
          cFRUserRoleId
        }
        cFRDepartment {
          id
          name
          details
          isdeleted
          isForGlobalUser
          financeBudgtedUsers
          financeUnBudgtedUsers
          procurementBudgtedUsers
          procurementUnBudgtedUsers
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
