import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import LoginButton from "./loginButton";
import { AppConstants } from "../../common/constants";
import Amplify, { Auth, Hub } from "aws-amplify";
import { useHistory } from "react-router-dom";
import awsExports from "../../aws-exports";
import awsauth from "../../awsauth";
import { UserContext } from "../../context/UserContext";
import LoginImage from "../../assets/images/login.png";
import FooterImage from "../../assets/images/footer.png";
import TcplImage from "../../assets/images/tcpl.png";
import ForBetterImage from "../../assets/images/forbetter.png";
import CfrLogo from "../../assets/images/cfr-logo-browser.svg";
import Countdown from "react-countdown";
import { useState, useEffect } from "react";

const LoginPage = () => {
  const [isLogout, setIsLogout] = useState(false);
  const { isLoggedin, user, updateLogin, updateCFRUser } =
    React.useContext(UserContext);
  Amplify.configure(awsExports);
  Auth.configure({ oauth: awsauth });

  let history = useHistory();
  const [signedIn, setSignedIn] = useState(false);

  const loginTop = {
    backgroundImage: `url(${LoginImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "80vh",
  };

  const loginBottom = {
    backgroundImage: `url(${FooterImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "20vh",
  };

  const tcplLogo = {
    height: "10vh",
  };

  const forBetterLogo = {
    height: "10vh",
  };

  const spacer = {
    height: "10vh",
  };

  const loginLink = {
    height: "5vh",
  };

  const cfrLogo = {
    height: "30vh",
  };

  const countdownStyle = {
    color: "#1468b2",
  };

  const logBackInStyle = {
    color: "#1468b2",
    cursor: "pointer",
  };

  const Completionist = () => <LoginButton />;

  const handleLogBackIn = () => {
    console.log("afsafd");
    clickLogin();
  };

  const renderer = ({ seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return <h1 className="text-center">{seconds}</h1>;
    }
  };

  const setLogout = () => {
    const storage = sessionStorage.getItem("isLogout");
    if (storage === "true") {
      setIsLogout(true);
    } else {
      setIsLogout(false);
    }
  };

  const clickLogin = (e) => {
    //e.preventDefault();
    if (user) {
      console.log("logged in", user);
      history.push("/config");
    } else {
      Auth.federatedSignIn({ provider: AppConstants.PROVIDER });
    }
  };

  useEffect(() => {
    setLogout();
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setSignedIn(true);
        console.log("Signed in", signedIn);
        history.push("/config");
        updateLogin(true);
      })
      .catch(() => {
        console.log("Not signed in");
        setSignedIn(false);
        //setUser();
        updateLogin(true);
      });
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col style={loginTop}>
          <Row style={{ height: "2vh" }}>
            <Col sm style={tcplLogo}>
              <Image src={TcplImage} fluid />
            </Col>
            <Col sm style={cfrLogo}>
              <Image src={CfrLogo} fluid />
            </Col>
            <Col sm></Col>
            <Col sm style={loginLink}>
              {isLogout == true ? (
                <div className="pull-right" style={logBackInStyle}>
                  <a onClick={() => handleLogBackIn()}>Log back in again</a>
                </div>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col></Col>
            <Col sm style={forBetterLogo}>
              <Image style={{ float: "right" }} src={ForBetterImage} fluid />
            </Col>
          </Row>
          <Row style={spacer}>
            <Col></Col>
            <Col></Col>
            <Col></Col>
          </Row>
          <Row></Row>
          <Row>
            <Col></Col>
            <Col align="center">
              <Container
                d-flex
                justify-content-sm-center
                style={countdownStyle}
              >
                <b>Capital Funding Request</b>
              </Container>
              <Container d-flex justify-content-sm-center>
                {isLogout === true ? (
                  <></>
                ) : (
                  <Countdown date={Date.now() + 5000} renderer={renderer} />
                )}
              </Container>
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col style={loginBottom}></Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
