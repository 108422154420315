/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCFRRole = /* GraphQL */ `
  mutation CreateCFRRole(
    $input: CreateCFRRoleInput!
    $condition: ModelCFRRoleConditionInput
  ) {
    createCFRRole(input: $input, condition: $condition) {
      id
      name
      details
      cfrRoleEntities {
        items {
          id
          createdAt
          updatedAt
          cFRRoleCfrRoleEntitiesId
          cFRRoleEntityEntityId
          cFRRoleEntityEntityOperationId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCFRRole = /* GraphQL */ `
  mutation UpdateCFRRole(
    $input: UpdateCFRRoleInput!
    $condition: ModelCFRRoleConditionInput
  ) {
    updateCFRRole(input: $input, condition: $condition) {
      id
      name
      details
      cfrRoleEntities {
        items {
          id
          createdAt
          updatedAt
          cFRRoleCfrRoleEntitiesId
          cFRRoleEntityEntityId
          cFRRoleEntityEntityOperationId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCFRRole = /* GraphQL */ `
  mutation DeleteCFRRole(
    $input: DeleteCFRRoleInput!
    $condition: ModelCFRRoleConditionInput
  ) {
    deleteCFRRole(input: $input, condition: $condition) {
      id
      name
      details
      cfrRoleEntities {
        items {
          id
          createdAt
          updatedAt
          cFRRoleCfrRoleEntitiesId
          cFRRoleEntityEntityId
          cFRRoleEntityEntityOperationId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCFRUser = /* GraphQL */ `
  mutation CreateCFRUser(
    $input: CreateCFRUserInput!
    $condition: ModelCFRUserConditionInput
  ) {
    createCFRUser(input: $input, condition: $condition) {
      id
      email
      name
      limit
      toLimit
      currencySymbol
      departmentadmin
      headoffunction
      department {
        items {
          id
          cFRUserID
          cFRDepartmentID
          createdAt
          updatedAt
        }
        nextToken
      }
      role {
        id
        name
        details
        cfrRoleEntities {
          nextToken
        }
        createdAt
        updatedAt
      }
      isActive
      isGlobalUser
      canApproveBudgetedCFR
      canApproveUnBudgetedCFR
      isDeleted
      createdAt
      updatedAt
      cFRUserRoleId
    }
  }
`;
export const updateCFRUser = /* GraphQL */ `
  mutation UpdateCFRUser(
    $input: UpdateCFRUserInput!
    $condition: ModelCFRUserConditionInput
  ) {
    updateCFRUser(input: $input, condition: $condition) {
      id
      email
      name
      limit
      toLimit
      currencySymbol
      departmentadmin
      headoffunction
      department {
        items {
          id
          cFRUserID
          cFRDepartmentID
          createdAt
          updatedAt
        }
        nextToken
      }
      role {
        id
        name
        details
        cfrRoleEntities {
          nextToken
        }
        createdAt
        updatedAt
      }
      isActive
      isGlobalUser
      canApproveBudgetedCFR
      canApproveUnBudgetedCFR
      isDeleted
      createdAt
      updatedAt
      cFRUserRoleId
    }
  }
`;
export const deleteCFRUser = /* GraphQL */ `
  mutation DeleteCFRUser(
    $input: DeleteCFRUserInput!
    $condition: ModelCFRUserConditionInput
  ) {
    deleteCFRUser(input: $input, condition: $condition) {
      id
      email
      name
      limit
      toLimit
      currencySymbol
      departmentadmin
      headoffunction
      department {
        items {
          id
          cFRUserID
          cFRDepartmentID
          createdAt
          updatedAt
        }
        nextToken
      }
      role {
        id
        name
        details
        cfrRoleEntities {
          nextToken
        }
        createdAt
        updatedAt
      }
      isActive
      isGlobalUser
      canApproveBudgetedCFR
      canApproveUnBudgetedCFR
      isDeleted
      createdAt
      updatedAt
      cFRUserRoleId
    }
  }
`;
export const createCFRDepartment = /* GraphQL */ `
  mutation CreateCFRDepartment(
    $input: CreateCFRDepartmentInput!
    $condition: ModelCFRDepartmentConditionInput
  ) {
    createCFRDepartment(input: $input, condition: $condition) {
      id
      name
      details
      isdeleted
      isForGlobalUser
      financeBudgtedUsers
      financeUnBudgtedUsers
      procurementBudgtedUsers
      procurementUnBudgtedUsers
      users {
        items {
          id
          cFRUserID
          cFRDepartmentID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCFRDepartment = /* GraphQL */ `
  mutation UpdateCFRDepartment(
    $input: UpdateCFRDepartmentInput!
    $condition: ModelCFRDepartmentConditionInput
  ) {
    updateCFRDepartment(input: $input, condition: $condition) {
      id
      name
      details
      isdeleted
      isForGlobalUser
      financeBudgtedUsers
      financeUnBudgtedUsers
      procurementBudgtedUsers
      procurementUnBudgtedUsers
      users {
        items {
          id
          cFRUserID
          cFRDepartmentID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCFRDepartment = /* GraphQL */ `
  mutation DeleteCFRDepartment(
    $input: DeleteCFRDepartmentInput!
    $condition: ModelCFRDepartmentConditionInput
  ) {
    deleteCFRDepartment(input: $input, condition: $condition) {
      id
      name
      details
      isdeleted
      isForGlobalUser
      financeBudgtedUsers
      financeUnBudgtedUsers
      procurementBudgtedUsers
      procurementUnBudgtedUsers
      users {
        items {
          id
          cFRUserID
          cFRDepartmentID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCFRApprovalLevel = /* GraphQL */ `
  mutation CreateCFRApprovalLevel(
    $input: CreateCFRApprovalLevelInput!
    $condition: ModelCFRApprovalLevelConditionInput
  ) {
    createCFRApprovalLevel(input: $input, condition: $condition) {
      id
      sequencenumber
      levelName
      ismandatory
      version
      department {
        id
        name
        details
        isdeleted
        isForGlobalUser
        financeBudgtedUsers
        financeUnBudgtedUsers
        procurementBudgtedUsers
        procurementUnBudgtedUsers
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      cFRApprovalLevelDepartmentId
    }
  }
`;
export const updateCFRApprovalLevel = /* GraphQL */ `
  mutation UpdateCFRApprovalLevel(
    $input: UpdateCFRApprovalLevelInput!
    $condition: ModelCFRApprovalLevelConditionInput
  ) {
    updateCFRApprovalLevel(input: $input, condition: $condition) {
      id
      sequencenumber
      levelName
      ismandatory
      version
      department {
        id
        name
        details
        isdeleted
        isForGlobalUser
        financeBudgtedUsers
        financeUnBudgtedUsers
        procurementBudgtedUsers
        procurementUnBudgtedUsers
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      cFRApprovalLevelDepartmentId
    }
  }
`;
export const deleteCFRApprovalLevel = /* GraphQL */ `
  mutation DeleteCFRApprovalLevel(
    $input: DeleteCFRApprovalLevelInput!
    $condition: ModelCFRApprovalLevelConditionInput
  ) {
    deleteCFRApprovalLevel(input: $input, condition: $condition) {
      id
      sequencenumber
      levelName
      ismandatory
      version
      department {
        id
        name
        details
        isdeleted
        isForGlobalUser
        financeBudgtedUsers
        financeUnBudgtedUsers
        procurementBudgtedUsers
        procurementUnBudgtedUsers
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      cFRApprovalLevelDepartmentId
    }
  }
`;
export const createCFRCapitalFundRequest = /* GraphQL */ `
  mutation CreateCFRCapitalFundRequest(
    $input: CreateCFRCapitalFundRequestInput!
    $condition: ModelCFRCapitalFundRequestConditionInput
  ) {
    createCFRCapitalFundRequest(input: $input, condition: $condition) {
      id
      masterCFRID
      projecttitle
      version
      territory
      location
      iscapitalbudget
      budgetReferenceNo {
        items {
          id
          budgetReferenceNo
          description
          remainingAmount
          proposedAmount
          createdAt
          updatedAt
          cFRCapitalFundRequestBudgetReferenceNoId
        }
        nextToken
      }
      budgetvaluetype
      budgetvalue
      proposalvaluetype
      proposalvalue
      irr
      npv
      payback
      assetlife
      budgetbenefits
      cfrbenefits
      cfrsubmissiondate
      spendtype
      maincontractors
      cfrFiles {
        items {
          bucket
          region
          key
          id
          createdAt
          updatedAt
          cFRCapitalFundRequestCfrFilesId
          cFRS3ObjectCfrId
        }
        nextToken
      }
      cfrstatus
      businesscontext
      justification
      optionsconsidered
      npvjson
      cfrApprovals {
        items {
          id
          expectedCfrApprovers
          cfrVersion
          approvalstatus
          approverjustification
          approvalDateTime
          createdAt
          updatedAt
          cFRCapitalFundRequestCfrApprovalsId
          cFRApproverCfrId
          cFRApproverApprovalLevelId
          cFRApproverActualCfrApproverId
        }
        nextToken
      }
      cfrCreator {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          nextToken
        }
        role {
          id
          name
          details
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        isDeleted
        createdAt
        updatedAt
        cFRUserRoleId
      }
      isDeleted
      costcenter
      profitcenter
      isIORequestGenerated
      IONumber
      expectedCompletionDate
      riskAndMitigation {
        items {
          id
          risk
          mitigation
          category
          occurrence
          consequence
          owner
          isDeleted
          createdAt
          updatedAt
          cFRCapitalFundRequestRiskAndMitigationId
        }
        nextToken
      }
      timelineActivities {
        items {
          id
          activityName
          activityDate
          createdAt
          updatedAt
          cFRCapitalFundRequestTimelineActivitiesId
        }
        nextToken
      }
      createdAt
      updatedAt
      cFRCapitalFundRequestCfrCreatorId
    }
  }
`;
export const updateCFRCapitalFundRequest = /* GraphQL */ `
  mutation UpdateCFRCapitalFundRequest(
    $input: UpdateCFRCapitalFundRequestInput!
    $condition: ModelCFRCapitalFundRequestConditionInput
  ) {
    updateCFRCapitalFundRequest(input: $input, condition: $condition) {
      id
      masterCFRID
      projecttitle
      version
      territory
      location
      iscapitalbudget
      budgetReferenceNo {
        items {
          id
          budgetReferenceNo
          description
          remainingAmount
          proposedAmount
          createdAt
          updatedAt
          cFRCapitalFundRequestBudgetReferenceNoId
        }
        nextToken
      }
      budgetvaluetype
      budgetvalue
      proposalvaluetype
      proposalvalue
      irr
      npv
      payback
      assetlife
      budgetbenefits
      cfrbenefits
      cfrsubmissiondate
      spendtype
      maincontractors
      cfrFiles {
        items {
          bucket
          region
          key
          id
          createdAt
          updatedAt
          cFRCapitalFundRequestCfrFilesId
          cFRS3ObjectCfrId
        }
        nextToken
      }
      cfrstatus
      businesscontext
      justification
      optionsconsidered
      npvjson
      cfrApprovals {
        items {
          id
          expectedCfrApprovers
          cfrVersion
          approvalstatus
          approverjustification
          approvalDateTime
          createdAt
          updatedAt
          cFRCapitalFundRequestCfrApprovalsId
          cFRApproverCfrId
          cFRApproverApprovalLevelId
          cFRApproverActualCfrApproverId
        }
        nextToken
      }
      cfrCreator {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          nextToken
        }
        role {
          id
          name
          details
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        isDeleted
        createdAt
        updatedAt
        cFRUserRoleId
      }
      isDeleted
      costcenter
      profitcenter
      isIORequestGenerated
      IONumber
      expectedCompletionDate
      riskAndMitigation {
        items {
          id
          risk
          mitigation
          category
          occurrence
          consequence
          owner
          isDeleted
          createdAt
          updatedAt
          cFRCapitalFundRequestRiskAndMitigationId
        }
        nextToken
      }
      timelineActivities {
        items {
          id
          activityName
          activityDate
          createdAt
          updatedAt
          cFRCapitalFundRequestTimelineActivitiesId
        }
        nextToken
      }
      createdAt
      updatedAt
      cFRCapitalFundRequestCfrCreatorId
    }
  }
`;
export const deleteCFRCapitalFundRequest = /* GraphQL */ `
  mutation DeleteCFRCapitalFundRequest(
    $input: DeleteCFRCapitalFundRequestInput!
    $condition: ModelCFRCapitalFundRequestConditionInput
  ) {
    deleteCFRCapitalFundRequest(input: $input, condition: $condition) {
      id
      masterCFRID
      projecttitle
      version
      territory
      location
      iscapitalbudget
      budgetReferenceNo {
        items {
          id
          budgetReferenceNo
          description
          remainingAmount
          proposedAmount
          createdAt
          updatedAt
          cFRCapitalFundRequestBudgetReferenceNoId
        }
        nextToken
      }
      budgetvaluetype
      budgetvalue
      proposalvaluetype
      proposalvalue
      irr
      npv
      payback
      assetlife
      budgetbenefits
      cfrbenefits
      cfrsubmissiondate
      spendtype
      maincontractors
      cfrFiles {
        items {
          bucket
          region
          key
          id
          createdAt
          updatedAt
          cFRCapitalFundRequestCfrFilesId
          cFRS3ObjectCfrId
        }
        nextToken
      }
      cfrstatus
      businesscontext
      justification
      optionsconsidered
      npvjson
      cfrApprovals {
        items {
          id
          expectedCfrApprovers
          cfrVersion
          approvalstatus
          approverjustification
          approvalDateTime
          createdAt
          updatedAt
          cFRCapitalFundRequestCfrApprovalsId
          cFRApproverCfrId
          cFRApproverApprovalLevelId
          cFRApproverActualCfrApproverId
        }
        nextToken
      }
      cfrCreator {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          nextToken
        }
        role {
          id
          name
          details
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        isDeleted
        createdAt
        updatedAt
        cFRUserRoleId
      }
      isDeleted
      costcenter
      profitcenter
      isIORequestGenerated
      IONumber
      expectedCompletionDate
      riskAndMitigation {
        items {
          id
          risk
          mitigation
          category
          occurrence
          consequence
          owner
          isDeleted
          createdAt
          updatedAt
          cFRCapitalFundRequestRiskAndMitigationId
        }
        nextToken
      }
      timelineActivities {
        items {
          id
          activityName
          activityDate
          createdAt
          updatedAt
          cFRCapitalFundRequestTimelineActivitiesId
        }
        nextToken
      }
      createdAt
      updatedAt
      cFRCapitalFundRequestCfrCreatorId
    }
  }
`;
export const createCFRS3Object = /* GraphQL */ `
  mutation CreateCFRS3Object(
    $input: CreateCFRS3ObjectInput!
    $condition: ModelCFRS3ObjectConditionInput
  ) {
    createCFRS3Object(input: $input, condition: $condition) {
      bucket
      region
      key
      cfr {
        id
        masterCFRID
        projecttitle
        version
        territory
        location
        iscapitalbudget
        budgetReferenceNo {
          nextToken
        }
        budgetvaluetype
        budgetvalue
        proposalvaluetype
        proposalvalue
        irr
        npv
        payback
        assetlife
        budgetbenefits
        cfrbenefits
        cfrsubmissiondate
        spendtype
        maincontractors
        cfrFiles {
          nextToken
        }
        cfrstatus
        businesscontext
        justification
        optionsconsidered
        npvjson
        cfrApprovals {
          nextToken
        }
        cfrCreator {
          id
          email
          name
          limit
          toLimit
          currencySymbol
          departmentadmin
          headoffunction
          isActive
          isGlobalUser
          canApproveBudgetedCFR
          canApproveUnBudgetedCFR
          isDeleted
          createdAt
          updatedAt
          cFRUserRoleId
        }
        isDeleted
        costcenter
        profitcenter
        isIORequestGenerated
        IONumber
        expectedCompletionDate
        riskAndMitigation {
          nextToken
        }
        timelineActivities {
          nextToken
        }
        createdAt
        updatedAt
        cFRCapitalFundRequestCfrCreatorId
      }
      id
      createdAt
      updatedAt
      cFRCapitalFundRequestCfrFilesId
      cFRS3ObjectCfrId
    }
  }
`;
export const updateCFRS3Object = /* GraphQL */ `
  mutation UpdateCFRS3Object(
    $input: UpdateCFRS3ObjectInput!
    $condition: ModelCFRS3ObjectConditionInput
  ) {
    updateCFRS3Object(input: $input, condition: $condition) {
      bucket
      region
      key
      cfr {
        id
        masterCFRID
        projecttitle
        version
        territory
        location
        iscapitalbudget
        budgetReferenceNo {
          nextToken
        }
        budgetvaluetype
        budgetvalue
        proposalvaluetype
        proposalvalue
        irr
        npv
        payback
        assetlife
        budgetbenefits
        cfrbenefits
        cfrsubmissiondate
        spendtype
        maincontractors
        cfrFiles {
          nextToken
        }
        cfrstatus
        businesscontext
        justification
        optionsconsidered
        npvjson
        cfrApprovals {
          nextToken
        }
        cfrCreator {
          id
          email
          name
          limit
          toLimit
          currencySymbol
          departmentadmin
          headoffunction
          isActive
          isGlobalUser
          canApproveBudgetedCFR
          canApproveUnBudgetedCFR
          isDeleted
          createdAt
          updatedAt
          cFRUserRoleId
        }
        isDeleted
        costcenter
        profitcenter
        isIORequestGenerated
        IONumber
        expectedCompletionDate
        riskAndMitigation {
          nextToken
        }
        timelineActivities {
          nextToken
        }
        createdAt
        updatedAt
        cFRCapitalFundRequestCfrCreatorId
      }
      id
      createdAt
      updatedAt
      cFRCapitalFundRequestCfrFilesId
      cFRS3ObjectCfrId
    }
  }
`;
export const deleteCFRS3Object = /* GraphQL */ `
  mutation DeleteCFRS3Object(
    $input: DeleteCFRS3ObjectInput!
    $condition: ModelCFRS3ObjectConditionInput
  ) {
    deleteCFRS3Object(input: $input, condition: $condition) {
      bucket
      region
      key
      cfr {
        id
        masterCFRID
        projecttitle
        version
        territory
        location
        iscapitalbudget
        budgetReferenceNo {
          nextToken
        }
        budgetvaluetype
        budgetvalue
        proposalvaluetype
        proposalvalue
        irr
        npv
        payback
        assetlife
        budgetbenefits
        cfrbenefits
        cfrsubmissiondate
        spendtype
        maincontractors
        cfrFiles {
          nextToken
        }
        cfrstatus
        businesscontext
        justification
        optionsconsidered
        npvjson
        cfrApprovals {
          nextToken
        }
        cfrCreator {
          id
          email
          name
          limit
          toLimit
          currencySymbol
          departmentadmin
          headoffunction
          isActive
          isGlobalUser
          canApproveBudgetedCFR
          canApproveUnBudgetedCFR
          isDeleted
          createdAt
          updatedAt
          cFRUserRoleId
        }
        isDeleted
        costcenter
        profitcenter
        isIORequestGenerated
        IONumber
        expectedCompletionDate
        riskAndMitigation {
          nextToken
        }
        timelineActivities {
          nextToken
        }
        createdAt
        updatedAt
        cFRCapitalFundRequestCfrCreatorId
      }
      id
      createdAt
      updatedAt
      cFRCapitalFundRequestCfrFilesId
      cFRS3ObjectCfrId
    }
  }
`;
export const createCFRApprover = /* GraphQL */ `
  mutation CreateCFRApprover(
    $input: CreateCFRApproverInput!
    $condition: ModelCFRApproverConditionInput
  ) {
    createCFRApprover(input: $input, condition: $condition) {
      id
      cfr {
        id
        masterCFRID
        projecttitle
        version
        territory
        location
        iscapitalbudget
        budgetReferenceNo {
          nextToken
        }
        budgetvaluetype
        budgetvalue
        proposalvaluetype
        proposalvalue
        irr
        npv
        payback
        assetlife
        budgetbenefits
        cfrbenefits
        cfrsubmissiondate
        spendtype
        maincontractors
        cfrFiles {
          nextToken
        }
        cfrstatus
        businesscontext
        justification
        optionsconsidered
        npvjson
        cfrApprovals {
          nextToken
        }
        cfrCreator {
          id
          email
          name
          limit
          toLimit
          currencySymbol
          departmentadmin
          headoffunction
          isActive
          isGlobalUser
          canApproveBudgetedCFR
          canApproveUnBudgetedCFR
          isDeleted
          createdAt
          updatedAt
          cFRUserRoleId
        }
        isDeleted
        costcenter
        profitcenter
        isIORequestGenerated
        IONumber
        expectedCompletionDate
        riskAndMitigation {
          nextToken
        }
        timelineActivities {
          nextToken
        }
        createdAt
        updatedAt
        cFRCapitalFundRequestCfrCreatorId
      }
      approvalLevel {
        id
        sequencenumber
        levelName
        ismandatory
        version
        department {
          id
          name
          details
          isdeleted
          isForGlobalUser
          financeBudgtedUsers
          financeUnBudgtedUsers
          procurementBudgtedUsers
          procurementUnBudgtedUsers
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        cFRApprovalLevelDepartmentId
      }
      expectedCfrApprovers
      actualCfrApprover {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          nextToken
        }
        role {
          id
          name
          details
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        isDeleted
        createdAt
        updatedAt
        cFRUserRoleId
      }
      cfrVersion
      approvalstatus
      approverjustification
      approvalDateTime
      createdAt
      updatedAt
      cFRCapitalFundRequestCfrApprovalsId
      cFRApproverCfrId
      cFRApproverApprovalLevelId
      cFRApproverActualCfrApproverId
    }
  }
`;
export const updateCFRApprover = /* GraphQL */ `
  mutation UpdateCFRApprover(
    $input: UpdateCFRApproverInput!
    $condition: ModelCFRApproverConditionInput
  ) {
    updateCFRApprover(input: $input, condition: $condition) {
      id
      cfr {
        id
        masterCFRID
        projecttitle
        version
        territory
        location
        iscapitalbudget
        budgetReferenceNo {
          nextToken
        }
        budgetvaluetype
        budgetvalue
        proposalvaluetype
        proposalvalue
        irr
        npv
        payback
        assetlife
        budgetbenefits
        cfrbenefits
        cfrsubmissiondate
        spendtype
        maincontractors
        cfrFiles {
          nextToken
        }
        cfrstatus
        businesscontext
        justification
        optionsconsidered
        npvjson
        cfrApprovals {
          nextToken
        }
        cfrCreator {
          id
          email
          name
          limit
          toLimit
          currencySymbol
          departmentadmin
          headoffunction
          isActive
          isGlobalUser
          canApproveBudgetedCFR
          canApproveUnBudgetedCFR
          isDeleted
          createdAt
          updatedAt
          cFRUserRoleId
        }
        isDeleted
        costcenter
        profitcenter
        isIORequestGenerated
        IONumber
        expectedCompletionDate
        riskAndMitigation {
          nextToken
        }
        timelineActivities {
          nextToken
        }
        createdAt
        updatedAt
        cFRCapitalFundRequestCfrCreatorId
      }
      approvalLevel {
        id
        sequencenumber
        levelName
        ismandatory
        version
        department {
          id
          name
          details
          isdeleted
          isForGlobalUser
          financeBudgtedUsers
          financeUnBudgtedUsers
          procurementBudgtedUsers
          procurementUnBudgtedUsers
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        cFRApprovalLevelDepartmentId
      }
      expectedCfrApprovers
      actualCfrApprover {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          nextToken
        }
        role {
          id
          name
          details
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        isDeleted
        createdAt
        updatedAt
        cFRUserRoleId
      }
      cfrVersion
      approvalstatus
      approverjustification
      approvalDateTime
      createdAt
      updatedAt
      cFRCapitalFundRequestCfrApprovalsId
      cFRApproverCfrId
      cFRApproverApprovalLevelId
      cFRApproverActualCfrApproverId
    }
  }
`;
export const deleteCFRApprover = /* GraphQL */ `
  mutation DeleteCFRApprover(
    $input: DeleteCFRApproverInput!
    $condition: ModelCFRApproverConditionInput
  ) {
    deleteCFRApprover(input: $input, condition: $condition) {
      id
      cfr {
        id
        masterCFRID
        projecttitle
        version
        territory
        location
        iscapitalbudget
        budgetReferenceNo {
          nextToken
        }
        budgetvaluetype
        budgetvalue
        proposalvaluetype
        proposalvalue
        irr
        npv
        payback
        assetlife
        budgetbenefits
        cfrbenefits
        cfrsubmissiondate
        spendtype
        maincontractors
        cfrFiles {
          nextToken
        }
        cfrstatus
        businesscontext
        justification
        optionsconsidered
        npvjson
        cfrApprovals {
          nextToken
        }
        cfrCreator {
          id
          email
          name
          limit
          toLimit
          currencySymbol
          departmentadmin
          headoffunction
          isActive
          isGlobalUser
          canApproveBudgetedCFR
          canApproveUnBudgetedCFR
          isDeleted
          createdAt
          updatedAt
          cFRUserRoleId
        }
        isDeleted
        costcenter
        profitcenter
        isIORequestGenerated
        IONumber
        expectedCompletionDate
        riskAndMitigation {
          nextToken
        }
        timelineActivities {
          nextToken
        }
        createdAt
        updatedAt
        cFRCapitalFundRequestCfrCreatorId
      }
      approvalLevel {
        id
        sequencenumber
        levelName
        ismandatory
        version
        department {
          id
          name
          details
          isdeleted
          isForGlobalUser
          financeBudgtedUsers
          financeUnBudgtedUsers
          procurementBudgtedUsers
          procurementUnBudgtedUsers
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        cFRApprovalLevelDepartmentId
      }
      expectedCfrApprovers
      actualCfrApprover {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          nextToken
        }
        role {
          id
          name
          details
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        isDeleted
        createdAt
        updatedAt
        cFRUserRoleId
      }
      cfrVersion
      approvalstatus
      approverjustification
      approvalDateTime
      createdAt
      updatedAt
      cFRCapitalFundRequestCfrApprovalsId
      cFRApproverCfrId
      cFRApproverApprovalLevelId
      cFRApproverActualCfrApproverId
    }
  }
`;
export const createCFRConfig = /* GraphQL */ `
  mutation CreateCFRConfig(
    $input: CreateCFRConfigInput!
    $condition: ModelCFRConfigConditionInput
  ) {
    createCFRConfig(input: $input, condition: $condition) {
      id
      geography
      currency
      currencySymbol
      interestRate
      createdAt
      updatedAt
    }
  }
`;
export const updateCFRConfig = /* GraphQL */ `
  mutation UpdateCFRConfig(
    $input: UpdateCFRConfigInput!
    $condition: ModelCFRConfigConditionInput
  ) {
    updateCFRConfig(input: $input, condition: $condition) {
      id
      geography
      currency
      currencySymbol
      interestRate
      createdAt
      updatedAt
    }
  }
`;
export const deleteCFRConfig = /* GraphQL */ `
  mutation DeleteCFRConfig(
    $input: DeleteCFRConfigInput!
    $condition: ModelCFRConfigConditionInput
  ) {
    deleteCFRConfig(input: $input, condition: $condition) {
      id
      geography
      currency
      currencySymbol
      interestRate
      createdAt
      updatedAt
    }
  }
`;
export const createCFRPendingUser = /* GraphQL */ `
  mutation CreateCFRPendingUser(
    $input: CreateCFRPendingUserInput!
    $condition: ModelCFRPendingUserConditionInput
  ) {
    createCFRPendingUser(input: $input, condition: $condition) {
      id
      email
      name
      isApproved
      createdAt
      updatedAt
    }
  }
`;
export const updateCFRPendingUser = /* GraphQL */ `
  mutation UpdateCFRPendingUser(
    $input: UpdateCFRPendingUserInput!
    $condition: ModelCFRPendingUserConditionInput
  ) {
    updateCFRPendingUser(input: $input, condition: $condition) {
      id
      email
      name
      isApproved
      createdAt
      updatedAt
    }
  }
`;
export const deleteCFRPendingUser = /* GraphQL */ `
  mutation DeleteCFRPendingUser(
    $input: DeleteCFRPendingUserInput!
    $condition: ModelCFRPendingUserConditionInput
  ) {
    deleteCFRPendingUser(input: $input, condition: $condition) {
      id
      email
      name
      isApproved
      createdAt
      updatedAt
    }
  }
`;
export const createCFREmailTemplate = /* GraphQL */ `
  mutation CreateCFREmailTemplate(
    $input: CreateCFREmailTemplateInput!
    $condition: ModelCFREmailTemplateConditionInput
  ) {
    createCFREmailTemplate(input: $input, condition: $condition) {
      id
      action
      emailsubject
      emailcontent
      isActive
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const updateCFREmailTemplate = /* GraphQL */ `
  mutation UpdateCFREmailTemplate(
    $input: UpdateCFREmailTemplateInput!
    $condition: ModelCFREmailTemplateConditionInput
  ) {
    updateCFREmailTemplate(input: $input, condition: $condition) {
      id
      action
      emailsubject
      emailcontent
      isActive
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteCFREmailTemplate = /* GraphQL */ `
  mutation DeleteCFREmailTemplate(
    $input: DeleteCFREmailTemplateInput!
    $condition: ModelCFREmailTemplateConditionInput
  ) {
    deleteCFREmailTemplate(input: $input, condition: $condition) {
      id
      action
      emailsubject
      emailcontent
      isActive
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createCFRCurrencyRepo = /* GraphQL */ `
  mutation CreateCFRCurrencyRepo(
    $input: CreateCFRCurrencyRepoInput!
    $condition: ModelCFRCurrencyRepoConditionInput
  ) {
    createCFRCurrencyRepo(input: $input, condition: $condition) {
      id
      configOne {
        id
        geography
        currency
        currencySymbol
        interestRate
        createdAt
        updatedAt
      }
      configTwo {
        id
        geography
        currency
        currencySymbol
        interestRate
        createdAt
        updatedAt
      }
      repoRate
      createdAt
      updatedAt
      cFRCurrencyRepoConfigOneId
      cFRCurrencyRepoConfigTwoId
    }
  }
`;
export const updateCFRCurrencyRepo = /* GraphQL */ `
  mutation UpdateCFRCurrencyRepo(
    $input: UpdateCFRCurrencyRepoInput!
    $condition: ModelCFRCurrencyRepoConditionInput
  ) {
    updateCFRCurrencyRepo(input: $input, condition: $condition) {
      id
      configOne {
        id
        geography
        currency
        currencySymbol
        interestRate
        createdAt
        updatedAt
      }
      configTwo {
        id
        geography
        currency
        currencySymbol
        interestRate
        createdAt
        updatedAt
      }
      repoRate
      createdAt
      updatedAt
      cFRCurrencyRepoConfigOneId
      cFRCurrencyRepoConfigTwoId
    }
  }
`;
export const deleteCFRCurrencyRepo = /* GraphQL */ `
  mutation DeleteCFRCurrencyRepo(
    $input: DeleteCFRCurrencyRepoInput!
    $condition: ModelCFRCurrencyRepoConditionInput
  ) {
    deleteCFRCurrencyRepo(input: $input, condition: $condition) {
      id
      configOne {
        id
        geography
        currency
        currencySymbol
        interestRate
        createdAt
        updatedAt
      }
      configTwo {
        id
        geography
        currency
        currencySymbol
        interestRate
        createdAt
        updatedAt
      }
      repoRate
      createdAt
      updatedAt
      cFRCurrencyRepoConfigOneId
      cFRCurrencyRepoConfigTwoId
    }
  }
`;
export const createCFREntity = /* GraphQL */ `
  mutation CreateCFREntity(
    $input: CreateCFREntityInput!
    $condition: ModelCFREntityConditionInput
  ) {
    createCFREntity(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateCFREntity = /* GraphQL */ `
  mutation UpdateCFREntity(
    $input: UpdateCFREntityInput!
    $condition: ModelCFREntityConditionInput
  ) {
    updateCFREntity(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteCFREntity = /* GraphQL */ `
  mutation DeleteCFREntity(
    $input: DeleteCFREntityInput!
    $condition: ModelCFREntityConditionInput
  ) {
    deleteCFREntity(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createCFREntityOperation = /* GraphQL */ `
  mutation CreateCFREntityOperation(
    $input: CreateCFREntityOperationInput!
    $condition: ModelCFREntityOperationConditionInput
  ) {
    createCFREntityOperation(input: $input, condition: $condition) {
      id
      name
      entity {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      cFREntityOperationEntityId
    }
  }
`;
export const updateCFREntityOperation = /* GraphQL */ `
  mutation UpdateCFREntityOperation(
    $input: UpdateCFREntityOperationInput!
    $condition: ModelCFREntityOperationConditionInput
  ) {
    updateCFREntityOperation(input: $input, condition: $condition) {
      id
      name
      entity {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      cFREntityOperationEntityId
    }
  }
`;
export const deleteCFREntityOperation = /* GraphQL */ `
  mutation DeleteCFREntityOperation(
    $input: DeleteCFREntityOperationInput!
    $condition: ModelCFREntityOperationConditionInput
  ) {
    deleteCFREntityOperation(input: $input, condition: $condition) {
      id
      name
      entity {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      cFREntityOperationEntityId
    }
  }
`;
export const createCFRRoleEntity = /* GraphQL */ `
  mutation CreateCFRRoleEntity(
    $input: CreateCFRRoleEntityInput!
    $condition: ModelCFRRoleEntityConditionInput
  ) {
    createCFRRoleEntity(input: $input, condition: $condition) {
      id
      entity {
        id
        name
        createdAt
        updatedAt
      }
      entityOperation {
        id
        name
        entity {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        cFREntityOperationEntityId
      }
      createdAt
      updatedAt
      cFRRoleCfrRoleEntitiesId
      cFRRoleEntityEntityId
      cFRRoleEntityEntityOperationId
    }
  }
`;
export const updateCFRRoleEntity = /* GraphQL */ `
  mutation UpdateCFRRoleEntity(
    $input: UpdateCFRRoleEntityInput!
    $condition: ModelCFRRoleEntityConditionInput
  ) {
    updateCFRRoleEntity(input: $input, condition: $condition) {
      id
      entity {
        id
        name
        createdAt
        updatedAt
      }
      entityOperation {
        id
        name
        entity {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        cFREntityOperationEntityId
      }
      createdAt
      updatedAt
      cFRRoleCfrRoleEntitiesId
      cFRRoleEntityEntityId
      cFRRoleEntityEntityOperationId
    }
  }
`;
export const deleteCFRRoleEntity = /* GraphQL */ `
  mutation DeleteCFRRoleEntity(
    $input: DeleteCFRRoleEntityInput!
    $condition: ModelCFRRoleEntityConditionInput
  ) {
    deleteCFRRoleEntity(input: $input, condition: $condition) {
      id
      entity {
        id
        name
        createdAt
        updatedAt
      }
      entityOperation {
        id
        name
        entity {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        cFREntityOperationEntityId
      }
      createdAt
      updatedAt
      cFRRoleCfrRoleEntitiesId
      cFRRoleEntityEntityId
      cFRRoleEntityEntityOperationId
    }
  }
`;
export const createCFRPlant = /* GraphQL */ `
  mutation CreateCFRPlant(
    $input: CreateCFRPlantInput!
    $condition: ModelCFRPlantConditionInput
  ) {
    createCFRPlant(input: $input, condition: $condition) {
      id
      plantCode
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateCFRPlant = /* GraphQL */ `
  mutation UpdateCFRPlant(
    $input: UpdateCFRPlantInput!
    $condition: ModelCFRPlantConditionInput
  ) {
    updateCFRPlant(input: $input, condition: $condition) {
      id
      plantCode
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteCFRPlant = /* GraphQL */ `
  mutation DeleteCFRPlant(
    $input: DeleteCFRPlantInput!
    $condition: ModelCFRPlantConditionInput
  ) {
    deleteCFRPlant(input: $input, condition: $condition) {
      id
      plantCode
      name
      createdAt
      updatedAt
    }
  }
`;
export const createCFRAssetClass = /* GraphQL */ `
  mutation CreateCFRAssetClass(
    $input: CreateCFRAssetClassInput!
    $condition: ModelCFRAssetClassConditionInput
  ) {
    createCFRAssetClass(input: $input, condition: $condition) {
      id
      cwip
      asset
      evgr
      description
      depKey
      revisedUsefullLife
      depRate
      createdAt
      updatedAt
    }
  }
`;
export const updateCFRAssetClass = /* GraphQL */ `
  mutation UpdateCFRAssetClass(
    $input: UpdateCFRAssetClassInput!
    $condition: ModelCFRAssetClassConditionInput
  ) {
    updateCFRAssetClass(input: $input, condition: $condition) {
      id
      cwip
      asset
      evgr
      description
      depKey
      revisedUsefullLife
      depRate
      createdAt
      updatedAt
    }
  }
`;
export const deleteCFRAssetClass = /* GraphQL */ `
  mutation DeleteCFRAssetClass(
    $input: DeleteCFRAssetClassInput!
    $condition: ModelCFRAssetClassConditionInput
  ) {
    deleteCFRAssetClass(input: $input, condition: $condition) {
      id
      cwip
      asset
      evgr
      description
      depKey
      revisedUsefullLife
      depRate
      createdAt
      updatedAt
    }
  }
`;
export const createCFRCostAndProfitCentre = /* GraphQL */ `
  mutation CreateCFRCostAndProfitCentre(
    $input: CreateCFRCostAndProfitCentreInput!
    $condition: ModelCFRCostAndProfitCentreConditionInput
  ) {
    createCFRCostAndProfitCentre(input: $input, condition: $condition) {
      id
      costcenter
      profitcenter
      profitcenterdesc
      departmentId
      createdAt
      updatedAt
    }
  }
`;
export const updateCFRCostAndProfitCentre = /* GraphQL */ `
  mutation UpdateCFRCostAndProfitCentre(
    $input: UpdateCFRCostAndProfitCentreInput!
    $condition: ModelCFRCostAndProfitCentreConditionInput
  ) {
    updateCFRCostAndProfitCentre(input: $input, condition: $condition) {
      id
      costcenter
      profitcenter
      profitcenterdesc
      departmentId
      createdAt
      updatedAt
    }
  }
`;
export const deleteCFRCostAndProfitCentre = /* GraphQL */ `
  mutation DeleteCFRCostAndProfitCentre(
    $input: DeleteCFRCostAndProfitCentreInput!
    $condition: ModelCFRCostAndProfitCentreConditionInput
  ) {
    deleteCFRCostAndProfitCentre(input: $input, condition: $condition) {
      id
      costcenter
      profitcenter
      profitcenterdesc
      departmentId
      createdAt
      updatedAt
    }
  }
`;
export const createCFRBudgetReference = /* GraphQL */ `
  mutation CreateCFRBudgetReference(
    $input: CreateCFRBudgetReferenceInput!
    $condition: ModelCFRBudgetReferenceConditionInput
  ) {
    createCFRBudgetReference(input: $input, condition: $condition) {
      id
      departmentId
      departmentName
      budgetReferenceNo
      projectDescription
      totalAmount
      spendAmount
      remainingAmount
      lockedAmount
      year
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateCFRBudgetReference = /* GraphQL */ `
  mutation UpdateCFRBudgetReference(
    $input: UpdateCFRBudgetReferenceInput!
    $condition: ModelCFRBudgetReferenceConditionInput
  ) {
    updateCFRBudgetReference(input: $input, condition: $condition) {
      id
      departmentId
      departmentName
      budgetReferenceNo
      projectDescription
      totalAmount
      spendAmount
      remainingAmount
      lockedAmount
      year
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteCFRBudgetReference = /* GraphQL */ `
  mutation DeleteCFRBudgetReference(
    $input: DeleteCFRBudgetReferenceInput!
    $condition: ModelCFRBudgetReferenceConditionInput
  ) {
    deleteCFRBudgetReference(input: $input, condition: $condition) {
      id
      departmentId
      departmentName
      budgetReferenceNo
      projectDescription
      totalAmount
      spendAmount
      remainingAmount
      lockedAmount
      year
      status
      createdAt
      updatedAt
    }
  }
`;
export const createCFRUserNotifications = /* GraphQL */ `
  mutation CreateCFRUserNotifications(
    $input: CreateCFRUserNotificationsInput!
    $condition: ModelCFRUserNotificationsConditionInput
  ) {
    createCFRUserNotifications(input: $input, condition: $condition) {
      id
      user {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          nextToken
        }
        role {
          id
          name
          details
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        isDeleted
        createdAt
        updatedAt
        cFRUserRoleId
      }
      userActivation
      userEnabled
      userDeactivation
      cFRCreation
      ioRequest
      ioGenerated
      cFRCreatorChanged
      projectSponsorApproval
      procurementHeadApproval
      functionalHeadApproval
      financeHeadApproval
      createdAt
      updatedAt
      cFRUserNotificationsUserId
    }
  }
`;
export const updateCFRUserNotifications = /* GraphQL */ `
  mutation UpdateCFRUserNotifications(
    $input: UpdateCFRUserNotificationsInput!
    $condition: ModelCFRUserNotificationsConditionInput
  ) {
    updateCFRUserNotifications(input: $input, condition: $condition) {
      id
      user {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          nextToken
        }
        role {
          id
          name
          details
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        isDeleted
        createdAt
        updatedAt
        cFRUserRoleId
      }
      userActivation
      userEnabled
      userDeactivation
      cFRCreation
      ioRequest
      ioGenerated
      cFRCreatorChanged
      projectSponsorApproval
      procurementHeadApproval
      functionalHeadApproval
      financeHeadApproval
      createdAt
      updatedAt
      cFRUserNotificationsUserId
    }
  }
`;
export const deleteCFRUserNotifications = /* GraphQL */ `
  mutation DeleteCFRUserNotifications(
    $input: DeleteCFRUserNotificationsInput!
    $condition: ModelCFRUserNotificationsConditionInput
  ) {
    deleteCFRUserNotifications(input: $input, condition: $condition) {
      id
      user {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          nextToken
        }
        role {
          id
          name
          details
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        isDeleted
        createdAt
        updatedAt
        cFRUserRoleId
      }
      userActivation
      userEnabled
      userDeactivation
      cFRCreation
      ioRequest
      ioGenerated
      cFRCreatorChanged
      projectSponsorApproval
      procurementHeadApproval
      functionalHeadApproval
      financeHeadApproval
      createdAt
      updatedAt
      cFRUserNotificationsUserId
    }
  }
`;
export const createCFRMultipleBudgetReference = /* GraphQL */ `
  mutation CreateCFRMultipleBudgetReference(
    $input: CreateCFRMultipleBudgetReferenceInput!
    $condition: ModelCFRMultipleBudgetReferenceConditionInput
  ) {
    createCFRMultipleBudgetReference(input: $input, condition: $condition) {
      id
      budgetReferenceNo
      description
      remainingAmount
      proposedAmount
      createdAt
      updatedAt
      cFRCapitalFundRequestBudgetReferenceNoId
    }
  }
`;
export const updateCFRMultipleBudgetReference = /* GraphQL */ `
  mutation UpdateCFRMultipleBudgetReference(
    $input: UpdateCFRMultipleBudgetReferenceInput!
    $condition: ModelCFRMultipleBudgetReferenceConditionInput
  ) {
    updateCFRMultipleBudgetReference(input: $input, condition: $condition) {
      id
      budgetReferenceNo
      description
      remainingAmount
      proposedAmount
      createdAt
      updatedAt
      cFRCapitalFundRequestBudgetReferenceNoId
    }
  }
`;
export const deleteCFRMultipleBudgetReference = /* GraphQL */ `
  mutation DeleteCFRMultipleBudgetReference(
    $input: DeleteCFRMultipleBudgetReferenceInput!
    $condition: ModelCFRMultipleBudgetReferenceConditionInput
  ) {
    deleteCFRMultipleBudgetReference(input: $input, condition: $condition) {
      id
      budgetReferenceNo
      description
      remainingAmount
      proposedAmount
      createdAt
      updatedAt
      cFRCapitalFundRequestBudgetReferenceNoId
    }
  }
`;
export const createCFRRiskAndMitigation = /* GraphQL */ `
  mutation CreateCFRRiskAndMitigation(
    $input: CreateCFRRiskAndMitigationInput!
    $condition: ModelCFRRiskAndMitigationConditionInput
  ) {
    createCFRRiskAndMitigation(input: $input, condition: $condition) {
      id
      risk
      mitigation
      category
      occurrence
      consequence
      owner
      isDeleted
      createdAt
      updatedAt
      cFRCapitalFundRequestRiskAndMitigationId
    }
  }
`;
export const updateCFRRiskAndMitigation = /* GraphQL */ `
  mutation UpdateCFRRiskAndMitigation(
    $input: UpdateCFRRiskAndMitigationInput!
    $condition: ModelCFRRiskAndMitigationConditionInput
  ) {
    updateCFRRiskAndMitigation(input: $input, condition: $condition) {
      id
      risk
      mitigation
      category
      occurrence
      consequence
      owner
      isDeleted
      createdAt
      updatedAt
      cFRCapitalFundRequestRiskAndMitigationId
    }
  }
`;
export const deleteCFRRiskAndMitigation = /* GraphQL */ `
  mutation DeleteCFRRiskAndMitigation(
    $input: DeleteCFRRiskAndMitigationInput!
    $condition: ModelCFRRiskAndMitigationConditionInput
  ) {
    deleteCFRRiskAndMitigation(input: $input, condition: $condition) {
      id
      risk
      mitigation
      category
      occurrence
      consequence
      owner
      isDeleted
      createdAt
      updatedAt
      cFRCapitalFundRequestRiskAndMitigationId
    }
  }
`;
export const createCFRTimelineActivity = /* GraphQL */ `
  mutation CreateCFRTimelineActivity(
    $input: CreateCFRTimelineActivityInput!
    $condition: ModelCFRTimelineActivityConditionInput
  ) {
    createCFRTimelineActivity(input: $input, condition: $condition) {
      id
      activityName
      activityDate
      createdAt
      updatedAt
      cFRCapitalFundRequestTimelineActivitiesId
    }
  }
`;
export const updateCFRTimelineActivity = /* GraphQL */ `
  mutation UpdateCFRTimelineActivity(
    $input: UpdateCFRTimelineActivityInput!
    $condition: ModelCFRTimelineActivityConditionInput
  ) {
    updateCFRTimelineActivity(input: $input, condition: $condition) {
      id
      activityName
      activityDate
      createdAt
      updatedAt
      cFRCapitalFundRequestTimelineActivitiesId
    }
  }
`;
export const deleteCFRTimelineActivity = /* GraphQL */ `
  mutation DeleteCFRTimelineActivity(
    $input: DeleteCFRTimelineActivityInput!
    $condition: ModelCFRTimelineActivityConditionInput
  ) {
    deleteCFRTimelineActivity(input: $input, condition: $condition) {
      id
      activityName
      activityDate
      createdAt
      updatedAt
      cFRCapitalFundRequestTimelineActivitiesId
    }
  }
`;
export const createCFRUserDepartmentMapping = /* GraphQL */ `
  mutation CreateCFRUserDepartmentMapping(
    $input: CreateCFRUserDepartmentMappingInput!
    $condition: ModelCFRUserDepartmentMappingConditionInput
  ) {
    createCFRUserDepartmentMapping(input: $input, condition: $condition) {
      id
      cFRUserID
      cFRDepartmentID
      cFRUser {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          nextToken
        }
        role {
          id
          name
          details
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        isDeleted
        createdAt
        updatedAt
        cFRUserRoleId
      }
      cFRDepartment {
        id
        name
        details
        isdeleted
        isForGlobalUser
        financeBudgtedUsers
        financeUnBudgtedUsers
        procurementBudgtedUsers
        procurementUnBudgtedUsers
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCFRUserDepartmentMapping = /* GraphQL */ `
  mutation UpdateCFRUserDepartmentMapping(
    $input: UpdateCFRUserDepartmentMappingInput!
    $condition: ModelCFRUserDepartmentMappingConditionInput
  ) {
    updateCFRUserDepartmentMapping(input: $input, condition: $condition) {
      id
      cFRUserID
      cFRDepartmentID
      cFRUser {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          nextToken
        }
        role {
          id
          name
          details
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        isDeleted
        createdAt
        updatedAt
        cFRUserRoleId
      }
      cFRDepartment {
        id
        name
        details
        isdeleted
        isForGlobalUser
        financeBudgtedUsers
        financeUnBudgtedUsers
        procurementBudgtedUsers
        procurementUnBudgtedUsers
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCFRUserDepartmentMapping = /* GraphQL */ `
  mutation DeleteCFRUserDepartmentMapping(
    $input: DeleteCFRUserDepartmentMappingInput!
    $condition: ModelCFRUserDepartmentMappingConditionInput
  ) {
    deleteCFRUserDepartmentMapping(input: $input, condition: $condition) {
      id
      cFRUserID
      cFRDepartmentID
      cFRUser {
        id
        email
        name
        limit
        toLimit
        currencySymbol
        departmentadmin
        headoffunction
        department {
          nextToken
        }
        role {
          id
          name
          details
          createdAt
          updatedAt
        }
        isActive
        isGlobalUser
        canApproveBudgetedCFR
        canApproveUnBudgetedCFR
        isDeleted
        createdAt
        updatedAt
        cFRUserRoleId
      }
      cFRDepartment {
        id
        name
        details
        isdeleted
        isForGlobalUser
        financeBudgtedUsers
        financeUnBudgtedUsers
        procurementBudgtedUsers
        procurementUnBudgtedUsers
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
