import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import PageLoader from "../../common/loader";
import MobileCFRList from "./mcfrList";
import { API, graphqlOperation } from "aws-amplify";
import { listCFRCapitalFundRequests } from "../../graphql/customQueries";
import { updateCFRCapitalFundRequest } from "../../graphql/mutations";
import { listCFREmailTemplates, sendEmail } from "../../graphql/queries";
import { isMobile, getLoggedInUser } from "../../Utils/common";
import MaterialTable from "material-table";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutlined";
import { Entity, EntityOperation, AppConstants } from "../../common/constants";

function CFRManagement(props) {
  const alert = useAlert();
  const [loggedInUser, setloggedInUser] = useState(null);
  const [isCreator, setIsCreator] = useState(true);
  const [isApprover, setIsApprover] = useState(true);
  const [cfrData, setCFRData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState({});
  const { register, handleSubmit, setValue } = useForm();
  const [selectedRow, setSelectedRow] = useState(null);
  const [isCreatePermission, setIsCreatePermission] = useState(false);
  const [isUpdatePermission, setIsUpdatePermission] = useState(false);
  const [isDeletePermission, setIsDeletePermission] = useState(false);
  const [isApprovePermission, setIsApprovePermission] = useState(false);

  let ShowFilterBox = false;
  let today = new Date();
  let toDate = new Date().toISOString();
  let lastweekdate = new Date();
  lastweekdate.setDate(today.getDate() - 7);
  let fromDate = lastweekdate.toISOString();

  // this function for get cfr data
  const getCFRList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/cfr/cfr.js\n Function Name: getCFRList";
    try {
      let searchParameter = {
        isDeleted: { eq: false },
      };
      if (filterData.projecttitle) {
        searchParameter.projecttitle = { contains: filterData.projecttitle };
      }
      if (filterData.budgetReferenceNo) {
        searchParameter.budgetReferenceNo = {
          contains: filterData.budgetReferenceNo,
        };
      }
      if (filterData.cfrstatus) {
        searchParameter.cfrstatus = { eq: filterData.cfrstatus };
      }
      //searchParameter.sortDirection = "DESC";
      let dateArray = [];
      if (filterData.fromDate) {
        dateArray.push(new Date(filterData.fromDate));
      } else {
        setValue("fromDate", fromDate.split("T")[0]);
        dateArray.push(new Date(fromDate));
      }
      if (filterData.toDate) {
        const td = new Date(filterData.toDate).getDate();
        const tm = new Date(filterData.toDate).getMonth();
        const ty = new Date(filterData.toDate).getFullYear();
        const currentDate = new Date(ty, tm, td, 23, 59, 59);
        dateArray.push(new Date(currentDate));
      } else {
        setValue("toDate", toDate.split("T")[0]);
        dateArray.push(new Date(toDate));
      }
      searchParameter.cfrsubmissiondate = {
        between: dateArray,
      };
      setIsLoading(true);
      const cfrData = await API.graphql(
        graphqlOperation(listCFRCapitalFundRequests, {
          filter: searchParameter, limit: 1000,
        })
      );
      if (cfrData) {
        const cfrList = cfrData.data.listCFRCapitalFundRequests.items;
        console.log(cfrList);
        setCFRData([]);
        setIsLoading(false);
        //totalItems = parseInt(response.headers.recordcount);
        //setTotalPageCount(totalItems);
        let pendingApprovalList = [];
        const loggedInUser = getLoggedInUser();
        if (loggedInUser) {
          if (loggedInUser.role) {
            if (loggedInUser.role.cfrRoleEntities) {
              if (loggedInUser.role.cfrRoleEntities.items.length > 0) {
                let approveArr = loggedInUser.role.cfrRoleEntities.items.filter(
                  (privilege) =>
                    privilege.entity.name == Entity.CFR &&
                    privilege.entityOperation.name == EntityOperation.Approve
                );
                if (approveArr.length > 0) {
                  cfrList.forEach((cfr) => {
                    if (cfr.cfrApprovals) {
                      if (cfr.cfrApprovals.items.length > 0) {
                        //order list by seq number
                        cfr.cfrApprovals.items.sort((a, b) => {
                          if (a.approvalLevel) {
                            if (b.approvalLevel) {
                              return (
                                a.approvalLevel.sequencenumber -
                                b.approvalLevel.sequencenumber
                              );
                            }
                          }
                        });
                        var isLoggedinUserApprover = false;
                        cfr.cfrApprovals.items.forEach((cfrApproval) => {
                          if (cfrApproval.expectedCfrApprovers) {
                            if (cfrApproval.expectedCfrApprovers.length > 0) {
                              cfrApproval.expectedCfrApprovers.forEach(
                                (approver) => {
                                  if (approver == loggedInUser.id) {
                                    isLoggedinUserApprover = true;
                                  }
                                }
                              );
                            }
                          }
                        });
                      }
                    }
                    if (isLoggedinUserApprover) {
                      pendingApprovalList.push(cfr);
                    }
                  });
                }
                let createArr = loggedInUser.role.cfrRoleEntities.items.filter(
                  (privilege) =>
                    privilege.entity.name == Entity.CFR &&
                    privilege.entityOperation.name == EntityOperation.Create
                );
                if (createArr.length > 0) {
                  cfrList.forEach((cfr) => {
                    // if (
                    //   cfr.cfrstatus == "draft" ||
                    //   cfr.cfrstatus == "submitted" ||
                    //   cfr.cfrstatus == "approvalinprocess" ||
                    //   cfr.cfrstatus == "needammendment" ||
                    //   cfr.cfrstatus == "ammendmentinprocess" ||
                    //   cfr.cfrstatus == "ammended" ||
                    //   cfr.cfrstatus == "rejected" ||
                    //   cfr.cfrstatus == "approved"
                    // ) {
                    if (cfr.cfrCreator) {
                      if (cfr.cfrCreator.id == loggedInUser.id) {
                        pendingApprovalList.push(cfr);
                      }
                    }
                    //}
                  });
                }
              }
            }
            pendingApprovalList.sort((a, b) => {
              return (
                new Date(b.cfrsubmissiondate).getTime() -
                new Date(a.cfrsubmissiondate).getTime()
              );
            });
            console.log(pendingApprovalList);
            setCFRData(pendingApprovalList);
          }
        }
      } else {
        if (cfrData.errors) {
          if (cfrData.errors.length > 0) {
            alert.show(cfrData.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  });

  // this function for open filter panel
  const showFilter = () => {
    setFilter(true);
  };

  // this function for close filter panel
  const hideFilter = () => {
    setFilter(false);
  };

  //this function for filterd the record
  const FilterRecords = (filterData) => {
    setFilterData(filterData);
    setFilter(false);
  };

  // function to reset grid.
  const resetFilters = () => {
    const fields = ["projecttitle", "proposalvalue", "cfrstatus"];
    setFilterData({});
    fields.forEach((field) => setValue(field, ""));
    setValue("cfrstatus", "");
    setValue("fromDate", fromDate);
    setValue("toDate", toDate);
    getCFRList();
    setFilter(false);
  };

  const HandleAddClick = () => {
    props.history.push("/cfr/add");
  };

  //this function for redirect in view page
  const viewCFR = (cfrObj, id) => {
    props.history.push({
      pathname: "/cfr/view/" + id,
      state: cfrObj,
    });
  };

  // click on update button and redirect to update page
  const editCFR = (cfrObj, id) => {
    let path = "/cfr/update/" + id;
    if (cfrObj.version.indexOf("CR") > -1) {
      path = "/cfr/updateChangeCfrRequest/" + id;
    }
    props.history.push({
      pathname: path,
      state: cfrObj,
    });
  };

  // this function is responsible for open confirmation message box
  const deleteCFR = (cfrObj, id) => {
    alert.show("Are you sure, you want to delete a CFR ?", {
      title: "Confirmation",
      closeCopy: "Cancel",
      actions: [
        {
          copy: "Yes",
          onClick: () => deleteCFRRecord(cfrObj, id),
        },
      ],
    });
  };
  // delete  user recored once click on confirm
  const deleteCFRRecord = async (cfrObj, id) => {
    const errorLocation =
      "Path Name: components/cfr/cfr.js\n Function Name: deleteCFRRecord";
    try {
      const deleteObj = {
        id: id,
        isDeleted: true,
      };
      setIsLoading(true);
      const deletedCFR = await API.graphql(
        graphqlOperation(updateCFRCapitalFundRequest, { input: deleteObj })
      );
      setIsLoading(false);
      if (deletedCFR.data) {
        if (deletedCFR.data.updateCFRCapitalFundRequest.masterCFRID) {
          if (
            !deletedCFR.data.updateCFRCapitalFundRequest.version.includes("CR")
          ) {
            updateMasterCFR(
              deletedCFR.data.updateCFRCapitalFundRequest.masterCFRID
            );
          } else {
            alert.show("CFR record is deleted.");
            getCFRList();
          }
        } else {
          alert.show("CFR record is deleted.");
          getCFRList();
        }
      } else {
        if (deletedCFR.errors) {
          if (deletedCFR.errors.length > 0) {
            alert.show(deletedCFR.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  };

  /// this function for Create and update cfr record
  async function updateMasterCFR(id) {
    const errorLocation =
      "Path Name: components/cfr/cfr.js\n Function Name: deleteCFRRecord";
    const cfrdata = {
      id: id,
      cfrstatus: "needammendment",
    };
    console.log(cfrdata);
    try {
      setIsLoading(true);
      console.log("update master cfr");
      const result = await API.graphql(
        graphqlOperation(updateCFRCapitalFundRequest, { input: cfrdata })
      );
      setIsLoading(false);
      if (result.data) {
        console.log("update master cfr success");
        alert.show("CFR record is deleted.");
        getCFRList();
      } else if (result.errors) {
        if (result.errors.length > 0) {
          alert.show(result.errors[0].message);
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  }

  // call user list on page load
  useEffect(() => {
    let loggedInUser = getLoggedInUser();
    setloggedInUser(loggedInUser);
    if (loggedInUser) {
      getCFRList();
      if (loggedInUser.role.cfrRoleEntities) {
        if (loggedInUser.role.cfrRoleEntities.items.length > 0) {
          let configCreateArr = loggedInUser.role.cfrRoleEntities.items.filter(
            (privilege) =>
              privilege.entity.name == Entity.CFR &&
              privilege.entityOperation.name == EntityOperation.Create
          );
          if (configCreateArr.length > 0) {
            setIsCreatePermission(true);
            setIsCreator(true);
          } else {
            setIsCreatePermission(false);
            setIsCreator(false);
          }
          let configUpdateArr = loggedInUser.role.cfrRoleEntities.items.filter(
            (privilege) =>
              privilege.entity.name == Entity.CFR &&
              privilege.entityOperation.name == EntityOperation.Update
          );
          if (configUpdateArr.length > 0) {
            setIsUpdatePermission(true);
          } else {
            setIsUpdatePermission(false);
          }
          let configDeleteArr = loggedInUser.role.cfrRoleEntities.items.filter(
            (privilege) =>
              privilege.entity.name == Entity.CFR &&
              privilege.entityOperation.name == EntityOperation.Delete
          );
          if (configDeleteArr.length > 0) {
            setIsDeletePermission(true);
          } else {
            setIsDeletePermission(false);
          }
          let configApproveArr = loggedInUser.role.cfrRoleEntities.items.filter(
            (privilege) =>
              privilege.entity.name == Entity.CFR &&
              privilege.entityOperation.name == EntityOperation.Approve
          );
          if (configApproveArr.length > 0) {
            setIsApprovePermission(true);
            setIsApprover(true);
          } else {
            setIsApprovePermission(false);
            setIsApprover(false);
          }
        }
      }
    }
  }, [filterData]);

  return (
    <div className="page-container">
      {isLoading ? <PageLoader></PageLoader> : ""}
      <div className="page-header">
        <div className="row">
          <div className="col-sm-8 col-8">
            <h4>
              CFR List
              <i className="fa fa-filter btn-filter" onClick={showFilter}></i>
            </h4>
          </div>

          <div className="col-sm-4 col-4">
            {isCreator == true && isCreatePermission == true && (
              <button
                type="button"
                className="btn-grid fa fa-plus"
                title="Add CFR"
                onClick={HandleAddClick}
              ></button>
            )}
          </div>
        </div>
      </div>
      <div className="d-none d-sm-block">
        <MaterialTable
          data={cfrData}
          columns={[
            { title: "Project Title", field: "projecttitle" },
            { title: "Proposed Value", field: "proposalvalue" },
            { title: "Version", field: "version" },
            {
              title: "Submitted On",
              field: "cfrsubmissiondate",
              type: "datetime",
            },
            {
              title: "Status",
              field: "cfrstatus",
              lookup: {
                submitted: "Submitted",
                draft: "Draft",
                approvalinprocess: "Approval in process",
                approved: "Approved",
                rejected: "Rejected",
                needammendment: "Need Ammendment",
                ammendmentinprocess: "Ammendment in process",
                ammended: "Ammended",
              },
            },
          ]}
          parentChildData={(row, rows) =>
            rows.find((a) => a.id === row.masterCFRID)
          }
          options={{
            toolbar: false,
            selection: false,
            showTitle: false,
            showTextRowsSelected: false,
            showSelectAllCheckbox: false,
            search: false,
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#1468b2",
              color: "#FFF",
              fontWeight: "600",
            },
            rowStyle: (rowData) => ({
              backgroundColor:
                selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
            }),
          }}
          onRowClick={(evt, selectedRow) =>
            setSelectedRow(selectedRow.tableData.id)
          }
          actions={[
            {
              icon: () => (
                <DescriptionIcon
                  fontSize="small"
                  sx={{
                    color: "text.secondary",
                    //color: "rgba(0, 0, 0, 0.5)",
                  }}
                />
              ),
              tooltip: "View CFR",
              onClick: (event, rowData) => {
                viewCFR(rowData, rowData.id);
              },
            },
            (rowData) => ({
              icon: "edit_outline",
              iconProps: {
                fontSize: "small",
                color: "rgba(0, 0, 0, 0.5)",
                // sx: {
                //   fontSize: "14",
                //   color: "text.secondary",
                //   //color: "rgba(0, 0, 0, 0.5)",
                // },
              },
              tooltip: "Edit CFR",
              onClick: (event, rowData) => {
                editCFR(rowData, rowData.id);
              },
              hidden:
                isCreator == false ||
                isUpdatePermission == false ||
                rowData.cfrstatus == "submitted" ||
                rowData.cfrstatus == "approvalinprocess" ||
                rowData.cfrstatus == "approved" ||
                rowData.cfrstatus == "rejected" ||
                rowData.cfrstatus == "ammendmentinprocess" ||
                rowData.cfrstatus == "ammended",
            }),
            (rowData) => ({
              icon: "delete_outline",
              tooltip: "Delete CFR",
              iconProps: {
                fontSize: "small",
                sx: {
                  fontSize: "14",
                  color: "text.secondary",
                },
              },
              onClick: (event, rowData) => {
                deleteCFR(rowData, rowData.id);
              },
              hidden:
                isCreator == false ||
                rowData.cfrstatus != "draft" ||
                isDeletePermission == false,
            }),
          ]}
        />
      </div>
      {/* <div className="card d-none d-sm-block">
        <div className="card-body">
          <div className="row form-group">
            <div className="col-sm-12">
              <CFRList
                cfrData={cfrData}
                isCreator={isCreator}
                isApprover={isApprover}
                actionView={viewCFR}
                actiondelete={deleteCFR}
                actionUpdate={editCFR}
              ></CFRList>
            </div>
          </div>
        </div>
      </div> */}

      {/* <MobileCFRList
        cfrData={cfrData}
        isCreator={isCreator}
        isApprover={isApprover}
        actionView={viewCFR}
        actiondelete={deleteCFR}
        actionUpdate={editCFR}
        isCreatePermission={isCreatePermission}
        isUpdatePermission={isUpdatePermission}
        isDeletePermission={isDeletePermission}
      /> */}
      <form>
        <div
          className={
            isFilter ? "filter-container show-filter" : "filter-container"
          }
        >
          <div className="filter-head">
            <label className="label-heading"> Apply Filter </label>
          </div>
          <div className="filter-body">
            <div className="row mb-3">
              <div className="col-sm-12">
                <label className="form-label"> Project Title </label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="ProjectTitle"
                    {...register("projecttitle")}
                  ></input>
                </div>
              </div>
            </div>
            {/* <div className="row mb-3">
              <div className="col-sm-12">
                <label className="form-label"> Budget Ref. No. </label>
                <input
                  type="text"
                  className="form-control"
                  name="BudgetRefNo"
                  {...register("budgetReferenceNo")}
                ></input>
              </div>
            </div> */}
            <div className="row mb-3">
              <div className="col-sm-12">
                <label className="form-label"> Status </label>
                <select
                  className="ddl-control"
                  name="Status"
                  {...register("cfrstatus")}
                >
                  <option value=""> --- Please select --- </option>
                  <option value="draft"> Draft </option>
                  <option value="submitted"> Submitted </option>
                  <option value="approvalinprocess">Approval in process</option>
                  <option value="approved"> Approved </option>
                  <option value="rejected"> Rejected </option>
                  <option value="needammendment"> Need ammendment </option>
                  <option value="ammendmentinprocess">
                    Ammendment in process
                  </option>
                  <option value="ammended"> Ammended </option>
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-12">
                <label className="form-label"> From Date </label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="dd-mm-yyyy"
                  name="fromDate"
                  {...register("fromDate")}
                ></input>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-12">
                <label className="form-label"> To Date </label>
                <input
                  type="date"
                  className="form-control"
                  name="toDate"
                  {...register("toDate")}
                ></input>
              </div>
            </div>
          </div>
          <div className="filter-action">
            <div
              className="filter-option br-btn"
              onClick={handleSubmit(FilterRecords)}
            >
              <span className="icon-tick"> </span>
            </div>
            <div className="filter-option br-btn" onClick={resetFilters}>
              <span className="icon-refresh"> </span>
            </div>
            <div className="filter-option br-btn" onClick={hideFilter}>
              <span className="icon-cross"> </span>
            </div>
          </div>
        </div>
        <div
          className={isFilter ? "filteroverlay show" : "filteroverlay"}
        ></div>
      </form>
    </div>
  );
}
export default CFRManagement;
