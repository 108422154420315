function MobileCFRList(props) {
  return (
    <div>
      {props.cfrData.length > 0 &&
        props.cfrData.map((cfr) => (
          <div className="card d-block d-sm-none mb-3" key={cfr.id}>
            <div className="card-body">
              <table className="table table-cust ">
                <tbody>
                  <tr>
                    <td className="width40">
                      <label className="label-heading"> Project Title</label>
                    </td>
                    <td className="width60">{cfr.projecttitle}</td>
                  </tr>
                  <tr>
                    <td className="width40">
                      <label className="label-heading"> Budget Ref. No.</label>
                    </td>
                    <td className="width60"> {cfr.budgetReferenceNo} </td>
                  </tr>
                  <tr>
                    <td className="width40">
                      <label className="label-heading"> Budget Value</label>
                    </td>
                    <td className="width60"> {cfr.budgetvalue} </td>
                  </tr>
                  <tr>
                    <td className="width40">
                      <label className="label-heading"> Proposed Value</label>
                    </td>
                    <td className="width60"> {cfr.proposalvalue} </td>
                  </tr>
                  <tr>
                    <td className="width40">
                      <label className="label-heading"> Submitted On</label>
                    </td>
                    <td className="width60">{cfr.cfrsubmissiondate} </td>
                  </tr>
                  <tr>
                    <td className="width40">
                      <label className="label-heading"> Status</label>
                    </td>
                    <td className="width60">
                      {cfr.cfrstatus == "submitted"
                        ? "Submitted"
                        : cfr.cfrstatus == "draft"
                        ? "Draft"
                        : cfr.cfrstatus == "approvalinprocess"
                        ? "Approval in process"
                        : cfr.cfrstatus == "approved"
                        ? "Approved"
                        : cfr.cfrstatus == "rejected"
                        ? "Rejected"
                        : cfr.cfrstatus == "needammendment"
                        ? "Need Ammendment"
                        : cfr.cfrstatus == "ammendmentinprocess"
                        ? "Ammendment in process"
                        : cfr.cfrstatus == "ammended"
                        ? "Ammended"
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="width100 text-center" colSpan="2">
                      <div className="button-group">
                        {props.isCreator &&
                          cfr.cfrstatus == "draft" &&
                          props.isDeletePermission == true && (
                            <button
                              type="button"
                              className="btn-grid fa fa-trash"
                              onClick={() => {
                                props.actiondelete(cfr, cfr.id);
                              }}
                            ></button>
                          )}
                        {props.isCreator &&
                          (cfr.cfrstatus == "draft" ||
                            cfr.cfrstatus == "needammendment") &&
                          props.isUpdatePermission == true && (
                            <button
                              type="button"
                              className="btn-grid fa fa-pencil"
                              onClick={() => {
                                props.actionUpdate(cfr, cfr.id);
                              }}
                            ></button>
                          )}
                        <button
                          type="button"
                          className="btn-grid fa fa-file-text-o"
                          onClick={() => {
                            props.actionView(cfr, cfr.id);
                          }}
                        ></button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ))}
      {props.cfrData.length == 0 && (
        <div className="mobile-msg d-block d-sm-none">No record found</div>
      )}
    </div>
  );
}
export default MobileCFRList;
