import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useAlert } from "react-alert";
import { API, graphqlOperation } from "aws-amplify";
import {
  listCFRUsers,
  listCFRCapitalFundRequests,
  sendEmail,
  listCFREmailTemplates,
} from "../../graphql/queries";
import { updateCFRCapitalFundRequest } from "../../graphql/mutations";
import { Card } from "react-bootstrap";
import { getLoggedInUser } from "../../Utils/common";
import { Roles, AppConstants } from "../../common/constants";
import awsconfig from "../../awsconfig.json";

const CfrCreator = () => {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [cfrData, setCfrData] = useState([]);
  const [assignUserId, setAssignUserId] = useState("");
  const [env, setEnv] = useState("");
  const [cfrCreatorData, setCfrCreatorData] = useState([]);

  const getUserList = useCallback(async () => {
    debugger;
    const errorLocation =
      "Path Name: components/config/cfrCreator.js\n Function Name: getUserList";
    try {
      setIsLoading(true);
      const response = await API.graphql(graphqlOperation(listCFRUsers));
      if (response) {
        setUserData([]);
        let _lstUser = response.data.listCFRUsers.items;
        const loggedInUser = getLoggedInUser();
        if (
          loggedInUser.departmentadmin === true &&
          loggedInUser.role.name !== Roles.SUPER_ADMIN
        ) {
          let deptUsers = [];
          _lstUser.forEach((user) => {
            if (user.department) {
              if (user.department.items) {
                user.department.items.forEach((userdept1) => {
                  if (loggedInUser.department) {
                    if (loggedInUser.department.items) {
                      loggedInUser.department.items.forEach((userdept2) => {
                        if (
                          userdept1.cFRDepartmentID == userdept2.cFRDepartmentID
                        ) {
                          deptUsers.push(user);
                        }
                      });
                    }
                  }
                });
              }
            }
          });
          setUserData(deptUsers);
          const noLoggedInUser = deptUsers.filter(
            (u) => u.name !== loggedInUser.name
          );
          const cfrCreatorUsers = noLoggedInUser.filter(
            (u) => u.role.name === Roles.CFR_CREATOR
          );
          setCfrCreatorData([]);
          setCfrCreatorData(cfrCreatorUsers);
        } else {
          setUserData(_lstUser);
          const noLoggedInUser = _lstUser.filter(
            (u) => u.name !== loggedInUser.name
          );
          setCfrCreatorData([]);
          setCfrCreatorData(noLoggedInUser);
        }
        setIsLoading(false);
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  });

  const getCfrList = useCallback(async (id) => {
    const errorLocation =
      "Path Name: components/config/cfrCreator.js\n Function Name: getCfrList";
    try {
      setIsLoading(true);
      const response = await API.graphql(
        graphqlOperation(listCFRCapitalFundRequests)
      );
      if (response) {
        setCfrData([]);
        const _lstCapitalFundRequests =
          response.data.listCFRCapitalFundRequests.items;
        const filteredCfr = _lstCapitalFundRequests.filter(
          (cfr) => cfr.cfrCreator !== null && cfr.cfrCreator.id === id
        );
        setCfrData(filteredCfr);
        setIsLoading(false);
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  });

  const setEnvironment = () => {
    const enviornment = awsconfig.Auth.redirectSignOut;
    setEnv(enviornment);
  };

  async function updateCfrCreator(data) {
    debugger;
    const errorLocation =
      "Path Name: components/config/cfrCreator.js\n Function Name: updateCfrCreator";
    try {
      const prevId = data.cfrCreator.id;
      delete data.cfrCreator;
      delete data.cfrFiles;
      delete data.cfrApprovals;
      //delete data.cfrResources;
      delete data.createdAt;
      delete data.updatedAt;
      delete data.budgetReferenceNo;
      const newObject = {
        ...data,
        cFRCapitalFundRequestCfrCreatorId: assignUserId,
      };
      setIsLoading(true);
      const result = await API.graphql(
        graphqlOperation(updateCFRCapitalFundRequest, { input: newObject })
      );
      setIsLoading(false);
      if (result.data) {
        getCfrList(prevId);
        const successMessage = "CFR creator updated successfully";
        alert.show(successMessage, {
          type: "success",
        });
        let filter = { action: { eq: "ASSIGN_CFR" } };
        const emailTemplates = await API.graphql(
          graphqlOperation(listCFREmailTemplates, { filter: filter })
        );
        const newUser = userData.filter((u) => u.id === assignUserId);
        const template = emailTemplates.data.listCFREmailTemplates.items[0];
        let body = template.emailcontent;
        let replaceName = body.replace("{Display Name}", newUser[0].name);
        let replaceCFRDetails = replaceName.replace(
          "{CFR Details}",
          result.data.updateCFRCapitalFundRequest.projecttitle
        );

        let finalBody = replaceCFRDetails.replace("{Link}", env);

        const emailTemplate = {
          To: newUser[0].email,
          Subject: template.emailsubject,
          MailBody: finalBody,
        };

        const res = await API.graphql({
          query: sendEmail,
          variables: {
            EmailTemplate: JSON.stringify(emailTemplate),
          },
        });

        console.log(res);
      } else if (result.errors) {
        if (result.errors.length > 0) {
          alert.show(result.errors[0].message);
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  }

  const handleUserChange = (e) => {
    const selectedId = e.target.value;
    getCfrList(selectedId);
  };

  const handleAssignUserChange = (e) => {
    const selectedId = e.target.value;
    console.log(selectedId);
    setAssignUserId("");
    setAssignUserId(selectedId);
  };

  const handleAssignCFRCreator = (item) => {
    console.log(item);
    updateCfrCreator(item);
  };

  useEffect(() => {
    getUserList();
    setEnvironment();
  }, []);

  return (
    <Card>
      <Card.Body>
        <div className="row">
          <div className="col-sm-2 mb-2">
            <label className="form-label">Change CFR Creator</label>
          </div>
          <div className="col-sm-3 mb-3">
            <select className="ddl-control" onChange={handleUserChange}>
              <option defaultValue="select">--- Select User ---</option>
              {userData.map((data) => (
                <option key={data.id} value={data.id}>
                  {data.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 column">
            <table className="table table-cust">
              <thead className="thead-light">
                <tr>
                  <th scope="col">CFR </th>
                  <th scope="col">Users</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {cfrData.map((item, idx) => (
                  <tr key={idx}>
                    <td>{item.projecttitle}</td>
                    <td>
                      <select
                        className="ddl-control"
                        onChange={handleAssignUserChange}
                      >
                        <option defaultValue="select">
                          --- Select User ---
                        </option>
                        {cfrCreatorData.map((data) => (
                          <option key={data.id} value={data.id}>
                            {data.name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => handleAssignCFRCreator(item)}
                      >
                        Assign
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CfrCreator;
