import React from "react";
import { useForm } from "react-hook-form";
import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import * as XLSX from "xlsx";
import { useAlert } from "react-alert";
import PageLoader from "../../common/loader";
import { API, graphqlOperation } from "aws-amplify";
import {
  createCFRConfig,
  updateCFRConfig,
  deleteCFRConfig,
  updateContigency,
  createCFRCurrencyRepo,
  updateCFRCurrencyRepo,
  deleteCFRCurrencyRepo,
  createCFRAssetClass,
  createCFRPlant,
  createCFRDepartment,
  createCFRCostAndProfitCentre,
  createCFRBudgetReference,
  updateCFRBudgetReference,
} from "../../graphql/mutations";
import {
  listCFRConfigs,
  listCFRCurrencyRepos,
  listCFRDepartments,
  listCFREmailTemplates,
  sendEmail,
  listCFRAssetClasses,
  listCFRPlants,
  listCFRCostAndProfitCentres,
  listCFRBudgetReferences,
} from "../../graphql/queries";
import ApprovalLevel from "./approvalLevel";
import CfrCreator from "./cfrCreator";
import countrylist from "../../Utils/countrylist.json";
import { getLoggedInUser } from "../../Utils/common";
import {
  Roles,
  Entity,
  EntityOperation,
  AppConstants,
  TemplateDownloadKeys
} from "../../common/constants";
import { Table, Card, Accordion } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { removeUserSession } from "../../Utils/common";
import { Auth , Storage} from "aws-amplify";
import { BudgetMultiple } from "../../common/constants";

const Config = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [configData, setConfigData] = useState([]);
  const [currencyRepoRateData, setCurrencyRepoRateData] = useState([]);
  const [currencyRateData, setCurrencyRateData] = useState([]);
  const [contigencyValue, setContigencyValue] = useState(0);
  const [updateId, setUpdateId] = useState("");
  const [contigencyId, setContigencyId] = useState("");
  const [departmentData, setDepartmentData] = useState([]);
  const [loggedinUser, setloggedinUser] = useState(null);
  const [isCreatePermission, setIsCreatePermission] = useState(false);
  const [isUpdatePermission, setIsUpdatePermission] = useState(false);
  const [isDeletePermission, setIsDeletePermission] = useState(false);
  const [budgetReferenceNumber, setBudgetReferenceNumber] = useState([]);
  const [filteredBudgetReferenceNumber, setFilteredBudgetReferenceNumber] =
    useState([]);
  const [budgetYears, setBudgetYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [costProfitCenterFiles, setCostProfitCenterFiles] = useState([]);
  const [plantFiles, setPlantFiles] = useState([]);
  const [assetFiles, setAssetFiles] = useState([]);
  const [budgetFiles, setBudgetFiles] = useState([]);
  let history = useHistory();
  const [contigencyBtn, setContigencyBtn] = useState(false);
  const [configBtn, setConfigBtn] = useState(false);
  const [geographyBtn, setGeographyBtn] = useState(false);
  const [deptAdminHidden, setDeptAdminHidden] = useState(false);

  const alert = useAlert();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    reset: reset2,
  } = useForm();

  const selectLogo = (geography) => {
    let logo;
    countrylist.map((data) => {
      if (data.name === geography) {
        logo = data.currencySymbol;
      }
    });
    return logo;
  };

  const handleCatagoryChange = (e) => {
    let key = e.target.value;
    let currency;
    countrylist.map((data) => {
      if (data.name === key) {
        currency = data.currencyCode;
      }
    });
    setValue("currency", currency);
  };

  const getConfigList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/config/config.js\n Function Name: getConfigList";
    try {
      setIsLoading(true);
      const configData = await API.graphql(graphqlOperation(listCFRConfigs));
      if (configData) {
        const configList = configData.data.listCFRConfigs.items;
        setConfigData([]);
        setIsLoading(false);
        setConfigData(configList);
        console.log(configList);
      } else {
        if (configData.errors) {
          if (configData.errors.length > 0) {
            alert.show(configData.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${configData.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  });

  async function onSubmit(data) {
    if (updateId) {
      onUpdate(updateId, data);
    } else {
      onAdd(data);
    }
  }

  async function onAdd(data) {
    const errorLocation =
      "Path Name: components/config/config.js\n Function Name: onAdd";
    try {
      setIsLoading(true);
      console.log("add");
      let logo;
      countrylist.map((list) => {
        if (list.name === data.geography) {
          logo = list.currencySymbol;
        }
      });
      const newData = {
        ...data,
        currencySymbol: logo,
      };
      const result = await API.graphql(
        graphqlOperation(createCFRConfig, { input: newData })
      );
      setIsLoading(false);
      if (result.data) {
        createCurrencyRepoData(result.data);
        setUpdateId("");
        const successMessage = "Config created successfully";
        alert.show(successMessage, {
          type: "success",
        });
        reset();
        getConfigList();
        getCurrencyRepoList();
      } else if (result.errors) {
        if (result.errors.length > 0) {
          alert.show(result.errors[0].message);
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);

          // send error email
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  }

  async function onUpdate(id, data) {
    const errorLocation =
      "Path Name: components/config/config.js\n Function Name: onUpdate";
    try {
      let logo;
      countrylist.map((list) => {
        if (list.name === data.geography) {
          logo = list.currencySymbol;
        }
      });
      const newObject = {
        ...data,
        id: id,
        currencySymbol: logo,
      };
      setIsLoading(true);
      console.log("update");
      const result = await API.graphql(
        graphqlOperation(updateCFRConfig, { input: newObject })
      );
      setIsLoading(false);
      if (result.data) {
        setUpdateId("");
        const successMessage = "Config updated successfully";
        alert.show(successMessage, {
          type: "success",
        });
        reset();
        getConfigList();
      } else if (result.errors) {
        if (result.errors.length > 0) {
          alert.show(result.errors[0].message);
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          // send error email
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  }

  const onUploadAssetFile = async (evt) => {
    setAssetFiles([]);
    const file = evt.target.files[0];
    let files = [];
    files.push(file);
    setAssetFiles(files);
    //handleAssetFile(files[0]);
  };

  const handleAssetFile = (files) => {
    try {
      if (files) {
        if (files.length > 0) {
          const file = files[0];
          const reader = new FileReader();
          reader.onload = async (e) => {
            /* Parse data */
            const ab = e.target.result;
            const wb = XLSX.read(ab, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            console.log(data);
            let existingAssets = [];
            const currentAssetData = await API.graphql(
              graphqlOperation(listCFRAssetClasses, { limit: 1000 })
            );
            if (currentAssetData) {
              if (currentAssetData.data) {
                if (currentAssetData.data.listCFRAssetClasses) {
                  if (currentAssetData.data.listCFRAssetClasses.items) {
                    if (
                      currentAssetData.data.listCFRAssetClasses.items.length > 0
                    ) {
                      existingAssets =
                        currentAssetData.data.listCFRAssetClasses.items;
                    }
                  }
                }
              }
            }
            createAsset(data, existingAssets);
          };
          setIsLoading(true);
          reader.readAsArrayBuffer(file);
        } else {
          const errorMessage = "Please select file.";
          alert.show(errorMessage, {
            type: "error",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onUploadCostAndProfitCentreFile = async (evt) => {
    setCostProfitCenterFiles([]);
    const file = evt.target.files[0];
    let files = [];
    files.push(file);
    setCostProfitCenterFiles(files);
    //handleCostAndProfitCentreFile(files[0]);
  };

  const handleCostAndProfitCentreFile = (files) => {
    try {
      if (files) {
        if (files.length > 0) {
          const file = files[0];
          const reader = new FileReader();
          reader.onload = async (e) => {
            /* Parse data */
            const ab = e.target.result;
            const wb = XLSX.read(ab, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            console.log(data);
            let existingCostProfitCenters = [];
            const currentCostProfitCenterData = await API.graphql(
              graphqlOperation(listCFRCostAndProfitCentres, { limit: 1000 })
            );
            if (currentCostProfitCenterData) {
              if (currentCostProfitCenterData.data) {
                if (
                  currentCostProfitCenterData.data.listCFRCostAndProfitCentres
                ) {
                  if (
                    currentCostProfitCenterData.data.listCFRCostAndProfitCentres
                      .items
                  ) {
                    if (
                      currentCostProfitCenterData.data
                        .listCFRCostAndProfitCentres.items.length > 0
                    ) {
                      existingCostProfitCenters =
                        currentCostProfitCenterData.data
                          .listCFRCostAndProfitCentres.items;
                    }
                  }
                }
              }
            }
            createCostAndProfitCenter(data, existingCostProfitCenters);
          };
          setIsLoading(true);
          reader.readAsArrayBuffer(file);
        } else {
          const errorMessage = "Please select file.";
          alert.show(errorMessage, {
            type: "error",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onUploadPlantFile = async (evt) => {
    setPlantFiles([]);
    const file = evt.target.files[0];
    let files = [];
    files.push(file);
    setPlantFiles(files);
    //handlePlantFile(files[0]);
  };

  const handlePlantFile = (files) => {
    try {
      if (files) {
        if (files.length > 0) {
          const file = files[0];
          const reader = new FileReader();
          reader.onload = async (e) => {
            /* Parse data */
            const ab = e.target.result;
            const wb = XLSX.read(ab, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            let existingPlants = [];
            const currentPlantData = await API.graphql(
              graphqlOperation(listCFRPlants, { limit: 1000 })
            );
            if (currentPlantData) {
              if (currentPlantData.data) {
                if (currentPlantData.data.listCFRPlants) {
                  if (currentPlantData.data.listCFRPlants.items) {
                    if (currentPlantData.data.listCFRPlants.items.length > 0) {
                      existingPlants =
                        currentPlantData.data.listCFRPlants.items;
                    }
                  }
                }
              }
            }
            createPlant(data, existingPlants);
          };
          setIsLoading(true);
          reader.readAsArrayBuffer(file);
        } else {
          const errorMessage = "Please select file.";
          alert.show(errorMessage, {
            type: "error",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function createAsset(data, existingData) {
    const errorLocation =
      "Path Name: components/config/config.js\n Function Name: createAsset";
    try {
      let assetRec = [];
      let newAssetRec = [];
      assetRec = data.slice(1).filter((d) => d.length > 0);
      assetRec.map((item) => {
        const result = existingData.filter((d) => {
          return (
            d.cwip == item[0] &&
            d.asset == item[1] &&
            d.evgr == item[2] &&
            d.description == item[3]
          );
        });
        if (result.length == 0) {
          newAssetRec.push(item);
        }
      });
      const promises = newAssetRec.map(async (a) => {
        //debugger;
        let _cwip;
        let _asset;
        let _evgr;
        let _description;
        let _depKey;
        let _revisedUsefullLife;
        let _depRate;
        _cwip = a[0] && typeof (a[0] === "string") ? a[0] : "";
        _asset = a[1] && typeof (a[1] === "string") ? a[1] : "";
        _evgr = a[2] && typeof (a[2] === "string") ? a[2] : "";
        _description = a[3] && typeof (a[3] === "string") ? a[3] : "";
        _depKey = a[4] && typeof (a[4] === "string") ? a[4] : "";
        _revisedUsefullLife = a[5] && typeof (a[5] === "number") ? a[5] : 0;
        _depRate = a[6] && typeof (a[6] === "number") ? a[6] : 0;
        let newAssetObj = {
          cwip: _cwip,
          asset: _asset,
          evgr: _evgr,
          description: _description,
          depKey: _depKey,
          revisedUsefullLife: _revisedUsefullLife,
          depRate: _depRate,
        };
        const result = await API.graphql(
          graphqlOperation(createCFRAssetClass, { input: newAssetObj })
        );
        if (result.data) {
          const successMessage = "Asset added successfully";
          console.log(successMessage);
          return result.data.createCFRAssetClass;
        } else if (result.errors) {
          if (result.errors.length > 0) {
            alert.show(result.errors[0].message);
          }
        }
      });
      const allresult = await Promise.all(promises);
      if (allresult) {
        setIsLoading(false);
        const successMessage = "Asset list uploaded successfully";
        alert.show(successMessage, {
          type: "success",
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  }

  async function createPlant(data, existingData) {
    try {
      const errorLocation =
        "Path Name: components/config/config.js\n Function Name: createPlant";
      let plantRec = [];
      let newPlantRec = [];
      plantRec = data.slice(1).filter((d) => d.length > 0);
      plantRec.map((item) => {
        const result = existingData.filter((d) => {
          return d.plantCode == item[0] && d.name == item[1];
        });
        if (result.length == 0) {
          newPlantRec.push(item);
        }
      });
      const promises = newPlantRec.map(async (a) => {
        let _plantCode;
        let _name;
        _plantCode = a[0] && typeof (a[0] === "string") ? a[0] : "";
        _name = a[1] && typeof (a[1] === "string") ? a[1] : "";

        let newPlantObj = {
          plantCode: _plantCode,
          name: _name,
        };

        const result = await API.graphql(
          graphqlOperation(createCFRPlant, { input: newPlantObj })
        );
        if (result.data) {
          const successMessage = "Plant added successfully";
          console.log(successMessage);
          return result.data.createCFRPlant;
        } else if (result.errors) {
          if (result.errors.length > 0) {
            alert.show(result.errors[0].message);
            const loggedInUser = getLoggedInUser();
            let filter = { action: { eq: "ERROR_MESSAGE" } };
            const emailTemplates = await API.graphql(
              graphqlOperation(listCFREmailTemplates, { filter: filter })
            );
            const template = emailTemplates.data.listCFREmailTemplates.items[0];
            let body = template.emailcontent;
            let replaceName = body.replace(
              "{Display Name}",
              AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
            );
            let replaceUser = replaceName.replace(
              "{Logged User}",
              loggedInUser.name
            );

            let replaceError = replaceUser.replace(
              "{Error}",
              `Error: ${result.errors[0].message} at ${errorLocation}`
            );
            const emailTemplate = {
              To: AppConstants.ERROR_MAIL_RECEIVER,
              Subject: template.emailsubject,
              MailBody: replaceError,
            };

            const res = await API.graphql({
              query: sendEmail,
              variables: {
                EmailTemplate: JSON.stringify(emailTemplate),
              },
            });

            console.log(res);
          }
        }
      });
      //wait for all CFRApprover to be updated
      const allresult = await Promise.all(promises);
      if (allresult) {
        setIsLoading(false);
        const successMessage = "Plant list uploaded successfully";
        alert.show(successMessage, {
          type: "success",
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  }

  async function createCostAndProfitCenter(data, existingData) {
    const errorLocation =
      "Path Name: components/config/config.js\n Function Name: createCostAndProfitCenter";
    try {
      let deptRec = [];
      let uniqueDeptRec = [];
      let newUniqueDeptRec = [];
      let existingDepts = [];
      data
        .slice(1)
        .filter((d) => d.length > 0)
        .map((a) => {
          deptRec.push(a[0]);
        });
      deptRec.map((item) => {
        if (!uniqueDeptRec.includes(item)) {
          uniqueDeptRec.push(item);
        }
      });

      departmentData.map(async (d) => {
        existingDepts.push(d.name);
      });

      uniqueDeptRec.map((item) => {
        if (!existingDepts.includes(item)) {
          newUniqueDeptRec.push(item);
        }
      });

      const deptpromises = newUniqueDeptRec.map(async (deptRec) => {
        let newDepartmentObj = {
          name: deptRec,
          details: "",
          isdeleted: false,
          isForGlobalUser: false,
        };
        console.log(newDepartmentObj);
        const result = await API.graphql(
          graphqlOperation(createCFRDepartment, { input: newDepartmentObj })
        );
        if (result.data) {
          getDepartmentList();
          return result.data.createCFRDepartment;
        } else if (result.errors) {
          if (result.errors.length > 0) {
            alert.show(result.errors[0].message);
          }
        }
      });
      const alldeptresult = await Promise.all(deptpromises);
    } catch (error) {
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }

    // adding cost/profit center
    try {
      getDepartmentList();
      let costProfitRec = [];
      let newCostProfitRec = [];
      costProfitRec = data.slice(1).filter((d) => d.length > 0);
      costProfitRec.map((item) => {
        const result = existingData.filter((d) => {
          return d.costcenter == item[3] && d.profitcenter == item[1];
        });
        if (result.length == 0) {
          newCostProfitRec.push(item);
        }
      });
      const promises = newCostProfitRec.map((a) => {
        departmentData.map(async (dept) => {
          if (dept.name === a[0]) {
            let _costcenter;
            let _profitcenter;
            let _profitcenterdesc;
            let _departmentId = dept.id;
            _costcenter = a[3] && typeof (a[3] === "string") ? a[3] : "";
            _profitcenter = a[1] && typeof (a[1] === "string") ? a[1] : "";
            _profitcenterdesc = a[2] && typeof (a[2] === "string") ? a[2] : "";

            let newCostAndProfitCenterObj = {
              costcenter: _costcenter,
              profitcenter: _profitcenter,
              profitcenterdesc: _profitcenterdesc,
              departmentId: _departmentId,
            };

            console.log(newCostAndProfitCenterObj);

            const result = await API.graphql(
              graphqlOperation(createCFRCostAndProfitCentre, {
                input: newCostAndProfitCenterObj,
              })
            );
            if (result.data) {
              console.log(result.data);
              return result.data.createCFRCostAndProfitCentre;
            } else if (result.errors) {
              if (result.errors.length > 0) {
                alert.show(result.errors[0].message);
              }
            }
          }
        });
      });
      const allresult = await Promise.all(promises);
      if (allresult) {
        setIsLoading(false);
        const successMessage =
          "Cost and profit center list uploaded successfully";
        alert.show(successMessage, {
          type: "success",
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  }

  const onUploadBudgetFile = async (evt) => {
    setBudgetFiles([]);
    const file = evt.target.files[0];
    let files = [];
    files.push(file);
    setBudgetFiles(files);
    //handleBudgetFile(files[0]);
  };

  const handleBudgetFile = (files) => {
    try {
      if (files) {
        if (files.length > 0) {
          const file = files[0];
          const reader = new FileReader();
          reader.onload = async (e) => {
            /* Parse data */
            const ab = e.target.result;
            const wb = XLSX.read(ab, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            let existingBudgets = [];
            let searchParameter = {};
            searchParameter.status = { eq: "Open" };
            const currentBudgetData = await API.graphql(
              graphqlOperation(listCFRBudgetReferences, {
                filter: searchParameter,
                limit: 1000,
              })
            );
            if (currentBudgetData) {
              if (currentBudgetData.data) {
                if (currentBudgetData.data.listCFRBudgetReferences) {
                  if (currentBudgetData.data.listCFRBudgetReferences.items) {
                    if (
                      currentBudgetData.data.listCFRBudgetReferences.items
                        .length > 0
                    ) {
                      existingBudgets =
                        currentBudgetData.data.listCFRBudgetReferences.items;
                    }
                  }
                }
              }
            }
            createBudget(data, existingBudgets);
          };
          setIsLoading(true);
          reader.readAsArrayBuffer(file);
        } else {
          const errorMessage = "Please select file.";
          alert.show(errorMessage, {
            type: "error",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function createBudget(data, existingData) {
    const errorLocation =
      "Path Name: components/config/config.js\n Function Name: createBudget";
    try {
      let budgetRec = [];
      let newBudgetRec = [];
      budgetRec = data.slice(1).filter((d) => d.length > 0);
      budgetRec.map((item) => {
        const result = existingData.filter((d) => {
          return d.budgetReferenceNo == item[1];
        });
        if (result.length == 0) {
          newBudgetRec.push(item);
        }
      });
      const promises = newBudgetRec.map(async (a) => {
        let _departmentName;
        let _budgetReferenceNo;
        let _projectDescription;
        let _totalAmount;
        let _remainingAmount;
        let _spendAmount;
        let _lockedAmount;
        let _year;
        let _status;

        _departmentName = a[0] && typeof (a[0] === "string") ? a[0] : "";
        _budgetReferenceNo = a[1] && typeof (a[1] === "string") ? a[1] : "";
        _projectDescription = a[2] && typeof (a[2] === "string") ? a[2] : "";
        _totalAmount = a[3] && typeof (a[3] === "number") ? a[3] : 0;
        _spendAmount = a[4] && typeof (a[4] === "number") ? a[4] : 0;
        _remainingAmount = a[5] && typeof (a[5] === "number") ? a[5] : 0;
        _lockedAmount = a[6] && typeof (a[6] === "number") ? a[6] : 0;
        _year = a[7] && typeof (a[7] === "number") ? a[7] : 0;
        _status = a[8] && typeof (a[8] === "string") ? a[8] : "";

        let newObj = {
          departmentId: "",
          departmentName: _departmentName,
          budgetReferenceNo: _budgetReferenceNo,
          projectDescription: _projectDescription,
          totalAmount: _totalAmount * BudgetMultiple.TOTAL_AMT_MULTIPLE,
          spendAmount: _spendAmount * BudgetMultiple.TOTAL_AMT_MULTIPLE,
          remainingAmount: _totalAmount * BudgetMultiple.TOTAL_AMT_MULTIPLE,
          lockedAmount: _lockedAmount * BudgetMultiple.TOTAL_AMT_MULTIPLE,
          year: _year == 0 ? new Date().getFullYear() : _year,
          status: _status == "" ? "Open" : _status,
        };
        const result = await API.graphql(
          graphqlOperation(createCFRBudgetReference, { input: newObj })
        );
        if (result.data) {
          const successMessage = "Budget reference added successfully";
          console.log(successMessage);
          return result.data.createCFRBudgetReference;
        } else if (result.errors) {
          if (result.errors.length > 0) {
            alert.show(result.errors[0].message);
          }
        }
      });
      const allresult = await Promise.all(promises);
      if (allresult) {
        setIsLoading(false);
        getBudgetReferenceNumbers();
        const successMessage = "Budget list uploaded successfully";
        alert.show(successMessage, {
          type: "success",
        });
      }
    } catch (error) {
      if (error.errors) {
        if (error.errors.length > 0) {
          setIsLoading(false);
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  }

  const getDepartmentList = useCallback(async () => {
    try {
      setIsLoading(true);
      let searchParameter = { isdeleted: { eq: false } };
      const deptData = await API.graphql(
        graphqlOperation(listCFRDepartments, { filter: searchParameter })
      );
      if (deptData) {
        const deptList = deptData.data.listCFRDepartments.items;
        console.log(deptList);
        setDepartmentData([]);
        setIsLoading(false);
        setDepartmentData(deptList);
      } else {
        if (deptData.errors) {
          if (deptData.errors.length > 0) {
            alert.show(deptData.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  });

  async function setFieldValues(id, data) {
    setUpdateId(id);
    const fields = ["geography", "currency", "interestRate"];
    fields.forEach((field) => setValue(field, data[field]));
  }

  const onDelete = (id) => {
    console.log(id);
    alert.show("Are you sure, you want to delete a config ?", {
      title: "Confirmation",
      closeCopy: "Cancel",
      actions: [
        {
          copy: "Yes",
          onClick: () => deleteConfigRecord(id),
        },
      ],
    });
  };

  const deleteConfigRecord = async (id) => {
    const errorLocation =
      "Path Name: components/config/config.js\n Function Name: getContigencyList";
    try {
      deleteCurrencyRepoData(id);
      const deleteObj = {
        id: id,
      };
      setIsLoading(true);
      const deletedConfig = await API.graphql(
        graphqlOperation(deleteCFRConfig, { input: deleteObj })
      );
      setIsLoading(false);
      if (deletedConfig.data) {
        alert.show("Config record is deleted.");
        getConfigList();
        getCurrencyRepoList();
      } else {
        if (deletedConfig.errors) {
          if (deletedConfig.errors.length > 0) {
            alert.show(deletedConfig.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  };

  const deleteCurrencyRepoData = async (id) => {
    const errorLocation =
      "Path Name: components/config/config.js\n Function Name: getContigencyList";
    try {
      setIsLoading(true);
      let currencyRepoList = currencyRateData;
      const filteredList = currencyRepoList.filter(
        (config) => config.configOne.id === id || config.configTwo.id === id
      );
      filteredList.map(async (config) => {
        const deleteObj = {
          id: config.id,
        };
        const deletedCurrencyRepo = await API.graphql(
          graphqlOperation(deleteCFRCurrencyRepo, { input: deleteObj })
        );
        if (deletedCurrencyRepo.data) {
          console.log("Currency Repo record is deleted.");
        } else {
          if (deletedCurrencyRepo.errors) {
            if (deletedCurrencyRepo.errors.length > 0) {
              alert.show(deletedCurrencyRepo.errors[0].message);
            }
          }
        }
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  };

  const getCurrencyRepoList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/config/config.js\n Function Name: getCurrencyRepoList";
    try {
      setIsLoading(true);
      const currencyRepoData = await API.graphql(
        graphqlOperation(listCFRCurrencyRepos)
      );
      const configData = await API.graphql(graphqlOperation(listCFRConfigs));
      if (currencyRepoData && configData) {
        const currencyRepoList =
          currencyRepoData.data.listCFRCurrencyRepos.items;
        console.log(currencyRepoList);
        setCurrencyRateData(currencyRepoList);
        const configList = configData.data.listCFRConfigs.items;
        setIsLoading(false);
        let repoRateData = [];
        configList.map((rowData) => {
          let repoRate = [];
          let currencyMapping = {};
          const rowName = rowData.currency;
          configList.map((colData) => {
            const cellData = currencyRepoList.filter(
              (obj) =>
                obj.configOne.currency === rowName &&
                obj.configTwo.currency === colData.currency
            );
            repoRate.push(cellData);
          });
          currencyMapping.currencyName = rowData.currency;
          currencyMapping.repoRate = repoRate;
          repoRateData.push(currencyMapping);
          let maplist = [];
          for (let i = 0; i < repoRateData.length; i++) {
            let map = {};
            map.mainCurrency = repoRateData[i].currencyName;
            let rates = [];
            for (let l = 0; l < repoRateData[i].repoRate.length; l++) {
              for (let m = 0; m < repoRateData[i].repoRate[l].length; m++) {
                let rowObj = {};
                let rate = repoRateData[i].repoRate[l][m];
                rowObj.currencyRate = rate.repoRate;
                rowObj.repoId = rate.id;
                rates.push(rowObj);
              }
            }
            map.rates = rates;
            maplist.push(map);
          }
          setCurrencyRepoRateData(maplist);
        });
      } else {
        if (currencyRepoData.errors) {
          if (currencyRepoData.errors.length > 0) {
            alert.show(currencyRepoData.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  });

  const createCurrencyRepoData = async (data) => {
    try {
      const errorLocation =
        "Path Name: components/config/config.js\n Function Name: createCurrencyRepoData";
      setIsLoading(true);
      const configData = await API.graphql(graphqlOperation(listCFRConfigs));
      const configList = configData.data.listCFRConfigs.items;
      const newConfigId = data.createCFRConfig.id;
      configList.map(async (config) => {
        const newObj = {
          repoRate: 0,
          cFRCurrencyRepoConfigOneId: newConfigId,
          cFRCurrencyRepoConfigTwoId: config.id,
        };
        const result = await API.graphql(
          graphqlOperation(createCFRCurrencyRepo, { input: newObj })
        );
        if (result.data) {
          getCurrencyRepoList();
        } else if (result.errors) {
          if (result.errors.length > 0) {
            alert.show(result.errors[0].message);
            const loggedInUser = getLoggedInUser();
            let filter = { action: { eq: "ERROR_MESSAGE" } };
            const emailTemplates = await API.graphql(
              graphqlOperation(listCFREmailTemplates, { filter: filter })
            );
            const template = emailTemplates.data.listCFREmailTemplates.items[0];
            let body = template.emailcontent;
            let replaceName = body.replace(
              "{Display Name}",
              AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
            );
            let replaceUser = replaceName.replace(
              "{Logged User}",
              loggedInUser.name
            );

            let replaceError = replaceUser.replace(
              "{Error}",
              `Error: ${result.errors[0].message} at ${errorLocation}`
            );
            const emailTemplate = {
              To: AppConstants.ERROR_MAIL_RECEIVER,
              Subject: template.emailsubject,
              MailBody: replaceError,
            };

            const res = await API.graphql({
              query: sendEmail,
              variables: {
                EmailTemplate: JSON.stringify(emailTemplate),
              },
            });

            console.log(res);
          }
        }
      });

      const filteredConfigList = configList.filter(
        (configRecord) => configRecord.id !== newConfigId
      );
      filteredConfigList.map(async (config) => {
        const newObj = {
          repoRate: 0,
          cFRCurrencyRepoConfigOneId: config.id,
          cFRCurrencyRepoConfigTwoId: newConfigId,
        };
        const result = await API.graphql(
          graphqlOperation(createCFRCurrencyRepo, { input: newObj })
        );
        if (result.data) {
          getCurrencyRepoList();
        } else if (result.errors) {
          if (result.errors.length > 0) {
            alert.show(result.errors[0].message);
            const loggedInUser = getLoggedInUser();
            let filter = { action: { eq: "ERROR_MESSAGE" } };
            const emailTemplates = await API.graphql(
              graphqlOperation(listCFREmailTemplates, { filter: filter })
            );
            const template = emailTemplates.data.listCFREmailTemplates.items[0];
            let body = template.emailcontent;
            let replaceName = body.replace(
              "{Display Name}",
              AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
            );
            let replaceUser = replaceName.replace(
              "{Logged User}",
              loggedInUser.name
            );

            let replaceError = replaceUser.replace(
              "{Error}",
              `Error: ${result.errors[0].message} at ${errorLocation}`
            );
            const emailTemplate = {
              To: AppConstants.ERROR_MAIL_RECEIVER,
              Subject: template.emailsubject,
              MailBody: replaceError,
            };

            const res = await API.graphql({
              query: sendEmail,
              variables: {
                EmailTemplate: JSON.stringify(emailTemplate),
              },
            });

            console.log(res);
          }
        }
      });
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  };

  const findAndUpdateCurrencyRepo = async (changedRate, repoId) => {
    const errorLocation =
      "Path Name: components/config/config.js\n Function Name: findAndUpdateCurrencyRepo";
    try {
      setIsLoading(true);
      let filter = { id: { eq: repoId } };
      const currencyRepoData = await API.graphql(
        graphqlOperation(listCFRCurrencyRepos, { filter: filter })
      );
      const currencyRepoList =
        currencyRepoData.data.listCFRCurrencyRepos.items[0];
      const newObject = {
        id: currencyRepoList.id,
        repoRate: changedRate,
        cFRCurrencyRepoConfigOneId: currencyRepoList.configOne.id,
        cFRCurrencyRepoConfigTwoId: currencyRepoList.configTwo.id,
      };
      const result = await API.graphql(
        graphqlOperation(updateCFRCurrencyRepo, { input: newObject })
      );
      setIsLoading(false);
      if (result.data) {
        getCurrencyRepoList();
      } else if (result.errors) {
        if (result.errors.length > 0) {
          alert.show(result.errors[0].message);
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  };

  const onChangeCurrencyRepo = (e, id) => {
    const changedRate = e.target.value;
    const repoId = id;
    if (changedRate !== "") {
      findAndUpdateCurrencyRepo(changedRate, repoId);
    }
  };

  const getBudgetReferenceNumbers = useCallback(async () => {
    const errorLocation =
      "Path Name: components/config/config.js\n Function Name: getBudgetReferenceNumbers";
    try {
      //debugger;
      setIsLoading(true);
      const loggedInUser = getLoggedInUser();
      let searchParameter = {};
      searchParameter.status = { eq: "Open" };
      const budgetData = await API.graphql(
        graphqlOperation(listCFRBudgetReferences, {
          filter: searchParameter,
          limit: 1000,
        })
      );
      setIsLoading(false);
      if (budgetData) {
        const records = budgetData.data.listCFRBudgetReferences.items;
        const budgtedRecords = records.filter(
          (rec) => rec.budgetReferenceNo !== "Unbudgted"
        );
        const uniqueArr = [...new Set(records.map((data) => data.year))];
        console.log(uniqueArr);
        setBudgetYears(uniqueArr);
        setBudgetReferenceNumber([]);
        setBudgetReferenceNumber(budgtedRecords);
      } else {
        if (budgetData.errors) {
          if (budgetData.errors.length > 0) {
            alert.show(budgetData.errors[0].message);
            const loggedInUser = getLoggedInUser();
            let filter = { action: { eq: "ERROR_MESSAGE" } };
            const emailTemplates = await API.graphql(
              graphqlOperation(listCFREmailTemplates, { filter: filter })
            );
            const template = emailTemplates.data.listCFREmailTemplates.items[0];
            let body = template.emailcontent;
            let replaceName = body.replace(
              "{Display Name}",
              AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
            );
            let replaceUser = replaceName.replace(
              "{Logged User}",
              loggedInUser.name
            );

            let replaceError = replaceUser.replace(
              "{Error}",
              `Error: ${budgetData.errors[0].message} at ${errorLocation}`
            );
            const emailTemplate = {
              To: AppConstants.ERROR_MAIL_RECEIVER,
              Subject: template.emailsubject,
              MailBody: replaceError,
            };

            const res = await API.graphql({
              query: sendEmail,
              variables: {
                EmailTemplate: JSON.stringify(emailTemplate),
              },
            });

            console.log(res);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  });

  const handleBudgetNumberChange = (e) => {
    const selectedDept = e.target.value;
    const refNum = budgetReferenceNumber;
    setFilteredBudgetReferenceNumber([]);
    const filterdRecs = refNum.filter(
      (rec) => rec.departmentName === selectedDept
    );
    let resArr = [];
    filterdRecs.filter(function (item) {
      let i = resArr.findIndex(
        (x) => x.budgetReferenceNo === item.budgetReferenceNo
      );
      if (i <= -1) {
        resArr.push(item);
      }
      return null;
    });

    setFilteredBudgetReferenceNumber(resArr);
    const filterBudNum = filteredBudgetReferenceNumber;
    console.log(filterBudNum);
    forceUpdate();
  };

  const handleBudgetYearChange = (e) => {
    const selectedYearValue = e.target.value;
    setSelectedYear(selectedYearValue);
  };

  // this function is responsible for open confirmation message box
  const handleCloseBudget = () => {
    if (selectedYear) {
      if (selectedYear != "---select---") {
        alert.show(
          "Are you sure, you want to close all budgets for year " +
            selectedYear +
            " ?",
          {
            title: "Confirmation",
            closeCopy: "Cancel",
            actions: [
              {
                copy: "Yes",
                onClick: () => closeBudget(),
              },
            ],
          }
        );
      } else {
        alert.show("Please select an year.");
      }
    } else {
      alert.show("Please select an year.");
    }
  };

  const closeBudget = async () => {
    const budgetData = budgetReferenceNumber;
    const filterdRecs = budgetData.filter((rec) => rec.year == selectedYear);
    setIsLoading(true);
    const promises = filterdRecs.map(async (budget) => {
      let updateobj = {};
      updateobj.id = budget.id;
      updateobj.status = "Closed";
      const budgetData = await API.graphql(
        graphqlOperation(updateCFRBudgetReference, {
          input: updateobj,
        })
      );
      if (budgetData.data) {
        return budgetData.data.updateCFRBudgetReference;
      }
    });
    //wait for all CFRApprover to be updated
    const allresult = await Promise.all(promises);
    if (allresult) {
      alert.show(
        "All budgets for year " + selectedYear + " closed successfully."
      );
    }
    setIsLoading(false);
  };

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      removeUserSession();
      sessionStorage.setItem("isLogout", true);
      console.log(sessionStorage);
      const result = await Auth.signOut();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error signing out: ", error);
      alert.show(error.message);
    }
  };
  const handleTemplateDownload = async (key) => {
    let response;
    let fileName;
    if(key == "ccpcFileTemplate"){
      response = await Storage.get(TemplateDownloadKeys.CCPCTemplateKey, { download: true });
      fileName = TemplateDownloadKeys.CCPCTemplateKey;
    }
    if(key == "plantFileTemplate"){
      response = await Storage.get(TemplateDownloadKeys.PlantTemplateKey, { download: true });
      fileName = TemplateDownloadKeys.PlantTemplateKey;
    }
    if(key == "assetFileTemplate"){
      response = await Storage.get(TemplateDownloadKeys.AssetTemplateKey, { download: true });
      fileName = TemplateDownloadKeys.AssetTemplateKey;
    }
    if(key == "budgetFileTemplate"){
      response = await Storage.get(TemplateDownloadKeys.BudgetTemplateKey, { download: true });
      fileName = TemplateDownloadKeys.BudgetTemplateKey;
    }
    if(response){
      console.log(response);
      await downloadBlob(response.Body, fileName);
    }
    console.log(key);
  } 

  // const uploadDocs = async (e) => {
  //   let file;
  //   if(e.target.files){
  //     if(e.target.files.length >0){
  //       file = e.target.files[0];
  //     }
  //   }
  //   try {
  //     let res = await Storage.put(file.name, file, {
  //       //contentType: "image/png", // contentType is optional
  //     });
  //     if(res){
  //       console.log("res", res);
  //     }
  //   } catch (error) {
  //     console.log("Error uploading file: ", error);
  //   }
  // }

  async function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    let file = filename.split("/")[0];
    a.download = file || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  useEffect(() => {
    const loggedinUser = getLoggedInUser();
    console.log(loggedinUser);
    if (loggedinUser) {
      if (loggedinUser.isActive) {
        if (loggedinUser.role) {
          if (loggedinUser.role.cfrRoleEntities) {
            if (loggedinUser.role.cfrRoleEntities.items.length > 0) {
              let configArr = loggedinUser.role.cfrRoleEntities.items.filter(
                (privilege) =>
                  privilege.entity.name == Entity.Config &&
                  privilege.entityOperation.name == EntityOperation.View
              );
              if (configArr.length > 0) {
                setCostProfitCenterFiles([]);
                setPlantFiles([]);
                setAssetFiles([]);
                setBudgetFiles([]);
                console.log(props);
                //getContigencyList();
                getConfigList();
                getDepartmentList();
                getCurrencyRepoList();
                getBudgetReferenceNumbers();
                setUpdateId("");
                if (loggedinUser.role.cfrRoleEntities) {
                  if (loggedinUser.role.cfrRoleEntities.items.length > 0) {
                    let configCreateArr =
                      loggedinUser.role.cfrRoleEntities.items.filter(
                        (privilege) =>
                          privilege.entity.name == Entity.Config &&
                          privilege.entityOperation.name ==
                            EntityOperation.Create
                      );
                    if (configCreateArr.length > 0) {
                      setIsCreatePermission(true);
                    } else {
                      setIsCreatePermission(false);
                    }
                    let configUpdateArr =
                      loggedinUser.role.cfrRoleEntities.items.filter(
                        (privilege) =>
                          privilege.entity.name == Entity.Config &&
                          privilege.entityOperation.name ==
                            EntityOperation.Update
                      );
                    if (configUpdateArr.length > 0) {
                      setIsUpdatePermission(true);
                    } else {
                      setIsUpdatePermission(false);
                    }
                    let configDeleteArr =
                      loggedinUser.role.cfrRoleEntities.items.filter(
                        (privilege) =>
                          privilege.entity.name == Entity.Config &&
                          privilege.entityOperation.name ==
                            EntityOperation.Delete
                      );
                    if (configDeleteArr.length > 0) {
                      setIsDeletePermission(true);
                    } else {
                      setIsDeletePermission(false);
                    }
                  }
                }
              } else {
                let userArr = loggedinUser.role.cfrRoleEntities.items.filter(
                  (privilege) =>
                    privilege.entity.name == Entity.User &&
                    privilege.entityOperation.name == EntityOperation.View
                );
                if (userArr.length > 0) {
                  history.push("/users");
                } else {
                  let userArr = loggedinUser.role.cfrRoleEntities.items.filter(
                    (privilege) =>
                      privilege.entity.name == Entity.Role &&
                      privilege.entityOperation.name == EntityOperation.View
                  );
                  if (userArr.length > 0) {
                    history.push("/roles");
                  } else {
                    let userArr =
                      loggedinUser.role.cfrRoleEntities.items.filter(
                        (privilege) =>
                          privilege.entity.name == Entity.Department &&
                          privilege.entityOperation.name == EntityOperation.View
                      );
                    if (userArr.length > 0) {
                      history.push("/departments");
                    } else {
                      let userArr =
                        loggedinUser.role.cfrRoleEntities.items.filter(
                          (privilege) =>
                            privilege.entity.name == Entity.CFR &&
                            (privilege.entityOperation.name ==
                              EntityOperation.View ||
                              privilege.entityOperation.name ==
                                EntityOperation.Approve)
                        );
                      if (userArr.length > 0) {
                        history.push("/cfrlist");
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      //alert.show('No logged in User. Please login');
      handleLogout();
    }
    if (
      loggedinUser.departmentadmin === true &&
      loggedinUser.role.name !== Roles.SUPER_ADMIN
    ) {
      setContigencyBtn(true);
      setConfigBtn(true);
      setGeographyBtn(true);
      setDeptAdminHidden(true);
    } else {
      setContigencyBtn(false);
      setConfigBtn(false);
      setGeographyBtn(false);
      setDeptAdminHidden(false);
    }
  }, []);

  return (
    <div className="page-container">
      {isLoading ? <PageLoader></PageLoader> : ""}
      <div className="page-header">
        <h4> Config </h4>
      </div>
      <Tabs>
        <TabList>
          <Tab>Config</Tab>
          <Tab>CFR Creator</Tab>
        </TabList>
        <TabPanel>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-3 mb-3">
                    <label className="form-label">Geography</label>
                    <select
                      className="ddl-control"
                      {...register("geography", { required: true })}
                      onChange={handleCatagoryChange}
                    >
                      <option value="select"> Select </option>
                      {countrylist.map((data, idx) => (
                        <option key={idx}>{data.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-3 mb-3">
                    <label className="form-label">Currency</label>
                    <input
                      type="text"
                      id="txtContigency"
                      name="currency"
                      className="form-control"
                      readOnly={true}
                      {...register("currency", {
                        required: true,
                        minLength: 1,
                        maxLength: 100,
                      })}
                    />
                    {errors.name && errors.name.type === "required" && (
                      <div className="error-msg">Name is required.</div>
                    )}
                    {errors.name && errors.name.type === "minLength" && (
                      <div className="error-msg">
                        Name should contain atleast 1 characters.
                      </div>
                    )}
                    {errors.name && errors.name.type === "maxLength" && (
                      <div className="error-msg">
                        Name should not be greter than 100 characters.
                      </div>
                    )}
                  </div>
                  <div className="col-sm-3 mb-3">
                    <label className="form-label">Interest Rate</label>
                    <input
                      type="text"
                      id="txtContigency"
                      name="interestRate"
                      className="form-control"
                      {...register("interestRate", {
                        required: true,
                        minLength: 1,
                        maxLength: 100,
                      })}
                    />
                    {errors.name && errors.name.type === "required" && (
                      <div className="error-msg">Name is required.</div>
                    )}
                    {errors.name && errors.name.type === "minLength" && (
                      <div className="error-msg">
                        Name should contain atleast 1 characters.
                      </div>
                    )}
                    {errors.name && errors.name.type === "maxLength" && (
                      <div className="error-msg">
                        Name should not be greter than 100 characters.
                      </div>
                    )}
                  </div>
                </div>
                {isCreatePermission == true && (
                  <div className="col-sm-3 mb-3">
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary"
                      hidden={configBtn}
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
            </div>
          </form>
          <div className="card">
            <div className="card-body">
              <table className="table table-cust">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Geography</th>
                    <th scope="col">Currency</th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Currency Logo
                    </th>
                    <th scope="col" style={{ textAlign: "center" }}>
                      Interest Rate
                    </th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {configData.length > 0 &&
                    configData.map((cData, idx) => {
                      const logo = selectLogo(cData.geography);
                      return (
                        <tr key={idx}>
                          <td>{cData.geography}</td>
                          <td>{cData.currency}</td>
                          <td style={{ textAlign: "center" }}>
                            {cData.currencySymbol}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {cData.interestRate}
                          </td>
                          <td>
                            <div className="button-group">
                              {isDeletePermission == true && (
                                <button
                                  type="button"
                                  className="btn-grid fa fa-trash"
                                  onClick={() => {
                                    onDelete(cData.id);
                                  }}
                                  hidden={geographyBtn}
                                ></button>
                              )}
                              {isUpdatePermission == true && (
                                <button
                                  type="button"
                                  className="btn-grid fa fa-pencil"
                                  onClick={() => {
                                    setFieldValues(cData.id, cData);
                                  }}
                                  hidden={geographyBtn}
                                ></button>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  {configData.length == 0 && (
                    <tr>
                      <td colSpan="7" align="center">
                        No record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {deptAdminHidden === true ? (
            <div></div>
          ) : (
            <div>
              <Card>
                <Card.Body>
                  <form>
                    <Table bordered responsive>
                      <tbody>
                        <tr>
                          <td></td>
                          {Array.from(currencyRepoRateData, (e, i) => {
                            return (
                              <td key={i} align="center">
                                <label className="form-label">
                                  {e.mainCurrency}
                                </label>
                              </td>
                            );
                          })}
                        </tr>
                        {Array.from(currencyRepoRateData, (e, i) => {
                          return (
                            <tr key={i}>
                              <td align="center">
                                <label className="form-label">
                                  {e.mainCurrency}
                                </label>
                              </td>
                              {Array.from(e.rates, (r, idx) => {
                                return (
                                  <td key={idx} align="center">
                                    <input
                                      defaultValue={r.currencyRate}
                                      onChange={(e) => {
                                        onChangeCurrencyRepo(e, r.repoId);
                                      }}
                                      type="number"
                                    ></input>
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </form>
                </Card.Body>
              </Card>
              <ApprovalLevel
                isCreatePermission={isCreatePermission}
                isUpdatePermission={isUpdatePermission}
                isDeletePermission={isDeletePermission}
              ></ApprovalLevel>
              <div className="card d-sm-block border-0">
                <div className="card-header">
                  <div
                    className="px-1 pt-1"
                    style={{ backgroundColor: "#1468b2" }}
                  >
                    <label className="form-label text-white">
                      Upload Cost Center/Profit Center File
                    </label>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-12">
                      <input
                        type="file"
                        onChange={(evt) => onUploadCostAndProfitCentreFile(evt)}
                      ></input>
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        onClick={() =>
                          handleCostAndProfitCentreFile(costProfitCenterFiles)
                        }
                      >
                        Upload File
                      </button>
                      <button
                        id = "ccpcFileTemplate"
                        type="button"
                        className="btn btn-sm btn-outline-secondary ml-10"
                        //style={{float : "right"}} 
                        onClick={(e) =>
                          handleTemplateDownload(e.target.id)
                        }
                      >
                        Download Template File
                      </button>
                      {/* <input
                        type="file"
                        onChange={(e) => uploadDocs(e)}
                      ></input> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card d-sm-block border-0">
                <div className="card-header">
                  <div
                    className="px-1 pt-1"
                    style={{ backgroundColor: "#1468b2" }}
                  >
                    <label className="form-label text-white">
                      Upload Plant File
                    </label>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-12">
                      <input
                        type="file"
                        onChange={(evt) => onUploadPlantFile(evt)}
                      ></input>
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        onClick={() => handlePlantFile(plantFiles)}
                      >
                        Upload File
                      </button>
                      <button
                        id = "plantFileTemplate"
                        type="button"
                        className="btn btn-sm btn-outline-secondary ml-10"
                        //style={{float : "right"}} 
                        onClick={(e) =>
                          handleTemplateDownload(e.target.id)
                        }
                      >
                        Download Template File
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card d-sm-block border-0">
                <div className="card-header">
                  <div
                    className="px-1 pt-1"
                    style={{ backgroundColor: "#1468b2" }}
                  >
                    <label className="form-label text-white">
                      Upload Asset File
                    </label>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-12">
                      <input
                        type="file"
                        onChange={(evt) => onUploadAssetFile(evt)}
                      ></input>
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        onClick={() => handleAssetFile(assetFiles)}
                      >
                        Upload File
                      </button>
                      <button
                        id = "assetFileTemplate"
                        type="button"
                        className="btn btn-sm btn-outline-secondary ml-10"
                        //style={{float : "right"}} 
                        onClick={(e) =>
                          handleTemplateDownload(e.target.id)
                        }
                      >
                        Download Template File
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card d-sm-block border-0">
                <div className="card-header">
                  <div
                    className="px-1 pt-1"
                    style={{ backgroundColor: "#1468b2" }}
                  >
                    <label className="form-label text-white">
                      Upload Budget File
                    </label>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-12">
                      <input
                        type="file"
                        onChange={(evt) => onUploadBudgetFile(evt)}
                      ></input>
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        onClick={() => handleBudgetFile(budgetFiles)}
                      >
                        Upload File
                      </button>
                      <button
                        id = "budgetFileTemplate"
                        type="button"
                        className="btn btn-sm btn-outline-secondary ml-10"
                        //style={{float : "right"}} 
                        onClick={(e) =>
                          handleTemplateDownload(e.target.id)
                        }
                      >
                        Download Template File
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card d-sm-block border-0">
                <div className="card-header">
                  <div
                    className="px-1 pt-1"
                    style={{ backgroundColor: "#1468b2" }}
                  >
                    <label className="form-label text-white">
                      Update Budget Status
                    </label>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-3 mb-3">
                      <select
                        className="ddl-control"
                        onChange={handleBudgetYearChange}
                      >
                        <option defaultValue="select"> ---select--- </option>
                        {budgetYears.map((year, idx) => (
                          <option key={idx}>{year}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-3 mb-3">
                      <button
                        type="submit"
                        className="btn btn-sm btn-primary"
                        onClick={() => handleCloseBudget()}
                      >
                        Close Budget
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-3 mb-3">
                  <label className="form-label">Budget Info</label>
                  <select
                    className="ddl-control"
                    onChange={handleBudgetNumberChange}
                  >
                    <option defaultValue="select"> ---select--- </option>
                    {departmentData.map((data, idx) => (
                      <option key={idx}>{data.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 column">
                  <table className="table table-cust">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Budget Refrence Number</th>
                        <th scope="col">Project Description</th>
                        <th scope="col">Total Amount</th>
                        <th scope="col">Utilized Amount</th>
                        <th scope="col">Remaining Amount</th>
                        <th scope="col">Locked Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredBudgetReferenceNumber.length > 0 &&
                        filteredBudgetReferenceNumber.map((rec, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{rec.budgetReferenceNo}</td>
                              <td>{rec.projectDescription}</td>
                              <td>{rec.totalAmount}</td>
                              <td>{rec.spendAmount}</td>
                              <td>{rec.remainingAmount}</td>
                              <td>{rec.lockedAmount}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <CfrCreator></CfrCreator>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Config;
