import React from "react";
function RoleList(props) {
  return (
    <table className="table table-cust ">
      <thead>
        <tr>
          <th className="width40"> Name </th>
          <th className="width40"> Details </th>
          <th className="width20"> Actions </th>
        </tr>
      </thead>
      <tbody>
        {props.deptData.length > 0 &&
          props.deptData.map((dept) => (
            <tr key={dept.id}>
              <td className="width40"> {dept.name} </td>
              <td className="width40"> {dept.details} </td>
              <td className="width20">
                <div className="button-group">
                  {props.isDeletePermission && (
                    <button
                      type="button"
                      className="btn-grid fa fa-trash"
                      onClick={() => {
                        props.actiondelete(dept.id);
                      }}
                    ></button>
                  )}
                  {props.isUpdatePermission && (
                    <button
                      type="button"
                      className="btn-grid fa fa-pencil"
                      onClick={() => {
                        props.actionUpdate(dept, dept.id);
                      }}
                    ></button>
                  )}
                  <button
                    type="button"
                    className="btn-grid fa fa-file-text-o"
                    onClick={() => {
                      props.actionView(dept, dept.id);
                    }}
                  ></button>
                </div>
              </td>
            </tr>
          ))}
        {props.deptData.length == 0 && (
          <tr>
            <td colSpan="7" align="center">
              No record Found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
export default RoleList;
