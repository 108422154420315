import React from "react";
import { withRouter } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/stylesheet/site.scss";
import "font-awesome/css/font-awesome.min.css";
import "./assets/stylesheet/iconmoon.css";
import { positions, Provider } from "react-alert";
import Routing from "./common/routing";
import AlertMUITemplate from "react-alert-template-mui";
const options = {
  position: positions.MIDDLE,
};

const App = ({ location }) => {
  return (
    <div>
      <Provider template={AlertMUITemplate} {...options}>
        <Routing />
      </Provider>
    </div>
  );
};

export default withRouter(App);
