function MobileDepartmentList(props) {
  return (
    <div>
      {props.deptData.length > 0 &&
        props.deptData.map((dept) => (
          <div className="card d-block d-sm-none mb-3" key={dept.id}>
            <div className="card-body">
              <table className="table table-cust ">
                <tbody>
                  <tr>
                    <td className="width40">
                      <label className="label-heading"> Name</label>
                    </td>
                    <td className="width60">{dept.name}</td>
                  </tr>
                  <tr>
                    <td className="width40">
                      <label className="label-heading"> Details</label>
                    </td>
                    <td className="width60"> {dept.details} </td>
                  </tr>
                  <tr>
                    <td className="width100 text-center" colSpan="2">
                      <div className="button-group">
                        {props.isUpdatePermission && (
                          <button
                            type="button"
                            className="btn-grid fa fa-trash"
                            onClick={() => {
                              props.actiondelete(dept, dept.id);
                            }}
                          ></button>
                        )}
                        {props.isDeletePermission && (
                          <button
                            type="button"
                            className="btn-grid fa fa-pencil"
                            onClick={() => {
                              props.actionUpdate(dept, dept.id);
                            }}
                          ></button>
                        )}
                        <button
                          type="button"
                          className="btn-grid fa fa-file-text-o"
                          onClick={() => {
                            props.actionView(dept, dept.id);
                          }}
                        ></button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ))}
      {props.deptData.length == 0 && (
        <div className="mobile-msg d-block d-sm-none">No record found</div>
      )}
    </div>
  );
}
export default MobileDepartmentList;
