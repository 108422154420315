import React from "react";
import Toggle from "react-toggle";
import "../../assets/stylesheet/react-toggle.css";
function UserList(props) {
  // console.log(props.userData);
  return (
    <table className="table table-cust ">
      <thead>
        <tr>
          <th className="width15"> Name </th>
          <th className="width20"> Email </th>
          <th className="width15"> Role </th>
          <th className="width13"> Department </th>
          <th className="width15">Approval Limit</th>
          <th className="width15"> Actions </th>
        </tr>
      </thead>
      <tbody>
        {props.userData.length > 0 &&
          props.userData.map((user) => (
            <tr key={user.id}>
              <td className="width15" title={user.name}>
                {user.name}
              </td>
              <td className="width20" title={user.email}>
                {user.email}
              </td>
              <td className="width15">
                {user.role && user.role.name ? (
                  user.role.name
                ) : (
                  <span>No Role</span>
                )}
              </td>
              <td className="width13">
                {user.department &&
                user.department.items &&
                user.department.items.length > 1 ? (
                  <span>Multiple Departments </span>
                ) : user.department.items.length == 1 ? (
                  user.department.items[0].name
                ) : (
                  <span>No Department</span>
                )}
              </td>
              <td className="width15">
                {user.currencySymbol.includes("select currency")
                  ? ""
                  : `${user.currencySymbol}${user.limit} - ${user.currencySymbol}${user.toLimit}`}
              </td>
              <td className="width15">
                <div className="button-group">
                  {props.isDeletePermission && (
                    <button
                      type="button"
                      className="btn-grid fa fa-trash"
                      onClick={() => {
                        props.actiondelete(user, user.id);
                      }}
                    ></button>
                  )}
                  {props.isUpdatePermission && (
                    <button
                      type="button"
                      className="btn-grid fa fa-pencil"
                      onClick={() => {
                        props.actionUpdate(user, user.id);
                      }}
                    ></button>
                  )}
                  <button
                    type="button"
                    className="btn-grid fa fa-file-text-o"
                    onClick={() => {
                      props.actionView(user, user.id);
                    }}
                  ></button>
                </div>
                <Toggle
                  checked={user.isActive}
                  icons={false}
                  onChange={() => props.actionToggle(user)}
                />
              </td>
            </tr>
          ))}
        {props.userData.length == 0 && (
          <tr>
            <td colSpan="7" align="center">
              No record Found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
export default UserList;
