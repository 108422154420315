import { useState, useEffect, useCallback, useRef } from "react";
import { useAlert } from "react-alert";
import { API, graphqlOperation } from "aws-amplify";
import {
  listCFRApprovalLevels,
  listCFRDepartments,
  sendEmail,
  listCFREmailTemplates,
} from "../../graphql/queries";
import {
  createCFRApprovalLevel,
  updateCFRApprovalLevel,
} from "../../graphql/mutations";
import { getLoggedInUser } from "../../Utils/common";
import { AppConstants } from "../../common/constants";

export default function ApprovalLevel(props) {
  const [latestVersion, setLatestVersion] = useState(0);
  const [isAddMode, setAddMode] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [deptData, setDeptData] = useState([]);
  const [approvallevels, setapprovallevels] = useState([{ levelName: "" }]);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const alert = useAlert();

  useEffect(async () => {
    await getDepartmentList();
    getApprovalLevelList();
  }, []);

  // this function for get department data
  const getDepartmentList = async function () {
    const errorLocation =
      "Path Name: components/config/approvalLevel.js\n Function Name: getDepartmentList";
    try {
      setIsLoading(true);
      let searchParameter = { isdeleted: { eq: false } };
      const deptresponse = await API.graphql(
        graphqlOperation(listCFRDepartments, { filter: searchParameter })
      );
      if (deptresponse) {
        const deptList = deptresponse.data.listCFRDepartments.items;
        console.log(deptList);
        setDeptData(deptList);
        setIsLoading(false);
      } else {
        if (deptresponse.errors) {
          if (deptresponse.errors.length > 0) {
            alert.show(deptresponse.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  };

  // this function for get customer data
  const getApprovalLevelList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/config/approvalLevel.js\n Function Name: getApprovalLevelList";
    try {
      setIsLoading(true);
      const approvalData = await API.graphql(
        graphqlOperation(listCFRApprovalLevels)
      );
      setIsLoading(false);
      if (approvalData) {
        const approvalList = approvalData.data.listCFRApprovalLevels.items;
        setapprovallevels([]);
        console.log(approvalList);
        let latestVersionApprovalList = [];
        if (approvalList.length == 0) {
          setAddMode(true);
        } else {
          //find latest version
          let version = 0;
          approvalList.forEach((lvl) => {
            if (lvl.version > version) {
              version = lvl.version;
            }
          });
          //set latest version
          setLatestVersion(version);
          //take only latest version records
          approvalList.forEach((lvl) => {
            if (lvl.version == version) {
              latestVersionApprovalList.push(lvl);
            }
          });
          setAddMode(false);
        }
        latestVersionApprovalList.sort((a, b) => {
          return a.sequencenumber - b.sequencenumber;
        });
        latestVersionApprovalList.forEach((lvl) => {
          if (lvl.department) {
            lvl.cFRApprovalLevelDepartmentId = lvl.department.id;
          } else {
            lvl.cFRApprovalLevelDepartmentId = "";
          }
        });
        setapprovallevels(latestVersionApprovalList);
      } else {
        if (approvalData.errors) {
          if (approvalData.errors.length > 0) {
            alert.show(approvalData.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  });

  function validateApprovals(approvallevels) {
    if (approvallevels.length == 0) {
      alert.show("Please add atleast one approval level.");
      return false;
    }
    var isInvalidLevel = false;
    approvallevels.forEach((level) => {
      if (level.levelName) {
      } else {
        isInvalidLevel = true;
      }
    });
    if (isInvalidLevel) {
      alert.show("Please enter valid name for level.");
      return false;
    }
    return true;
  }

  /// this function for Create and update cfr record
  async function submitForm() {
    const errorLocation =
      "Path Name: components/config/approvalLevel.js\n Function Name: submitForm";
    let isValid = validateApprovals(approvallevels);
    if (isValid) {
      try {
        setIsLoading(true);
        if (isAddMode) {
          console.log("add");
          let approvalData = [];
          approvallevels.forEach((level, index) => {
            level.sequencenumber = index + 1;
            level.version = 1;
            approvalData.push(level);
          });
          const promises = approvalData.map(async (level) => {
            const approvalLevelResult = await API.graphql(
              graphqlOperation(createCFRApprovalLevel, {
                input: level,
              })
            );
            if (approvalLevelResult.data) {
              return approvalLevelResult.data.createCFRApprovalLevel;
            }
          });
          const allresult = await Promise.all(promises);
          if (allresult.length > 0) {
            setIsLoading(false);
            const successMessage = "Approval levels added successfully.";
            alert.show(successMessage, {
              type: "success",
            });
          } else if (allresult.errors) {
            if (allresult.errors.length > 0) {
              alert.show(allresult.errors[0].message);
            }
          }
        } else {
          console.log("update");
          let approvalData = [];
          approvallevels.forEach((level, index) => {
            delete level.id;
            delete level.createdAt;
            delete level.updatedAt;
            delete level.department;
            level.sequencenumber = index + 1;
            level.version = latestVersion + 1;
            approvalData.push(level);
          });
          const promises = approvalData.map(async (level) => {
            const approvalLevelResult = await API.graphql(
              graphqlOperation(createCFRApprovalLevel, {
                input: level,
              })
            );
            if (approvalLevelResult.data) {
              return approvalLevelResult.data.createCFRApprovalLevel;
            }
          });
          const allresult = await Promise.all(promises);
          if (allresult.length > 0) {
            setIsLoading(false);
            getApprovalLevelList();
            const successMessage = "Approval levels updated successfully.";
            alert.show(successMessage, {
              type: "success",
            });
          } else if (allresult.errors) {
            if (allresult.errors.length > 0) {
              alert.show(allresult.errors[0].message);
            }
          }
        }
      } catch (error) {
        setIsLoading(false);
        if (error.errors) {
          if (error.errors.length > 0) {
            alert.show(error.errors[0].message);
            const loggedInUser = getLoggedInUser();
            let filter = { action: { eq: "ERROR_MESSAGE" } };
            const emailTemplates = await API.graphql(
              graphqlOperation(listCFREmailTemplates, { filter: filter })
            );
            const template = emailTemplates.data.listCFREmailTemplates.items[0];
            let body = template.emailcontent;
            let replaceName = body.replace(
              "{Display Name}",
              AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
            );
            let replaceUser = replaceName.replace(
              "{Logged User}",
              loggedInUser.name
            );

            let replaceError = replaceUser.replace(
              "{Error}",
              `Error: ${error.errors[0].message} at ${errorLocation}`
            );
            const emailTemplate = {
              To: AppConstants.ERROR_MAIL_RECEIVER,
              Subject: template.emailsubject,
              MailBody: replaceError,
            };

            const res = await API.graphql({
              query: sendEmail,
              variables: {
                EmailTemplate: JSON.stringify(emailTemplate),
              },
            });

            console.log(res);
          }
        } else if (error.message) {
          alert.show(error.message, {
            onClose: () => {
              props.history.push("/");
            },
          });
        }
      }
    }
  }

  const handleDepartmentSelection = function (value, idx) {
    approvallevels[idx].cFRApprovalLevelDepartmentId = value;
    console.log(approvallevels);
    setapprovallevels(approvallevels);
    forceUpdate();
  };

  const handleChange = (idx) => (e) => {
    e.persist();
    const { name, value } = e.target;
    approvallevels[idx].levelName = value;
    console.log(approvallevels);
    setapprovallevels(approvallevels);
    forceUpdate();
  };

  const handleAddRow = () => {
    const item = {
      levelName: "",
      ismandatory: false,
    };
    let oldrows = approvallevels;
    oldrows.push(item);
    setapprovallevels(oldrows);
    forceUpdate();
  };

  const handleRemoveSpecificRow = (idx) => () => {
    approvallevels.splice(idx, 1);
    setapprovallevels(approvallevels);
    forceUpdate();
    console.log(approvallevels);
  };

  const handleTypeChecked = (idx) => (e) => {
    e.persist();
    const { checked } = e.target;
    approvallevels[idx].ismandatory = checked;
    console.log(approvallevels);
    setapprovallevels(approvallevels);
    forceUpdate();
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div>
            <div className="row clearfix">
              <div className="col-md-12 column">
                <table className="table table-cust" id="tab_logic">
                  <thead className="thead-light">
                    <tr>
                      <th className="form-label">Approval Level</th>
                      <th className="form-label">Department</th>
                      <th className="form-label">Mandatory</th>
                      <th className="form-label">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvallevels.map((item, idx) => (
                      <tr key={idx}>
                        <td>
                          <input
                            type="text"
                            name="levelName"
                            value={approvallevels[idx].levelName}
                            //ref={inputRef}
                            onChange={handleChange(idx)}
                            className="form-control"
                          />
                        </td>
                        <td>
                          <select
                            id={"selectDept" + idx}
                            name={"selectDept" + idx}
                            className="ddl-control"
                            // value={
                            //   item.department != null
                            //     ? item.department.id
                            //     : ""
                            // }
                            value={item.cFRApprovalLevelDepartmentId}
                            //onSelect={handleDepartmentSelection(idx)}
                            onChange={(e) => {
                              handleDepartmentSelection(e.target.value, idx);
                              //this.setState({ selectedTeam: e.target.value });
                            }}
                          >
                            <option value="">--- select department ---</option>
                            {deptData.map((dept) => (
                              <option key={dept.id} value={dept.id}>
                                {dept.name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            name="ismandatory"
                            className="text-center"
                            checked={approvallevels[idx].ismandatory}
                            onChange={handleTypeChecked(idx)}
                          />
                        </td>
                        {props.isDeletePermission && (
                          <td>
                            <div className="button-group">
                              <button
                                type="button"
                                className="btn-grid fa fa-trash"
                                onClick={handleRemoveSpecificRow(idx)}
                              ></button>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {props.isCreatePermission && (
                  <button
                    type="button"
                    onClick={handleAddRow}
                    className="btn btn-sm btn-primary"
                  >
                    Add Row
                  </button>
                )}
              </div>
            </div>
          </div>
          {(props.isCreatePermission == true ||
            props.isUpdatePermission == true) && (
            <div className="form-footer">
              <div className="row">
                <div className="col-sm-12">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    onClick={() => submitForm()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
