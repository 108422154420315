function UserView(props) {
  let userObj;
  if (props.location.state) {
    if (props.location.state.user) {
      userObj = props.location.state.user;
    }
  }

  const BackBtnClicked = () => {
    props.history.push({
      pathname: "/users",
    });
  };

  return (
    <div className="page-container">
      <div className="page-header">
        <h4> User Details </h4>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Name </label>
              <div> {userObj?.name} </div>
            </div>
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Email </label>
              <div> {userObj?.email}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Department </label>
              <div>
                {userObj?.department &&
                userObj?.department?.items &&
                userObj?.department?.items?.length > 1 ? (
                  <span>Multiple Departments </span>
                ) : userObj?.department?.items?.length == 1 ? (
                  userObj?.department?.items[0]?.name
                ) : (
                  <span>No Department</span>
                )}
              </div>
            </div>
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Role </label>
              <div>
                {userObj?.role ? (
                  <span>{userObj?.role?.name}</span>
                ) : (
                  <span>No Role</span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Head of Department </label>
              <div>
                {userObj?.headoffunction ? <span>Yes</span> : <span>No</span>}
              </div>
            </div>
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Department Admin </label>
              <div>
                {userObj?.departmentadmin ? <span>Yes</span> : <span>No</span>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Approval Limit Range </label>
              <div>
                {`${userObj.currencySymbol}${userObj.limit} - ${userObj.currencySymbol}${userObj.toLimit}`}
              </div>
            </div>
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Is Global User </label>
              <div>
                {userObj?.isGlobalUser ? <span>Yes</span> : <span>No</span>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Can Approve Budgeted CFR </label>
              <div>
                {userObj?.canApproveBudgetedCFR ? (
                  <span>Yes</span>
                ) : (
                  <span>No</span>
                )}
              </div>
            </div>
            <div className="col-sm-4  mb-3">
              <label className="form-label"> Can Approve UnBudgeted CFR </label>
              <div>
                {userObj?.canApproveUnBudgetedCFR ? (
                  <span>Yes</span>
                ) : (
                  <span>No</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-footer">
        <div className="row">
          <div className="col-sm-12">
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary"
              onClick={() => BackBtnClicked()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UserView;
