import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import PageLoader from "../../common/loader";
import EventEmitter from "../../Utils/eventEmitter";

function NpvCalculator(props) {

    const alert = useAlert();
    const [isLoading, setIsLoading] = useState(false);
    //const { register, handleSubmit, setValue } = useForm();
    const [capExRows, setCapExRows] = useState([{ capex_res_org: "", capex_category: "", capex_quantity: "", capex_cost: "" }]);
    const [revExRows, setRevExRows] = useState([{ revex_res_org: "", revex_start_year: "", revex_end_year: "", revex_cost: "" }]);
    const [contingencyPercentage, setContingencyPercentage] = useState(10);
    const [currency, setCurrency] = useState("INR");
    const [capex_total, setCapex_total] = useState(0);
    const [capex_contingency, setCapex_contingency] = useState(0);
    const [capExContingencyChecked, setCapExContingencyChecked] = useState(false);
    const [revex_total, setRevex_total] = useState(0);
    const [assetLife, setAssetLife] = useState(props.assetLife);
    const [colWidth, setColWidth] = useState(0);

    const [costValues, setCostValues] = useState([]);
    const [totalSavings, setTotalSavings] = useState([]);
    const [cashFlowValues, setCashFlowValues] = useState([]);
    const [cumulativeCFValues, setCumulativeCFValues] = useState([]);
    const [positiveCashFlowValues, setPositiveCashFlowValues] = useState([]);
    const [pvValues, setPvValues] = useState([]);
    const [pvOfCashflowValues, setPvOfCashflowValues] = useState([]);
    const [cumulativePVValues, setCumulativePVValues] = useState([]);
    const [interestRate, setInterestRate] = useState(9);
    const [undiscountedPaybackPeriod, setUndiscountedPaybackPeriod] = useState(0);
    const [partialYearPaybackPeriod, setPartialYearPaybackPeriod] = useState(0);
    const [irrValue, setIRRValue] = useState(0);

    const handleSavingValueChange = (idx) => e => {
        let _lstTotalSaving = [...totalSavings];
        let saving = {
            ...totalSavings,
            Saving : e.target.value
        }
        _lstTotalSaving[idx] = saving;
        setTotalSavings(_lstTotalSaving);

        let _lstCostValues = [...costValues];
        handleCashFlowValues(_lstCostValues, _lstTotalSaving);
    };

    const handleCostValues = (rows, capextotal, assetLife) => {
        let costValues = [];
        costValues.push({"Year": 0, "Cost": capextotal});
        for(var i=1; i<assetLife; i++) {
            let _lst = rows.filter(rev => Number(rev.revex_start_year) <= i && Number(rev.revex_end_year) >= i);
            let totalVal = _lst.reduce((total, obj) => Number(obj.revex_cost) + total, 0);
            costValues.push({"Year": i, "Cost": totalVal});
        }
        setCostValues(costValues);

        let _lstTotalSaving = [...totalSavings];
        if(_lstTotalSaving.length > 0){
            handleCashFlowValues(costValues, _lstTotalSaving);
        }
    };

    const handleCashFlowValues = (_lstCostValues, _lstTotalSaving) => {
        let _lstCashflow = [];
        _lstTotalSaving.forEach((item, idx) => {
            let costVal = _lstCostValues[idx];
            _lstCashflow.push({"Year": idx, "Cost": (Number(item.Saving) - Number(costVal.Cost)) });
        });

        setCashFlowValues(_lstCashflow);
        handleCumulativeCfValues(_lstCashflow);
        handleIrrValueCalculation(_lstCashflow, interestRate);
    }

    const handleCumulativeCfValues = (_lstCashflow) => {
        let _lstCumulativeCfValues = [];
        let lastCalculatedCfValue = 0;
        _lstCashflow.forEach((item, idx) => {
            if(idx == 0){
                lastCalculatedCfValue = Number(_lstCashflow[idx].Cost);
                
            }else {
                lastCalculatedCfValue = lastCalculatedCfValue + Number(_lstCashflow[idx].Cost);
            }
            _lstCumulativeCfValues.push({ "Year": idx, "Cost": lastCalculatedCfValue });
        });

        setCumulativeCFValues(_lstCumulativeCfValues);
        handlePositiveCashflowValues(_lstCumulativeCfValues);
        handlePvValues(_lstCashflow);
    }

    const handlePositiveCashflowValues = (_lstCumulativeCfValues) => {

        if(_lstCumulativeCfValues.length > 0) {
            let _lstPositiveCashFlows = [];
            _lstCumulativeCfValues.forEach(item => {
                if(Number(item.Cost) > 0) {
                    _lstPositiveCashFlows.push("TRUE");
                }else {
                    _lstPositiveCashFlows.push("FALSE");
                }
            });

            setPositiveCashFlowValues(_lstPositiveCashFlows);

            let period = _lstPositiveCashFlows.findIndex(it => it == "TRUE");
            setUndiscountedPaybackPeriod(period);

            let lstCashflow = [...cashFlowValues];
            if(lstCashflow.length > 0) {
                let partialYearPaybackPeriodVal = parseFloat(period - (_lstCumulativeCfValues[0].Cost / lstCashflow[0].Cost)).toFixed(2);
                setPartialYearPaybackPeriod(partialYearPaybackPeriodVal);
            }
        }
    }

    const handlePvValues = (_lstCashflow) => {
        let lstPvValues = [];
        let lastCalculatedPvValue = 0;
        Array.from(Array(assetLife), (e, i) => {
            if(i == 0) {
                lastCalculatedPvValue = 100;
            }else {
                lastCalculatedPvValue = parseFloat(lastCalculatedPvValue / (1 + interestRate)).toFixed(2);
            }
            lstPvValues.push(lastCalculatedPvValue);
        });
        setPvValues(lstPvValues);
        handlePvOfCashflow(_lstCashflow, lstPvValues);
    };

    const handlePvOfCashflow = (lstCashflow, lstPvValues) => {
        let lstPvOfCashflow = [];
        Array.from(Array(assetLife), (e, i) => {
            lstPvOfCashflow.push(lstCashflow[i] != undefined && lstPvValues[i] != undefined ? parseFloat(Number(lstCashflow[i].Cost) * Number(lstPvValues[i])).toFixed(2) : 0);
        });
        setPvOfCashflowValues(lstPvOfCashflow);
        handleCumulativePvValues(lstPvOfCashflow);
    };

    const handleCumulativePvValues = (lstPvOfCashflow) => {
        let lstCumulativePvValues = [];
        let lastCalculatedPvValue = 0;
        lstPvOfCashflow.forEach((item, idx) => {
            if(idx == 0){
                lastCalculatedPvValue = Number(lstPvOfCashflow[idx]);
                
            }else {
                lastCalculatedPvValue = lastCalculatedPvValue + Number(lstPvOfCashflow[idx]);
            }
            lstCumulativePvValues.push(lastCalculatedPvValue);
            setCumulativePVValues(lstCumulativePvValues);
        });
    }

    let lastCalculatedIrrVal = 0;
    const handleIrrValueCalculation = (lstCashflow, interestRateVal) => {
        let irrValue = 0;
        if(lstCashflow.length > 0) {
            Array.from(Array(assetLife), (e, i) => {
                if(lstCashflow[i] != undefined) {
                    if(i==0) {
                        irrValue = irrValue + lstCashflow[i].Cost;
                    }else{
                        irrValue = irrValue + (lstCashflow[i].Cost / Math.pow((1 + Number(interestRateVal) / 100), assetLife));
                    }
                }
            });
    
            irrValue = Math.ceil(irrValue);  //parseFloat(irrValue).toFixed(2);
            console.log("Current val: "+ irrValue + " Last Calculated: " +lastCalculatedIrrVal + " Interest Rate: " + interestRateVal);
            
            if(irrValue > 0) {
                interestRateVal = parseFloat(Number(interestRateVal) + 0.1).toFixed(2);
                lastCalculatedIrrVal = irrValue;
                handleIrrValueCalculation(lstCashflow, interestRateVal);
            }

            if(irrValue <= 0) {
                //setInterestRate(interestRateVal);
                setIRRValue(interestRateVal);
                let npvVal = cumulativePVValues[assetLife-1] != undefined ? cumulativePVValues[assetLife-1] : 0;
                let irrVal = interestRateVal;
                EventEmitter.emit("NpvCalculation", {"NPV": npvVal, "IRR": irrVal} );
            }
        }
        
    }

    // page load
    useEffect(() => {

        if(props.location.state !== null) {
            var data = props.location.state;
            console.log(data);
            
            setCapExRows(data.CapExRows);
            setRevExRows(data.RevExRows);
            setAssetLife(data.AssetLife);
            setCapex_total(data.CapExTotal);
            setRevex_total(data.ReveExTotal);
            handleCostValues(data.RevExRows, data.CapExTotal, data.AssetLife);

        }
        setColWidth(75 / data.AssetLife);
    },[]);

    return(
        <div className="page-container pt-1">
            {isLoading ? <PageLoader></PageLoader> : ""}
            <div className="page-header">NPV Calculation</div>
        
            <div className="card d-sm-block border-0">
                <div className="card-body">
                    <div className="row form-group">
                        <div className="col-sm-12">
                            <table className="table table-bordered table-sm table-responsive" id="CumulativeTable">
                                <tbody>
                                    <tr>
                                        <td className="width15 ">
                                            <label className="form-label pt-1">Interest Rate</label>
                                        </td>
                                        <td align="center">
                                            <label className="form-label pt-1">{interestRate}%</label>
                                        </td>
                                        <td colSpan={assetLife}></td>
                                    </tr>
                                    <tr>
                                        <td className="width15"/>                                        
                                        {Array.from(Array(assetLife), (e, i) => {
                                            return <td className="" align='center' style={{width: colWidth+'%'}}>
                                                <label className="form-label">Y{i}</label></td>
                                        })}
                                        <td align='center' className="width10 ">
                                            <label className="form-label">Total</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="width15 ">
                                            <label className="form-label pt-1">Cost</label>
                                        </td>
                                        {
                                            costValues == undefined || costValues.length == 0 ? 
                                            Array.from(Array(assetLife), (e, i) => {
                                                return <td align='center' style={{width: colWidth+'%'}}>0</td>
                                            }) 
                                            :
                                            costValues.map((item, idx) => {
                                                return <td align='center' style={{width: colWidth+'%'}} key={idx}>
                                                    <span>{costValues[idx].Cost}</span>
                                                </td>
                                            })
                                        }
                                        
                                        <td align='center' className="width10">
                                            <label className="form-label">{costValues.reduce((total, obj) => Number(obj.Cost) + total, 0)}</label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="width15 ">
                                            <label className="form-label pt-1">Savings</label>
                                        </td>
                                        {Array.from(Array(assetLife), (e, i) => {
                                            return <td align='center' style={{width: colWidth+'%'}}>
                                                <input type="text" name={"saving_"+i} 
                                                    onChange={handleSavingValueChange(i)} className="form-control text-center" min="0"/>
                                            </td>
                                        })}
                                        <td align="center">
                                            <label className="form-label pt-1">
                                                {totalSavings.reduce((total, obj) => Number(obj.Saving) + total, 0) != 0 ? totalSavings.reduce((total, obj) => Number(obj.Saving) + total, 0) : ''}
                                            </label>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td className="width15 ">
                                            <label className="form-label pt-1">Cashflow</label>
                                        </td>
                                        {
                                            cashFlowValues == undefined || cashFlowValues.length == 0 ? 
                                            Array.from(Array(assetLife), (e, i) => {
                                                return <td align='center' style={{width: colWidth+'%'}}>0</td>
                                            }) 
                                            :
                                            cashFlowValues.map((item, idx) => {
                                                return <td align='center' style={{width: colWidth+'%'}} key={idx}>
                                                    <span>{cashFlowValues[idx].Cost}</span>
                                                </td>
                                            })
                                        }
                                        <td/>
                                    </tr>

                                    <tr>
                                        <td className="width15 ">
                                            <label className="form-label pt-1">Cumulative CF</label>
                                        </td>
                                        {
                                            cumulativeCFValues == undefined || cumulativeCFValues.length == 0 ? 
                                            Array.from(Array(assetLife), (e, i) => {
                                                return <td align='center' style={{width: colWidth+'%'}}>0</td>
                                            }) 
                                            :
                                            cumulativeCFValues.map((item, idx) => {
                                                return <td align='center' style={{width: colWidth+'%'}} key={idx}>
                                                    <span>{cumulativeCFValues[idx].Cost}</span>
                                                </td>
                                            })
                                        }
                                        <td/>
                                    </tr>
                                    <tr>
                                        <td className="width15 ">
                                            <label className="form-label pt-1">+ve Cashflow</label>
                                        </td>
                                        {
                                            positiveCashFlowValues == undefined || positiveCashFlowValues.length == 0 ? 
                                            Array.from(Array(assetLife), (e, i) => {
                                                return <td align='center' style={{width: colWidth+'%'}}>FALSE</td>
                                            }) 
                                            :
                                            positiveCashFlowValues.map((item, idx) => {
                                                return <td align='center' style={{width: colWidth+'%'}} key={idx}>
                                                    <span>{positiveCashFlowValues[idx]}</span>
                                                </td>
                                            })
                                        }
                                        <td/>
                                    </tr>
                                    <tr>
                                        <td className="width15 ">
                                            <label className="form-label pt-1">PV</label>
                                        </td>
                                        {
                                            pvValues == undefined || pvValues.length == 0 ? 
                                            Array.from(Array(assetLife), (e, i) => {
                                                return <td align='center' style={{width: colWidth+'%'}}>0</td>
                                            }) 
                                            :
                                            pvValues.map((item, idx) => {
                                                return <td align='center' style={{width: colWidth+'%'}} key={idx}>
                                                    <span>{pvValues[idx]}%</span>
                                                </td>
                                            })
                                        }
                                        <td/>
                                    </tr>
                                    <tr>
                                        <td className="width15 ">
                                            <label className="form-label pt-1">PV of Cashflow</label>
                                        </td>
                                        {
                                            pvOfCashflowValues == undefined || pvOfCashflowValues.length == 0 ? 
                                            Array.from(Array(assetLife), (e, i) => {
                                                return <td align='center' style={{width: colWidth+'%'}}>0</td>
                                            }) 
                                            :
                                            pvOfCashflowValues.map((item, idx) => {
                                                return <td align='center' style={{width: colWidth+'%'}} key={idx}>
                                                    <span>{pvOfCashflowValues[idx]}</span>
                                                </td>
                                            })
                                        }
                                        <td/>
                                    </tr>
                                    <tr>
                                        <td className="width15 ">
                                            <label className="form-label pt-1">Cumulative PV</label>
                                        </td>
                                        {
                                            cumulativePVValues == undefined || cumulativePVValues.length == 0 ? 
                                            Array.from(Array(assetLife), (e, i) => {
                                                return <td align='center' style={{width: colWidth+'%'}}>0</td>
                                            }) 
                                            :
                                            cumulativePVValues.map((item, idx) => {
                                                return <td align='center' style={{width: colWidth+'%'}} key={idx}>
                                                    <span>{cumulativePVValues[idx]}</span>
                                                </td>
                                            })
                                        }
                                        <td/>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                
                    <div className="row form-group pt-3">
                        <div className="col-sm-12">
                            <table className="table table-bordered table-sm table-responsive width30" id="npvTable">
                                <tbody>
                                <tr>
                                    <td className="width40"></td>
                                    <td className=" width10" align="center">
                                        <label className='form-label'>{assetLife - 1} Years</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className=" width40">
                                        <label className="form-label pt-1">NPV </label>
                                    </td>
                                    <td className="width10" align="center">{cumulativePVValues[assetLife-1] != undefined ? cumulativePVValues[assetLife-1] : 0}</td>
                                </tr>
                                <tr>
                                    <td className=" width40">
                                        <label className="form-label pt-1">IRR </label>
                                    </td>
                                    <td className="width10" align="center">{irrValue}%</td>
                                </tr>
                                <tr>
                                    <td className=" width40">
                                        <label className="form-label pt-1">Undiscounted Payback Period </label>
                                    </td>
                                    <td className="width10" align="center">{undiscountedPaybackPeriod}</td>
                                </tr>
                                <tr>
                                    <td className=" width40">
                                        <label className="form-label pt-1">Partial Year Payback Period </label>
                                    </td>
                                    <td className="width10" align="center">{partialYearPaybackPeriod}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default NpvCalculator;