import React from "react";
import { useEffect, useState } from "react";
import Amplify, { Auth, Hub } from "aws-amplify";
import { useHistory } from "react-router-dom";
import awsExports from "../../aws-exports";
import awsauth from "../../awsauth";
import { UserContext } from "../../context/UserContext";
import { AppConstants } from "../../common/constants";

const LoginButton = () => {
  const buttonStyle = {
    color: "#fff",
    backgroundColor: "#3469b2",
  };
  const { isLoggedin, user, updateLogin, updateCFRUser } =
    React.useContext(UserContext);
  Amplify.configure(awsExports);
  Auth.configure({ oauth: awsauth });

  let history = useHistory();
  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    clickLogin();
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setSignedIn(true);
        console.log("Signed in", signedIn);
        history.push("/config");
        updateLogin(true);
      })
      .catch(() => {
        console.log("Not signed in");
        setSignedIn(false);
        //setUser();
        updateLogin(true);
      });
  }, []);

  const clickLogin = (e) => {
    //e.preventDefault();
    if (user) {
      console.log("logged in", user);
      history.push("/config");
    } else {
      Auth.federatedSignIn({ provider: AppConstants.PROVIDER });
    }
  };
  return (
    <div>
      {/* <Button style={buttonStyle} onClick={(e) => clickLogin(e)}>Login With SSO</Button> */}
    </div>
  );
};

export default LoginButton;
