// import React, { useState, useEffect } from "react";
// import { Auth } from "aws-amplify";

// const CognitoUserContext = React.createContext();

// const CognitoUserProvider = ({ children }) => {
//   const [user, setUser] = useState();

//   useEffect(() => {
//     Auth.currentAuthenticatedUser()
//       .then((value) => setUser(value))
//       .catch((e) => console.log(e));
//   }, []);

//   return (
//     <CognitoUserContext.Provider value={{ user }}>
//       {children}
//     </CognitoUserContext.Provider>
//   );
// };

// export { CognitoUserContext, CognitoUserProvider };

import React, { useState, useEffect } from "react";

const UserContext = React.createContext();

const Userprovider = ({ children }) => {
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [user, setUser] = useState(null);

  const updateLogin = (data) => {
    setIsLoggedin(data);
    console.log("yuyuuuuu");
  };

  const updateCFRUser = (data) => {
    setUser(data);
    console.log("yuyddddddduuuuu");
  };

  return (
    <UserContext.Provider
      value={{ isLoggedin, user, updateLogin, updateCFRUser }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, Userprovider };
