import React from "react";
import { useState, useContext } from "react";
import Navigation from "./navigation";
import { Link, useHistory } from "react-router-dom";
import HelpIcon from '@mui/icons-material/Help';
import { Tooltip } from "@mui/material";
import { useAlert } from "react-alert";
import EventEmitter from "../Utils/eventEmitter";
import { Auth, Storage } from "aws-amplify";
import { AppConstants } from "../common/constants"
import Download from "../assets/images/Download.xlsx";
import {
  getLoggedInUser,
  getCognitoUser,
  getHeaders,
  removeUserSession,
} from "../Utils/common";
import PageLoader from "../common/loader";

function Header(props) {
  const [isProfileNav, setIsProfileNav] = useState(false);
  const [isNavigation, setIsNavigation] = useState(false);
  const [alertCount, setAlertCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const loggedInUserObj = getLoggedInUser();
  const cognitoUserObj = getCognitoUser();
  const headers = getHeaders();
  const alert = useAlert();
  const history = useHistory();

  // handle click event of logout button
  const handleLogout = async () => {
    try {
      setIsLoading(true);
      removeUserSession();
      sessionStorage.setItem("isLogout", true);
      console.log(sessionStorage);
      const result = await Auth.signOut();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error signing out: ", error);
      alert.show(error.message);
    }
  };

  const handleManualDownload = async () => {
    let response = await Storage.get(AppConstants.MANUAL_DOWNLOAD_KEY, { download: true });
    if (response) {
      console.log(response);
      await downloadBlob(response.Body, AppConstants.MANUAL_DOWNLOAD_KEY);
    }
  }

  async function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    let file = filename.split("/")[0];
    a.download = file || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }


  const handleProfileClick = () => {
    if (isProfileNav) {
      setIsProfileNav(false);
      const loggedinUser = getLoggedInUser();
      history.push({
        pathname: "/user/profile/" + loggedinUser.id,
        state: {
          user: loggedinUser,
        },
      });
    } else {
      setIsProfileNav(true);
    }
  };

  const openNavigationPanel = () => {
    if (isNavigation) {
      setIsNavigation(false);
    } else {
      setIsNavigation(true);
    }
  };
  const closeNavigation = () => {
    setIsNavigation(false);
  };

  if (window.location.pathname === "/login" || window.location.pathname === "/")
    return null;
  return (
    <div>
      {isLoading ? <PageLoader></PageLoader> : ""}
      <div className="top-area">
        <div
          className="hamburger-menu fa fa-bars d-block d-sm-none"
          onClick={openNavigationPanel}
        ></div>
        <div style={{ float: "left", paddingTop: 5 }}>
          <img style={{ width: 100, height: 40 }} src="/images/cfr-logo.svg" />
        </div>
        <div style={{ float: "left", paddingLeft: 10, paddingTop: 10 }}>
          <img src="/images/TCPL-Logo.png" />
        </div>
        <div
          className={"top-navbar"}
          style={isNavigation ? { display: "none" } : { display: "block" }}
        >
          <Tooltip title="Download User Manual">
          <HelpIcon
            type="button"
            //style={{float : "right", marginTop : 12}}
            className="user-manual"
            onClick={() => { handleManualDownload() }}
          ></HelpIcon>
          </Tooltip>
          <ul className="top-nav-ul">
            <li className="top-nav-li d-block d-sm-none">
              <Link
                className="fa fa-sign-out"
                to="/#"
                onClick={handleLogout}
              ></Link>
            </li>
            <li className="top-nav-li d-none d-sm-block dropdown-submenu">
              <div className="user-profile" onClick={handleProfileClick}>
                <i className="fa fa-user"> </i>
                <span>
                  <LoggedinUser />
                </span>
                <span className="fa fa-angle-down"> </span>
                {/* <a
                  //href={process.env.PUBLIC_URL + "/assets/Download.xlsx"}
                  href={Download}
                  download="Download.xlsx"
                >
                  <span>here</span>
                </a>
                <Link to="/files/Download.xlsx" target="_blank" download>
                  <span>Download</span>
                </Link> */}
              </div>
              <ul
                className={isProfileNav ? "top-nav-menu show" : "top-nav-menu"}
              >
                <li className="top-nav-menu-li" onClick={handleProfileClick}>
                  <Link to="/userProfile"> My Profile </Link>
                </li>
                {/* <li className="top-nav-menu-li" onClick={handleProfileClick}>
                  <Link to="/changePassword">Change Password </Link>
                </li> */}
                <li className="top-nav-menu-li">
                  <Link to="/home" onClick={handleLogout}>
                    Logout
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={
          isNavigation ? "navigation-container show" : "navigation-container"
        }
      >
        <div className="navigation-top">
          <div className="navigation-img">
            {/* <img src="/images/cfr-logo.svg" /> */}
          </div>
          {/* <span
            style={{
              color: "rgba(255, 255, 255, 0.8)",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            CFR
          </span> */}
          <div
            className="user-profile d-block d-sm-none"
            onClick={closeNavigation}
          >
            <i className="fa fa-user-o"> </i>
            <span>
              <Link to="/userProfile">
                <LoggedinUser />
              </Link>
            </span>
          </div>
        </div>
        <Navigation
          closeNavigationSidebar={closeNavigation}
          profileClick={handleProfileClick}
        />
      </div>
      <div
        className={isNavigation ? "navigation-bg show" : "navigation-bg"}
        onClick={closeNavigation}
      ></div>
    </div>
  );
}
const LoggedinUser = () => {
  let email = "";
  // const { user } = useContext(UserContext);
  // if (user) {
  //   if (user.signInUserSession) {
  //     if (user.signInUserSession.idToken) {
  //       if (user.signInUserSession.idToken.payload) {
  //         email = user.signInUserSession.idToken.payload.email;
  //       } else {
  //         email = "nouser";
  //       }
  //     } else {
  //       email = "nouser";
  //     }
  //   } else {
  //     email = "nouser";
  //   }
  // } else {
  //   email = "nouser";
  // }
  const loggedinUser = getLoggedInUser();
  if (loggedinUser) {
    email = loggedinUser.name;
  }
  return <div>{email}</div>;
};
export default Header;
