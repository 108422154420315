import React from "react";
import { withRouter } from "react-router-dom";
import { Route, Switch, Redirect } from "react-router-dom";
import Header from "./header";
import LoginPage from "../components/authentication/loginPage";
import Home from "../components/authentication/home";
import User from "../components/user/user";
import Department from "../components/department/department";
import DepartmentAddEdit from "../components/department/departmentAddEdit";
import DepartmentView from "../components/department/departmentView";
import CFRView from "../components/cfr/cfrView";
import CFRManagement from "../components/cfr/cfr";
import CFRAddEdit from "../components/cfr/cfrAddEdit";
import UserAddEdit from "../components/user/userAddEdit";
import UserView from "../components/user/userView";
import UserProfile from "../components/user/userProfile";
import Role from "../components/role/role";
import RoleAddEdit from "../components/role/roleAddEdit";
import RoleView from "../components/role/roleView";
import Config from "../components/config/config";
import PendingUser from "../components/user/pendingUser";
import PendingUserView from "../components/user/pendingUserView";
import NpvCalculator from "../components/npv/npvCalculator";
import CFRChangeRequest from "../components/cfr/cfrChangeRequest";

function Routing({ location, props }) {
  return (
    <div>
      {location.pathname !== "/" && <Header />}
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <div className="main-area">
          <Route path="/home" component={Home} />

          <Route path="/users" component={User} />
          <Route path="/user/add" component={UserAddEdit} />
          <Route path="/user/update" component={UserAddEdit} />
          <Route path="/user/view" component={UserView} />
          <Route path="/user/profile/" component={UserProfile} />
          <Route path="/user/pendingUsers" component={PendingUser} />
          <Route path="/user/pendingUserView/" component={PendingUserView} />

          <Route path="/departments" component={Department} />
          <Route path="/department/add" component={DepartmentAddEdit} />
          <Route path="/department/update" component={DepartmentAddEdit} />
          <Route path="/department/view" component={DepartmentView} />

          <Route path="/cfrlist" component={CFRManagement} />
          <Route path="/cfr/add" component={CFRAddEdit} />
          <Route path="/cfr/update" component={CFRAddEdit} />
          <Route path="/cfr/view" component={CFRView} />
          <Route path="/cfr/changeCfrRequest" component={CFRChangeRequest} />
          <Route
            path="/cfr/updateChangeCfrRequest"
            component={CFRChangeRequest}
          />

          <Route path="/roles" component={Role} />
          <Route path="/role/add" component={RoleAddEdit} />
          <Route path="/role/update" component={RoleAddEdit} />
          <Route path="/role/view" component={RoleView} />

          <Route path="/config" component={Config} />

          <Route path="/npvCalc" component={NpvCalculator} />
        </div>
      </Switch>
    </div>
  );
}

export default withRouter(Routing);
