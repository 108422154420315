import React, { useState, useEffect, useCallback } from "react";
import { useAlert } from "react-alert";
import { getLoggedInUser } from "../../Utils/common";
import PageLoader from "../../common/loader";
import { AppConstants } from "../../common/constants";
import { API, graphqlOperation, Storage } from "aws-amplify";
import _ from "lodash";
import {
  updateCFRCapitalFundRequest,
  updateCFRApprover,
  updateCFRBudgetReference,
} from "../../graphql/mutations";
import {
  sendEmail,
  listCFRCapitalFundRequests,
} from "../../graphql/customQueries";
import {
  listCFREmailTemplates,
  listCFRPlants,
  listCFRCostAndProfitCentres,
  listCFRBudgetReferences,
  listCFRUsers,
  listCFRUserNotifications,
} from "../../graphql/queries";
import "../../assets/stylesheet/collapsable-panel.css";
import Pdf from "react-to-pdf";
import awsconfig from "../../awsconfig.json";
import Modal from "react-modal";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import $ from "jquery";
// import * as _html2canvas from "html2canvas";

function CFRView(props) {
  //const html2canvas = _html2canvas;
  const alert = useAlert();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [isLoading, setIsLoading] = useState(false);
  const [isApprovalButtonsVisible, setisApprovalButtonsVisible] =
    useState(false);
  const [justification, setjustification] = useState("");
  const [costcenter, setcostcenter] = useState("");
  const [profitcenter, setprofitcenter] = useState("");
  const [IONumber, setIONumber] = useState("");
  const [isIONumberSet, setisIONumberSet] = useState(false);
  const [isProcurementApprover, setisProcurementApprover] = useState(false);
  const [isProcurementApproved, setisProcurementApproved] = useState(false);
  const [plants, setPlants] = useState([]);
  const [costAndProfitCentres, setCostAndProfitCentres] = useState([]);
  const [cfrCostCenter, setCfrCostCenter] = useState("");
  const [cfrProfitCenter, setCfrProfitCenter] = useState("");
  const [selectedPlant, setSelectedPlant] = useState("");
  const [isCapitalBudget, setIsCapitalBudget] = useState(true);

  const [assetLife, setAssetLife] = useState(0);
  const [capExRows, setCapExRows] = useState([
    {
      capex_resource: "",
      capex_category: "",
      capex_quantity: "",
      capex_unitcost: "",
      capex_totalunitcost: "",
      capex_assetName: "",
      capex_evaluationCategory: "",
      capex_description: "",
      capex_life: 0,
    },
  ]);
  const [revExRows, setRevExRows] = useState([
    {
      revex_resource: "",
      revex_start_year: "",
      revex_end_year: "",
      revex_cost: "",
    },
  ]);

  const [currency, setCurrency] = useState("");
  const [currencylogo, setCurrencylogo] = useState("");
  const [capex_total, setCapex_total] = useState(0);
  const [capex_contingency, setCapex_contingency] = useState(0);
  const [capExContingencyChecked, setCapExContingencyChecked] = useState(false);
  const [capExContingencyType, setCapExContingencyType] =
    useState("Percentage");
  const [contingencyPercentage, setContingencyPercentage] = useState(0);
  const [revex_total, setRevex_total] = useState(0);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [colWidth, setColWidth] = useState(0);

  const [yearlyCapexValues, setYearlyCapexValues] = useState([]);
  const [yearlyRevexValues, setYearlyRevexValues] = useState([]);
  const [costValues, setCostValues] = useState([]);
  const [totalSavings, setTotalSavings] = useState([{ Saving: "0" }]);
  const [cashFlowValues, setCashFlowValues] = useState([]);
  const [cumulativeCFValues, setCumulativeCFValues] = useState([]);
  const [positiveCashFlowValues, setPositiveCashFlowValues] = useState([]);
  const [pvValues, setPvValues] = useState([]);
  const [pvOfCashflowValues, setPvOfCashflowValues] = useState([]);
  const [cumulativePVValues, setCumulativePVValues] = useState([]);
  const [interestRate, setInterestRate] = useState(9);
  const [undiscountedPaybackPeriod, setUndiscountedPaybackPeriod] = useState(0);
  const [partialYearPaybackPeriod, setPartialYearPaybackPeriod] = useState(0);
  const [irrValue, setIRRValue] = useState("N/A");
  const [npvValue, setNPVValue] = useState(0);
  const [cfrUploadedFiles, setCfrUploadedFiles] = useState([]);
  const [isCfrCreator, setIsCfrCreator] = useState(false);
  const [isAnyChildCfrInDraftMode, setIsAnyChildCfrInDraftMode] =
    useState(false);
  const [env, setEnv] = useState("");
  const [currentApprovalLevel, setCurrentApprovalLevel] = useState(null);
  const [budgetReferenceNumber, setBudgetReferenceNumber] = useState([]);
  const [userDataForEmail, setUserDataForEmail] = useState([]);
  const [userNotificationsList, setUserNotificationsList] = useState([]);
  const [cfrObj, setCfrObj] = useState(null);
  const [capexAnalysisPeriod, setCapexAnalysisPeriod] = useState(1);
  const [capexAnalysisPeriodArray, setcapexAnalysisPeriodArray] = useState([]);

  const customStyles = {
    content: {
      top: "25%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "35%",
      height: "35%",
      transform: "translate(-50%, 0%)",
    },
    overlay: {
      backgroundColor: "#e9eaec",
    },
  };

  const ref = React.createRef();

  const pdfoptions = {
    orientation: "p",
    unit: "in",
    format: [29, 12],
    hotfixes: ["px_scaling"],
  };

  let dataFromList;
  if (props.location.state) {
    dataFromList = props.location.state;
  }
  console.log(dataFromList);

  function handleJustification(e) {
    if (e.target) {
      setjustification(e.target.value);
      console.log(e.target.value);
    }
  }

  function handleCostCenter(e) {
    if (e.target) {
      setcostcenter(e.target.value);
      console.log(e.target.value);
    }
  }

  function handleProfitCenter(e) {
    if (e.target) {
      setprofitcenter(e.target.value);
      console.log(e.target.value);
    }
  }

  function handleIONumber(e) {
    if (e.target) {
      setIONumber(e.target.value);
      console.log(e.target.value);
    }
  }

  const handleApproverNotifFlag = (approverLevel, filterId) => {
    let userNotif = userNotificationsList.filter(
      (notif) => filterId === notif.cFRUserNotificationsUserId
    );
    console.log(userNotif, filterId);
    if (userNotif && userNotif.length > 0) {
      if (approverLevel == "Project Sponsor") {
        console.log(approverLevel, userNotif[0].projectSponsorApproval);
        return userNotif[0].projectSponsorApproval;
      } else if (approverLevel == "Procurement") {
        return userNotif[0].procurementHeadApproval;
      } else if (approverLevel == "Functional Head") {
        return userNotif[0].functionalHeadApproval;
      } else if (approverLevel == "Finance Controller") {
        return userNotif[0].financeHeadApproval;
      }
    }
    else {
      return false;
    }
  };
  /// this function for Create and update cfr record
  async function updateCFR(arg) {
    const errorLocation =
      "Path Name: components/cfr/cfrView.js\n Function Name: updateCFR";
    //let cfrdata = cfrObj;
    let cfrdata = _.cloneDeep(cfrObj);
    const approverList = cfrdata.cfrApprovals.items;
    console.log(justification);
    if (arg == "Reject" || arg == "Ammend") {
      if (!justification) {
        alert.show("Please enter justification.");
        return;
      }
    }
    if (arg == "Approve") {
      if (currentApprovalLevel) {
        if (currentApprovalLevel.sequencenumber == 4) {
          cfrdata.cfrstatus = "approved";
        } else {
          cfrdata.cfrstatus = "approvalinprocess";
        }
      }
    } else if (arg == "Reject") {
      cfrdata.cfrstatus = "rejected";
    } else if (arg == "Ammend") {
      cfrdata.cfrstatus = "needammendment";
    }
    //return;
    cfrdata.costcenter = costcenter;
    cfrdata.profitcenter = profitcenter;
    console.log(cfrdata);
    let cfrApprovalArr = [];
    const loggedInUser = getLoggedInUser();
    if (loggedInUser) {
      if (cfrdata.cfrApprovals) {
        cfrdata.cfrApprovals.items.forEach((lvl, index) => {
          if (lvl.approvalLevel.id == currentApprovalLevel.id) {
            let obj = {};
            if (arg == "Approve") {
              obj.approvalstatus = "approved";
            } else if (arg == "Reject") {
              obj.approvalstatus = "rejected";
            } else if (arg == "Ammend") {
              obj.approvalstatus = "needammendment";
            }
            obj.id = lvl.id;
            obj.cfrVersion = lvl.cfrVersion;
            obj.expectedCfrApprovers = cfrdata.expectedCfrApprovers;
            obj.cFRApproverCfrId = cfrdata.id;
            obj.cFRApproverApprovalLevelId = cfrdata.cFRApproverApprovalLevelId;
            obj.cFRApproverActualCfrApproverId = loggedInUser.id;
            obj.approverjustification = justification;
            obj.approvalDateTime = new Date().toISOString();
            cfrApprovalArr.push(obj);
          }
        });
      }
    }
    let tempcfrData = { ...cfrdata };
    delete cfrdata.file;
    delete cfrdata.cfrApprovals;
    //delete cfrdata.cfrResources;
    delete cfrdata.cfrFiles;
    delete cfrdata.createdAt;
    delete cfrdata.updatedAt;
    delete cfrdata.tableData;
    delete cfrdata.cfrsubmissiondatestring;
    delete cfrdata.expectedCompletionDateString;
    delete cfrdata.budgetReferenceNo;
    delete cfrdata.riskAndMitigation;
    delete cfrdata.timelineActivities;

    const _loggedInUser = getLoggedInUser();
    const newData = {
      ...cfrdata,
      //cFRCapitalFundRequestCfrCreatorId: _loggedInUser.id,
    };
    delete newData.cfrCreator;

    try {
      setIsLoading(true);
      console.log("update");
      const result = await API.graphql(
        graphqlOperation(updateCFRCapitalFundRequest, { input: newData })
      );
      setIsLoading(false);
      let updatedCFRObject = {};
      if (result.data) {
        updatedCFRObject = result.data?.updateCFRCapitalFundRequest;
        if (cfrApprovalArr.length > 0) {
          const cfrApproverResult = await API.graphql(
            graphqlOperation(updateCFRApprover, {
              input: cfrApprovalArr[0],
            })
          );
          if (cfrApproverResult.data) {
            //update budget reference number spend/remaining/locked amount
            if (cfrdata.cfrstatus == "approved") {
              const selectedBudgetReference = [];
              let cfrRelatedBudgetRefs = [];
              budgetReferenceNumber.forEach((budget) => {
                tempcfrData.budgetReferenceNo.items.forEach((dataBudget) => {
                  if (
                    budget.budgetReferenceNo == dataBudget.budgetReferenceNo
                  ) {
                    selectedBudgetReference.push(budget);
                    cfrRelatedBudgetRefs.push(dataBudget);
                  }
                });
              });
              if (selectedBudgetReference) {
                if (selectedBudgetReference.length > 0) {
                  // iterate  multiple selected budget refs and handle spent, locked for each
                  let tempResponse = selectedBudgetReference.map(async (budget, idx) => {
                    let budgetData = { ...budget };
                    let cfrBudgetData = cfrRelatedBudgetRefs[idx];
                    delete budgetData.createdAt;
                    delete budgetData.updatedAt;
                    budgetData.lockedAmount =
                      parseFloat(budgetData.lockedAmount) -
                      parseFloat(cfrBudgetData.proposedAmount / 1);
                    budgetData.spendAmount =
                      parseFloat(budgetData.spendAmount) +
                      parseFloat(cfrBudgetData.proposedAmount / 1);
                    budgetData.remainingAmount = cfrBudgetData.remainingAmount;

                    const budgetReferenceResult = await API.graphql(
                      graphqlOperation(updateCFRBudgetReference, {
                        input: budgetData,
                      })
                    );
                  });
                }
              }
            }
            else if (cfrdata.cfrstatus == "rejected") {
              const selectedBudgetReference = [];
              let cfrRelatedBudgetRefs = [];
              budgetReferenceNumber.forEach((budget) => {
                tempcfrData.budgetReferenceNo?.items.forEach((dataBudget) => {
                  if (
                    budget.budgetReferenceNo == dataBudget.budgetReferenceNo
                  ) {
                    selectedBudgetReference.push(budget);
                    cfrRelatedBudgetRefs.push(dataBudget);
                  }
                });
              });
              if (selectedBudgetReference) {
                if (selectedBudgetReference.length > 0) {
                  // iterate  multiple selected budget refs and handle spent, locked for each
                  let tempResponse = selectedBudgetReference.map(
                    async (budget, idx) => {
                      let budgetData = { ...budget };
                      let cfrBudgetData = cfrRelatedBudgetRefs[idx];
                      delete budgetData.createdAt;
                      delete budgetData.updatedAt;
                      budgetData.lockedAmount =
                        parseFloat(budgetData.lockedAmount) -
                        parseFloat(cfrBudgetData.proposedAmount / 1);
                      budgetData.remainingAmount =
                        parseFloat(budgetData.remainingAmount) +
                        parseFloat(cfrBudgetData.proposedAmount / 1);

                      const budgetReferenceResult = await API.graphql(
                        graphqlOperation(updateCFRBudgetReference, {
                          input: budgetData,
                        })
                      );
                    });
                }
              }
            }
            // Write Else if for Reject and Ammend conditions like above
            let approverRole = currentApprovalLevel.levelName;
            let successMessage = "";
            if (arg == "Approve") {
              let filter = { action: { eq: "APPROVE_CFR" } };
              const emailTemplates = await API.graphql(
                graphqlOperation(listCFREmailTemplates, { filter: filter })
              );
              if (emailTemplates.data.listCFREmailTemplates.items) {
                if (
                  emailTemplates.data.listCFREmailTemplates.items.length > 0
                ) {
                  const template =
                    emailTemplates.data.listCFREmailTemplates.items[0];
                  approverList.map((cfrApprover) => {
                    if (cfrApprover.expectedCfrApprovers) {
                      if (cfrApprover.expectedCfrApprovers.length > 0) {
                        cfrApprover.expectedCfrApprovers.forEach(
                          (recipient) => {
                            userDataForEmail.forEach(async (user) => {
                              let approverLevelNotifFlag =
                                await handleApproverNotifFlag(
                                  approverRole,
                                  user.id
                                );
                              if (recipient == user.id) {
                                if (approverLevelNotifFlag) {
                                  let body = template.emailcontent;
                                  let replaceName = body.replace(
                                    "{Display Name}",
                                    user.name
                                  );
                                  let replaceRole = replaceName.replace(
                                    "{Approver}",
                                    approverRole
                                  );
                                  let finalBody = replaceRole.replace(
                                    "{Link}",
                                    env
                                  );
                                  const emailTemplate = {
                                    To: user.email,
                                    Subject: template.emailsubject,
                                    MailBody: finalBody,
                                  };

                                  try {
                                    const res = await API.graphql({
                                      query: sendEmail,
                                      variables: {
                                        EmailTemplate:
                                          JSON.stringify(emailTemplate),
                                      },
                                    });
                                    console.log(res);
                                  } catch (error) {
                                    console.log(error);
                                  }
                                }
                              }
                            });
                          }
                        );
                      }
                    }
                  });
                  if (updatedCFRObject != {}) {
                    let body = template.emailcontent;
                    let replaceName = body.replace(
                      "{Display Name}",
                      updatedCFRObject.cfrCreator.name
                    );
                    let replaceRole = replaceName.replace(
                      "{Approver}",
                      approverRole
                    );
                    let finalBody = replaceRole.replace(
                      "{Link}",
                      env
                    );
                    const emailTemplate = {
                      To: updatedCFRObject.cfrCreator.email,
                      Subject: template.emailsubject,
                      MailBody: finalBody,
                    };

                    try {
                      const res = await API.graphql({
                        query: sendEmail,
                        variables: {
                          EmailTemplate: JSON.stringify(emailTemplate),
                        },
                      });
                      console.log(res);
                    } catch (error) {
                      console.log(error);
                    }
                  }
                }
              }

              successMessage = "CFR approved successfully.";
            } else if (arg == "Reject") {
              let filter = { action: { eq: "REJECT_CFR" } };
              const emailTemplates = await API.graphql(
                graphqlOperation(listCFREmailTemplates, { filter: filter })
              );
              if (emailTemplates.data.listCFREmailTemplates.items) {
                if (
                  emailTemplates.data.listCFREmailTemplates.items.length > 0
                ) {
                  const template =
                    emailTemplates.data.listCFREmailTemplates.items[0];
                  approverList.map((cfrApprover) => {
                    if (cfrApprover.expectedCfrApprovers) {
                      if (cfrApprover.expectedCfrApprovers.length > 0) {
                        cfrApprover.expectedCfrApprovers.forEach(
                          (recipient) => {
                            userDataForEmail.forEach(async (user) => {
                              let approverLevelNotifFlag =
                                await handleApproverNotifFlag(
                                  approverRole,
                                  user.id
                                );
                              if (recipient == user.id) {
                                if (approverLevelNotifFlag) {
                                  let body = template.emailcontent;
                                  let replaceName = body.replace(
                                    "{Display Name}",
                                    user.name
                                  );
                                  let replaceRole = replaceName.replace(
                                    "{Approver}",
                                    approverRole
                                  );
                                  let replaceJustification =
                                    replaceRole.replace(
                                      "{Justification}",
                                      justification
                                    );
                                  let finalBody =
                                    replaceJustification.replace(
                                      "{Link}",
                                      env
                                    );
                                  const emailTemplate = {
                                    To: user.email,
                                    Subject: template.emailsubject,
                                    MailBody: finalBody,
                                  };

                                  const res = await API.graphql({
                                    query: sendEmail,
                                    variables: {
                                      EmailTemplate:
                                        JSON.stringify(emailTemplate),
                                    },
                                  });
                                  console.log(res);
                                }
                              }
                            });
                          }
                        );
                      }
                    }
                  });
                  if (updatedCFRObject != {}) {
                    let body = template.emailcontent;
                    let replaceName = body.replace(
                      "{Display Name}",
                      updatedCFRObject.cfrCreator.name
                    );
                    let replaceRole = replaceName.replace(
                      "{Approver}",
                      approverRole
                    );
                    let replaceJustification =
                      replaceRole.replace(
                        "{Justification}",
                        justification
                      );
                    let finalBody =
                      replaceJustification.replace(
                        "{Link}",
                        env
                      );
                    const emailTemplate = {
                      To: updatedCFRObject.cfrCreator.email,
                      Subject: template.emailsubject,
                      MailBody: finalBody,
                    };

                    try {
                      const res = await API.graphql({
                        query: sendEmail,
                        variables: {
                          EmailTemplate: JSON.stringify(emailTemplate),
                        },
                      });
                      console.log(res);
                    } catch (error) {
                      console.log(error);
                    }
                  }
                }
              }
              successMessage = "CFR rejected successfully.";
            } else if (arg == "Ammend") {
              let filter = { action: { eq: "AMMEND_CFR" } };
              const emailTemplates = await API.graphql(
                graphqlOperation(listCFREmailTemplates, { filter: filter })
              );
              if (emailTemplates.data.listCFREmailTemplates.items) {
                if (
                  emailTemplates.data.listCFREmailTemplates.items.length > 0
                ) {
                  const template =
                    emailTemplates.data.listCFREmailTemplates.items[0];
                  approverList.map(async (cfrApprover) => {
                    if (cfrApprover.expectedCfrApprovers) {
                      if (cfrApprover.expectedCfrApprovers.length > 0) {
                        cfrApprover.expectedCfrApprovers.forEach(
                          (recipient) => {
                            userDataForEmail.forEach(async (user) => {
                              let approverLevelNotifFlag =
                                await handleApproverNotifFlag(
                                  approverRole,
                                  user.id
                                );
                              if (recipient == user.id) {
                                if (approverLevelNotifFlag) {
                                  let body = template.emailcontent;
                                  let replaceName = body.replace(
                                    "{Display Name}",
                                    user.name
                                  );
                                  let replaceRole = replaceName.replace(
                                    "{Approver}",
                                    approverRole
                                  );
                                  let replaceJustification =
                                    replaceRole.replace(
                                      "{Justification}",
                                      justification
                                    );
                                  let finalBody =
                                    replaceJustification.replace(
                                      "{Link}",
                                      env
                                    );
                                  const emailTemplate = {
                                    To: user.email,
                                    Subject: template.emailsubject,
                                    MailBody: finalBody,
                                  };

                                  const res = await API.graphql({
                                    query: sendEmail,
                                    variables: {
                                      EmailTemplate:
                                        JSON.stringify(emailTemplate),
                                    },
                                  });
                                  console.log(res);
                                }
                              }
                            });
                          }
                        );
                      }
                    }
                  });
                  if (updatedCFRObject != {}) {
                    let body = template.emailcontent;
                    let replaceName = body.replace(
                      "{Display Name}",
                      updatedCFRObject.cfrCreator.name
                    );
                    let replaceRole = replaceName.replace(
                      "{Approver}",
                      approverRole
                    );
                    let replaceJustification =
                      replaceRole.replace(
                        "{Justification}",
                        justification
                      );
                    let finalBody =
                      replaceJustification.replace(
                        "{Link}",
                        env
                      );
                    const emailTemplate = {
                      To: updatedCFRObject.cfrCreator.email,
                      Subject: template.emailsubject,
                      MailBody: finalBody,
                    };

                    try {
                      const res = await API.graphql({
                        query: sendEmail,
                        variables: {
                          EmailTemplate: JSON.stringify(emailTemplate),
                        },
                      });
                      console.log(res);
                    } catch (error) {
                      console.log(error);
                    }
                  }
                }
              }
              successMessage = "CFR sent back for ammendment.";
            }

            alert.show(successMessage, {
              type: "success",
              onClose: () => {
                props.history.push("/cfrlist");
              }, // callback that
            });
          }
        }
      }
      else if (result.errors) {
        if (result.errors.length > 0) {
          alert.show(result.errors[0].message);
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  }

  async function getSelectedCFR() {
    let filter = { id: { eq: dataFromList.id } };
    const list = await API.graphql(
      graphqlOperation(listCFRCapitalFundRequests, { filter: filter ,  limit: 1000 })
    );
    if (list.data?.listCFRCapitalFundRequests.items) {
      if (list.data?.listCFRCapitalFundRequests.items.length > 0) {
        const currentCFR = list.data?.listCFRCapitalFundRequests.items[0];
        currentCFR.cfrApprovals.items.sort((a, b) => {
          if (a.approvalLevel) {
            if (b.approvalLevel) {
              return (
                a.approvalLevel.sequencenumber - b.approvalLevel.sequencenumber
              );
            }
          }
        });
        currentCFR.cfrApprovals.items.forEach((approver) => {
          if (approver.expectedCfrApprovers) {
            if (approver.expectedCfrApprovers.length > 0) {
              approver.expectedCfrApproverNamesArray = [];
              approver.expectedCfrApproverNames = "";
              approver.expectedCfrApprovers.forEach((expectedApproverId) => {
                userDataForEmail.forEach((user) => {
                  if (expectedApproverId == user.id) {
                    approver.expectedCfrApproverNamesArray.push(user.name);
                  }
                });
              });
              approver.expectedCfrApproverNames =
                approver.expectedCfrApproverNamesArray.join(",");
            }
          }
        });
        currentCFR.cfrsubmissiondatestring = new Date(
          currentCFR.cfrsubmissiondate
        ).toLocaleString();
        if (currentCFR.expectedCompletionDate) {
          currentCFR.expectedCompletionDateString = new Date(
            currentCFR.expectedCompletionDate
          ).toLocaleString();
        } else {
          currentCFR.expectedCompletionDateString = "";
        }
        setCfrObj(currentCFR);
      }
    }
  }

  async function checkChildCfrStatus(cfrObj) {
    let filter = { masterCFRID: { eq: cfrObj.id }, isDeleted: { eq: false } };
    const _lstChildCfr = await API.graphql(
      graphqlOperation(listCFRCapitalFundRequests, { filter: filter })
    );
    if (
      _lstChildCfr != null &&
      _lstChildCfr.data?.listCFRCapitalFundRequests != null &&
      _lstChildCfr.data?.listCFRCapitalFundRequests.items.length > 0
    ) {
      var childCfrList = _lstChildCfr.data?.listCFRCapitalFundRequests.items;
      var _lstDraftCfr = childCfrList.filter((x) => x.cfrstatus == "draft");
      if (_lstDraftCfr.length > 0) {
        setIsAnyChildCfrInDraftMode(true);
      }
    }
  }
  const setEnvironment = () => {
    const enviornment = awsconfig.Auth.redirectSignOut;
    setEnv(enviornment);
  };

  useEffect(async () => {
    await getUserListForEmail();
    await getUserNotificationList();
  }, []);

  // call user list on page load
  useEffect(async () => {
    setEnvironment();
    getPlantList();
    getCfrCostAndProfitCentres();
    await getSelectedCFR();
    await getBudgetReferenceNumbers();
    const loggedInUser = getLoggedInUser();
    if (dataFromList) {
      if (loggedInUser) {
        setIsCfrCreator(loggedInUser.id == dataFromList.cfrCreator.id);
        checkChildCfrStatus(dataFromList);
        let ismandatory = false;
        let index = -1;
        let apLvl = null;
        let isAllPriorLevelsApproved = true;
        let isCurrentLevelApproved = false;
        //find index/seq no of logged in user approver
        for (let i = 0; i < dataFromList.cfrApprovals.items.length; i++) {
          let cfrApproval = dataFromList.cfrApprovals.items[i];
          if (cfrApproval.expectedCfrApprovers) {
            let isApproverFound = false;
            if (cfrApproval.expectedCfrApprovers.length > 0) {
              cfrApproval.expectedCfrApprovers.forEach((approver) => {
                if (approver == loggedInUser.id) {
                  isApproverFound = true;
                }
              });
              if (isApproverFound) {
                index = i;
                ismandatory = cfrApproval.approvalLevel.ismandatory;
                apLvl = cfrApproval.approvalLevel;
                break;
              }
            }
          }
        }
        setCurrentApprovalLevel(apLvl);
        if (apLvl) {
          if (ismandatory) {
            //check if all previous levels are approved
            for (let i = 0; i < index; i++) {
              let cfrApproval = dataFromList.cfrApprovals.items[i];
              if (
                cfrApproval.approvalstatus == "pending" ||
                cfrApproval.approvalstatus == "rejected" ||
                cfrApproval.approvalstatus == "needammendment"
              ) {
                isAllPriorLevelsApproved = false;
                break;
              }
            }
            //if the cfr is already approved by user
            if (
              dataFromList.cfrApprovals.items[index] != undefined &&
              dataFromList.cfrApprovals.items[index].approvalstatus ==
              "approved"
            ) {
              isCurrentLevelApproved = true;
            }
            if (
              isAllPriorLevelsApproved == true &&
              isCurrentLevelApproved == false
            ) {
              setisApprovalButtonsVisible(true);
            }
          } else {
            //if the cfr is already approved by user
            if (
              dataFromList.cfrApprovals.items[index] != undefined &&
              dataFromList.cfrApprovals.items[index].approvalstatus ==
              "approved"
            ) {
              isCurrentLevelApproved = true;
            }
            if (
              isAllPriorLevelsApproved == true &&
              isCurrentLevelApproved == false
            ) {
              setisApprovalButtonsVisible(true);
            }
          }
        }
        //check if it is procurment approver
        if (dataFromList.cfrApprovals.items[index]) {
          if (dataFromList.cfrApprovals.items[index]) {
            if (dataFromList.cfrApprovals.items[index].approvalLevel) {
              if (
                dataFromList.cfrApprovals.items[index].approvalLevel
                  .levelName == AppConstants.PROCUREMENT_LVL
              ) {
                setisProcurementApprover(true);
              }
            }
          }
        }
        //check if its procurment level is approved
        for (let i = 0; i < dataFromList.cfrApprovals.items.length; i++) {
          let cfrApproval = dataFromList.cfrApprovals.items[i];
          if (cfrApproval.approvalLevel) {
            if (
              cfrApproval.approvalLevel.levelName ==
              AppConstants.PROCUREMENT_LVL
            ) {
              if (cfrApproval.approvalstatus == "approved") {
                setisProcurementApproved(true);
              } else {
                setisProcurementApproved(false);
              }
              break;
            }
          }
        }

        if (dataFromList.npvjson != null) {
          let npvdata = JSON.parse(dataFromList.npvjson);
          setCapExRows(npvdata.capExRows);
          setRevExRows(npvdata.revExRows);
          setCapExContingencyChecked(npvdata.capExContingencyChecked);
          setCapExContingencyType(npvdata.capExContingencyType);
          setContingencyPercentage(npvdata.contingencyPercentage);
          setCapex_contingency(npvdata.capex_contingency);
          setCapex_total(npvdata.capex_total);
          setYearlyCapexValues(npvdata.yearlyCapexValues);
          setYearlyRevexValues(npvdata.yearlyRevexValues);
          setCostValues(npvdata.costValues);
          setTotalSavings(npvdata.totalSavings);
          setRevex_total(npvdata.revex_total);
          setAssetLife(npvdata.assetLife);
          setInterestRate(npvdata.interestRate);
          setCurrency(npvdata.currency);
          setCurrencylogo(
            npvdata.currencySymbol != undefined ? npvdata.currencySymbol : ""
          );
          setUndiscountedPaybackPeriod(
            npvdata.paybackYear != null
              ? npvdata.paybackYear
              : dataFromList.payback
          );
          setCapexAnalysisPeriod(npvdata.capexAnalysisPeriod);
          let arr = [];
          for (let i = 0; i < npvdata.capexAnalysisPeriod; i++) {
            arr.push(i);
          }
          setcapexAnalysisPeriodArray(arr);
          setColWidth(75 / npvdata.capexAnalysisPeriod);
          handleCostValues(
            npvdata.costValues,
            npvdata.capexAnalysisPeriod,
            npvdata.totalSavings,
            npvdata.interestRate
          );
        }

        if (dataFromList.cfrFiles) {
          if (dataFromList.cfrFiles.items) {
            if (dataFromList.cfrFiles.items.length > 0) {
              dataFromList.cfrFiles.items.forEach((item) => {
                item.name = item.key.split("/")[0];
              });
            }
          }
        }

        setCfrUploadedFiles(dataFromList.cfrFiles.items);
      }
    }
  }, [userDataForEmail]);

  const handleCostValues = (
    costValues,
    capexAnalysisPeriod,
    totalSavings,
    interestRate
  ) => {
    handleCashFlowValues(
      costValues,
      totalSavings,
      capexAnalysisPeriod,
      interestRate
    );
  };

  const handleCashFlowValues = (
    _lstCostValues,
    _lstTotalSaving,
    capexAnalysisPeriod,
    interestRate
  ) => {
    let _lstCashflow = [];
    if (_lstCostValues.length > 0) {
      _lstTotalSaving.forEach((item, idx) => {
        let costVal = _lstCostValues[idx];
        let calcCost = 0;
        if (Number(item.Saving) - Number(costVal.Cost) < 0) {
          calcCost = Math.floor(Number(item.Saving) - Number(costVal.Cost));
        } else {
          calcCost = Math.ceil(Number(item.Saving) - Number(costVal.Cost));
        }
        _lstCashflow.push({ Year: idx, Cost: calcCost });
      });
    }
    setCashFlowValues(_lstCashflow);
    handleCumulativeCfValues(_lstCashflow, capexAnalysisPeriod, interestRate);
    handleIrrValueCalculation(_lstCashflow, interestRate, capexAnalysisPeriod);
  };

  const handleCumulativeCfValues = (
    _lstCashflow,
    capexAnalysisPeriod,
    interestRate
  ) => {
    let _lstCumulativeCfValues = [];
    let lastCalculatedCfValue = 0;
    _lstCashflow.forEach((item, idx) => {
      if (idx == 0) {
        lastCalculatedCfValue = Number(_lstCashflow[idx].Cost);
      } else {
        let calcCfVal = 0;
        if (lastCalculatedCfValue + Number(_lstCashflow[idx].Cost) < 0) {
          calcCfVal = Math.floor(
            lastCalculatedCfValue + Number(_lstCashflow[idx].Cost)
          );
        } else {
          calcCfVal = Math.ceil(
            lastCalculatedCfValue + Number(_lstCashflow[idx].Cost)
          );
        }
        lastCalculatedCfValue = calcCfVal;
      }
      _lstCumulativeCfValues.push({ Year: idx, Cost: lastCalculatedCfValue });
    });

    setCumulativeCFValues(_lstCumulativeCfValues);
    handlePositiveCashflowValues(_lstCumulativeCfValues, _lstCashflow);
    //handlePvValues(_lstCashflow, capexAnalysisPeriod, interestRate);
  };

  const handlePositiveCashflowValues = (
    _lstCumulativeCfValues,
    _lstCashflow
  ) => {
    let _lstPositiveCashFlows = [];
    if (_lstCumulativeCfValues.length > 0) {
      _lstCumulativeCfValues.forEach((item) => {
        if (Number(item.Cost) > 0) {
          _lstPositiveCashFlows.push("TRUE");
        } else {
          _lstPositiveCashFlows.push("FALSE");
        }
      });
    }

    setPositiveCashFlowValues(_lstPositiveCashFlows);
    let period =
      _lstPositiveCashFlows.findIndex((it) => it == "TRUE") >= 0
        ? _lstPositiveCashFlows.findIndex((it) => it == "TRUE")
        : "N/A";
    setUndiscountedPaybackPeriod(period);

    if (period != "N/A") {
      if (period > 0) {
        if (_lstCashflow.length > period) {
          let partialYearPaybackPeriodVal = parseFloat(
            period -
            _lstCumulativeCfValues[period].Cost / _lstCashflow[period].Cost
          ).toFixed(2);
          setPartialYearPaybackPeriod(partialYearPaybackPeriodVal);
        }
      } else {
        setPartialYearPaybackPeriod(0);
      }
    } else {
      setPartialYearPaybackPeriod("N/A");
    }
  };

  useEffect(() => {
    if (capexAnalysisPeriodArray.length > 0) {
      if (cashFlowValues.length > 0) {
        handlePvValues(cashFlowValues, capexAnalysisPeriod, interestRate);
      }
    }
  }, [capexAnalysisPeriodArray]);

  useEffect(() => {
    if (capexAnalysisPeriodArray.length > 0) {
      if (cashFlowValues.length > 0) {
        handlePvValues(cashFlowValues, capexAnalysisPeriod, interestRate);
      }
    }
  }, [cashFlowValues]);

  const handlePvValues = (_lstCashflow, capexAnalysisPeriod, interestRate) => {
    let lstPvValues = [];
    let lstRoundedPvValues = [];
    let lastCalculatedPvValue = 0;
    // Array.from(Array(assetLife), (e, i) => {
    capexAnalysisPeriodArray.map((item, i) => {
      if (i == 0) {
        lastCalculatedPvValue = 100;
      } else {
        lastCalculatedPvValue =
          lastCalculatedPvValue / (1 + interestRate / 100);
        //Number(parseFloat(lastCalculatedPvValue / (1 + interestRate / 100)).toFixed(2));
      }
      lstPvValues.push(lastCalculatedPvValue);
    });
    lstPvValues.map((value) => {
      let roundvalue = Math.round(value);
      lstRoundedPvValues.push(roundvalue);
    });
    setPvValues(lstRoundedPvValues);
    handlePvOfCashflow(_lstCashflow, lstPvValues, capexAnalysisPeriod);
  };

  const handlePvOfCashflow = (
    lstCashflow,
    lstPvValues,
    capexAnalysisPeriod
  ) => {
    let lstPvOfCashflow = [];
    //Array.from(Array(assetLife), (e, i) => {
    capexAnalysisPeriodArray.map((item, i) => {
      lstPvOfCashflow.push(
        lstCashflow[i] != undefined && lstPvValues[i] != undefined
          ? Math.ceil(
            (Number(lstCashflow[i].Cost) * Number(lstPvValues[i])) / 100
          )
          : 0
      );
    });
    setPvOfCashflowValues(lstPvOfCashflow);
    handleCumulativePvValues(lstPvOfCashflow, capexAnalysisPeriod);
  };

  const handleCumulativePvValues = (lstPvOfCashflow, assetLife) => {
    let lstCumulativePvValues = [];
    let lastCalculatedPvValue = 0;
    lstPvOfCashflow.forEach((item, idx) => {
      if (idx == 0) {
        lastCalculatedPvValue = Number(lstPvOfCashflow[idx]);
      } else {
        lastCalculatedPvValue = Math.ceil(
          parseFloat(lastCalculatedPvValue + Number(lstPvOfCashflow[idx]))
        );
        //Number(parseFloat(lastCalculatedPvValue + Number(lstPvOfCashflow[idx])).toFixed(2));
      }
      lstCumulativePvValues.push(lastCalculatedPvValue);
    });

    setCumulativePVValues(lstCumulativePvValues);
    let npvVal =
      lstCumulativePvValues[parseFloat(capexAnalysisPeriod) - 1] != undefined
        ? lstCumulativePvValues[parseFloat(capexAnalysisPeriod) - 1]
        : 0;
    setNPVValue(npvVal);
  };

  const handleIrrValueCalculation = (
    lstCashflow,
    interestRateVal,
    assetLife
  ) => {
    let NPV = 0;
    if (lstCashflow.length > 0) {
      let min = 0.0;
      let max = 1.0;
      let guess = 0;
      do {
        guess = (min + max) / 2;
        NPV = 0;
        for (var j = 0; j < lstCashflow.length; j++) {
          NPV += Math.ceil(
            parseFloat(lstCashflow[j].Cost / Math.pow(1 + guess, j))
          );
          //Number(parseFloat(lstCashflow[j].Cost / Math.pow(1 + guess, j)).toFixed(2));
        }

        if (guess == 0 || guess == 1) {
          break;
        }

        if (NPV > 0) {
          min = guess;
        } else {
          max = guess;
        }
        //console.log("Math.abs(NPV): " + Math.abs(NPV) + " guess: " + guess);
      } while (Math.abs(NPV) > 0.000001);
      if (Math.ceil(guess * 100) > 0) {
        setIRRValue(Math.ceil(guess * 100));
      } else {
        setIRRValue("N/A");
      }
    }
  };

  const DownloadFile = (item, fileId) => async () => {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: DownloadFile";
    //console.log("downloading" + item.key);
    setIsLoading(true);
    try {
      const result = await Storage.get(item.key, { download: true });
      if (result) {
        await downloadBlob(result.Body, item.key);
      }
      setIsLoading(false);
    } catch (error) {
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      }
      setIsLoading(false);
      alert.show(
        "Something went wrong while downloading, please contact administrator.",
        {
          type: "success",
          onClose: () => {
            //props.history.push("/cfrlist");
          }, // callback that
        }
      );
    }
  };

  async function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    let file = filename.split("/")[0];
    a.download = file || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  function printCfr() {
    html2canvas($("#cfDetailsDiv")[0], {
      allowTaint: true,
      onclone: function (documentClone) {
        var divContents = document.getElementById("cfDetailsDiv").innerHTML;
        var a = window.open("", "", "height=600, width=1000");
        var base_url = window.location.origin;
        console.log(base_url);
        var htmlhead =
          "<html><head><link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.1.1/css/bootstrap.min.css'> <script src='https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js'></script> <script src='https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.1.1/js/bootstrap.min.js'></script></head><body>";
        var cfrlogohtml =
          "<div className='row'> <img src='" +
          base_url +
          "/images/cfr-logo-browser.svg' style='height:150px;padding-left:20%;' className='img-thumbnail'> </div>";
        var htmlbody =
          htmlhead +
          cfrlogohtml +
          '<div className="page-container">' +
          divContents +
          "</div> </body></html>";
        if (a) {
          if (a.document) {
            a.document.write(htmlbody);
            setTimeout(function () {
              //a.document.close();
              a.print();
            }, 500);
          }
        }
      },
    }).then(function (canvas) { });
  }

  const changeCfrRequest = () => {
    let cfrdata = _.cloneDeep(cfrObj);
    props.history.push({
      pathname: "/cfr/changeCfrRequest",
      state: cfrdata,
    });
  };

  const requestIO = async () => {
    const loggedInUser = getLoggedInUser();
    //set isIORequestGenerated property to true
    let cfrdata = _.cloneDeep(cfrObj);
    let newCFRData = {
      ...cfrdata,
      isIORequestGenerated: true,
      location: selectedPlant,
      costcenter: cfrCostCenter,
      profitcenter: cfrProfitCenter,
    };
    //let resourceHtmlArr = [];
    let capExHtmlArr = [];
    let revExHtmlArr = [];
    // let resourcesArr = cfrdata.cfrResources.items;
    // if (resourcesArr.length > 0) {
    //   resourcesArr.map((resource) => {
    //     let resourceHtml = `<tr>
    //     <td style='padding: 20px 20px 0px 20px; background-color: #fff; color: #153643; font-family: Arial, sans-serif; font-size: 14px; line-height: 20px; text-align: justify;'>
    //       ${resource.role}
    //     </td>
    //     <td style='padding: 20px 20px 0px 20px; background-color: #fff; color: #153643; font-family: Arial, sans-serif; font-size: 14px; line-height: 20px; text-align: justify;'>
    //       ${resource.rate}
    //     </td>
    //     <td style='padding: 20px 20px 0px 20px; background-color: #fff; color: #153643; font-family: Arial, sans-serif; font-size: 14px; line-height: 20px; text-align: justify;'>
    //       ${resource.time}
    //     </td>
    //     <td style='padding: 20px 20px 0px 20px; background-color: #fff; color: #153643; font-family: Arial, sans-serif; font-size: 14px; line-height: 20px; text-align: justify;'>
    //       ${resource.total}
    //     </td>
    //   </tr>`;
    //     resourceHtmlArr.push(resourceHtml);
    //   });
    // } else {
    //   let resourceHtml = `<tr>
    //     <td colspan='4' style='padding: 20px 20px 0px 20px; background-color: #fff; color: #153643; font-family: Arial, sans-serif; font-size: 14px; line-height: 20px; text-align: justify;'>
    //       No records to display
    //     </td>
    //   </tr>`;
    //   resourceHtmlArr.push(resourceHtml);
    // }
    if (capExRows.length > 0) {
      capExRows.map((row) => {
        let capExHtml = `<tr>
        <td style='padding: 20px 20px 0px 20px; background-color: #fff; color: #153643; font-family: Arial, sans-serif; font-size: 14px; line-height: 20px; text-align: justify;'>
          ${row.capex_resource}
        </td>
        <td style='padding: 20px 20px 0px 20px; background-color: #fff; color: #153643; font-family: Arial, sans-serif; font-size: 14px; line-height: 20px; text-align: justify;'>
          ${row.capex_category}
        </td>
        <td style='padding: 20px 20px 0px 20px; background-color: #fff; color: #153643; font-family: Arial, sans-serif; font-size: 14px; line-height: 20px; text-align: justify;'>
          ${row.capex_quantity}
        </td>
        <td style='padding: 20px 20px 0px 20px; background-color: #fff; color: #153643; font-family: Arial, sans-serif; font-size: 14px; line-height: 20px; text-align: justify;'>
          ${row.capex_unitcost}
        </td>
        <td style='padding: 20px 20px 0px 20px; background-color: #fff; color: #153643; font-family: Arial, sans-serif; font-size: 14px; line-height: 20px; text-align: justify;'>
          ${row.capex_totalunitcost}
        </td>
      </tr>`;
        capExHtmlArr.push(capExHtml);
      });
    } else {
      let capExHtml = `<tr>
        <td colspan='9' style='padding: 20px 20px 0px 20px; background-color: #fff; color: #153643; font-family: Arial, sans-serif; font-size: 14px; line-height: 20px; text-align: justify;'>
          No records to display
        </td>
      </tr>`;
      capExHtmlArr.push(capExHtml);
    }

    if (revExRows.length > 0) {
      revExRows.map((row) => {
        let revExHtml = `<tr>
        <td style='padding: 20px 20px 0px 20px; background-color: #fff; color: #153643; font-family: Arial, sans-serif; font-size: 14px; line-height: 20px; text-align: justify;'>
          ${row.revex_resource}
        </td>
        <td style='padding: 20px 20px 0px 20px; background-color: #fff; color: #153643; font-family: Arial, sans-serif; font-size: 14px; line-height: 20px; text-align: justify;'>
          ${row.revex_cost}
        </td>
      </tr>`;
        revExHtmlArr.push(revExHtml);
      });
    } else {
      let revExHtml = `<tr>
        <td colspan='4' style='padding: 20px 20px 0px 20px; background-color: #fff; color: #153643; font-family: Arial, sans-serif; font-size: 14px; line-height: 20px; text-align: justify;'>
          No records to display
        </td>
      </tr>`;
      revExHtmlArr.push(revExHtml);
    }

    //let replaceResourcesHtml = resourceHtmlArr.join("");
    let replaceCapExHtml = capExHtmlArr.join("");
    let replaceRevExHtml = revExHtmlArr.join("");

    delete newCFRData.cfrFiles;
    delete newCFRData.cfrApprovals;
    delete newCFRData.cfrResources;
    delete newCFRData.cfrsubmissiondatestring;
    delete newCFRData.cfrCreator;
    delete newCFRData.createdAt;
    delete newCFRData.updatedAt;
    delete newCFRData.tableData;
    delete newCFRData.cfrsubmissiondatestring;
    delete newCFRData.expectedCompletionDateString;
    delete newCFRData.budgetReferenceNo;
    delete newCFRData.riskAndMitigation;
    delete newCFRData.timelineActivities;
    setIsLoading(true);
    const result = await API.graphql(
      graphqlOperation(updateCFRCapitalFundRequest, { input: newCFRData })
    );
    let filter = { action: { eq: "GENERATE_IO" } };
    const emailTemplates = await API.graphql(
      graphqlOperation(listCFREmailTemplates, { filter: filter })
    );
    const template = emailTemplates.data.listCFREmailTemplates.items[0];
    let body = template.emailcontent;
    let replaceName = body.replace("{Display Name}", "Vikramjit");
    let replaceProjName = replaceName.replace(
      "{Project Name}",
      newCFRData.projecttitle
    );
    let replaceBudgetRefNo = replaceProjName.replace(
      "{Budget Reference Number}",
      newCFRData.budgetReferenceNo
    );
    let replacePlantCode = replaceBudgetRefNo.replace(
      "{Plant Code}",
      newCFRData.location
    );
    let replaceCostCenterNo = replacePlantCode.replace(
      "{Cost Center Number}",
      newCFRData.costcenter
    );
    let replaceProfitCenterNo = replaceCostCenterNo.replace(
      "{Profit Center Number}",
      newCFRData.profitcenter
    );
    let replaceProposedAmt = replaceProfitCenterNo.replace(
      "{Proposed Amount}",
      newCFRData.proposalvalue
    );
    let replaceCFRCreator = replaceProposedAmt.replace(
      "{CFR Creator}",
      loggedInUser.name
    );

    // let replaceResources = replaceCFRCreator.replace(
    //   "{Resources-Value}",
    //   replaceResourcesHtml
    // );

    let replaceCapEx = replaceCFRCreator.replace(
      "{CapEx-Value}",
      replaceCapExHtml
    );

    let replaceRevEx = replaceCapEx.replace("{RevEx-Value}", replaceRevExHtml);

    const promises = AppConstants.IO_MAIL_RECEIVER.map(async (receiver) => {
      const emailTemplate = {
        To: receiver,
        Subject: template.emailsubject,
        MailBody: replaceRevEx,
      };
      const res = await API.graphql({
        query: sendEmail,
        variables: { EmailTemplate: JSON.stringify(emailTemplate) },
      });
      console.log(res);
      if (res) {
        return res;
      }
    });
    //wait for all email to be sent
    const allresult = await Promise.all(promises);
    if (allresult) {
      alert.show("Request for IO number is generated. ");
      await getSelectedCFR();
    }
    setIsLoading(false);
  };
  const updateIO = () => {
    setIsOpen(true);
  };

  function closeIOModal() {
    setIsOpen(false);
  }

  function onIONumberConfirmed() {
    setIsOpen(false);
    updateCFRIONumber();
  }

  /// this function for update cfr record
  async function updateCFRIONumber() {
    const errorLocation =
      "Path Name: components/cfr/cfrAddEdit.js\n Function Name: updateCFRIONumber";
    const cfrdata = {
      id: cfrObj.id,
      IONumber: IONumber,
    };
    console.log(cfrdata);
    try {
      setIsLoading(true);
      const result = await API.graphql(
        graphqlOperation(updateCFRCapitalFundRequest, { input: cfrdata })
      );
      setIsLoading(false);
      if (result.data) {
        setisIONumberSet(true);

        let filter = { action: { eq: "UPDATE_IO" } };
        const emailTemplates = await API.graphql(
          graphqlOperation(listCFREmailTemplates, { filter: filter })
        );
        if (emailTemplates.data.listCFREmailTemplates.items) {
          if (emailTemplates.data.listCFREmailTemplates.items.length > 0) {
            const template = emailTemplates.data.listCFREmailTemplates.items[0];
            if (cfrObj.cfrApprovals) {
              if (cfrObj.cfrApprovals.items) {
                cfrObj.cfrApprovals.items.map((cfrApprover) => {
                  if (cfrApprover.expectedCfrApprovers) {
                    if (cfrApprover.expectedCfrApprovers.length > 0) {
                      cfrApprover.expectedCfrApprovers.forEach((recipient) => {
                        userDataForEmail.forEach(async (user) => {
                          if (recipient == user.id) {
                            let body = template.emailcontent;
                            let replaceName = body.replace(
                              "{Display Name}",
                              user.name
                            );
                            let replaceCFRName = replaceName.replace(
                              "{CFRName}",
                              cfrObj.projecttitle
                            );
                            let replaceIONumber = replaceCFRName.replace(
                              "{IONumber}",
                              IONumber
                            );
                            let finalBody = replaceIONumber.replace(
                              "{Link}",
                              env
                            );
                            const emailTemplate = {
                              To: user.email,
                              Subject: template.emailsubject,
                              MailBody: finalBody,
                            };

                            try {
                              const res = await API.graphql({
                                query: sendEmail,
                                variables: {
                                  EmailTemplate: JSON.stringify(emailTemplate),
                                },
                              });
                              console.log(res);
                            } catch (error) {
                              console.log(error);
                            }
                          }
                        });
                      });
                    }
                  }
                });
              }
            }
            alert.show("IO number is set.");
            await getSelectedCFR();
          }
        }
      } else if (result.errors) {
        if (result.errors.length > 0) {
          alert.show(result.errors[0].message);
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {
            props.history.push("/");
          },
        });
      }
    }
  }

  const handlePlantChange = (e) => {
    console.log(e.target.value);
    setSelectedPlant(e.target.value);
  };

  const handleCostAndProfitCenterChange = (e) => {
    console.log(e.target.value);
    const profitCenter = e.target.value;
    const profitCenterArray = profitCenter.split("-");
    let costCenter;
    costAndProfitCentres.map((data) => {
      if (data.profitcenter === profitCenterArray[0]) {
        costCenter = data.costcenter;
      }
    });
    console.log(costCenter);
    setCfrCostCenter("");
    setCfrCostCenter(costCenter);
    setCfrProfitCenter("");
    setCfrProfitCenter(profitCenterArray);
  };

  const getPlantList = useCallback(async () => {
    const errorLocation =
      "Path Name: components/cfr/cfrView.js\n Function Name: getPlantList";
    try {
      setIsLoading(true);
      const plantData = await API.graphql(graphqlOperation(listCFRPlants));
      setIsLoading(false);
      if (plantData) {
        const records = plantData.data.listCFRPlants.items;
        setPlants([]);
        setPlants(records);
      } else {
        if (plantData.errors) {
          if (plantData.errors.length > 0) {
            alert.show(plantData.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  });

  const getCfrCostAndProfitCentres = useCallback(async () => {
    const errorLocation =
      "Path Name: components/cfr/cfrView.js\n Function Name: getCfrCostAndProfitCentres";
    try {
      setIsLoading(true);
      const costAndProfitCenterData = await API.graphql(
        graphqlOperation(listCFRCostAndProfitCentres)
      );
      setIsLoading(false);
      if (costAndProfitCenterData) {
        const records =
          costAndProfitCenterData.data.listCFRCostAndProfitCentres.items;
        setCostAndProfitCentres([]);
        setCostAndProfitCentres(records);
      } else {
        if (costAndProfitCenterData.errors) {
          if (costAndProfitCenterData.errors.length > 0) {
            alert.show(costAndProfitCenterData.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  });

  const getBudgetReferenceNumbers = useCallback(async () => {
    const errorLocation =
      "Path Name: components/cfr/cfrView.js\n Function Name: getBudgetReferenceNumbers";
    try {
      setIsLoading(true);
      let searchParameter = {};
      searchParameter.status = { eq: "Open" };
      const budgetData = await API.graphql(
        graphqlOperation(listCFRBudgetReferences, {
          filter: searchParameter,
          limit: 1000,
        })
      );
      setIsLoading(false);
      if (budgetData) {
        const records = budgetData.data.listCFRBudgetReferences.items;
        setBudgetReferenceNumber([]);
        setBudgetReferenceNumber(records);
      } else {
        if (budgetData.errors) {
          if (budgetData.errors.length > 0) {
            alert.show(budgetData.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  });

  const getUserListForEmail = async function () {
    const errorLocation =
      "Path Name: components/cfr/cfrView.js\n Function Name: getUserListForEmail";
    try {
      setIsLoading(true);
      const response = await API.graphql(graphqlOperation(listCFRUsers));
      if (response) {
        const allUsers = response.data.listCFRUsers.items;
        setIsLoading(false);
        setUserDataForEmail(allUsers);
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  };

  // const doc = new jsPDF();
  // //const Foo = <b>foo</b>;
  // const Foo = <img src="http://localhost:3000/images/cfr-logo.svg" />;
  // const save = () => {
  //   doc.html(ReactDOMServer.renderToStaticMarkup(Foo), {
  //     callback: () => {
  //       doc.save("myDocument.pdf");
  //     },
  //   });
  // };

  const printMultiPageDocument = () => {
    var HTML_Width = $("#cfDetailsDiv").width();
    var HTML_Height = $("#cfDetailsDiv").height();
    var top_left_margin = 15;
    var PDF_Width = HTML_Width + top_left_margin * 2;
    var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    html2canvas($("#cfDetailsDiv")[0], {
      allowTaint: true,
      onclone: function (documentClone) {
        documentClone.getElementsByClassName("cfrLogoDiv")[0].style.display =
          "block";
        if (cfrObj.budgetReferenceNo) {
          if (cfrObj.budgetReferenceNo.items) {
            if (cfrObj.budgetReferenceNo.items.length == 0) {
              documentClone.getElementById("approvalCard").style.marginBottom =
                "600px";
            } else if (cfrObj.budgetReferenceNo.items.length == 1) {
              documentClone.getElementById("approvalCard").style.marginBottom =
                "600px";
            } else if (cfrObj.budgetReferenceNo.items.length == 2) {
              documentClone.getElementById("approvalCard").style.marginBottom =
                "550px";
            } else if (cfrObj.budgetReferenceNo.items.length == 3) {
              documentClone.getElementById("approvalCard").style.marginBottom =
                "500px";
            } else if (cfrObj.budgetReferenceNo.items.length == 4) {
              documentClone.getElementById("approvalCard").style.marginBottom =
                "450px";
            } else if (cfrObj.budgetReferenceNo.items.length == 5) {
              documentClone.getElementById("approvalCard").style.marginBottom =
                "400px";
            } else {
              documentClone.getElementById("approvalCard").style.marginBottom =
                "300px";
            }
          }
        }
      },
    }).then(function (canvas) {
      canvas.getContext("2d");

      console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      var pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height], ["px_scaling"]);
      //var pdf = new jsPDF("p", "pt", "a4");
      // var pdf = new jsPDF({
      //   orientation: "p",
      //   unit: "pt",
      //   // format: [29, 12],
      //   format: "a4",
      //   hotfixes: ["px_scaling"],
      // });
      pdf.addImage(
        imgData,
        "JPG",
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      );

      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height]);
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          -(PDF_Height * i) + top_left_margin * 4,
          canvas_image_width,
          canvas_image_height
        );
      }

      pdf.save("CFR.pdf");
    });
  };

  const printDocument = () => {
    const input = document.getElementById("cfDetailsDiv");
    //const copyInput = input;
    //const copyInput = { ...input };
    //const copyInput = Object.assign({}, input);
    // const copyInput = input.cloneNode(true);
    // copyInput.getElementsByClassName("cfrLogoDiv")[0].style.display = "block";
    html2canvas(input, {
      onclone: function (documentClone) {
        documentClone.getElementsByClassName("cfrLogoDiv")[0].style.display =
          "block";
      },
    }).then((canvas) => {
      //copyInput.getElementsByClassName("cfrLogoDiv")[0].style.display = "none";
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "p",
        unit: "in",
        // format: [29, 12],
        format: "a4",
        hotfixes: ["px_scaling"],
      });
      pdf.addImage(imgData, "JPEG", 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("download.pdf");
    });
  };

  const getUserNotificationList = async function () {
    const errorLocation =
      "Path Name: components/cfr/cfrView.js\n Function Name: getUserNotificationList";
    try {
      setIsLoading(true);
      const response = await API.graphql(
        graphqlOperation(listCFRUserNotifications)
      );
      if (response) {
        const userNotifs = response.data.listCFRUserNotifications.items;
        setIsLoading(false);
        setUserNotificationsList(userNotifs);
      } else {
        if (response.errors) {
          if (response.errors.length > 0) {
            alert.show(response.errors[0].message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
          const loggedInUser = getLoggedInUser();
          let filter = { action: { eq: "ERROR_MESSAGE" } };
          const emailTemplates = await API.graphql(
            graphqlOperation(listCFREmailTemplates, { filter: filter })
          );
          const template = emailTemplates.data.listCFREmailTemplates.items[0];
          let body = template.emailcontent;
          let replaceName = body.replace(
            "{Display Name}",
            AppConstants.ERROR_MAIL_RECEIVER_DISPLAY_NAME
          );
          let replaceUser = replaceName.replace(
            "{Logged User}",
            loggedInUser.name
          );

          let replaceError = replaceUser.replace(
            "{Error}",
            `Error: ${error.errors[0].message} at ${errorLocation}`
          );
          const emailTemplate = {
            To: AppConstants.ERROR_MAIL_RECEIVER,
            Subject: template.emailsubject,
            MailBody: replaceError,
          };

          const res = await API.graphql({
            query: sendEmail,
            variables: {
              EmailTemplate: JSON.stringify(emailTemplate),
            },
          });

          console.log(res);
        }
      } else if (error.message) {
        alert.show(error.message);
      }
    }
  };

  return (
    <div className="page-container">
      {isLoading ? <PageLoader></PageLoader> : ""}
      <div className="page-header">
        <h4> CFR Details </h4>

        {/* <Pdf
          targetRef={ref}
          filename="cfr.pdf"
          options={pdfoptions}
          x={0.5}
          y={0.5}
          scale={0.9}
        >
          {({ toPdf }) => (
            <button
              className="btn-grid fa fa-download"
              onClick={toPdf}
            ></button>
          )}
        </Pdf> */}

        <button
          type="button"
          className="btn-grid fa fa-print"
          onClick={() => printCfr()}
        ></button>
        <button
          type="button"
          className="btn-grid fa fa-download"
          onClick={printMultiPageDocument}
        ></button>
      </div>

      <div id="cfDetailsDiv" ref={ref}>
        <div id="cfrLogoDiv" className="cfrLogoDiv" style={{ display: "none" }}>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <img
                style={{ width: 450, height: 200 }}
                src="/images/cfr-logo-browser.png"
              />
            </div>
          </div>
          <div className="col-sm-3"></div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6  mb-3">
                <label className="form-label"> Project Title </label>
                <div> {cfrObj?.projecttitle} </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6  mb-3">
                <label className="form-label"> Geography</label>
                <div> {cfrObj?.territory} </div>
              </div>
              <div className="col-sm-6  mb-3">
                <label className="form-label"> Version </label>
                <div> {cfrObj?.version}</div>
              </div>
              {/* <div className="col-sm-6  mb-3">
                <label className="form-label"> Plant Id/Location </label>
                <div> {cfrObj.location}</div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-sm-6  mb-3">
                <label className="form-label"> Budget Value Type </label>
                <div> {cfrObj?.budgetvaluetype}</div>
              </div>
              <div className="col-sm-6  mb-3">
                <label className="form-label"> Budgeted </label>
                <div> {cfrObj?.iscapitalbudget == true ? "Yes" : "No"} </div>
              </div>

              {/* {cfrObj?.iscapitalbudget == true && (
                <div className="col-sm-6  mb-3">
                  <label className="form-label">Budget Reference Number</label>
                  {cfrObj.budgetReferenceNo != null &&
                  cfrObj.budgetReferenceNo?.items.map((obj) => {
                    <div> {obj.budgetReferenceNo}&nbsp</div>
                  })}
                </div>
              )} */}
            </div>
            {/* <div className="row">
              
              {/* <div className="col-sm-6  mb-3">
                <label className="form-label">
                  Budget Value {currencylogo != "" ? currencylogo : ""}
                </label>
                <div> {cfrObj?.budgetvalue}</div>
              </div> 
            </div> */}
            <div className="row">
              <div className="col-sm-6  mb-3">
                <label className="form-label"> Proposed Value Type </label>
                <div> {cfrObj?.proposalvaluetype}</div>
              </div>
              <div className="col-sm-6  mb-3">
                <label className="form-label">
                  Proposed Value {currencylogo != "" ? currencylogo : ""}
                </label>
                <div> {cfrObj?.proposalvalue}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6  mb-3">
                <label className="form-label"> IRR (%)</label>
                <div> {cfrObj?.irr}</div>
              </div>
              <div className="col-sm-6  mb-3">
                <label className="form-label"> NPV </label>
                <div> {cfrObj?.npv}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6  mb-3">
                <label className="form-label"> Payback (Years) </label>
                <div> {cfrObj?.payback}</div>
              </div>
              <div className="col-sm-6  mb-3">
                <label className="form-label">Asset Life (Years)</label>
                <div> {cfrObj?.assetlife}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6  mb-3">
                <label className="form-label"> Budget Benefits </label>
                <div> {cfrObj?.budgetbenefits}</div>
              </div>
              <div className="col-sm-6  mb-3">
                <label className="form-label"> CFR Benefits </label>
                <div> {cfrObj?.cfrbenefits}</div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6  mb-3">
                <label className="form-label"> Submission Date </label>
                <div>{cfrObj?.cfrsubmissiondatestring}</div>
              </div>
              <div className="col-sm-6  mb-3">
                <label className="form-label"> Expected Completion Date </label>
                <div> {cfrObj?.expectedCompletionDateString}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6  mb-3">
                <label className="form-label"> Submitted By </label>
                <div> {cfrObj?.cfrCreator?.name}</div>
              </div>
              <div className="col-sm-6  mb-3">
                <label className="form-label"> Status </label>
                <div>
                  {cfrObj?.cfrstatus == "draft"
                    ? "Draft"
                    : cfrObj?.cfrstatus == "submitted"
                      ? "Submitted"
                      : cfrObj?.cfrstatus == "ammendmentinprocess"
                        ? "Ammendment in process"
                        : cfrObj?.cfrstatus == "ammended"
                          ? "Ammended"
                          : cfrObj?.cfrstatus == "approvalinprocess"
                            ? "Approval in process"
                            : cfrObj?.cfrstatus == "approved"
                              ? "Approved"
                              : cfrObj?.cfrstatus == "rejected"
                                ? "Rejected"
                                : cfrObj?.cfrstatus == "needammendment"
                                  ? "Need Ammendment"
                                  : ""}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6  mb-3">
                <label className="form-label">
                  Spend Type (Principal driver)
                </label>
                <div> {cfrObj?.spendtype ? cfrObj?.spendtype : "-"}</div>
              </div>
              <div className="col-sm-6  mb-3">
                <label className="form-label"> Main Contractor(s) </label>
                <div>
                  {cfrObj?.maincontractors ? cfrObj?.maincontractors : "-"}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8  mb-3">
                <label className="form-label"> Business Context </label>
                <div>
                  {cfrObj?.businesscontext ? cfrObj?.businesscontext : "-"}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8  mb-3">
                <label className="form-label"> Justification </label>
                <div>{cfrObj?.justification ? cfrObj?.justification : "-"}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8  mb-3">
                <label className="form-label"> Options Considered </label>
                <div>
                  {cfrObj?.optionsconsidered ? cfrObj?.optionsconsidered : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
          
        <div className="card" id="timelineActivityCard">
          <div className="card-header form-label">Timelines</div>
          <div className="card-body">
            <div className="row form-group">
              <div className="col-sm-12">
                <table className="table table-cust" id="timelineActivityTable">
                  <thead>
                    <tr>
                      <th className="width30"> Activity</th>
                      <th className="width30"> Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cfrObj?.timelineActivities?.items?.map((item, idx) => (
                      <tr id="timelineActivity" key={idx}>
                        <td className="width30">{item.activityName}</td>
                        <td className="width30">{item.activityDate}</td>
                      </tr>
                    ))}
                    {cfrObj?.timelineActivities?.items?.length == 0 && (
                      <tr>
                        <td colSpan="7" align="center">
                          No record found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        
        {/* {Budget References} */}
        <div className="card" id="budgetRefCard">
          <div className="card-header form-label">Budget References</div>
          <div className="card-body">
            <div className="row form-group">
              <div className="col-sm-12">
                <table className="table table-cust" id="budgetRefTable">
                  <thead>
                    <tr>
                      <th className="width15"> Budget Reference No. </th>
                      <th className="width20"> Description</th>
                      {/* <th className="width12">
                        
                        Remaining Amount({currencylogo})
                      </th> */}
                      <th className="width12">
                        Proposed Amount({currencylogo})
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cfrObj?.budgetReferenceNo?.items?.map((item, idx) => (
                      <tr id="budgetRef" key={idx}>
                        <td className="width15">{item.budgetReferenceNo}</td>
                        <td className="width20">{item.description}</td>
                        {/* <td className="width12">
                          {item.remainingAmount}
                        </td> */}
                        <td className="width12">{item.proposedAmount}</td>
                      </tr>
                    ))}
                    {cfrObj?.budgetReferenceNo?.items?.length == 0 && (
                      <tr>
                        <td colSpan="7" align="center">
                          No record found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* Approvals */}
        <div className="card" id="approvalCard">
          <div className="card-header form-label">
            Approvals
            {/* <div className="btn-card-toggle fa fa-angle-down"> </div> */}
          </div>
          <div className="card-body">
            <div className="row form-group d-sm-block">
              <div className="col-sm-12">
                <table className="table table-cust ">
                  <thead>
                    <tr>
                      <th className="width7"> Seq No. </th>
                      <th className="width13"> Approval Level </th>
                      <th className="width10"> Status </th>
                      <th className="width20"> SOA Approvers </th>
                      <th className="width15"> Actual Approver </th>
                      <th className="width15"> Approved On </th>
                      <th className="width20"> Justification </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cfrObj?.cfrApprovals?.items?.length > 0 &&
                      cfrObj?.cfrApprovals?.items?.map((lvl) => (
                        <tr key={lvl.id}>
                          <td className="width7">
                            {lvl.approvalLevel != null
                              ? lvl.approvalLevel.sequencenumber
                              : ""}
                          </td>
                          <td className="width13">
                            {lvl.approvalLevel != null
                              ? lvl.approvalLevel.levelName
                              : ""}
                          </td>
                          <td className="width10">
                            {lvl.approvalstatus == "pending"
                              ? "Pending"
                              : lvl.approvalstatus == "approved"
                                ? "Approved"
                                : lvl.approvalstatus == "rejected"
                                  ? "Rejected"
                                  : lvl.approvalstatus == "needammendment"
                                    ? "Need Ammendment"
                                    : ""}
                          </td>
                          <td className="width20">
                            {lvl.expectedCfrApproverNames}
                            {/* {lvl.expectedCfrApprovers.length > 0 &&
                              lvl?.expectedCfrApprovers?.map(
                                (expectedApproverId) => {
                                  userDataForEmail.map((user) => {
                                    if (expectedApproverId == user.id) {
                                      <p>{user.name}</p>;
                                    }
                                  });
                                }
                              )} */}
                          </td>
                          <td className="width15">
                            {lvl.actualCfrApprover != null
                              ? lvl.actualCfrApprover.name
                              : ""}
                          </td>
                          <td className="width15">
                            {lvl.approvalDateTime != null
                              ? new Date(lvl.approvalDateTime).toLocaleString()
                              : ""}
                          </td>
                          <td className="width20">
                            {lvl.approverjustification}
                          </td>
                        </tr>
                      ))}
                    {cfrObj?.cfrApprovals?.items?.length == 0 && (
                      <tr>
                        <td colSpan="4" align="center">
                          No record Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header form-label">
            Capital Expenditure (CapEx)
          </div>
          <div className="card-body">
            <div className="row form-group d-sm-block">
              <div className="col-sm-12">
                <table className="table table-cust" id="CapExTable">
                  <thead>
                    <tr>
                      <th className="width30"> Resource </th>
                      {/* <th className="width15"> Asset Code </th>
                      <th className="width15"> Eval Cat </th>
                      <th className="width15"> Desc </th> 
                      <th className="width15"> Life </th>*/}
                      <th className="width20"> Category </th>
                      <th className="width10"> Quantity </th>
                      <th className="width20"> Unit Cost ({currencylogo}) </th>
                      <th className="width20"> Total Cost ({currencylogo}) </th>
                    </tr>
                  </thead>
                  <tbody>
                    {capExRows.map((item, idx) => (
                      <tr id="capex" key={idx}>
                        <td className="width30">
                          {capExRows[idx].capex_resource}
                        </td>
                        {/* <td className="width15">
                          {capExRows[idx].capex_assetName}
                        </td>
                        <td className="width15">
                          {capExRows[idx].capex_evaluationCategory}
                        </td>
                        <td className="width15">
                          {capExRows[idx].capex_description}
                        </td> 
                        <td className="width15">{capExRows[idx].capex_life}</td>*/}
                        <td className="width20">
                          {capExRows[idx].capex_category}
                        </td>
                        <td className="width10">
                          {capExRows[idx].capex_quantity}
                        </td>
                        <td className="width20">
                          {capExRows[idx].capex_unitcost}
                        </td>
                        <td className="width20">
                          {capExRows[idx].capex_totalunitcost}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="4">
                        <div className="form-check pt-1">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="capExContingency"
                            id="capExContingency"
                            checked={capExContingencyChecked}
                            readOnly
                          />
                          <label
                            className="form-check-label"
                            htmlFor="defaultCheck1"
                          >
                            Contingency (In {capExContingencyType})
                          </label>
                          <label
                            className="form-check-label"
                            style={{ marginLeft: "15px" }}
                          >
                            {contingencyPercentage}
                          </label>
                        </div>
                      </td>
                      <td className="width15">{capex_contingency}</td>
                    </tr>
                    <tr>
                      <td colSpan="4" align="right">
                        <div className="pt-1">
                          <label className="form-label">Total</label>
                        </div>
                      </td>
                      <td className="width15">{capex_total}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-header form-label">
            Capital Related Revenue (CRR)
          </div>
          <div className="card-body">
            <div className="row form-group">
              <div className="col-sm-12">
                <table className="table table-cust" id="RevExTable">
                  <thead>
                    <tr>
                      <th className="width60"> Resource </th>
                      {/* <th className="width13"> Start Year </th>
                      <th className="width12"> End Year </th> */}
                      <th className="width15"> Cost ({currencylogo}) </th>
                    </tr>
                  </thead>
                  <tbody>
                    {revExRows.map((item, idx) => (
                      <tr id="revex" key={idx}>
                        <td className="width80">
                          {revExRows[idx].revex_resource}
                        </td>
                        {/* <td className="width13">
                          {revExRows[idx].revex_start_year}
                        </td>
                        <td className="width12">
                          {revExRows[idx].revex_end_year}
                        </td> */}
                        <td className="width20">{revExRows[idx].revex_cost}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="1" align="right">
                        <div className="pt-1">
                          <label className="form-label">Total</label>
                        </div>
                      </td>
                      <td className="width20">{revex_total}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="card" id="riskMitigationCard">
          <div className="card-header form-label">Risks and Mitigation Plan</div>
          <div className="card-body">
            <div className="row form-group">
              <div className="col-sm-12">
                <table className="table table-cust" id="riskMitigationTable">
                  <thead>
                    <tr>
                        <th className="width30"> Risk </th>
                        <th className="width30"> Mitigation </th>
                        <th className="width25"> Consequence </th>
                        <th className="width10"> Category </th>
                        <th className="width10"> Occurrence </th>
                        <th className="width15"> Owner </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cfrObj?.riskAndMitigation?.items?.map((item, idx) => (
                      <tr id="risk" key={idx}>
                        <td className="width30">{item.risk}</td>
                        <td className="width30">{item.mitigation}</td>
                        <td className="width25">{item.consequence}</td>
                        <td className="width10">{item.category}</td>
                        <td className="width10">{item.occurrence}</td>
                        <td className="width15">{item.owner}</td>
                      </tr>
                    ))}
                    {cfrObj?.riskAndMitigation?.items?.length == 0 && (
                      <tr>
                        <td colSpan="7" align="center">
                          No record found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        

        <div className="card">
          <div className="card-header form-label">NPV Calculation </div>
          <div className="card-body p-4">
            <div className="row form-group">
              <div className="col-sm-12">
                <table
                  className="table table-bordered table-sm table-responsive"
                  id="CumulativeTable"
                >
                  <tbody>
                    <tr>
                      <td className="width15">
                        <label className="form-label pt-1">Interest Rate</label>
                      </td>
                      <td className="width15" align="right">
                        <label className="form-label pt-1">
                          {interestRate}%
                        </label>
                      </td>
                      <td colSpan={4}></td>
                    </tr>
                    <tr>
                      <td className="width15">
                        <label className="form-label pt-1">
                          Capex Analysis Period
                        </label>
                      </td>
                      <td className="width15" align="right">
                        <label className="form-label pt-1">
                          {capexAnalysisPeriod} years
                        </label>
                      </td>
                      <td colSpan={4}></td>
                    </tr>
                  </tbody>
                </table>
                <table
                  className="table table-bordered table-sm table-responsive"
                  id="CumulativeTable"
                >
                  <tbody>
                    <tr>
                      <td className="width15" />
                      {capexAnalysisPeriodArray.map((item, idx) => {
                        return (
                          <td
                            key={idx}
                            className=""
                            align="center"
                            style={{ width: colWidth + "%" }}
                          >
                            <label className="form-label">Y{idx}</label>
                          </td>
                        );
                      })}
                      <td align="center" className="width10">
                        <label className="form-label">Total</label>
                      </td>
                    </tr>

                    <tr>
                      <td className="width15 ">
                        <label className="form-label pt-1">CapEx</label>
                      </td>
                      {yearlyCapexValues.map((item, idx) => {
                        return (
                          <td
                            key={idx}
                            align="center"
                            style={{ width: colWidth + "%" }}
                          >
                            <span>{yearlyCapexValues[idx].Cost}</span>
                          </td>
                        );
                      })}

                      <td align="center" className="width10">
                        <label className="form-label">
                          {yearlyCapexValues.reduce(
                            (total, obj) =>
                              Number(
                                parseFloat(Number(obj.Cost) + total).toFixed(2)
                              ),
                            0
                          )}
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td className="width15 ">
                        <label className="form-label pt-1">CRR</label>
                      </td>
                      {yearlyRevexValues == undefined ||
                        yearlyRevexValues.length == 0
                        ? Array.from(Array(capexAnalysisPeriod), (e, i) => {
                          return (
                            <td
                              key={i}
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              0
                            </td>
                          );
                        })
                        : yearlyRevexValues.map((item, idx) => {
                          return (
                            <td
                              key={idx}
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              <span>{yearlyRevexValues[idx].Cost}</span>
                            </td>
                          );
                        })}

                      <td align="center" className="width10">
                        <label className="form-label">
                          {yearlyRevexValues.reduce(
                            (total, obj) =>
                              Number(
                                parseFloat(Number(obj.Cost) + total).toFixed(2)
                              ),
                            0
                          )}
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td className="width15 ">
                        <label className="form-label pt-1">Cost</label>
                      </td>
                      {costValues == undefined || costValues.length == 0
                        ? Array.from(Array(capexAnalysisPeriod), (e, i) => {
                          return (
                            <td
                              key={i}
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              0
                            </td>
                          );
                        })
                        : costValues.map((item, idx) => {
                          return (
                            <td
                              key={idx}
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              <span>{costValues[idx].Cost}</span>
                            </td>
                          );
                        })}

                      <td align="center" className="width10">
                        <label className="form-label">
                          {costValues.reduce(
                            (total, obj) =>
                              Number(
                                parseFloat(Number(obj.Cost) + total).toFixed(2)
                              ),
                            0
                          )}
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td className="width15 ">
                        <label className="form-label">Savings</label>
                      </td>
                      {capexAnalysisPeriodArray.map((item, i) => {
                        return (
                          <td
                            key={i}
                            align="center"
                            style={{ width: colWidth + "%" }}
                          >
                            {totalSavings[i] != undefined
                              ? totalSavings[i].Saving
                              : 0}
                          </td>
                        );
                      })}
                      <td align="center">
                        <label className="form-label">
                          {totalSavings.reduce(
                            (total, obj) => Number(obj.Saving) + total,
                            0
                          ) != 0
                            ? totalSavings.reduce(
                              (total, obj) => Number(obj.Saving) + total,
                              0
                            )
                            : ""}
                        </label>
                      </td>
                    </tr>

                    <tr>
                      <td className="width15 ">
                        <label className="form-label">Cashflow</label>
                      </td>
                      {cashFlowValues == undefined || cashFlowValues.length == 0
                        ? capexAnalysisPeriodArray.map((item, i) => {
                          return (
                            <td
                              key={i}
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              0
                            </td>
                          );
                        })
                        : cashFlowValues.map((item, idx) => {
                          return (
                            <td
                              key={idx}
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              <span>{cashFlowValues[idx].Cost}</span>
                            </td>
                          );
                        })}
                      <td />
                    </tr>

                    <tr>
                      <td className="width15 ">
                        <label className="form-label">Cumulative CF</label>
                      </td>
                      {cumulativeCFValues == undefined ||
                        cumulativeCFValues.length == 0
                        ? capexAnalysisPeriodArray.map((item, i) => {
                          return (
                            <td
                              key={i}
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              0
                            </td>
                          );
                        })
                        : cumulativeCFValues.map((item, idx) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                              key={idx}
                            >
                              <span>{cumulativeCFValues[idx].Cost}</span>
                            </td>
                          );
                        })}
                      <td />
                    </tr>
                    <tr>
                      <td className="width15 ">
                        <label className="form-label">+ve Cashflow</label>
                      </td>
                      {positiveCashFlowValues == undefined ||
                        positiveCashFlowValues.length == 0
                        ? capexAnalysisPeriodArray.map((item, i) => {
                          return (
                            <td
                              key={i}
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              FALSE
                            </td>
                          );
                        })
                        : positiveCashFlowValues.map((item, idx) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                              key={idx}
                            >
                              <span>{positiveCashFlowValues[idx]}</span>
                            </td>
                          );
                        })}
                      <td />
                    </tr>
                    <tr>
                      <td className="width15 ">
                        <label className="form-label">PV</label>
                      </td>
                      {pvValues == undefined || pvValues.length == 0
                        ? capexAnalysisPeriodArray.map((item, i) => {
                          return (
                            <td
                              key={i}
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              0
                            </td>
                          );
                        })
                        : pvValues.map((item, idx) => {
                          return (
                            <td
                              key={idx}
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              <span>{pvValues[idx]}%</span>
                            </td>
                          );
                        })}
                      <td />
                    </tr>
                    <tr>
                      <td className="width15 ">
                        <label className="form-label">PV of Cashflow</label>
                      </td>
                      {pvOfCashflowValues == undefined ||
                        pvOfCashflowValues.length == 0
                        ? capexAnalysisPeriodArray.map((item, i) => {
                          return (
                            <td
                              key={i}
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              0
                            </td>
                          );
                        })
                        : pvOfCashflowValues.map((item, idx) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                              key={idx}
                            >
                              <span>{pvOfCashflowValues[idx]}</span>
                            </td>
                          );
                        })}
                      <td />
                    </tr>
                    <tr>
                      <td className="width15 ">
                        <label className="form-label">Cumulative PV</label>
                      </td>
                      {cumulativePVValues == undefined ||
                        cumulativePVValues.length == 0
                        ? capexAnalysisPeriodArray.map((item, i) => {
                          return (
                            <td
                              key={i}
                              align="center"
                              style={{ width: colWidth + "%" }}
                            >
                              0
                            </td>
                          );
                        })
                        : cumulativePVValues.map((item, idx) => {
                          return (
                            <td
                              align="center"
                              style={{ width: colWidth + "%" }}
                              key={idx}
                            >
                              <span>{cumulativePVValues[idx]}</span>
                            </td>
                          );
                        })}
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row form-group pt-3">
              <div className="col-sm-12">
                <table
                  className="table table-bordered table-sm table-responsive width30"
                  id="npvTable"
                >
                  <tbody>
                    <tr>
                      <td className="width40"></td>
                      <td className=" width10" align="center">
                        <label className="form-label">
                          {capexAnalysisPeriod} Years
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td className=" width40">
                        <label className="form-label">NPV </label>
                      </td>
                      <td className="width10" align="center">
                        {npvValue}
                      </td>
                    </tr>
                    <tr>
                      <td className=" width40">
                        <label className="form-label">IRR (%) </label>
                      </td>
                      <td className="width10" align="center">
                        {irrValue}
                      </td>
                    </tr>
                    <tr>
                      <td className=" width40">
                        <label className="form-label">
                          Undiscounted Payback Period
                        </label>
                      </td>
                      <td className="width10" align="center">
                        {undiscountedPaybackPeriod}
                      </td>
                    </tr>
                    <tr>
                      <td className=" width40">
                        <label className="form-label">
                          Partial Year Payback Period
                        </label>
                      </td>
                      <td className="width10" align="center">
                        {partialYearPaybackPeriod}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {cfrUploadedFiles.length > 0 && (
        <div className="card d-sm-block border-0">
          <div className="card-header">
            <label className="form-label">Uploaded Documents</label>
          </div>
          <div className="card-body">
            <div className="row form-group">
              <div className="col-sm-12">
                <table className="table table-cust" id="RevExTable">
                  <thead>
                    <tr>
                      <th> File Name </th>
                      <th className="width15"> Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cfrUploadedFiles.map((item, idx) => (
                      <tr id="cfrfile" key={item.id}>
                        <td>{item.name}</td>
                        <td className="width15">
                          <button
                            type="button"
                            className="btn-grid fa fa-download pull-left"
                            title="Download file"
                            onClick={DownloadFile(item, item.id)}
                          ></button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      {isApprovalButtonsVisible &&
        (cfrObj?.cfrstatus == "submitted" ||
          cfrObj?.cfrstatus == "approvalinprocess") && (
          <div className="card">
            <div className="card-header form-label">Comments</div>
            <div className="card-body">
              <div className="row form-group mb-3 d-none d-sm-block">
                <label className="form-label">
                  Rejection/Ammendment Justification
                </label>
                <div className="col-sm-12">
                  <textarea
                    id="txtJustification"
                    name="justification"
                    className="form-control"
                    rows="3"
                    // value={justification}
                    onChange={(e) => handleJustification(e)}
                  />
                </div>
              </div>
              {/* {isProcurementApprover &&
                cfrObj?.cfrstatus == "approvalinprocess" && (
                  <div className="row form-group mb-3">
                    <div className="col-sm-6">
                      <label className="form-label">Cost Center</label>
                      <input
                        type="text"
                        id="txtCostCenter"
                        name="costcenter"
                        className="form-control"
                        onChange={(e) => handleCostCenter(e)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label">Profit Center</label>
                      <input
                        type="text"
                        id="txtProfitCenter"
                        name="profitcenter"
                        className="form-control"
                        onChange={(e) => handleProfitCenter(e)}
                      />
                    </div>
                  </div>
                )} */}
            </div>
          </div>
        )}

      {isCfrCreator && isProcurementApproved && cfrObj?.cfrstatus == "approved" && (
        <div className="card">
          <div className="card-body">
            <div className="row form-group mb-3">
              <div className="col-sm-3 mb-3">
                <label className="form-label">Plant</label>
                <input
                  type="text"
                  className="form-control"
                  value={cfrObj?.location}
                  readOnly="true"
                  hidden={cfrObj?.isIORequestGenerated === false ? true : false}
                />
                <select
                  className="ddl-control"
                  onChange={handlePlantChange}
                  hidden={cfrObj?.isIORequestGenerated === true ? true : false}
                >
                  <option defaultValue>---select plant---</option>
                  {plants.map((data) => (
                    <option
                      value={data.plantCode}
                    >{`${data.plantCode} - ${data.name}`}</option>
                  ))}
                </select>
              </div>
              <div className="col-sm-3 mb-3">
                <label className="form-label">Profit Center</label>
                <input
                  type="text"
                  className="form-control"
                  value={cfrObj?.profitcenter?.replace("[", "").replace("]", "")}
                  readOnly="true"
                  hidden={cfrObj?.isIORequestGenerated === false ? true : false}
                />
                <select
                  className="ddl-control"
                  onChange={handleCostAndProfitCenterChange}
                  hidden={cfrObj?.isIORequestGenerated === true ? true : false}
                >
                  <option defaultValue>---select profit center---</option>
                  {costAndProfitCentres.map((data) => (
                    <option
                      value={`${data.profitcenter}-${data.profitcenterdesc}`}
                    >{`${data.profitcenter} - ${data.profitcenterdesc}`}</option>
                  ))}
                </select>
              </div>
              <div className="col-sm-3 mb-3">
                <label className="form-label">Cost Center</label>
                <input
                  type="text"
                  className="form-control"
                  value={cfrObj?.costcenter}
                  readOnly="true"
                  hidden={cfrObj?.isIORequestGenerated === false ? true : false}
                />
                <select
                  className="ddl-control"
                  //onChange={handleCatagoryChange}
                  value={cfrCostCenter}
                  hidden={cfrObj?.isIORequestGenerated === true ? true : false}
                >
                  <option defaultValue>---select cost center---</option>
                  <option>{cfrCostCenter}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      )}

      {cfrObj?.IONumber && cfrObj?.IONumber !== "" && (
        <div className="card">
          <div className="card-body">
            <div className="row form-group mb-3">
              <div className="col-sm-3 mb-3">
                <label className="form-label">IO Number</label>
                <input
                  type="text"
                  className="form-control"
                  value={cfrObj?.IONumber}
                  readOnly="true"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="form-footer">
        <div className="row">
          <div className="col-sm-12">
            <div className="button-group">
              {isApprovalButtonsVisible &&
                (cfrObj?.cfrstatus == "submitted" ||
                  cfrObj?.cfrstatus == "approvalinprocess") && (
                  <button
                    type="button"
                    className="btn btn-sm  btn-primary ml-10"
                    onClick={() => updateCFR("Approve")}
                  >
                    Approve
                  </button>
                )}
              {isApprovalButtonsVisible &&
                (cfrObj?.cfrstatus == "submitted" ||
                  cfrObj?.cfrstatus == "approvalinprocess") && (
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary ml-10"
                    onClick={() => updateCFR("Reject")}
                  >
                    Reject
                  </button>
                )}
              {isApprovalButtonsVisible &&
                (cfrObj?.cfrstatus == "submitted" ||
                  cfrObj?.cfrstatus == "approvalinprocess") && (
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary ml-10"
                    onClick={() => updateCFR("Ammend")}
                  >
                    Need Ammendment
                  </button>
                )}
              {isProcurementApproved &&
                cfrObj?.cfrstatus == "approved" &&
                (cfrObj?.isIORequestGenerated === false ||
                  cfrObj?.isIORequestGenerated === null) && (
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary ml-10"
                    onClick={() => requestIO()}
                  >
                    Request IO
                  </button>
                )}
              {isIONumberSet == false &&
                cfrObj?.isIORequestGenerated === true &&
                (cfrObj?.IONumber == "" || cfrObj?.IONumber == null) && (
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary ml-10"
                    onClick={() => updateIO()}
                  >
                    Update IO
                  </button>
                )}
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary ml-10"
                onClick={() => props.history.goBack()}
              >
                Cancel
              </button>
              {isCfrCreator &&
                !isAnyChildCfrInDraftMode &&
                cfrObj?.cfrstatus === "approved" &&
                cfrObj?.IONumber !== "" && (
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary ml-10"
                    onClick={() => changeCfrRequest()}
                  >
                    Change CFR Request
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        //onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
      >
        <div className="row">
          <div className="col-sm-9">
            <h4>Set IO Number</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8  mb-3">
            <label className="form-label">
              IO Number <span> * </span>
            </label>
            <input
              type="text"
              id="txtIONumber"
              name="ionumber"
              className="form-control"
              onChange={(e) => handleIONumber(e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <button
              className="btn btn-sm btn-primary"
              style={{ width: "80px", marginRight: "5px" }}
              onClick={onIONumberConfirmed}
            >
              OK
            </button>
            <button
              className="btn btn-sm btn-primary"
              style={{ width: "80px", marginRight: "15px" }}
              onClick={closeIOModal}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default CFRView;
