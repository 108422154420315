import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useAlert } from "react-alert";
import { API, graphqlOperation } from "aws-amplify";
import { listCFRRoles } from "../../graphql/customQueries";
import { getLoggedInUser } from "../../Utils/common";
import { Alert as alert } from "bootstrap";
import { Roles } from "../../common/constants";
import Toggle from "react-toggle";
import "../../assets/stylesheet/react-toggle.css";

function DepartmentList(props) {
  const [roleData, setRoleData] = useState([]);
  const alert = useAlert();
  const [loggedinUser, setloggedinUser] = useState(null);

  const getRoleList = useCallback(async () => {
    try {
      const roleData = await API.graphql(graphqlOperation(listCFRRoles));
      if (roleData) {
        const roleList = roleData.data.listCFRRoles.items;
        console.log(roleList);
        setRoleData([]);
        setRoleData(roleList);
      } else {
        if (roleData.errors) {
          if (roleData.errors.length > 0) {
            alert.show(roleData.errors[0].message);
          }
        }
      }
    } catch (error) {
      if (error.errors) {
        if (error.errors.length > 0) {
          alert.show(error.errors[0].message);
        }
      } else if (error.message) {
        alert.show(error.message, {
          onClose: () => {},
        });
      }
    }
  });

  const checkRolePermission = () => {
    const user = getLoggedInUser();
    // console.log(user);
    // console.log(roleData);
    setloggedinUser(user);
  };

  useEffect(() => {
    getRoleList();
    checkRolePermission();
  }, []);

  return (
    <table className="table table-cust ">
      <thead>
        <tr>
          <th className="width40"> Name </th>
          <th className="width40"> Details </th>
          <th className="width20"> Actions </th>
        </tr>
      </thead>
      <tbody>
        {props.deptData.length > 0 &&
          props.deptData.map((dept) => (
            <tr key={dept.id}>
              <td className="width40">{dept.name}</td>
              <td className="width40">{dept.details}</td>
              <td className="width20">
                <div className="button-group">
                  {props.isDeletePermission && (
                    <button
                      type="button"
                      className="btn-grid fa fa-trash"
                      onClick={() => {
                        props.actiondelete(dept, dept.id);
                      }}
                    ></button>
                  )}
                  {props.isUpdatePermission && (
                    <span>
                      {loggedinUser?.role?.name == Roles.DEPT_ADMIN &&
                        loggedinUser?.department?.items?.filter((userdept) => {
                          return userdept.cFRDepartmentID === dept.id;
                        }).length > 0 && (
                          <button
                            type="button"
                            className="btn-grid fa fa-pencil"
                            onClick={() => {
                              props.actionUpdate(dept, dept.id);
                            }}
                          ></button>
                        )}
                      {loggedinUser?.role?.name != Roles.DEPT_ADMIN && (
                        <button
                          type="button"
                          className="btn-grid fa fa-pencil"
                          onClick={() => {
                            props.actionUpdate(dept, dept.id);
                          }}
                        ></button>
                      )}
                    </span>
                  )}
                  <button
                    type="button"
                    className="btn-grid fa fa-file-text-o"
                    onClick={() => {
                      props.actionView(dept, dept.id);
                    }}
                  ></button>
                </div>
                {props.isUpdatePermission && (
                  <span>
                    {loggedinUser?.role?.name == Roles.DEPT_ADMIN &&
                      loggedinUser?.department?.items?.filter((userdept) => {
                        return userdept.cFRDepartmentID === dept.id;
                      }).length > 0 && (
                        <div title="Global User">
                          <Toggle
                            checked={dept.isForGlobalUser}
                            icons={false}
                            onChange={() => props.actionToggle(dept)}
                          />
                        </div>
                      )}
                    {loggedinUser?.role?.name != Roles.DEPT_ADMIN && (
                      <div title="Global User">
                        <Toggle
                          checked={dept.isForGlobalUser}
                          icons={false}
                          onChange={() => props.actionToggle(dept)}
                        />
                      </div>
                    )}
                  </span>
                )}
              </td>
            </tr>
          ))}
        {props.deptData.length == 0 && (
          <tr>
            <td colSpan="7" align="center">
              No record Found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
export default DepartmentList;
